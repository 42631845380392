import React, { useEffect, memo } from 'react'
import { styled, useTheme } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Form } from '@forms'

import { ModalScrollContainer } from '../ModalScrollContainer'
import { ModalBody, ModalSection, ModalHeader, ModalHeadline, ModalSubHeadline, ModalFooter } from '..'

import { useModalContext, useModalView, useModalViewProperties } from '../..'
import { useSlots, useSlotsAsArray } from 'lib/hooks/useSlots'

import { getThemeValue } from 'theming'

const LayoutOuterWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['width', 'height', 'size', 'isHidden', 'withForm'].includes(prop),
})`
  display: ${(props) => (props.isHidden ? 'none' : 'block')};
  width: 100%;
  height: 100%;
  max-height: ${themeGet('modal.dialog.space.maxHeight', '100vh')};
  overflow-y: hidden;
`

const LayoutInnerWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['width', 'height', 'size', 'isHidden', 'withForm'].includes(prop),
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const Headline = memo(({ ...props }) => {
  return (
    <ModalHeadline marginBottom={props.marginBottom}>
      {props.children.props.text || props.children}
    </ModalHeadline>
  )
})

const SubHeadline = memo(({ ...props }) => {
  return <ModalSubHeadline>{props.children.props.text || props.children}</ModalSubHeadline>
})

const ModalBodyWrapper = ({ children, show, name, ...props }) => {
  const { currentView } = useModalView(name, children)
  const [headline, subHeadline] = useSlots(['headline', 'subHeadline'], currentView)
  const [sections] = useSlotsAsArray(['sections'], currentView)

  return (
    <ModalBody scrollable={props.scrollable} name={name}>
      {show && (
        <>
          {subHeadline && headline ? (
            <Headline marginBottom="8px">{headline}</Headline>
          ) : headline ? (
            <Headline>{headline}</Headline>
          ) : null}
          {subHeadline && <SubHeadline>{subHeadline}</SubHeadline>}
          {sections.map((section, index) => (
            <ModalSection key={index} scrollable={props.scrollable} expand={section.props.expand}>
              {React.cloneElement(section, {
                ...section.props,
              })}
            </ModalSection>
          ))}
        </>
      )}
    </ModalBody>
  )
}

const FormComponent = ({ formHandler, children }) => {
  const { onSubmit, onChange } = { ...{ formHandler: {} }, ...formHandler }

  return (
    <Form onSubmit={onSubmit} onChange={onChange}>
      {children}
    </Form>
  )
}

const NoForm = ({ children }) => children

const LayoutWrapperComponent = ({
  children,
  name,
  alignHeaderPaddingRight,
  footer,
  scrollContainerPaddingRight,
  title,
  isHidden,
  initialAnimationRunning,
  withForm,
  formHandler,
  ...props
}) => {
  const Form = withForm ? FormComponent : NoForm
  return (
    <LayoutOuterWrapper isHidden={isHidden}>
      <Form formHandler={formHandler}>
        <LayoutInnerWrapper>
          <ModalHeader
            alignHeaderPaddingRight={alignHeaderPaddingRight}
            onClickClose={props.onClickClose}
            title={title}
          />
          {props.scrollable ? (
            <ModalBodyWrapperWithScrollbar
              name={name}
              show={!initialAnimationRunning}
              scrollContainerPaddingRight={scrollContainerPaddingRight}
              {...props}
            >
              {children}
            </ModalBodyWrapperWithScrollbar>
          ) : (
            <ModalBodyWrapper name={name} show={!initialAnimationRunning} {...props}>
              {children}
            </ModalBodyWrapper>
          )}
          {footer && <ModalFooter>{footer}</ModalFooter>}
        </LayoutInnerWrapper>
      </Form>
    </LayoutOuterWrapper>
  )
}

const withScrollBar =
  (Component) =>
  ({ ...props }) => {
    return (
      <ModalScrollContainer containerPaddingRight={props.scrollContainerPaddingRight}>
        <Component {...props} />
      </ModalScrollContainer>
    )
  }

const ModalBodyWrapperWithScrollbar = withScrollBar(ModalBodyWrapper)

export const ModalLayout = ({ children, name, ...props }) => {
  const theme = useTheme()
  const {
    state: { activeModal, initialAnimationRunning },
    dispatch,
  } = useModalContext()

  const isHidden = name !== activeModal

  const { currentView } = useModalView(name, children)

  const { currentViewProps } = useModalViewProperties(children)
  const { size, withForm, formHandler } = currentViewProps

  useEffect(() => {
    if (name === activeModal) {
      dispatch({ type: 'setSize', payload: size })
    }
  }, [dispatch, name, activeModal, size])

  const [title, footer] = useSlots(['title', 'footer'], currentView)

  const scrollContainerPaddingRight = getThemeValue(theme, 'modal.body.space.scrollbar')
  const alignHeaderPaddingRight = props.scrollable ? getThemeValue(theme, 'modal.body.space.scrollbar') : null

  const layoutWrapperProps = {
    name,
    alignHeaderPaddingRight,
    title,
    footer,
    scrollContainerPaddingRight,
    isHidden,
    initialAnimationRunning,
    withForm,
    formHandler,
    ...props,
  }

  if (!currentView) {
    return null
  } else {
    return <LayoutWrapperComponent {...layoutWrapperProps}>{children}</LayoutWrapperComponent>
  }
}

ModalLayout.displayName = 'modalHeader'
