import React, { useCallback, useState } from 'react'
import { FlexColumn } from '@layout/BuildingBlocks'
import { DownloadList, QuotaInfoList, UserData } from './components'
// import { UserLicense } from './components/UserLicense'
import { useTheme } from 'styled-components'
import { getThemeValue } from 'theming'
import { RoyalSemiBold } from '@typography'
import { useTranslation } from 'react-i18next'
import { useAuthzChecks } from 'lib/hooks'

export const UserProfile = () => {
  const { t } = useTranslation()
  const { hasLicence } = useAuthzChecks()

  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')
  const horizontalSpaces = spaces.rhythm.horizontal
  const verticalSpaces = spaces.rhythm.vertical
  const [foldedSections, setFoldedSections] = useState({
    userData: true,
    quotaInfoList: true,
    downloadList: false,
  })

  const toggleFoldedSection = useCallback((section) => {
    setFoldedSections((prevState) => {
      const newState = { ...prevState }
      newState[section] = !newState[section]

      return newState
    })
  }, [])

  return (
    <FlexColumn
      width="100%"
      px={[horizontalSpaces.large, null, null, horizontalSpaces.extraLarge]}
      py={[verticalSpaces.large, null, null, verticalSpaces.extraLarge]}
      flexRowGap={spaces.rhythm.vertical.medium}
    >
      <RoyalSemiBold mb={spaces.rhythm.vertical.large}>{t('userprofile.title')}</RoyalSemiBold>
      <UserData
        sectionName="userData"
        folded={foldedSections.userData}
        toggleFoldedSection={toggleFoldedSection}
      />
      {/* <UserLicense /> */}
      {hasLicence('reportsUseQuota') && (
        <QuotaInfoList
          sectionName="quotaInfoList"
          folded={foldedSections.quotaInfoList}
          toggleFoldedSection={toggleFoldedSection}
        />
      )}
      <DownloadList
        sectionName="downloadList"
        folded={foldedSections.downloadList}
        toggleFoldedSection={toggleFoldedSection}
        foldedSections={foldedSections}
      />
    </FlexColumn>
  )
}
