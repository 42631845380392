import React, { forwardRef } from 'react'
import { styled } from 'styled-components'
import { typography } from 'styled-system'
import { bool, string } from 'prop-types'

import { HTMLButtonElement } from '../SubComponents'

import { appearance, shape, size } from './variants'
import { typeMap, typeScale, typeStyle } from '@typography'

const ButtonElement = styled(HTMLButtonElement)`
  align-self: center;
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: ${(props) => props.opacity || 1};
  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  ${appearance}
  ${shape}
  ${(props) => props.shape !== 'shapeless' && size}
  ${(props) => props.width && { width: props.width }}
  ${(props) => props.height && { height: props.height }}
  ${(props) => props.stretch && { width: '100%' }}
  ${(props) => props.leftAlign && { justifyContent: 'flex-start' }}

  &:focus {
    text-decoration: underline;
  }
`

const fontSizeDefault = [0, 1, 2, 3, 4]

export const Button = forwardRef(
  (
    {
      children,
      fontFamily = 'whitneySSm',
      fontSize = fontSizeDefault,
      lineHeight = 'normal',
      appearance = 'lightgray',
      leftAlign = null,
      size = 'medium',
      shape = 'oval',
      stretch = null,
      ...props
    },
    ref
  ) => {
    props = { ...props, fontFamily, fontSize, lineHeight, appearance, leftAlign, size, shape, stretch }
    const { typeStyle, typeScale } = typeMap[props.size]

    return <ButtonElement typeScale={typeScale} typeStyle={typeStyle} ref={ref} {...props} />
  }
)
Button.propTypes = {
  appearance: string,
  leftAlign: bool,
  shape: string,
  size: string,
  text: string,
  stretch: bool,
}
