import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { styled } from 'styled-components'

import { ButtonIcon } from '@ui/Buttons'
import { BudiconCrossUi as IconReset } from 'bgag-budicons'

import { func } from 'prop-types'
import { ControlsSegmentStyledComponent, ContainerStyledComponent } from '@ui/ControlsSegment/SubComponents'
import { InnerPanelStyledComponent } from '@layout/Layout/SubComponents/ControlsPanel'

const ResetButtonStyledComponent = styled.div`
  opacity: ${({ alwaysVisible }) => (alwaysVisible ? 1 : 0)};
  transition: all 0.2s linear;
  // $ {ControlsSegmentStyledComponent}:not(:has($ {ControlsSegmentStyledComponent})):hover &,
  // replace the :has selector in order to apply the effect for browser versions that not support :has. (expl.: firefox <= 120)
  ${ControlsSegmentStyledComponent} .toggle-reset-btn:hover &,
  ${(props) =>
    props.globalHover ? InnerPanelStyledComponent : ContainerStyledComponent}:hover &:first-of-type {
    opacity: 1;
  }
`

export const ControlsReset = ({ onClick, tooltip, globalHover, name = '', alwaysVisible, ...props }) => {
  const elementRef = useRef()

  useLayoutEffect(() => {
    const parentContainer = elementRef.current?.parentNode
    if (parentContainer) {
      parentContainer.classList.add('toggle-reset-btn')
    }
  }, [globalHover])

  const handleClick = useCallback(() => {
    if (typeof onClick === 'function') {
      onClick(name)
    }
  }, [onClick, name])

  return (
    <ResetButtonStyledComponent globalHover={globalHover} ref={elementRef} alwaysVisible={alwaysVisible}>
      <ButtonIcon
        appearance="bare"
        icon={{ Icon: IconReset, strokeWidth: '0.3', type: 'outline' }}
        iconPosition={{
          marginTop: globalHover
            ? ['0px', '-1.5px', '-1.5px', '-0.5px', '0px']
            : ['-5px', '-5.5px', '-5.5px', null, '-3px'],
        }}
        onClick={handleClick}
        shape="shapeless"
        iconVariantSize="large"
        name={name}
        {...props}
      />
    </ResetButtonStyledComponent>
  )
}
ControlsReset.propTypes = {
  onClick: func,
}
