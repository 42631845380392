import React, { memo } from 'react'
import { styled, useTheme } from 'styled-components'
import { layout, space, typography } from 'styled-system'

import { appearance } from './variants'

import { LightBulb } from '@utilities/SVG/LightBulb'
import { ExclamationMark } from '@utilities/SVG/ExclamationMark'
import { InfoBubble } from '@utilities/SVG/InfoBubble'
import { Checkmark } from '@utilities/SVG/Checkmark'
import { FlexItem } from '@layout/BuildingBlocks'
import { PicaMedium as Type } from '@typography'

import { themeGet } from '@styled-system/theme-get'
import { array, node, oneOfType, string } from 'prop-types'
import { getThemeValue } from 'theming'

const MessageText = styled(Type)`
  display: flex;
  ${typography}
  ${appearance}
`

const MessageBox = styled.div.withConfig({
  shouldForwardProp: (props) => ['style', 'children'].includes(props),
})`
  align-items: center;

  background: ${(props) => themeGet(props.color, 'black')(props)};
  border-radius: 4px;
  border: 1px solid;
  display: flex;
  flex-direction: row;
  flex: auto;
  width: 100%;
  overflow: hidden;

  ${layout}
  ${space}
  ${appearance}
`

const MessageIcon = memo(({ type, ...props }) => {
  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')

  const Wrapper = ({ children, ...props }) => (
    <FlexItem height="100%" alignItems="center" px={spaces.rhythm.horizontal.medium} {...props}>
      {children}
    </FlexItem>
  )

  switch (type) {
    case 'info':
      return (
        <Wrapper>
          <InfoBubble />
        </Wrapper>
      )
    case 'error':
      return (
        <Wrapper pb="3px">
          <ExclamationMark {...props} strokeWidth="0.3" />
        </Wrapper>
      )
    case 'success':
      return (
        <Wrapper>
          <Checkmark {...props} width={['16px', null, null, '18px']} />
        </Wrapper>
      )
    case 'warning':
      return (
        <Wrapper pb="3px">
          <LightBulb {...props} />
        </Wrapper>
      )
    default:
      return (
        <Wrapper>
          <InfoBubble {...props} />
        </Wrapper>
      )
  }
})

const size = ['20px', null, null, '24px']

export const SystemMessage = ({
  children = 'message...',
  iconWidth = size,
  iconHeight = size,
  type = 'success',
  ...props
}) => {
  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')

  return (
    <MessageBox type={type} {...props}>
      <FlexItem className="MessageIconBox" alignSelf="stretch" justifyContent="center" maxWidth="36px">
        <MessageIcon width={iconWidth} height={iconHeight} type={type} />
      </FlexItem>
      <MessageText
        tag="div"
        lineHeight="1.5"
        px={spaces.rhythm.horizontal.medium}
        py={spaces.rhythm.vertical.small}
        type={type}
      >
        {children}
      </MessageText>
    </MessageBox>
  )
}
SystemMessage.propTypes = {
  children: oneOfType([string, node]),
  iconWidth: oneOfType([string, array]),
  iconHeight: oneOfType([string, array]),
  type: string,
}
