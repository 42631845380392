import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import api from 'stores/api'
import { Modal, useModalSwitchView } from '@ui/Modal'
import { Button } from '@ui/Buttons'
import { FlexColumn, FlexContainer, FlexItem, FlexRow } from '@layout/BuildingBlocks'
import { useTheme } from 'styled-components'
import { getThemeValue } from 'theming'
import { ControlsSegmentSection } from '@ui/ControlsSegment/SubComponents'
import { Input, Radio, RadioGroup, Textarea } from '@forms'

import { PriceTable, priceList } from 'pages/Download/Profile/PriceTable'
import { useAuthStore } from 'stores/AuthStore'
import { useNumbersFormats } from 'config/numbersFormats'
import { Copy, GreatPrimer } from '@typography'
import { withMaxLengthIndicator } from '@forms/Wrapper'

const ButtonRow = ({ children }) => {
  const theme = useTheme()
  const gap = getThemeValue(theme, 'spaces.rhythm.horizontal.medium')
  return (
    <FlexRow justifyContent="flex-end" flexColumnGap={gap} width="100%">
      {children}
    </FlexRow>
  )
}

const ModalViewSwitch = ({ target = 0, text = '', appearance, onClick, onError, disabled = false }) => {
  const { toggleView } = useModalSwitchView()
  const onButtonClick = useCallback(() => {
    if (typeof onClick === 'function') {
      Promise.resolve(onClick())
        .then((result) => {
          toggleView(target)
        })
        .catch((error) => onError(error))
    } else {
      toggleView(target)
    }
  }, [toggleView, target, onClick, onError])
  return (
    <Button text={text} shape="oval" appearance={appearance} onClick={onButtonClick} disabled={disabled} />
  )
}

export const OrderModal = ({ children }) => {
  const { t } = useTranslation()
  const numbersFormats = useNumbersFormats()
  const [{ currentUser }] = useAuthStore()
  const [userGroups, setUserGroups] = useState([])
  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')
  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState({
    packet: null,
    price: null,
  })
  const [billingInformation, setBillingInformation] = useState(() => {
    return {
      firstname: currentUser?.data?.firstname || '',
      lastname: currentUser?.data?.name || '',
      email: currentUser?.data?.email || '',
      phone: currentUser?.data?.phone || '',
      address: currentUser?.data?.street || '',
      zipCode: currentUser?.data?.zip || '',
      city: currentUser?.data?.city || '',
      country: currentUser?.data?.country || '',
      company: currentUser?.data?.company || '',
      message: '',
      groupId: null,
    }
  })
  const [error, setError] = useState({})
  const setIsActiveAndEmptyItems = useCallback((isActive) => {
    if (isActive === false) {
      setSelectedItem({ packet: null, price: null })
      setError({})
    }
    setIsActive(isActive)
  }, [])
  const onInputChange = useCallback(({ value, name }) => {
    setBillingInformation((prevState) => {
      const newState = { ...prevState }
      newState[name] = value
      return newState
    })
  }, [])
  const onChange = useCallback(
    ({ value }) => {
      setSelectedItem({
        packet: value,
        price: value ? numbersFormats.roundedFraction2.format(priceList[value]) : '-',
      })
    },
    [numbersFormats]
  )

  const onRequestClick = useCallback(async () => {
    setError({})
    setIsLoading(true)
    return await api.Requests.contingentOrder({
      ...billingInformation,
      selectedPacket: selectedItem.packet,
      price: selectedItem.price,
    }).then((result) => {
      setIsLoading(false)
      return result
    })
  }, [billingInformation, selectedItem])

  const handleError = useCallback((errorResponse) => {
    const error = errorResponse.response.data.messages.reduce((obj, message) => {
      obj[message.param] = `orderForm.error.${message.msg}`
      return obj
    }, {})
    setError(error)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!userGroups.length && isActive) {
      api.Auth.userGroups()
        .then((res) => {
          const groups = res.data.data
            .filter((group) => group.id !== 0 && !group.isSystem && group.listUsers === true)
            .map((group) => ({ label: group.name, value: group.id }))

          setUserGroups(groups)
          if (groups?.length === 1) {
            setBillingInformation((prev) => {
              return { ...prev, groupId: groups[0].value }
            })
          }
        })
        .catch((res) => {
          console.warn(res.response.data.message)
        })
    }
  }, [userGroups, isActive])

  return (
    <Modal active={isActive} onChangeActive={setIsActiveAndEmptyItems} scrollable={false}>
      <Modal.Trigger>{children}</Modal.Trigger>
      <Modal.View size="priceTableModal.default">
        <Modal.Title>{t('orderForm.modal.priceTable.title')}</Modal.Title>
        <Modal.Section>
          <Copy mt={spaces.rhythm.vertical.small} mb={spaces.rhythm.vertical.medium}>
            {t('orderForm.modal.priceTable.text')}
          </Copy>
          <PriceTable onChange={onChange} initialSelected={selectedItem.packet} />
        </Modal.Section>
        <Modal.Footer>
          <ButtonRow>
            <ModalViewSwitch
              target="1"
              text={t('orderForm.modal.button.next')}
              appearance={'primary'}
              disabled={!selectedItem.packet}
            />
          </ButtonRow>
        </Modal.Footer>
      </Modal.View>
      <Modal.View size="priceTableModalBillingAddress.default">
        <Modal.Title>{t('orderForm.modal.billingAddress.title')}</Modal.Title>
        <Modal.Section>
          <Copy mt={spaces.rhythm.vertical.small} mb={spaces.rhythm.vertical.large}>
            {t('orderForm.modal.billingAddress.text')}
          </Copy>
          <FlexContainer
            flexWrap="wrap"
            flexColumnGap={spaces.rhythm.horizontal.small}
            flexRowGap={spaces.rhythm.vertical.medium}
          >
            <FrmInput type="other" fld="groupId" columns={1} data={billingInformation} error={error}>
              <RadioGroup name="groupId" onChange={onInputChange} value={billingInformation.groupId}>
                <FlexRow
                  flexWrap={'wrap'}
                  flexColumnGap={spaces.rhythm.horizontal.medium}
                  flexRowGap={spaces.rhythm.vertical.small}
                >
                  {userGroups.map((group) => (
                    <Radio
                      key={group.value}
                      label={group.label}
                      value={group.value}
                      forElementName="groupId"
                    />
                  ))}
                </FlexRow>
              </RadioGroup>
            </FrmInput>
            <FrmInput
              fld="company"
              columns={1}
              onChange={onInputChange}
              data={billingInformation}
              error={error}
            />
            <FrmInput
              fld="email"
              columns={2}
              onChange={onInputChange}
              data={billingInformation}
              error={error}
            />
            <FrmInput
              fld="phone"
              columns={2}
              onChange={onInputChange}
              data={billingInformation}
              error={error}
            />
            <FrmInput
              fld="firstname"
              columns={2}
              onChange={onInputChange}
              data={billingInformation}
              error={error}
            />
            <FrmInput
              fld="lastname"
              columns={2}
              onChange={onInputChange}
              data={billingInformation}
              error={error}
            />
            <FrmInput
              fld="address"
              columns={1}
              onChange={onInputChange}
              data={billingInformation}
              error={error}
            />
            <FrmInput
              fld="zipCode"
              columns={2}
              onChange={onInputChange}
              data={billingInformation}
              error={error}
            />
            <FrmInput
              fld="city"
              columns={2}
              onChange={onInputChange}
              data={billingInformation}
              error={error}
            />
            <FrmInput
              fld="country"
              columns={2}
              onChange={onInputChange}
              data={billingInformation}
              error={error}
            />
            <FrmInput
              fld="message"
              columns={1}
              onChange={onInputChange}
              data={billingInformation}
              error={error}
              type="textarea"
            />
          </FlexContainer>
        </Modal.Section>
        <Modal.Footer>
          <ButtonRow>
            <ModalViewSwitch target="0" text={t('orderForm.modal.button.back')} appearance={'white'} />
            <ModalViewSwitch
              target="2"
              text={t('orderForm.modal.button.send')}
              appearance={'primary'}
              onClick={onRequestClick}
              onError={handleError}
              disabled={isLoading}
            />
          </ButtonRow>
        </Modal.Footer>
      </Modal.View>
      <Modal.View size="small.landscape.aspcr_16_9">
        <Modal.Title></Modal.Title>
        <Modal.Section>
          <FlexColumn height="100%" justifyContent="center" alignItems="center">
            <GreatPrimer>{t('orderForm.success.confirmation.title')}</GreatPrimer>
            <GreatPrimer>{t('orderForm.success.confirmation.subtitle')}</GreatPrimer>
          </FlexColumn>
        </Modal.Section>
        <Modal.Footer>
          <ButtonRow>
            <ModalViewSwitch
              target="2"
              text={t('orderForm.modal.button.close')}
              appearance={'primary'}
              onClick={() => setIsActiveAndEmptyItems(false)}
            />
          </ButtonRow>
        </Modal.Footer>
      </Modal.View>
    </Modal>
  )
}

const TextAreaWithMaxLengthIndicator = withMaxLengthIndicator(Textarea)

const FrmInput = ({ fld, columns, data, error, type = 'input', children, ...rest }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const flds = fld.split('.')

  let value = data
  flds.forEach((curFld) => {
    value = value[curFld]
  })
  if (value === null || typeof value === 'undefined') {
    value = ''
  }

  return (
    <FlexItem
      flexDirection="column"
      flex="1 0 100px"
      minWidth={columns ? Math.round(90 / columns) + '%' : '90%'}
      width={columns ? Math.round(100 / columns) + '%' : '100%'}
    >
      <ControlsSegmentSection>
        <ControlsSegmentSection.SubHeadline>
          {type === 'other' && error[fld] ? (
            <span style={{ color: theme.colors.base.error.default }}>
              {t(error[fld], { value: t(`orderForm.${fld}`) })}
            </span>
          ) : (
            t('orderForm.' + fld)
          )}
        </ControlsSegmentSection.SubHeadline>
        <ControlsSegmentSection.Content>
          {type === 'textarea' ? (
            <TextAreaWithMaxLengthIndicator
              id={`orderForm.${fld}`}
              name={fld}
              appearance="white"
              maxLength="200"
              rows="10"
              stretch={true}
              value={value}
              {...rest}
            />
          ) : type === 'other' ? (
            children
          ) : (
            <Input
              id={`orderForm.${fld}`}
              name={fld}
              placeholder={t('orderForm.' + fld + 'Placeholder')}
              value={value}
              size="small"
              appearance="white"
              shape="square"
              error={error[fld] ? t(error[fld], { value: t(`orderForm.${fld}`) }) : null}
              {...rest}
            />
          )}
        </ControlsSegmentSection.Content>
      </ControlsSegmentSection>
    </FlexItem>
  )
}
