import createStore from 'lib/flux-store'
import api from 'stores/api'
import functionQueue from 'lib/util/functionQueue'

const initialState = {
  isLoading: true,
  dateType: 'currentMonth', //last month, current month, last 30 days, custom
  dateFrom: '',
  dateTo: '',
  groups: [],
  locationStats: {
    filter: {
      columns: null,
      groupBy: 'group',
      gacs: [],
      tableColumns: [],
    },
    sort: { key: null, dir: null },
  },
  navigationStats: {
    filter: {
      routes: null,
      tableColumns: [],
    },
    sort: { key: null, dir: null },
  },
  exportStats: {
    filter: {
      columns: null,
      tableColumns: [],
    },
    sort: { key: null, dir: null },
  },
  exportOptions: {
    time: { from: null, to: null },
    filetype: 'xls',
  },
}

const __NAME__ = 'StatisticsStateStore'

const ignoreKeysOnUpdate = {
  isLoading: true,
  isFailure: true,
  messages: true,
}

const queuedSave = functionQueue(api.AppState.save)
let lastSavedState = initialState

const callbacks = {
  onUpdate: (state) => {
    const changed = Object.keys(state).reduce((changed, key) => {
      return changed || (ignoreKeysOnUpdate[key] ? false : !Object.is(lastSavedState[key], state[key]))
    }, false)

    if (changed) {
      lastSavedState = { ...state }
      const saveState = {}
      Object.keys(lastSavedState).forEach((key) => {
        if (!ignoreKeysOnUpdate[key]) {
          saveState[key] = lastSavedState[key]
        }
      })

      return queuedSave(__NAME__, saveState)
    } else {
      return Promise.resolve()
    }
  },
}

const actions = {
  loadState: () => (dispatch) => {
    api.AppState.get(__NAME__)
      .then((response) => {
        const state = response.data.data
        dispatch({ type: 'setLoadedState', payload: state })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
  reset: () => ({}),
  setDateFilter: (filter) => ({ filter }),
  setGroupsFilter: (filter) => ({ filter }),
  setFilterLocationStats: (filter) => ({ filter }),
  setFilterNavigationStats: (filter) => ({ filter }),
  setFilterExportStats: (filter) => ({ filter }),
  setSortLocationStats: (sort) => ({ sort }),
  setSortNavigationStats: (sort) => ({ sort }),
  setSortExportStats: (sort) => ({ sort }),
  setExportOptions: (target) => ({ target }),
}

const reducer = {
  setLoadedState: (state, { payload }) => {
    const set = Object.keys(initialState).reduce((set, key) => {
      if (typeof payload[key] !== 'undefined') {
        set[key] = payload[key]
      }

      if (Array.isArray(initialState[key])) {
        if (!Array.isArray(set[key])) {
          set[key] = [...initialState[key]]
        }
      } else if (typeof set[key] === 'object' && typeof initialState[key] === 'object') {
        set[key] = { ...initialState[key], ...set[key] }
      } else if (typeof set[key] === 'undefined') {
        if (Array.isArray(initialState[key])) {
          set[key] = [...initialState[key]]
        } else if (typeof initialState[key] === 'object') {
          set[key] = { ...initialState[key] }
        } else {
          set[key] = initialState[key]
        }
      }
      return set
    }, {})

    lastSavedState = { ...state, ...set, isLoading: false }
    return lastSavedState
  },
  reset: (state) => {
    return { ...initialState }
  },
  setDateFilter: (state, { filter }) => {
    if (!(filter?.dateFrom || filter?.dateTo || filter?.dateType)) {
      return state
    }

    return {
      ...state,
      dateType: filter?.dateType ?? state.dateType,
      dateTo: filter?.dateTo ?? state.dateTo,
      dateFrom: filter?.dateFrom ?? state.dateFrom,
    }
  },
  setGroupsFilter: (state, { filter }) => {
    if (!filter?.groups) {
      return state
    }

    return {
      ...state,
      groups: filter?.groups,
    }
  },
  setFilterNavigationStats: (state, { filter }) => {
    return {
      ...state,
      navigationStats: { filter: { ...state.navigationStats.filter, ...filter } },
    }
  },
  setFilterLocationStats: (state, { filter }) => {
    return {
      ...state,
      locationStats: { filter: { ...state.locationStats.filter, ...filter } },
    }
  },
  setFilterExportStats: (state, { filter }) => {
    return {
      ...state,
      exportStats: { filter: { ...state.exportStats.filter, ...filter } },
    }
  },
  setSortNavigationStats: (state, { sort }) => {
    if (sort.key === state.navigationStats.sort.key && sort.dir === state.navigationStats.sort.dir) {
      return state
    }
    return { ...state, navigationStats: { sort: sort } }
  },

  setSortLocationStats: (state, { sort }) => {
    if (sort.key === state.locationStats.sort.key && sort.dir === state.locationStats.sort.dir) {
      return state
    }
    return { ...state, locationStats: { sort: sort } }
  },
  setSortExportStats: (state, { sort }) => {
    if (sort.key === state.exportStats.sort.key && sort.dir === state.exportStats.sort.dir) {
      return state
    }
    return { ...state, exportStats: { sort: sort } }
  },
  setExportOptions: (state, { target }) => ({
    ...state,
    exportOptions: { ...state.exportOptions, [target.name]: target.value },
  }),
}

export const [StatisticsStateContext, StatisticsStateProvider, useStatisticsStateStore] = createStore(
  reducer,
  actions,
  initialState,
  callbacks,
  __NAME__
)
