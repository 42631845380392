import * as variables from '../default/variables'

import * as uiElements from '../default/uiElements'

// uiElements
const accordion = uiElements.getAccordion(variables)
const card = uiElements.getCard(variables)
const badge = uiElements.getBadge(variables)
const buttonIcons = uiElements.getButtonIcons(variables)
const checkboxBar = uiElements.getCheckboxBar(variables)
const cityStructure = uiElements.getCityStructure(variables)
const controlsPanel = uiElements.getControlsPanel(variables)
const controlsSegment = uiElements.getControlsSegment(variables)
const datepicker = uiElements.getDatepicker(variables)
const dropdown = uiElements.getDropdown(variables)
const flex = uiElements.getFlex(variables)
const flyout = uiElements.getFlyout(variables)
const grid = uiElements.getGrid(variables)
const header = uiElements.getHeader(variables)
const landingPage = uiElements.getLandingPage(variables)
const informationPage = uiElements.getInformationPage(variables)
const layout = uiElements.getLayout(variables)
const modal = uiElements.getModal(variables)
const navigationMain = uiElements.getNavigationMain(variables)
const navigationMeta = uiElements.getNavigationMeta(variables)
const scrollbar = uiElements.getScrollbar(variables)
const switches = uiElements.getSwitches(variables)
const table = uiElements.getTable(variables)
const taglist = uiElements.getTaglist(variables)
const uiActionElements = uiElements.getUiActionElements(variables)

// Layout

const themeRiwis = {
  name: 'themeRiwis',
  breakpoints: variables.breakpoints,
  colors: {
    base: {
      ...variables.colors,
    },
    theme: {
      ...variables.themeColors,
    },
  },
  radii: variables.radii,
  shadow: variables.shadow,
  sizes: variables.sizes,
  spaces: variables.spaces,
  ...variables.typography,
  accordion,
  card,
  badge,
  buttonIcons,
  checkboxBar,
  cityStructure,
  controlsPanel,
  controlsSegment,
  datepicker,
  dropdown,
  ...flex,
  flyout,
  ...grid,
  header,
  layout,
  modal,
  landingPage,
  informationPage,
  navigationMain,
  navigationMeta,
  scrollbar,
  switches,
  table,
  taglist,
  uiActionElements,
}

export default themeRiwis
