import React, { useCallback } from 'react'

import { useLayoutContext } from '@layout/Layout'
import { OverlayRight } from '@layout/OverlayRight/OverlayRight'
import { ButtonIcon } from '@ui/Buttons'
import { OnClickOutside } from '@utilities/OnClickOutside'

import { UserProfile } from 'pages/UserProfile/UserProfile'

import { BudiconProfilePicture } from 'bgag-budicons'

export const UserProfileButton = ({ iconColor }) => {
  const {
    state: { overlayRightIsOpen },
    dispatch,
  } = useLayoutContext()

  const onHandleClick = useCallback(() => {
    dispatch({
      type: 'setOverlayRightIsOpen',
      payload: { isOpen: !overlayRightIsOpen, component: UserProfile },
    })
  }, [overlayRightIsOpen, dispatch])

  const toggleOverlay = useCallback(
    (event) => {
      if (
        overlayRightIsOpen &&
        !event.target.classList.values().some((value) => value.startsWith('ModalOverlay'))
      ) {
        dispatch({
          type: 'setOverlayRightIsOpen',
          payload: { isOpen: false },
        })
      }
    },
    [dispatch, overlayRightIsOpen]
  )

  return (
    <OnClickOutside handler={toggleOverlay}>
      <ButtonIcon
        appearance="bare"
        linkType="reactRouterDomLink"
        icon={{
          Icon: BudiconProfilePicture,
          type: 'solid',
          fill: iconColor,
          width: ['18px', null, '20px', null, '23px'],
        }}
        iconPosition={{ top: ['-12px', '-12px', '-11.5px', '-11.5px', '-13px'] }}
        onClick={onHandleClick}
      />
      <OverlayRight />
    </OnClickOutside>
  )
}
