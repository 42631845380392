import React from 'react'

import { StyledText } from './SubComponents'

import { defaultPropTypes as textPropTypes } from '@typography'

export const DefinitionText = ({ children, ...props }) => {
  return <StyledText {...props}>{children}</StyledText>
}
DefinitionText.propTypes = {
  ...textPropTypes,
}
