import React from 'react'
import { styled, css } from 'styled-components'
import { color, typography, compose } from 'styled-system'

import { typeScale, typeStyle } from '@typography'

const stylesBreakWord = css`
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
`

const Text = ({
  // see https://styled-components.com/docs/faqs#transient-props-since-5.1
  // alternative to shouldForwardProp...might replace shouldForwardProp in App.js!
  breakWord,
  color,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  lineHeight,
  scale,
  tag,
  typeScale,
  typeStyle,
  meta,
  text,
  textAlign,
  textTransform,
  children,
  ...props
}) => {
  return <span {...props}>{children}</span>
}

export const StyledText = styled(Text)`
  ${compose(color, typography)};
  ${(props) => typeScale(props.typeScale)};
  ${(props) => typeStyle(props.typeStyle)};
  ${(props) => props.breakWord && stylesBreakWord};
  height: ${(props) => `${props.height}` || 'auto'};
  width: 100%;
  text-transform: ${(props) => props.textTransform};
`
