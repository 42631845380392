import React, { useEffect, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router'
import { StyleSheetManager, ThemeProvider } from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'

import { ImpersonateFrame } from 'pages/Admin/ImpersonateUser'
import { VersionCheck } from 'components/VersionCheck'
import { Notifications } from '@ui/Notifications'

import { Layout, LayoutContextProvider } from '@layout/Layout'
import { Header } from '@layout/Header'
import { NavigationMeta } from '@ui/NavigationMeta'
import { ModalContextProvider, ModalContainer } from '@ui/Modal'

import { AppStoreProvider, useAppStore } from 'stores/AppStore'
import { LayoutProvider } from 'stores/LayoutStore'
import { AdminStoreProvider } from 'stores/AdminStore'
import { MapMultiStoreProvider } from 'stores/MapMultiStore'
import { AuthStoreProvider, useAuthStore } from 'stores/AuthStore'
import { NotificationsProvider } from 'stores/Notifications'
import { CityStructureProvider } from 'pages/MarketAnalysis/CityStructure/store'
import { ObjectStateProvider } from 'pages/Objects/stateStore'
import { ObjectDataStoreProvider } from 'pages/Objects/dataStore'
import { GlobalObjectStoreProvider } from 'pages/GlobalObjects/ObjectStore'
import { MarketTextStateProvider } from 'pages/MarketAnalysis/MarketText/stateStore'
import { MarketTextDataProvider } from 'pages/MarketAnalysis/MarketText/dataStore'
import { MarketDataDataProvider } from 'pages/MarketAnalysis/marketData/dataStore'
import { ScoringStateProvider } from 'pages/Scoring/stateStore'
import { ScoringDataProvider } from 'pages/Scoring/dataStore'
import { L3PlusDataProvider } from 'pages/L3Plus/dataStore'
import { HighstreetReportDataProvider } from 'pages/HighstreetReport/dataStore'
import { ThematicMapsProvider } from 'pages/Makro/ThematicMaps/ThematicMapsStore'
import { IndexProvider } from 'pages/Makro/PropertyIndices/IndexStore'
import { HedonicRentsDataProvider } from 'pages/Hedonic/Rents/dataStore'
import { HedonicHouseDataProvider } from 'pages/Hedonic/House/dataStore'
import { HedonicOfficeDataProvider } from 'pages/Hedonic/Office/dataStore'
import { HedonicIndustryDataProvider } from 'pages/Hedonic/Industry/dataStore'
import { DownloadDocumentsDataProvider } from 'pages/Download/Documents/dataStore'
import { RawDataDataProvider } from 'pages/Download/RawData/dataStore'

import { CTLoginModule, L3PlusLoginModule, LoginModule, LogoutModule } from 'pages/public/Login'
import {
  RegisterTestAccount,
  RegisterTestAccountDeveloper,
  RegisterTestAccountL3Plus,
} from 'pages/public/Registration'
import { SetPassword, SetPasswordL3Plus } from 'pages/public/SetPassword'
import { ForgotPassword, ForgotPasswordL3Plus } from 'pages/public/ForgotPassword'

import ErrorBoundary from 'components/ErrorBoundary'
import { MarketDataStateProvider } from 'pages/MarketAnalysis/marketData/stateStore'
import { GlossarDataProvider } from 'pages/Information/Glossary/dataStore'

import { StatisticsStateProvider } from 'pages/Statistics/stateStore'
import { StatisticsDataStoreProvider } from 'pages/Statistics/dataStore'

import { NotFound, NotFoundInApp } from 'pages/Error'
import { PrivateAccessGuard, PublicAccessGuard } from 'lib/route-guards'
import { PrivateAccessSuspenseGuard } from 'lib/route-guards/PrivateAccessGuard'
import { GeotoolsZoneStoreProvider } from 'stores/GeoTools/GeoToolsZonesStore'
import { GeotoolsDraftsProvider } from 'stores/GeoTools/GeoToolsDraftsStore'
import { GeoToolsEditZoneStoreProvider } from 'stores/GeoTools/GeoToolsEditZoneStore'
import { ControlsPanelContextProvider } from '@layout/Layout/context/ControlsPanelContext'
import { NavigationStatistics } from 'core/Components/NavigationStatistics'

function lazyWithPreload(factory) {
  const Component = React.lazy(factory)
  setTimeout(() => requestIdleCallback(() => factory()), 3000)
  return Component
}

const LandingPageHome = lazyWithPreload(() => import('pages/Home'))
const LandingPageApps = lazyWithPreload(() => import('pages/Apps'))
const SingleSignOn = lazyWithPreload(() => import('pages/public/SingleSignOn'))
const AdminModule = lazyWithPreload(() => import('pages/Admin'))
const SysAdminModule = lazyWithPreload(() => import('pages/SystemAdmin'))
const ExportModule = lazyWithPreload(() => import('pages/export'))
const MarketAnalysis = lazyWithPreload(() => import('pages/MarketAnalysis'))
const GlobalObjects = lazyWithPreload(() => import('pages/GlobalObjects'))
const Makro = lazyWithPreload(() => import('pages/Makro'))
const Disco = lazyWithPreload(() => import('pages/Disco'))
const Scoring = lazyWithPreload(() => import('pages/Scoring'))
const L3Plus = lazyWithPreload(() => import('pages/L3Plus'))
const Hedonic = lazyWithPreload(() => import('pages/Hedonic'))
const Download = lazyWithPreload(() => import('pages/Download'))
const RetailMarkets = lazyWithPreload(() => import('pages/RetailMarkets'))
const HighstreetReport = lazyWithPreload(() => import('pages/HighstreetReport'))
const Developer = lazyWithPreload(() => import('pages/Developer'))
const Information = lazyWithPreload(() => import('pages/Information'))
const Statistics = lazyWithPreload(() => import('pages/Statistics'))

const SyncedThemeProvider = ({ children }) => {
  const [{ currentTheme }] = useAppStore()
  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
}
const shouldForwardProp = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName)
  }
  // For other elements, forward all props
  return true
}
const StyleSheetManagerProvider = ({ children }) => {
  return (
    <StyleSheetManager enableVendorPrefixes shouldForwardProp={shouldForwardProp}>
      {children}
    </StyleSheetManager>
  )
}

// BlockRender is a component wrapped in memo to prevent the whole subtree of a Provider to be rerendered on every state-change
// memo's isEqual returns always true, otherwise usage of "children" will rerender the subtree anyways
// https://gist.github.com/slikts/e224b924612d53c1b61f359cfb962c06
const BlockRender = memo(
  ({ children }) => children,
  () => true
)

const createStoreProvider =
  (providers) =>
  ({ children }) =>
    providers
      .reverse()
      .reduce((tree, Provider) => <Provider>{tree}</Provider>, <BlockRender>{children}</BlockRender>)

const NestedProviders = createStoreProvider([
  AuthStoreProvider,
  NotificationsProvider,
  LayoutProvider,
  LayoutContextProvider,
  ControlsPanelContextProvider,
  AdminStoreProvider,
  MapMultiStoreProvider,
  CityStructureProvider,
  ObjectStateProvider,
  ObjectDataStoreProvider,
  GlobalObjectStoreProvider,
  MarketTextStateProvider,
  MarketTextDataProvider,
  MarketDataDataProvider,
  MarketDataStateProvider,
  L3PlusDataProvider,
  ScoringDataProvider,
  ScoringStateProvider,
  HighstreetReportDataProvider,
  ThematicMapsProvider,
  IndexProvider,
  GeotoolsDraftsProvider,
  GeotoolsZoneStoreProvider,
  GeoToolsEditZoneStoreProvider,
  HedonicRentsDataProvider,
  HedonicHouseDataProvider,
  HedonicOfficeDataProvider,
  HedonicIndustryDataProvider,
  DownloadDocumentsDataProvider,
  GlossarDataProvider,
  RawDataDataProvider,
  SyncedThemeProvider,
  StyleSheetManagerProvider,
  ModalContextProvider,
  StatisticsStateProvider,
  StatisticsDataStoreProvider,
])

/**
 * Checks if the user has i18n license and forces german language if not
 *
 * Temporary until i18n module is released universally.
 * Needs to be a Component, so it can be mounted as the child of the relevant
 * Provider
 *
 * Might "flicker" on first change of the language, but since i18next remembers
 * the language selection subsequent page loads already have the correct
 * language selected.
 */
const I18nAuthzCheck = () => {
  const { i18n } = useTranslation()
  const [{ currentUser }] = useAuthStore()
  useEffect(() => {
    const licences = currentUser.permissions?.licence?.modules ?? []
    if (currentUser !== 'init' && !licences.includes('i18n')) {
      i18n.changeLanguage('de')
    }
  }, [currentUser, i18n])
  return null
}

const LayoutFallback = () => {
  return (
    <Layout>
      <Layout.Header>
        <Header.AppLogo />
        <Header.NavigationMeta>
          <NavigationMeta />
        </Header.NavigationMeta>
      </Layout.Header>
    </Layout>
  )
}

const App = () => (
  <>
    <ImpersonateFrame />
    <AppStoreProvider>
      <NestedProviders>
        <ErrorBoundary>
          <VersionCheck>
            <NavigationStatistics />
            <Notifications />
            <I18nAuthzCheck />
            <ModalContainer />
            <Routes>
              {/* <!-- Public --> */}
              <Route path="/" element={<PublicAccessGuard Component={LoginModule} />} />
              <Route path="/CTLogin" element={<PublicAccessGuard Component={CTLoginModule} />} />
              <Route path="/L3PlusLogin" element={<PublicAccessGuard Component={L3PlusLoginModule} />} />
              <Route
                path="/registerTestAccount"
                element={<PublicAccessGuard Component={RegisterTestAccount} />}
              />
              <Route
                path="/registerTestAccountL3Plus"
                element={<PublicAccessGuard Component={RegisterTestAccountL3Plus} />}
              />

              <Route
                path="/registerTestAccountDeveloperProfiles"
                element={<PublicAccessGuard Component={RegisterTestAccountDeveloper} />}
              />
              <Route path="/setPassword/:type/:userId/:token" element={<SetPassword />} />
              <Route path="/setPasswordL3Plus/:type/:userId/:token" element={<SetPasswordL3Plus />} />
              <Route path="/sso/:token">
                <Route index element={<PublicAccessGuard Component={SingleSignOn} />} />
                <Route path="*" element={<PublicAccessGuard Component={SingleSignOn} />} />
              </Route>
              <Route path="/forgotPassword" element={<PublicAccessGuard Component={ForgotPassword} />} />
              <Route
                path="/forgotPasswordL3Plus"
                element={<PublicAccessGuard Component={ForgotPasswordL3Plus} />}
              />

              {/* <!-- Private without LayoutFallback --> */}
              <Route path="/export">
                <Route index element={<PrivateAccessGuard Component={ExportModule} />} />
                <Route path="*" element={<PrivateAccessGuard Component={ExportModule} />} />
              </Route>
              <Route path="/features/l3plus/standalone">
                <Route index element={<PrivateAccessGuard Component={L3Plus} />} />
                <Route path="*" element={<PrivateAccessGuard Component={L3Plus} />} />
              </Route>

              {/* <!-- Private --> */}
              <Route path="admin">
                <Route
                  index
                  element={
                    <PrivateAccessSuspenseGuard Component={AdminModule} FallBackComponent={LayoutFallback} />
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateAccessSuspenseGuard Component={AdminModule} FallBackComponent={LayoutFallback} />
                  }
                />
              </Route>
              <Route
                path="features/home"
                element={
                  <PrivateAccessSuspenseGuard
                    Component={LandingPageApps}
                    FallBackComponent={LayoutFallback}
                  />
                }
              />
              <Route path="features/l3plus">
                <Route
                  index
                  element={
                    <PrivateAccessSuspenseGuard Component={L3Plus} FallBackComponent={LayoutFallback} />
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateAccessSuspenseGuard Component={L3Plus} FallBackComponent={LayoutFallback} />
                  }
                />
              </Route>
              <Route path="features/developer">
                <Route
                  index
                  element={
                    <PrivateAccessSuspenseGuard Component={Developer} FallBackComponent={LayoutFallback} />
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateAccessSuspenseGuard Component={Developer} FallBackComponent={LayoutFallback} />
                  }
                />
              </Route>
              <Route path="features/scoring">
                <Route
                  index
                  element={
                    <PrivateAccessSuspenseGuard Component={Scoring} FallBackComponent={LayoutFallback} />
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateAccessSuspenseGuard Component={Scoring} FallBackComponent={LayoutFallback} />
                  }
                />
              </Route>
              <Route path="features/disco">
                <Route
                  index
                  element={
                    <PrivateAccessSuspenseGuard Component={Disco} FallBackComponent={LayoutFallback} />
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateAccessSuspenseGuard Component={Disco} FallBackComponent={LayoutFallback} />
                  }
                />
              </Route>
              <Route path="features/hedonic">
                <Route
                  index
                  element={
                    <PrivateAccessSuspenseGuard Component={Hedonic} FallBackComponent={LayoutFallback} />
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateAccessSuspenseGuard Component={Hedonic} FallBackComponent={LayoutFallback} />
                  }
                />
              </Route>
              <Route path="features/retail-markets">
                <Route
                  index
                  element={
                    <PrivateAccessSuspenseGuard
                      Component={RetailMarkets}
                      FallBackComponent={LayoutFallback}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateAccessSuspenseGuard
                      Component={RetailMarkets}
                      FallBackComponent={LayoutFallback}
                    />
                  }
                />
              </Route>
              <Route path="download">
                <Route
                  index
                  element={
                    <PrivateAccessSuspenseGuard Component={Download} FallBackComponent={LayoutFallback} />
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateAccessSuspenseGuard Component={Download} FallBackComponent={LayoutFallback} />
                  }
                />
              </Route>
              <Route path="statistics">
                <Route
                  index
                  element={
                    <PrivateAccessSuspenseGuard Component={Statistics} FallBackComponent={LayoutFallback} />
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateAccessSuspenseGuard Component={Statistics} FallBackComponent={LayoutFallback} />
                  }
                />
              </Route>
              <Route path="features/highstreet-report">
                <Route
                  index
                  element={
                    <PrivateAccessSuspenseGuard
                      Component={HighstreetReport}
                      FallBackComponent={LayoutFallback}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateAccessSuspenseGuard
                      Component={HighstreetReport}
                      FallBackComponent={LayoutFallback}
                    />
                  }
                />
              </Route>
              <Route
                path="home"
                element={
                  <PrivateAccessSuspenseGuard
                    Component={LandingPageHome}
                    FallBackComponent={LayoutFallback}
                  />
                }
              />
              <Route path="information">
                <Route
                  index
                  element={
                    <PrivateAccessSuspenseGuard Component={Information} FallBackComponent={LayoutFallback} />
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateAccessSuspenseGuard Component={Information} FallBackComponent={LayoutFallback} />
                  }
                />
              </Route>
              <Route
                path="logout"
                element={
                  <PrivateAccessSuspenseGuard Component={LogoutModule} FallBackComponent={LayoutFallback} />
                }
              />
              <Route path="makro">
                <Route
                  index
                  element={
                    <PrivateAccessSuspenseGuard Component={Makro} FallBackComponent={LayoutFallback} />
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateAccessSuspenseGuard Component={Makro} FallBackComponent={LayoutFallback} />
                  }
                />
              </Route>
              <Route path="market-analysis">
                <Route
                  index
                  element={
                    <PrivateAccessSuspenseGuard
                      Component={MarketAnalysis}
                      FallBackComponent={LayoutFallback}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateAccessSuspenseGuard
                      Component={MarketAnalysis}
                      FallBackComponent={LayoutFallback}
                    />
                  }
                />
              </Route>
              <Route path="objects">
                <Route
                  index
                  element={
                    <PrivateAccessSuspenseGuard
                      Component={GlobalObjects}
                      FallBackComponent={LayoutFallback}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateAccessSuspenseGuard
                      Component={GlobalObjects}
                      FallBackComponent={LayoutFallback}
                    />
                  }
                />
              </Route>
              <Route path="sysadmin">
                <Route
                  index
                  element={
                    <PrivateAccessSuspenseGuard
                      Component={SysAdminModule}
                      FallBackComponent={LayoutFallback}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateAccessSuspenseGuard
                      Component={SysAdminModule}
                      FallBackComponent={LayoutFallback}
                    />
                  }
                />
              </Route>

              {/* <!-- Catch all -> 404 --> */}
              <Route
                path="404-in-app"
                element={
                  <PrivateAccessSuspenseGuard Component={NotFoundInApp} FallBackComponent={LayoutFallback} />
                }
              />
              <Route
                path="*"
                element={<PrivateAccessGuard Component={NotFoundInApp} FallbackComponent={NotFound} />}
              />
            </Routes>
          </VersionCheck>
        </ErrorBoundary>
      </NestedProviders>
    </AppStoreProvider>
  </>
)

export default App
