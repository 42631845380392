import React, { memo, useRef, useCallback, useMemo, useState, useEffect } from 'react'
import { array, bool, func, number, object, string, oneOf } from 'prop-types'
import { useTheme } from 'styled-components'

import { FormElement, InputSearch, withFormElementProps } from '@forms'
import { useCursor, useKeyboardNavigation } from '@forms/DropdownSelectComponents'

import { isFunction } from 'lib/util'
import { useControllableState, useDebouncedUpdates } from 'lib/hooks'

import { withBadge, withCustomOpener } from './Hocs'

import { Flyout } from '@utilities/Flyout'
import { OnClickOutside } from '@utilities/OnClickOutside'
import { Box } from '@layout/BuildingBlocks'
import { Suggestions } from '@utilities/Suggestions'

import { getThemeValue } from 'theming'

const rowHeight = 44

const buildItem = (item) => {
  const value = item !== null && typeof item.value !== 'undefined' ? item.value : item
  let label = item.label ? item.label : value
  if (item !== null && typeof item.amount !== 'undefined') {
    label += ' (' + item.amount + ')'
  }

  return {
    label,
    value,
    disabled: !!item.disabled,
  }
}

const valuesToGroupState = (value, filterValue, options) => {
  const index = options.findIndex((option) => option.value === value)
  let label
  if (index >= 0) {
    label = options[index].label
  } else {
    label = null
  }

  return { label: { value: label }, filter: { value: filterValue } }
}

const emptyArray = []

export const DropdownInputSuggestionsComponent = ({
  appearance = 'lightgray',
  debounce = null,
  disabled = false,
  items = emptyArray,
  formElementRefs = null,
  id = null,
  numRows = 5,
  onChange = null,
  optionsListPlacement = 'bottomStart',
  optionsMinWidth = '100%',
  placeholder = 'Bitte wählen',
  renderCell = null,
  size = 'medium',
  shape = 'square',
  suggestionsSize = 'medium',
  stretch = true,
  defaultValue,
  name,
  value,
  children,
  ...props
}) => {
  const [initialValue] = useState(defaultValue ?? value ?? null)

  const onDebouncedUpdate = useCallback(
    (value) => {
      isFunction(onChange) && onChange({ id, name, value })
    },
    [onChange, id, name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [internalValue, setInternalValue] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const [filterValue, setFilterValue] = useState('')

  const itemsInternal = useMemo(() => items.map((item) => buildItem(item)), [items])

  const options = useMemo(
    () => itemsInternal.filter((item) => item.label.toUpperCase().indexOf(filterValue.toUpperCase()) >= 0),
    [itemsInternal, filterValue]
  )

  const formState = useMemo(
    () => valuesToGroupState(internalValue, filterValue, itemsInternal),
    [internalValue, filterValue, itemsInternal]
  )

  const internalOnChange = useCallback((target) => {
    if (target?.changeEmitter?.name === 'filter') {
      setFilterValue(target.value.filter.value)
    }
  }, [])

  const onReset = useCallback(() => {
    setInternalValue(initialValue)
  }, [setInternalValue, initialValue])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = internalValue
    }
  }, [internalValue, formElementRefs])

  const [isActive, setIsActive] = useState(false)
  const [isFocusedBadge, setIsFocusedBadge] = useState(false)

  const onClickBadge = useCallback(() => {
    setIsActive((isActive) => !isActive)
  }, [])

  const onFocusBadge = useCallback(() => {
    setIsFocusedBadge(true)
  }, [])

  const onBlurBadge = useCallback(() => {
    setIsFocusedBadge(false)
  }, [])

  const localRenderCell = useMemo(() => {
    return typeof renderCell === 'function'
      ? renderCell
      : ({ rowData, dataKey }) => ({ value: rowData[dataKey] })
  }, [renderCell])

  const tableScrollY = useRef(null)
  const tableScrollToPx = useRef(null)

  const onScroll = useCallback((scrollX, scrollY) => {
    if (typeof scrollY === 'number' && !isNaN(scrollY)) {
      tableScrollY.current = Math.abs(scrollY)
    }
  }, [])

  const calculatedTableHeight = useMemo(() => {
    return options.length < numRows ? options.length * rowHeight : numRows * rowHeight
  }, [options, numRows])

  const setScrollTo = useCallback(
    (index) => {
      const topLimit = tableScrollY.current && calculatedTableHeight > 0 ? tableScrollY.current : 0
      const bottomLimit = topLimit + calculatedTableHeight
      if (topLimit === bottomLimit) {
        tableScrollY.current = 0
        tableScrollToPx.current = 0
      } else {
        const topCurIndex = index * rowHeight
        const bottomCurIndex = topCurIndex + rowHeight
        if (topLimit > topCurIndex) {
          tableScrollToPx.current = topCurIndex
        } else if (bottomLimit < bottomCurIndex) {
          tableScrollToPx.current = bottomCurIndex - calculatedTableHeight
        }
      }
    },
    [calculatedTableHeight]
  )

  const refInputSearch = useRef()
  const refBadge = useRef()
  const activeByArrow = useRef(false)
  const refocusOnClose = useRef(false)

  useEffect(() => {
    if (isActive && refInputSearch.current) {
      refInputSearch.current.focus()
    }
  }, [isActive, refInputSearch])

  const { cursorValue, setNextCursorValue } = useCursor({
    options,
    isActive,
    setScrollTo,
  })

  const closeDropdown = useCallback((refocus = false) => {
    refocusOnClose.current = refocus
    setIsActive(false)
  }, [])

  const openDropdown = useCallback((byArrow) => {
    activeByArrow.current = byArrow
    setIsActive(true)
  }, [])

  const selectCursor = useCallback(
    (key) => {
      if (key === 'space') {
        return
      }
      if (cursorValue !== internalValue) {
        setInternalValue(cursorValue)
      }
      closeDropdown(true)
    },
    [cursorValue, internalValue, setInternalValue, closeDropdown]
  )

  const closeDropdownNoFocus = useCallback(() => closeDropdown(false), [closeDropdown])

  useKeyboardNavigation({
    focused: isFocusedBadge,
    active: isActive,
    closeDropdown,
    openDropdown,
    selectCursor,
    moveCursor: setNextCursorValue,
  })

  const onRowClick = useCallback(
    (data) => {
      if (data.disabled) return
      tableScrollToPx.current = tableScrollY.current + 1
      setInternalValue(data.value)
      closeDropdown(true)
    },
    [setInternalValue, closeDropdown]
  )

  useEffect(() => {
    if (isActive) {
      setNextCursorValue(activeByArrow.current, internalValue)
      activeByArrow.current = false
    }
  }, [isActive, setNextCursorValue, internalValue])

  useEffect(() => {
    if (!isActive) {
      if (refocusOnClose.current) {
        refocusOnClose.current = false
        refBadge.current.focus()
      }
    }
  }, [isActive])

  const theme = useTheme()
  const headerBg = getThemeValue(theme, 'dropdown.header.bg')
  const headerPx = getThemeValue(theme, 'dropdown.header.px')
  const headerPy = getThemeValue(theme, 'dropdown.header.py')
  const headerMb = getThemeValue(theme, 'dropdown.header.mb')
  const spacerBg = getThemeValue(theme, 'dropdown.spacer.bg')
  const spacerHeightTop = getThemeValue(theme, 'dropdown.spacer.height.top')

  return (
    <FormElement {...props} id={id} name={name} value={formState} onChange={internalOnChange}>
      <OnClickOutside handler={closeDropdownNoFocus} stretch={stretch}>
        {React.cloneElement(children, {
          name,
          appearance,
          isActive,
          onBlur: onBlurBadge,
          onClick: onClickBadge,
          onFocus: onFocusBadge,
          placeholder,
          refBadge,
          shape,
          size,
          stretch,
        })}
        {isActive && (
          <Flyout refOpener={refBadge} minWidth={optionsMinWidth} placement={optionsListPlacement}>
            <Box px={headerPx} py={headerPy} mb={headerMb} bg={headerBg}>
              <InputSearch
                appearance="white"
                autoComplete="off"
                forElementName={name}
                name="filter"
                size="small"
                ref={refInputSearch}
              />
            </Box>
            <Suggestions
              className="rs-table-dropdown"
              cursorValue={cursorValue}
              currentValue={internalValue}
              height={calculatedTableHeight}
              renderCell={localRenderCell}
              onRowClick={onRowClick}
              scrollToPx={tableScrollToPx.current}
              rowHeight={rowHeight}
              onScroll={onScroll}
              options={options}
              size={suggestionsSize}
            />
            <Box pt={spacerHeightTop} bg={spacerBg} />
          </Flyout>
        )}
      </OnClickOutside>
    </FormElement>
  )
}

const WithBadge = withBadge(DropdownInputSuggestionsComponent)
WithBadge.type = 'DropdownInputSuggestions'

export const DropdownInputSuggestions = withFormElementProps(memo(WithBadge))

const WithCustomOpener = withCustomOpener(DropdownInputSuggestionsComponent)
WithCustomOpener.type = 'DropdownInputSuggestions'

export const DropdownInputSuggestionsCustomOpener = withFormElementProps(memo(WithCustomOpener))
DropdownInputSuggestionsComponent.propTypes = {
  appearance: string,
  size: string,
  shape: string,
  stretch: bool,
  debounce: number,
  disabled: bool,
  items: array,
  formElementRefs: object,
  id: string,
  name: string,
  placeholder: string,
  renderCell: func,
  numRows: number,
  optionsListPlacement: oneOf([
    'topStart',
    'rightStart',
    'bottomStart',
    'leftStart',
    'topEnd',
    'rightEnd',
    'bottomEnd',
    'leftEnd',
  ]),
  optionsMinWidth: string,
  suggestionsSize: string,
  onChange: func,
}
