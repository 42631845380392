import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import useWebSocket, { ReadyState } from 'react-use-websocket'

import { sleep } from 'lib/util'

import appConfig from 'config/appConfig'

import api from 'stores/api'
import { useAuthStore } from 'stores/AuthStore'

import { useNotificationsStore } from 'stores/Notifications'

const bgagServicesUrl = appConfig.bgagServicesUrl

const connectionStatus = {
  [ReadyState.CONNECTING]: 'connecting',
  [ReadyState.OPEN]: 'open',
  [ReadyState.CLOSING]: 'closing',
  [ReadyState.CLOSED]: 'closed',
  [ReadyState.UNINSTANTIATED]: 'uninstantiated',
}

const runningStatusPolls = {}

async function* createStatusPoll(id, intervall = 200) {
  let notification
  while (!(notification && notification.status === 'completed')) {
    const pollResult = await api.Queue.getJob(id)
    notification = pollResult.data.data
    if (notification.status === 'active') {
      yield notification
    } else if (notification.status === 'failed') {
      throw new Error('job failed')
    }
    await sleep(intervall)
  }
  yield notification
}

export const useWebsocketQueueUpdates = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const isPuppeteer = params.get('puppeteer') === 'true'

  const [{ currentUser }] = useAuthStore()

  const [socketUrl, setSocketUrl] = useState(null)

  const [{ notifications }, { addNotification, closeNotification }] = useNotificationsStore()

  useEffect(() => {
    if (!isPuppeteer && typeof currentUser === 'object' && currentUser !== null) {
      setSocketUrl(bgagServicesUrl.replace(/^http/, 'ws').replace(/\/$/, '') + '/ws/queueUpdates')
    } else {
      setSocketUrl(null)
    }
  }, [currentUser, isPuppeteer])

  const { sendMessage, lastMessage, lastJsonMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => {
      if (closeEvent.reason) {
        console.log(closeEvent.reason)
      }
      return closeEvent.code !== 1008
    },
  })

  useEffect(() => {
    console.debug('websocket ', connectionStatus[readyState])
  }, [readyState])

  useEffect(() => {
    const poll = async (generator) => {
      try {
        for await (const message of generator) {
          const notification = { ...message }
          notification.onClose = () => sendMessage(JSON.stringify({ type: 'closed', id: notification.id }))
          addNotification(notification)
        }
      } catch (err) {
        console.error(err)
      }
    }

    if (readyState !== 1) {
      const setupFallbackFor = Object.values(notifications).filter(
        (notification) =>
          notification.queue_name &&
          !runningStatusPolls[notification.id] &&
          notification.status !== 'completed' &&
          notification.status !== 'failed'
      )
      setupFallbackFor.forEach((notification) => {
        const generator = createStatusPoll(notification.id)
        runningStatusPolls[notification.id] = true
        poll(generator).then(() => {
          delete runningStatusPolls[notification.id]
        })
      })
    }
  }, [readyState, sendMessage, notifications, addNotification])

  useEffect(() => {
    if (lastMessage !== null) {
      if (lastJsonMessage.type === 'dbUpdate') {
        const notification = { ...lastJsonMessage.message }
        notification.onClose = () => sendMessage(JSON.stringify({ type: 'closed', id: notification.id }))
        addNotification(notification)
      }
      if (lastJsonMessage.type === 'clientState' && lastJsonMessage.message.type === 'closed') {
        closeNotification(lastJsonMessage.message.id)
      }
    }
  }, [lastMessage, lastJsonMessage, addNotification, closeNotification, sendMessage])

  return [sendMessage, readyState]
}
