import createStore from 'lib/flux-store'

// import api from 'stores/api'

import {
  getAreas,
  getScores,
  getClassifiedScores,
  getYieldData,
  getComparableDataSources,
  getDetail,
  getDocumentList,
  getScoringTopicData,
} from './Cache'
import { assetClasses } from 'config/scoring'

import { functionOutrunStatus } from 'lib/util/functionOutrunStatus'

const _getClassifiedScores = functionOutrunStatus(getClassifiedScores)
const _getDetail = functionOutrunStatus(getDetail)

const initialState = {
  isLoading: false,
  isFailure: false,
  loading: {
    initialData: false,
  },
  messages: null,
  federalStates: [],
  currentAssetClass: 'office',
  scores: [],
  allAssetClassesScores: {},
  detailGac: '',
  detail: {},
  yieldData: [],
  scoringGacs: [],
  activeGacs: [],
  classifiedScores: { bounds: [], data: [] },
  currentAssetClassClassified: 'office',
  documentList: null,
  scoringTopicDefinitions: null,
}

const actions = {
  fetchInitialData: () => (dispatch) => {
    dispatch({ type: 'setLoading', payload: 'initialData' })
    const promises = [getAreas(), getComparableDataSources()]
    Promise.all(promises)
      .then((results) => {
        dispatch({
          type: 'setInitialData',
          payload: {},
        })

        const [areas, comparableDataSources] = results

        dispatch({
          type: 'setInitialData',
          payload: { areas, comparableDataSources },
        })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
  fetchScores: (assetClass) => (dispatch) => {
    dispatch({ type: 'setLoading', payload: 'scores' })
    getScores(assetClass)
      .then((scores) => {
        dispatch({
          type: 'setScores',
          payload: {
            currentAssetClass: assetClass,
            scores,
          },
        })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
  fetchAllScores: () => (dispatch) => {
    dispatch({ type: 'setLoading', payload: 'allAssetClassesScores' })
    getScores('all')
      .then((scores) => {
        dispatch({
          type: 'setAssetClassesScores',
          payload: {
            allAssetClassesScores: assetClasses.reduce((result, assetClass, idx) => {
              const keysToKeep = Object.keys(scores[0]).filter((key) => !assetClasses.includes(key))

              result[assetClass] = scores.map((area) => {
                const selectedKeysForScore = area[assetClass]
                keysToKeep.forEach((key) => {
                  selectedKeysForScore[key] = area[key]
                })
                return selectedKeysForScore
              })
              return result
            }, {}),
          },
        })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
  fetchClassifiedScores: (assetClass, cluster) => (dispatch) => {
    dispatch({ type: 'setLoading', payload: 'classifiedScores' })
    _getClassifiedScores(assetClass, cluster.toSorted())
      .then(({ status, response: classifiedScores }) => {
        if (status === 'outrun') {
          // another request was done by user, so the result of this request should not be displayed
          // loading state was already unset by other request, or will be if it is done
          return
        }
        dispatch({
          type: 'setClassifiedScores',
          payload: {
            currentAssetClassClassified: assetClass,
            classifiedScores,
          },
        })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
  fetchYieldData: () => (dispatch) => {
    dispatch({ type: 'setLoading', payload: 'scores' })
    getYieldData()
      .then((result) => {
        dispatch({
          type: 'setYieldData',
          payload: {
            result,
          },
        })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
  fetchDetail: (gac) => (dispatch) => {
    dispatch({ type: 'setLoading', payload: 'detail' })
    _getDetail(gac)
      .then(({ status, response: detail }) => {
        if (status === 'outrun') {
          // another request was done by user, so the result of this request should not be displayed
          // loading state was already unset by other request, or will be if it is done
          return
        }
        dispatch({
          type: 'setDetail',
          payload: {
            detailGac: gac,
            detail,
          },
        })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
  setActiveGacs: (locations, visibleLocations) => ({ locations, visibleLocations }),
  fetchDocumentList: () => (dispatch) => {
    // copy to /srv/bg-services/scoring/current/files/download
    getDocumentList('scoring').then((documentList) => {
      dispatch({ type: 'setDocumentList', payload: documentList })
    })
  },
  fetchScoringTopicDefinitions: () => (dispatch) => {
    getScoringTopicData().then((res) => {
      dispatch({ type: 'setScoringTopicDefinitions', scoringTopicDefinitions: res })
    })
  },
}
const reducer = {
  setLoading: (state, { payload }) => {
    const loading = { ...state.loading, [payload]: true }
    return { ...state, isLoading: true, loading, isFailure: false }
  },
  setFailure: (state, { payload }) => ({ ...state, isLoading: false, isFailure: true, messages: payload }),
  setInitialData: (state, { payload }) => {
    const loading = { ...state.loading, initialData: false }
    const isLoading = Object.values(loading).reduce((isLoading, subLoader) => isLoading || subLoader, false)
    const { areas, comparableDataSources, ...rest } = payload
    return {
      ...state,
      isLoading,
      loading,
      ...areas,
      ...comparableDataSources,
      ...rest,
    }
  },
  setScores: (state, { payload }) => {
    const loading = { ...state.loading, scores: false }
    const isLoading = Object.values(loading).reduce((isLoading, subLoader) => isLoading || subLoader, false)

    const nextState = {
      ...state,
      isLoading,
      loading,
      ...payload,
    }

    if (!state.scoringGacs.length) {
      nextState.scoringGacs = payload.scores.filter((area) => area.gac !== null).map((area) => area.gac)
    }

    return nextState
  },
  setAssetClassesScores: (state, { payload }) => {
    const loading = { ...state.loading, scores: false }
    const isLoading = Object.values(loading).reduce((isLoading, subLoader) => isLoading || subLoader, false)

    const nextState = {
      ...state,
      isLoading,
      loading,
      ...payload,
    }

    if (!state.scoringGacs.length) {
      nextState.scoringGacs =
        Object.values(payload.allAssetClassesScores)
          ?.filter((area) => area.gac !== null)
          ?.map((area) => area.gac) ?? []
    }

    return nextState
  },
  setClassifiedScores: (state, { payload }) => {
    const loading = { ...state.loading, classifiedScores: false }
    const isLoading = Object.values(loading).reduce((isLoading, subLoader) => isLoading || subLoader, false)

    const nextState = {
      ...state,
      isLoading,
      loading,
      ...payload,
    }

    return nextState
  },
  setYieldData: (state, { payload: { result } }) => {
    return { ...state, yieldData: result }
  },
  setDetail: (state, { payload }) => {
    const loading = { ...state.loading, detail: false }
    const isLoading = Object.values(loading).reduce((isLoading, subLoader) => isLoading || subLoader, false)

    const nextState = {
      ...state,
      isLoading,
      loading,
      ...payload,
    }

    return nextState
  },
  setActiveGacs: (state, { locations, visibleLocations }) => {
    const activeGacs = new Set()
    visibleLocations.forEach((locationId) => {
      if (locations[locationId]) {
        const area = Object.values(locations[locationId].areas).find((area) =>
          state.scoringGacs.includes(area.gac)
        )
        if (area) {
          activeGacs.add(area.gac)
        }
      }
    })
    return {
      ...state,
      activeGacs: Array.from(activeGacs),
    }
  },
  setDocumentList: (state, { payload }) => {
    return { ...state, list: payload }
  },
  setScoringTopicDefinitions: (state, { scoringTopicDefinitions }) => {
    return { ...state, scoringTopicDefinitions }
  },
}

export const [ScoringDataContext, ScoringDataProvider, useScoringDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'ScoringDataStore'
)
