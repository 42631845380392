import React, { useState, useEffect, useCallback, useRef, cloneElement } from 'react'

import { useSlots } from 'lib/hooks/useSlots'

import { Box, BoxForwardedRef } from '@layout/BuildingBlocks'
import { Flyout } from '@utilities/Flyout'

export const Tooltip = ({
  width = null,
  minWidth = null,
  widthBox = null,
  minWidthBox = null,
  children,
  delayOpen = 0,
  delayClose = 200,
  disabled = false,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false)
  const timeoutEnter = useRef(null)
  const timeoutLeave = useRef(null)
  const isMounted = useRef(false)
  const refOpener = useRef(null)

  const [opener, content] = useSlots(['opener', 'content'], children)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const clearTimeouts = useCallback(() => {
    if (timeoutEnter.current !== null) {
      clearTimeout(timeoutEnter.current)
      timeoutEnter.current = null
    }
    if (timeoutLeave.current !== null) {
      clearTimeout(timeoutLeave.current)
      timeoutLeave.current = null
    }
  }, [])

  const onMouseEnter = useCallback(() => {
    if (disabled) {
      return
    }
    clearTimeouts()
    timeoutEnter.current = setTimeout(() => {
      if (isMounted.current) {
        setIsActive(true)
      }
    }, delayOpen)
  }, [disabled, clearTimeouts, delayOpen])

  const onMouseLeave = useCallback(() => {
    if (disabled) {
      return
    }
    clearTimeouts()
    timeoutLeave.current = setTimeout(() => {
      if (isMounted.current) {
        setIsActive(false)
      }
    }, delayClose)
  }, [disabled, clearTimeouts, delayClose])

  return (
    <>
      <BoxForwardedRef
        ref={refOpener}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        width={widthBox}
        minWidth={minWidthBox}
      >
        {React.Children.toArray(opener?.props?.children).map((child) => cloneElement(child, { ...props }))}
      </BoxForwardedRef>
      <Flyout
        refOpener={refOpener}
        active={isActive}
        renderTriangle={true}
        alignOffset={-5}
        placement="bottomStart"
        minWidth={minWidth}
        width={width}
      >
        <Box onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} padding="4px 8px">
          {content}
        </Box>
      </Flyout>
    </>
  )
}

Tooltip.Opener = ({ children }) => children
Tooltip.Opener.displayName = 'opener'

Tooltip.Content = ({ children }) => children
Tooltip.Content.displayName = 'content'
