export const concatThemeValues = (obj1, obj2) => {
  const result = {}

  for (const key in obj2) {
    if (obj2[key]) {
      if (
        typeof obj2[key] === 'object' &&
        !Array.isArray(obj2[key]) &&
        obj1[key] &&
        typeof obj1[key] === 'object' &&
        !Array.isArray(obj1[key])
      ) {
        result[key] = concatThemeValues(obj1[key], obj2[key])
      } else if (typeof obj2[key] !== 'object' || Object.keys(obj2[key]).length > 0) {
        result[key] = obj2[key]
      }
    }
  }

  for (const key in obj1) {
    if (obj1[key] && !result[key]) {
      if (typeof obj1[key] === 'object' && !Array.isArray(obj1[key])) {
        result[key] = concatThemeValues(obj1[key], {})
      } else {
        result[key] = obj1[key]
      }
    }
  }

  return result
}
