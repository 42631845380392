import React, { useRef, useMemo, forwardRef } from 'react'
import { styled, useTheme } from 'styled-components'
import { layout, space } from 'styled-system'

import { PosRelative } from '@layout/BuildingBlocks'

import { customScrollbar, customScrollbarNotHidden } from 'theming/styles/CustomScrollbar'
import { getThemeValue } from 'theming'

import { useOverflowObserver, useArrayValueByBreakpoint } from 'lib/hooks'

import { disabled } from './defaultPropTypes'
import { typeScale, typeStyle } from '@typography'

const StyledScrollContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => ['style', 'children', 'id'].includes(prop),
})`
  overflow-y: ${(props) => props.overflow};
  //   font size 0 needed to prevent inline-block child "ScrollView" from adding extra "margin"
  //     https://stackoverflow.com/a/8262649
  font-size: 0px;
  ${customScrollbar}
  ${layout}
  ${space}

  scroll-behavior: ${(props) => props.scrollBehavior}
`

const StyledScrollView = styled(PosRelative)`
  ${typeScale('copy')}
  ${typeStyle('copy')}
`

const ScrollView = ({ children, ...props }) => {
  return (
    <StyledScrollView fontSize={[0, 1, 2, 3, 4]} {...props}>
      {children}
    </StyledScrollView>
  )
}

export const ScrollContainer = forwardRef(
  (
    {
      children,
      disabled = false,
      forceNoPadding = false,
      forcePadding = false,
      paddingRight = null,
      ...props
    },
    forwardedRef
  ) => {
    const theme = useTheme()
    const fallbackRef = useRef(null)
    const ref = forwardedRef || fallbackRef

    const scrollbarThumbWidth = getThemeValue(theme, 'sizes.scrollbar.default.thumb.width')
    const areaMainPaddingRight = getThemeValue(theme, 'layout.areaMain.pr')
    const areaMainPaddingRightValue = useArrayValueByBreakpoint(areaMainPaddingRight)

    const renderPaddingRight = paddingRight !== null ? paddingRight : areaMainPaddingRightValue

    const [hasOverflow] = useOverflowObserver(ref)

    const computedPaddingRight = useMemo(
      () =>
        forceNoPadding || (disabled && !forcePadding)
          ? '0px'
          : hasOverflow && !disabled
            ? `calc(${renderPaddingRight} - ${scrollbarThumbWidth})`
            : renderPaddingRight,
      [hasOverflow, forcePadding, forceNoPadding, renderPaddingRight, disabled, scrollbarThumbWidth]
    )

    return (
      <StyledScrollContainer
        height="100%"
        overflow={disabled ? 'hidden' : 'auto'}
        paddingRight={computedPaddingRight}
        ref={ref}
        {...props}
      >
        <ScrollView
          height={!disabled && hasOverflow ? 'unset' : '100%'}
          minHeight={hasOverflow ? '100%' : 'unset'}
          display="inline-block"
          width="100%"
        >
          {children}
        </ScrollView>
      </StyledScrollContainer>
    )
  }
)

ScrollContainer.propTypes = {
  disabled,
}
export const ScrollContainerNotHidden = styled(ScrollContainer)`
  ${customScrollbarNotHidden}
`
