import React from 'react'
import { styled } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const StyledComponent = styled.div`
  background-color: ${(props) =>
    props.highlighted
      ? themeGet('card.colors.body.highlighted', 'lightgray')
      : !props.noBackground
        ? themeGet('card.colors.body.bg', 'lightgray')
        : 'unset'};
  border-radius: ${(props) =>
    props.rimless ? themeGet('card.borderRimless.radius', '0px') : themeGet('card.border.radius', '0px')};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: ${(props) => props.padding};
`

export const CardContainer = ({ id, highlighted, rimless, padding, noBackground, children }) => (
  <StyledComponent
    highlighted={highlighted}
    rimless={rimless}
    id={id}
    padding={padding}
    noBackground={noBackground}
  >
    {children}
  </StyledComponent>
)
