import React, { memo } from 'react'
import { styled, useTheme } from 'styled-components'
import { layout } from 'styled-system'
import { array, string, oneOfType } from 'prop-types'
import { themeGet } from '@styled-system/theme-get'

import { getThemeValue } from 'theming'

const StyledComponent = styled.footer.withConfig({
  shouldForwardProp: (prop) => ['style', 'children'].includes(prop),
})`
  background: ${themeGet(`colors.theme.app.bg`, 'white')};
  display: block;
  width: 100%;
  ${layout}
`

export const Footer = memo(({ children, footerHeight = 'layout.footer.height' }) => {
  const theme = useTheme()
  const height = getThemeValue(theme, footerHeight)

  return (
    <StyledComponent height={height} minHeight={height}>
      {children}
    </StyledComponent>
  )
})
Footer.propTypes = {
  footerHeight: oneOfType([string, array]),
}
