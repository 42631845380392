import React, { forwardRef } from 'react'

import { styled, useTheme } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { getThemeValue } from 'theming'

import { Royal } from 'stories/typography'

import { FlexColumnForwardedRef, FlexRow } from '@layout/BuildingBlocks'

const Container = styled(FlexColumnForwardedRef)`
  padding-bottom: ${themeGet('card.header.space.bottom', '0')};
  align-items: flex-start;
  postion: relative;
`

export const CardSubHeader = forwardRef(({ children, py, pt, pb, px }, ref) => {
  const theme = useTheme()
  const color = getThemeValue(theme, 'card.colors.header.public.color')

  return (
    <Container ref={ref}>
      <FlexRow py={py} pb={pb} pt={pt} px={px} justifyContent="center" width="100%" height="100%">
        <Royal color={color}>{children}</Royal>
      </FlexRow>
    </Container>
  )
})
