import React from 'react'
import { styled, useTheme } from 'styled-components'
import { border, space, layout, compose } from 'styled-system'

import { CanonBlack as Text } from '@typography'

import { bool, array, node, string, oneOfType } from 'prop-types'
import { appearance } from './variants'
import { getThemeValue } from 'theming'

export const BadgeWrapper = styled.span.withConfig({
  shouldForwardProp: (prop) => ['style', 'children'].includes(prop),
})`
  align-items: center;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  pointer-events: none;

  > span: {
    pointer-events: none;
  }

  ${compose(layout, border, space)}
  ${appearance}
`

const size = ['24px', '24px', '24px', '26px', '30px']

export const BadgeLetter = ({
  children,
  appearance = 'lightgray',
  borderRadius = 'radii.medium',
  height = size,
  checked = false,
  pb = '4px',
  pl = '5px',
  pr = '5px',
  pt = '5px',
  width = size,
  ...props
}) => {
  const theme = useTheme()
  borderRadius = getThemeValue(theme, borderRadius)

  return (
    <BadgeWrapper
      appearance={appearance}
      height={height}
      checked={checked}
      pb={pb}
      pl={pl}
      pr={pr}
      pt={pt}
      width={width}
      {...props}
      borderRadius={borderRadius}
    >
      <Text tag="span" color={null}>
        {children}
      </Text>
    </BadgeWrapper>
  )
}
BadgeLetter.propTypes = {
  appearance: string,
  borderRadius: string,
  checked: bool,
  children: node,
  height: oneOfType([string, array]),
  pb: string,
  pl: string,
  pr: string,
  pt: string,
  width: oneOfType([string, array]),
}
