import React from 'react'
import { styled } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { CopyMedium as Text } from '@typography'
import { color } from 'styled-system'

const StyledComponent = styled(Text)`
  margin: 0;
  padding-top: ${themeGet('card.header.space.top', '0')};
  line-height: normal;
  ${color};
`

export const CardTitle = ({ color, children }) => (
  <StyledComponent color={color} tag="h3" lineHeight="1">
    {children}
  </StyledComponent>
)
