import React, { useRef } from 'react'
import { styled, css } from 'styled-components'

import { useControlsSegmentContext } from '@ui/ControlsSegment'
import { useMaxHeightOnTransition } from '@ui/ControlsSegment/useMaxHeightOnTransition'

const foldedStyle = css`
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.01s;
`

const unfoldedStyle = css`
  flex: 1 1 auto;
  display: block;
  opacity: 1;
  max-height: ${(props) => props.maxHeight}px;
  transition: all 0.3s;
`

const StyledBody = styled.div`
  opacity: 1;
  ${(props) => (props.folded ? foldedStyle : unfoldedStyle)};
`

export const ControlsSegmentBody = ({ children, ...props }) => {
  const { folded, foldDisabled } = useControlsSegmentContext()
  const spaceTop = props.spaceTop === 'large' && '12px'

  const refContainer = useRef()

  const maxHeight = useMaxHeightOnTransition({ refTarget: refContainer, folded })

  return (
    <StyledBody
      maxHeight={maxHeight}
      folded={folded}
      foldDisabled={foldDisabled}
      spaceTop={spaceTop}
      ref={refContainer}
    >
      {children}
    </StyledBody>
  )
}
