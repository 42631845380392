import React, { memo, useEffect, useCallback, useMemo, useState, useLayoutEffect } from 'react'
import { useTheme } from 'styled-components'
import { string, number, func, bool, oneOfType, arrayOf } from 'prop-types'

import RcSlider from 'rc-slider'

import { useDebouncedUpdates, useControllableState, useMediaQuery } from 'lib/hooks'
import { isFunction, isArray } from 'lib/util'

import { withFormElementProps } from '@forms'
import { PosAbsolute, FlexContainer, FlexItem } from '@layout/BuildingBlocks'

import { Pica, Copy } from '@typography'

import { InputNumber } from '@forms/InputNumber/'

const groupStateToValue = (state, minLimit, maxLimit) => {
  if (minLimit === null && maxLimit === null) {
    return state
  } else {
    let value = [...state]
    if (minLimit !== null) {
      value.shift()
    }
    if (maxLimit !== null) {
      value.pop()
    }
    if (value.length === 1) {
      value = value[0]
    }
    return value
  }
}

const valueToGroupState = (value, minLimit, maxLimit) => {
  if (minLimit === null && maxLimit === null) {
    return value
  } else {
    const state = Array.isArray(value) ? [...value] : [value]
    if (minLimit !== null) {
      state.unshift(minLimit)
    }
    if (maxLimit !== null) {
      state.push(maxLimit)
    }
    return state
  }
}

const tooltipStyle = { transform: 'translateX(-50%) translateY(70%)', pointerEvents: 'none' }

const Tooltip = ({ left, value, id }) => {
  const style = useMemo(() => ({ ...tooltipStyle, left: left }), [left])

  return (
    <PosAbsolute style={style} id={id}>
      <Pica>{value}</Pica>
      {
        // store the original value as hidden in order to get the right text width by checking text overlapping
        <PosAbsolute>
          <Pica opacity="0">{value}</Pica>
        </PosAbsolute>
      }
    </PosAbsolute>
  )
}

const computeDistance = (leftElement, rightElement, displayedTextWidth) =>
  rightElement.offsetLeft - (leftElement.offsetLeft + displayedTextWidth)

const concatenateStackItems = (stack, currentItem) => {
  const rootStack = stack[0]
  const aggregatedText = stack.reduce((agg, item) => [...agg, item.lastChild.textContent], []).join(' - ')
  const displayElement = rootStack.firstChild
  const hiddenElement = rootStack.lastChild
  const hiddenElementWidth = hiddenElement.clientWidth
  rootStack.style.transform = `translateX(${-hiddenElementWidth / 2}px) translateY(70%)`
  rootStack.style.whiteSpace = 'nowrap'
  displayElement.style.opacity = 1
  displayElement.textContent = aggregatedText
  currentItem.firstChild.style.opacity = 0
}

const initUnstackedItem = (item) => {
  item.firstChild.style.opacity = 1
  item.firstChild.textContent = item.lastChild.textContent
  item.style.transform = `translateX(-50%) translateY(70%)`
}

const checkTooltipsOverlaps = (name) => {
  const tooltips = Array.from(document.querySelectorAll(`[id^=tooltip-${name}-for-handle-idx-]`)).sort(
    (a, b) => {
      return parseInt(a.lastChild.textContent) - parseInt(b.lastChild.textContent)
    }
  )

  tooltips.reduce((acc, item, idx) => {
    if (idx === 0) {
      initUnstackedItem(item)
      acc.push([item])
    } else {
      const lastStack = acc[acc.length - 1]
      const rootStack = lastStack[0]
      let displayedTextWidth = rootStack.offsetWidth
      const distance = computeDistance(rootStack, item, displayedTextWidth)
      if (distance < 5) {
        lastStack.push(item)
        concatenateStackItems(lastStack, item)
      } else {
        initUnstackedItem(item)
        acc.push([item])
      }
    }
    return acc
  }, [])
}

const defaultValueFormatter = (value) => value
const defaultValueProp = [0]

let timeOut = 0
export const SliderComponent = ({
  name = 'SliderComponent',
  min = 0,
  max = 100,
  minLimit = null,
  maxLimit = null,
  hideMinMax = false,
  defaultValue = defaultValueProp,
  debounce = 100,
  valueFormatter = defaultValueFormatter,
  formElementRefs,
  onChange,
  formattedMin,
  formattedMax,
  value,
  appearance,
  customRailStyle,
  customTrackStyle,
  withInput,
  ...props
}) => {
  const theme = useTheme()
  const marginTop = `calc(${useMediaQuery(theme.pica)} / 2)`
  const marginBottom = `calc(${useMediaQuery(theme.pica)} + (${useMediaQuery(theme.pica)} / 2))`

  const [initialValue] = useState(defaultValue ?? value ?? [])

  const onDebouncedUpdate = useCallback(
    (value) => {
      isFunction(onChange) && onChange({ id: props.id, name, value })
    },
    [onChange, props.id, name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [internalValue, setInternalValue] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const formState = useMemo(
    () => valueToGroupState(internalValue, minLimit, maxLimit),
    [internalValue, minLimit, maxLimit]
  )

  const isRange = useMemo(() => isArray(formState), [formState])

  const internalOnChange = useCallback(
    (value) => {
      clearTimeout(timeOut)
      setInternalValue(groupStateToValue(value, minLimit, maxLimit))
      if (isRange) {
        timeOut = setTimeout(() => {
          checkTooltipsOverlaps(name)
          clearTimeout(timeOut)
        }, 15)
      }
    },
    [setInternalValue, minLimit, maxLimit, isRange, name]
  )

  const trackColor = useMemo(() => theme.colors.theme.app.color, [theme])
  const railColor = useMemo(() => theme.colors.base.lightgray[700], [theme])

  const numberHandles = useMemo(
    () => (Array.isArray(internalValue) ? internalValue.length : 1),
    [internalValue]
  )

  const railStyle = useMemo(() => {
    return customRailStyle || { backgroundColor: appearance === 'allTrack' ? trackColor : railColor }
  }, [appearance, railColor, trackColor, customRailStyle])

  const trackStyle = useMemo(() => {
    const track = customTrackStyle || { backgroundColor: appearance === 'allRail' ? railColor : trackColor }
    const style = []

    for (let i = 0; i < numberHandles; i++) {
      style.push(track)
    }

    if (maxLimit !== null) {
      style.push(track)
    }
    return style
  }, [appearance, railColor, trackColor, maxLimit, numberHandles, customTrackStyle])

  const handleStyle = useMemo(() => {
    const handle = {
      border: `2px solid ${theme.colors.theme.app.color}`,
      boxShadow: 'none',
      opacity: 1,
    }
    const style = []

    for (let i = 0; i < numberHandles; i++) {
      style.push(handle)
    }

    if (minLimit !== null) {
      style.unshift({ visibility: 'hidden' })
    }
    if (maxLimit !== null) {
      style.push({ visibility: 'hidden' })
    }

    return style
  }, [theme, minLimit, maxLimit, numberHandles])

  const onReset = useCallback(() => {
    setInternalValue(initialValue)
  }, [setInternalValue, initialValue])

  const handleRender = useCallback(
    (handle, { value }) => {
      const showTooltip = handle.props.style?.visibility !== 'hidden'
      const handleKey = handle?._owner?.key
      const left = handle.props.style.left
      return (
        <>
          {handle}
          {showTooltip && (
            <Tooltip
              id={`tooltip-${name}-for-handle-idx-${handleKey}`}
              left={left}
              value={valueFormatter(value)}
            />
          )}
        </>
      )
    },
    [valueFormatter, name]
  )
  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = internalValue
    }
  }, [internalValue, formElementRefs])

  useLayoutEffect(() => {
    if (isRange) {
      checkTooltipsOverlaps(name)
    }
  }, [isRange, name])

  return (
    <>
      <FlexContainer
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
        flexColumnGap="8px"
        mt={marginTop}
        mb={withInput ? '0' : marginBottom}
      >
        {!hideMinMax && (
          <FlexItem>
            <Copy>{formattedMin ?? min}</Copy>
          </FlexItem>
        )}
        <FlexItem flex="1 0 auto">
          <RcSlider
            range={isRange}
            min={min}
            max={max}
            value={formState}
            onChange={internalOnChange}
            railStyle={railStyle}
            trackStyle={trackStyle}
            handleStyle={handleStyle}
            handleRender={withInput ? null : handleRender}
            //onAfterChange={onAfterChange}
            allowCross={false}
            {...props}
          />
        </FlexItem>
        {!hideMinMax && (
          <FlexItem>
            <Copy>{formattedMax ?? max}</Copy>
          </FlexItem>
        )}
      </FlexContainer>
      {withInput && (
        <FlexContainer mt="8px" alignItems="center" justifyContent="center" width="100%" mb={marginBottom}>
          <FlexItem width="100%">
            <InputNumber
              min={min}
              max={max}
              value={formState}
              onChange={(val) => {
                if (parseInt(val.value) > max || isNaN(parseInt(val.value))) return
                internalOnChange(val.value)
              }}
              size="small"
              appearance="white"
              textAlign="right"
            />
          </FlexItem>
        </FlexContainer>
      )}
    </>
  )
}

SliderComponent.type = 'Slider'
SliderComponent.propTypes = {
  name: string,
  min: number,
  max: number,
  minLimit: number,
  maxLimit: number,
  hideMinMax: bool,
  defaultValue: oneOfType([number, arrayOf(number)]),
  debounce: number,
  valueFormatter: func,
}

export const Slider = withFormElementProps(memo(SliderComponent))
