import { createDataCache } from 'lib/cache'

import api from 'stores/api'

const conditionals = [{ name: 'yearFrom' }, { name: 'yearTo' }]

const getter = (year) => {
  return api.Data.getData().then((res) => res.data.data)
}

export const [getData] = createDataCache(conditionals, getter, { name: 'propertyIndices' })

const conditionalsDocumentList = [{ name: 'type' }]
const getterDocumentList = (type) => {
  return api.Downloads.getDocuments(type).then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

export const [getDocumentList, bustDocumentList] = createDataCache(
  conditionalsDocumentList,
  getterDocumentList,
  {
    name: 'GPIDocumentList',
  }
)
