import React from 'react'

import { StyledText } from '../StyledText'
import { typeStyles } from '../typeStyles'

import { defaultPropTypes } from '../defaultPropTypes'

export const Imperial = ({ children, tag, ...props }) => (
  <StyledText scale="imperial" {...props} {...typeStyles.imperial} as={tag}>
    {children}
  </StyledText>
)

export const ImperialMedium = ({ children, tag, ...props }) => (
  <StyledText scale="imperial" {...props} {...typeStyles.imperialMedium} as={tag}>
    {children}
  </StyledText>
)

export const ImperialSemiBold = ({ children, tag, ...props }) => (
  <StyledText scale="imperial" {...props} {...typeStyles.imperialSemiBold} as={tag}>
    {children}
  </StyledText>
)

export const ImperialBold = ({ children, tag, ...props }) => (
  <StyledText scale="imperial" {...props} {...typeStyles.imperialBold} as={tag}>
    {children}
  </StyledText>
)
Imperial.propTypes = defaultPropTypes
ImperialBold.propTypes = defaultPropTypes
