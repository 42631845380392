import { useEffect, useState, useMemo } from 'react'
import i18n, { loadLanguages } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { initReactI18next, useTranslation } from 'react-i18next'

import { verboseI18n, nodeEnv } from 'config/appConfig'

import { isNullOrUndefined } from 'lib/util'

import api from '../stores/api'

async function getBackendResource(language, namespace) {
  const result = await api.I18n.loadNamespace(language, namespace)
  if (!result.data || result.data.status !== 'success') {
    throw Error('Error while fetching backend resource')
  }
  return result.data.data
}

/**
 * Lists available languages and namespaces
 *
 * Each resource is listed as an async function to retrieve the resource.
 * Imports have to be specified by their full paths (no variable substitutions)
 * so Webpack can find and bundle them.
 */
const resources = {
  de: {
    landingPage: () => getBackendResource('de', 'landingPage'),
    fallback: () => import('./de.json').then((module) => module.default),
    translation: () => getBackendResource('de', 'translation'),
    thematicMaps: () => getBackendResource('de', 'thematicMaps'),
    disco: () => getBackendResource('de', 'disco'),
    download: () => getBackendResource('de', 'download'),
    scoring: () => getBackendResource('de', 'scoring'),
    l3plus: () => getBackendResource('de', 'l3plus'),
    developer: () => getBackendResource('de', 'developer'),
    objects: () => getBackendResource('de', 'objects'),
    highstreetReport: () => getBackendResource('de', 'highstreetReport'),
    cityStructure: () => getBackendResource('de', 'cityStructure'),
    marketData: () => getBackendResource('de', 'marketData'),
    datasources: () => getBackendResource('de', 'datasources'),
    tabledata: () => getBackendResource('de', 'tabledata'),
    retailMarkets: () => getBackendResource('de', 'retailMarkets'),
    typeOfGoods: () => getBackendResource('de', 'typeOfGoods'),
    informations: () => getBackendResource('de', 'informations'),
    statistics: () => getBackendResource('de', 'statistics'),
  },
  en: {
    fallback: () => import('./en.json').then((module) => module.default),
    translation: () => getBackendResource('en', 'translation'),
    landingPage: () => getBackendResource('en', 'landingPage'),
    thematicMaps: () => getBackendResource('en', 'thematicMaps'),
    disco: () => getBackendResource('en', 'disco'),
    download: () => getBackendResource('en', 'download'),
    scoring: () => getBackendResource('en', 'scoring'),
    l3plus: () => getBackendResource('en', 'l3plus'),
    developer: () => getBackendResource('en', 'developer'),
    objects: () => getBackendResource('en', 'objects'),
    highstreetReport: () => getBackendResource('en', 'highstreetReport'),
    cityStructure: () => getBackendResource('en', 'cityStructure'),
    marketData: () => getBackendResource('en', 'marketData'),
    datasources: () => getBackendResource('en', 'datasources'),
    tabledata: () => getBackendResource('en', 'tabledata'),
    retailMarkets: () => getBackendResource('en', 'retailMarkets'),
    typeOfGoods: () => getBackendResource('en', 'typeOfGoods'),
    informations: () => getBackendResource('en', 'informations'),
    statistics: () => getBackendResource('en', 'statistics'),
  },
}

/**
 * Get configured namespaces
 *
 * Also assures that all languages have the same namespaces configured
 */
function getNamespaces() {
  const canonicalize = (arr) => arr.sort().join('')
  let namespaces = null
  for (const lang in resources) {
    const nsArray = Object.keys(resources[lang])
    if (namespaces === null) {
      namespaces = nsArray
    } else {
      if (canonicalize(namespaces) !== canonicalize(nsArray)) {
        throw Error('All languages must have the same namespaces configured')
      }
    }
  }
  return namespaces
}
export const languages = Object.keys(resources)
export const namespaces = getNamespaces()

/**
 * Custom backend for i18n
 *
 * Will acquire resources either from json file or backend depending on namespace
 *
 * See https://www.i18next.com/misc/creating-own-plugins
 */
const CustomBackend = {
  type: 'backend',
  init: function (services, backendOptions, i18nextOptions) {},
  read: function (language, namespace, callback) {
    if (language === 'dev') return callback(null, {})
    if (!languages.includes(language)) callback(Error(`Language ${language} not configured`), null)
    if (!namespaces.includes(namespace)) callback(Error(`Namespace ${namespace} not configured`), null)
    const retrieveResource = resources[language][namespace]
    retrieveResource()
      .then((resource) => callback(null, resource))
      .catch((err) => callback(err, null))
  },
  preload: ['de'],
  save: function (language, namespace, data) {},
  create: function (_, namespace, key) {
    api.I18n.postLog({ key: `${namespace}:${key}`, location: window.location.pathname })
  },
}

/**
 * By default i18n is very verbose in logging. When setting debug: false
 * however, all logs are supresed, even warnings and errors. I want to keep
 * warnings and errors while supressing messages.
 *
 * Some of the messages i18next keeps in log level "log": initialized,
 * language changed, namespace loaded, missing key
 *
 * These messages will now be logged as "debug" messages. To supress them
 * disable the category in the browsers devtools or set VERBOSE_I18N=false in
 * .env
 */
const CustomLogger = {
  type: 'logger',
  error: (args) => console.error(...args),
  warn: (args) => console.warn(...args),
  log: (args) => verboseI18n === 'true' && console.debug(...args),
}

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(LanguageDetector)
  .use(CustomBackend)
  .use(initReactI18next)
  .use(CustomLogger)
  .init({
    supportedLngs: [...languages, 'dev'],
    // set dev as first callback so missing keys are saved there and not pollute the 'de'-ressource
    fallbackLng: ['dev', 'de'],
    debug: nodeEnv === 'development',
    saveMissing: true,
    interpolation: {
      // react already safes from xss mit del mit mm mn mReac.tcompne
      escapeValue: false,
      format,
      skipOnVariables: false,
    },
    // treat null as missing translation
    returnNull: false,
    appendNamespaceToCIMode: true,
  })

export default i18n

function format(value, format, lng) {
  switch (format) {
    case 'plus1':
      return Number(value) + 1
    case 'minus2':
      return value - 2
    case 'minus3':
      return value - 3
    case 'minus5':
      return value - 5
    case 'date': {
      if (!value) return '-'
      const date = new Date(value)
      return lng === 'de'
        ? `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(
            -2
          )}.${date.getFullYear()}`
        : `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(
            -2
          )}/${date.getFullYear()}`
    }
    default:
      return value
  }
}

// Sync attribute "lang" of the root html-Tag with selected language
i18n.on('languageChanged', (lang) => document.documentElement.setAttribute('lang', lang))

export function sanitizeKey(key) {
  if (!key || typeof key.replaceAll !== 'function') {
    return 'null'
  }
  return key.replaceAll('.', '-=dot=-').replaceAll(':', '-=colon=-')
}

export const useLoadAllTranslations = ({ start = true } = {}) => {
  const { i18n } = useTranslation(start ? namespaces : [])

  // load all languages
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    if (start) {
      loadLanguages(languages).then(() => setLoading(false))
    }
  }, [i18n, start])

  return isLoading
}

export const useTranslationKeys = (namespace) => {
  const { i18n } = useTranslation(namespaces)

  const keys = useMemo(() => {
    const lng = i18n.options.fallbackLng[1]
    const data = i18n.getDataByLanguage(lng)
    if (isNullOrUndefined(namespace) || namespace === 'all') {
      return extractKeys(data).map((key) => ({ key: key.replace('.', ':') }))
    } else {
      return extractKeys(data[namespace]).map((key) => ({ key: [namespace, key].join(':') }))
    }
  }, [i18n, namespace])

  return keys
}

function extractKeys(obj, path, keys = []) {
  for (const key in obj) {
    const keyPath = `${path ? path + '.' : ''}${key}`
    if (typeof obj[key] === 'object') {
      extractKeys(obj[key], keyPath, keys)
    } else {
      keys.push(keyPath)
    }
  }
  return keys
}
