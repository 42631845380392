import createStore from 'lib/flux-store'
import api from 'stores/api'
import functionQueue from 'lib/util/functionQueue'
import { getDocumentList } from './IndexCache'

const currentYear = new Date().getFullYear()

const initialState = {
  isLoading: true,
  topic: [],
  time: { from: 2010, to: currentYear },
  documentToDownload: null,
}

const setLoadingSrc = {}

const queuedSave = functionQueue(api.AppState.save)
let lastSavedState = initialState

const __NAME__ = 'PropertyIndexStateStore'

const callbacks = {
  onUpdate: (state) => {
    const changed = Object.keys(state).reduce((changed, key) => {
      return changed || !Object.is(lastSavedState[key], state[key])
    }, false)

    if (changed) {
      lastSavedState = { ...state }
      return queuedSave(__NAME__, lastSavedState)
    } else {
      return Promise.resolve()
    }
  },
}

const actions = {
  loadState: () => (dispatch) => {
    api.AppState.get(__NAME__)
      .then((response) => {
        const state = response.data.data
        dispatch({ type: 'setLoadedState', payload: state })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
  setTopic: (topic) => ({ topic }),
  setTime: (time) => ({ time }),
  fetchDocument: () => (dispatch) => {
    // copy to /srv/bg-services/scoring/current/files/download
    getDocumentList('gpi-report').then((documentList) => {
      dispatch({ type: 'setDocumentList', payload: documentList?.[0] ?? null })
    })
  },
}

const reducer = {
  setFailure: (state, { payload }) => ({ ...state, isLoading: false, isFailure: true, messages: payload }),
  setLoadedState: (state, { payload }) => {
    const set = Object.keys(initialState).reduce((set, key) => {
      if (typeof payload[key] !== 'undefined') {
        if (setLoadingSrc[key]) {
          set[key] = { ...payload[key], setBy: 'loader' }
        } else {
          set[key] = payload[key]
        }
      }
      if (Array.isArray(initialState[key])) {
        if (!Array.isArray(set[key])) {
          set[key] = [...initialState[key]]
        }
      } else if (typeof set[key] === 'object' && typeof initialState[key] === 'object') {
        set[key] = { ...initialState[key], ...set[key] }
      } else if (typeof set[key] === 'undefined') {
        if (Array.isArray(initialState[key])) {
          set[key] = [...initialState[key]]
        } else if (typeof initialState[key] === 'object') {
          set[key] = { ...initialState[key] }
        } else {
          set[key] = initialState[key]
        }
      }
      return set
    }, {})
    lastSavedState = { ...state, ...set, isLoading: false }
    return lastSavedState
  },
  setTopic: (state, { topic }) => ({ ...state, topic }),
  setTime: (state, { time }) => {
    let nextTime
    if (typeof time === 'function') {
      nextTime = time(state.time)
    } else {
      nextTime = time
    }
    return { ...state, time: nextTime }
  },
  setDocumentList: (state, { payload }) => {
    return { ...state, documentToDownload: payload }
  },
}

export const [IndexContext, IndexProvider, useIndexStore] = createStore(
  reducer,
  actions,
  initialState,
  callbacks,
  __NAME__
)
