import React from 'react'
import { useTheme } from 'styled-components'

import { FlexColumn } from '@layout/BuildingBlocks'
import { Text } from '@typography/ContentElements'

import { getThemeValue } from 'theming'
import { array, oneOfType, string } from 'prop-types'

export const MetalineWithComponents = ({
  metaline = 'Metaline',
  metalineColor = null,
  typeScaleMeta = 'minion',
  typeStyleMeta = 'minionLight',
  width = null,
  flexRowGap = null,
  metalineTextTransform,
  children,
}) => {
  const theme = useTheme()
  const space = flexRowGap || getThemeValue(theme, 'spaces.rhythm.vertical.nano')
  const defaultMetalineColor = getThemeValue(theme, 'colors.base.grayScale.800')
  return (
    <FlexColumn alignItems="flex-start" flexRowGap={space} width={width}>
      <Text
        height="100%"
        text={metaline}
        color={metalineColor || defaultMetalineColor}
        typeStyle={typeStyleMeta}
        typeScale={typeScaleMeta}
        textTransform={metalineTextTransform}
        display="block"
      />
      {children}
    </FlexColumn>
  )
}
MetalineWithComponents.propTypes = {
  metaline: string,
  metalineColor: string,
  typeScaleMeta: string,
  typeStyleMeta: string,
  width: string,
  flexRowGap: oneOfType([string, array]),
}
