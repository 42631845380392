import React, { useMemo } from 'react'
import { styled, css } from 'styled-components'
import { space, layout } from 'styled-system'

import { IconComponent, PositionComponent } from './SubComponents'

import { size } from './variants'
import { array, string, elementType, number, oneOfType, shape } from 'prop-types'

const PlaceholderIcon = ({ ...props }) => {
  return (
    <svg viewBox="0 0 24 124" xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height}>
      <circle cx="50%" cy="50%" r="10" fill="lightgray" stroke="gray" strokeWidth="2px" />
    </svg>
  )
}

const defaultPropsIcon = {
  icon: {
    Icon: PlaceholderIcon,
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: '0px',
    type: 'solid',
    height: '100%',
    width: '100%',
  },
}

const defaultPropsIconWrapper = {
  iconWrapper: {
    height: 'auto',
    marginTop: null,
    marginRight: null,
    marginBottom: null,
    marginLeft: null,
    width: 'auto',
  },
}

const styles = css`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`

const VariantStyledComponent = styled.span.withConfig({
  shouldForwardProp: (prop) => ['style', 'children'].includes(prop),
})`
  transform: ${(props) => props.transform};
  ${styles}
  ${space}
  ${layout}
  ${size}
`

const CustomStyledComponent = styled.span.withConfig({
  shouldForwardProp: (prop) => ['style', 'children'].includes(prop),
})`
  transform: ${(props) => props.transform};
  ${styles}
  ${layout}
  ${space}
`

const withVariantStyle =
  (Component) =>
  ({ className, iconWrapper, iconVariantSize, mr, ml, transform, ...props }) => {
    const { height, marginTop, marginRight, marginBottom, marginLeft, width } = useMemo(
      () => ({ ...defaultPropsIconWrapper.iconWrapper, ...iconWrapper }),
      [iconWrapper]
    )

    return (
      <VariantStyledComponent
        className={className}
        height={height}
        marginTop={marginTop}
        marginRight={mr || marginRight}
        marginBottom={marginBottom}
        marginLeft={mr || marginLeft}
        width={width}
        size={iconVariantSize}
        transform={transform}
        {...props}
      >
        <Component {...props} />
      </VariantStyledComponent>
    )
  }

const withCustomStyle =
  (Component) =>
  ({ className, iconWrapper, transform, ...props }) => {
    const { height, marginTop, marginRight, marginBottom, marginLeft, width } = useMemo(
      () => ({ ...defaultPropsIconWrapper.iconWrapper, ...iconWrapper }),
      [iconWrapper]
    )
    return (
      <CustomStyledComponent
        className={className}
        height={height}
        marginTop={marginTop}
        marginRight={marginRight}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        width={width}
        transform={transform}
      >
        <Component {...props} />
      </CustomStyledComponent>
    )
  }

const WrappedComponent = ({ icon, iconPosition, ...props }) => {
  return (
    <PositionComponent iconPosition={iconPosition}>
      <IconComponent icon={icon} {...props} />
    </PositionComponent>
  )
}

const IconWrapperWithVariantStyle = withVariantStyle(WrappedComponent)
const IconWrapperWithCustomStyle = withCustomStyle(WrappedComponent)

export const IconWrapper = ({
  icon = defaultPropsIcon.icon,
  iconVariantSourceName = 'icons',
  ml = 0,
  mr = 0,
  iconVariantSize = 'medium',
  height = null,
  width = null,
  ...props
}) => {
  const customWidth = icon?.width || props.iconWrapper?.width

  props = { ...props, icon, iconVariantSourceName, ml, mr, iconVariantSize, height, width }

  return customWidth ? <IconWrapperWithCustomStyle {...props} /> : <IconWrapperWithVariantStyle {...props} />
}

IconWrapper.propTypes = {
  icon: shape({
    Icon: elementType,
    fill: string,
    stroke: string,
    strokeWidth: oneOfType([string, number, array]),
    type: string,
    height: oneOfType([string, array]),
    width: oneOfType([string, array]),
  }),
  iconPosition: shape({
    top: oneOfType([string, number, array]),
    right: oneOfType([string, number, array]),
    bottom: oneOfType([string, number, array]),
    left: oneOfType([string, number, array]),
    marginTop: oneOfType([string, number, array]),
    marginRight: oneOfType([string, number, array]),
    marginBottom: oneOfType([string, number, array]),
    marginLeft: oneOfType([string, number, array]),
  }),
  iconWrapper: shape({
    marginTop: oneOfType([string, number, array]),
    marginRight: oneOfType([string, number, array]),
    marginBottom: oneOfType([string, number, array]),
    marginLeft: oneOfType([string, number, array]),
    height: oneOfType([string, number, array]),
    width: oneOfType([string, number, array]),
  }),
  iconVariantSourceName: string,
  iconVariantSize: string,
  transform: string,
  ml: oneOfType([string, number, array]),
  mr: oneOfType([string, number, array]),
  height: oneOfType([string, array]),
  width: oneOfType([string, array]),
}
