import React from 'react'
import { styled, useTheme, keyframes, css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { space } from 'styled-system'

import { getThemeValue } from 'theming'

const dialogAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(75%,75%);
  }
  100% {
    opacity: 1;
    transform: scale(1,1);
  }
`

const animation = css`
  animation-name: ${dialogAnimation};
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-delay: 0.25s;
  animation-duration: 0.25s;
`

const ModalDialogComponent = styled.div`
  background: ${themeGet('modal.colors.dialog.bg', '0')};
  border-radius: ${themeGet('modal.border.radius', '0')};
  display: flex;
  flex-direction: column;
  max-height: ${themeGet('modal.dialog.space.maxHeight', '100vh')};
  overflow-y: hidden;
  transform: scale(0, 0);
  opacity: 0;
  ${space};
  ${animation};
`

export const ModalDialog = ({ children, ...props }) => {
  const theme = useTheme()
  const spaceX = getThemeValue(theme, 'modal.dialog.space.x')

  return (
    <ModalDialogComponent
      id={props.id}
      className="modal-dialog"
      onAnimationStart={props.handlerAnimationStart}
      onAnimationEnd={props.handlerAnimationEnd}
      px={spaceX}
    >
      {children}
    </ModalDialogComponent>
  )
}

ModalDialog.displayName = 'modalDialog'
ModalDialog.propTypes = {}
