import React from 'react'

import { StyledText } from '../StyledText'
import { typeStyles } from '../typeStyles'

import { defaultPropTypes } from '../defaultPropTypes'

export const Canon = ({ children, tag, ...props }) => (
  <StyledText scale="canon" {...props} {...typeStyles.canon} as={tag}>
    {children}
  </StyledText>
)

export const CanonMedium = ({ children, tag, ...props }) => (
  <StyledText scale="canon" {...props} {...typeStyles.canonMedium} as={tag}>
    {children}
  </StyledText>
)

export const CanonSemiBold = ({ children, tag, ...props }) => (
  <StyledText scale="canon" {...props} {...typeStyles.canonSemiBold} as={tag}>
    {children}
  </StyledText>
)

export const CanonBlack = ({ children, tag, ...props }) => (
  <StyledText scale="canon" {...props} {...typeStyles.canonBlack} as={tag}>
    {children}
  </StyledText>
)
Canon.propTypes = defaultPropTypes
CanonMedium.propTypes = defaultPropTypes
CanonSemiBold.propTypes = defaultPropTypes
CanonBlack.propTypes = defaultPropTypes
