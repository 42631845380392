import React, { forwardRef } from 'react'
import { styled } from 'styled-components'
import { typography } from 'styled-system'
import { bool, string } from 'prop-types'

import { HTMLButtonElement } from '../SubComponents'

import { useButtonProgressbar } from 'lib/hooks'

import { appearance, shape, size } from './variants'
import { typeMap, typeScale, typeStyle } from '@typography'

const ButtonElement = styled(HTMLButtonElement)`
  align-self: center;
  display: flex;
  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  ${appearance}
  ${shape}
  ${(props) => props.shape !== 'shapeless' && size}
  ${(props) => props.width && { width: props.width }}
  ${(props) => props.height && { height: props.height }}
  ${(props) => props.stretch && { width: '100%' }}
  ${(props) => props.leftAlign && { justifyContent: 'flex-start' }}
`

const fontSizeDefault = [0, 1, 2, 3, 4]

export const ButtonProgressbar = forwardRef(
  (
    {
      children,
      fontFamily = 'whitneySSm',
      fontSize = fontSizeDefault,
      lineHeight = 'normal',
      appearance = 'lightgray',
      leftAlign = null,
      size = 'medium',
      shape = 'oval',
      stretch = null,

      ...props
    },
    ref
  ) => {
    props = { ...props, fontFamily, fontSize, lineHeight, appearance, leftAlign, size, shape, stretch }
    const { typeStyle, typeScale } = typeMap[props.size]
    const progressbarStyles = useButtonProgressbar(props)
    const disabled = props.disabled || typeof props.progress === 'number'

    return (
      <ButtonElement
        {...props}
        typeScale={typeScale}
        typeStyle={typeStyle}
        ref={ref}
        style={progressbarStyles}
        disabled={disabled}
      />
    )
  }
)
ButtonProgressbar.propTypes = {
  appearance: string,
  leftAlign: bool,
  shape: string,
  size: string,
  text: string,
  stretch: bool,
}
