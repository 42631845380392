import React, { forwardRef } from 'react'
import { styled, css } from 'styled-components'
import { typography } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { typeMap, typeScale, typeStyle } from '@typography'

import { CheckboxCheckmark } from '@utilities/Switches'
import { FlexRow, PosAbsolute, PosRelative } from '@layout/BuildingBlocks'

import { bool, string } from 'prop-types'

const excludeProps = [
  'hasCursor',
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'scale',
  'tag',
  'typeScale',
  'typeStyle',
  'withCheckbox',
]

const defaultStyle = css`
  background: none;
  color: ${(props) => themeGet('dropdown.option.default.color', 'gray')(props)};
`

const disabledStyle = css`
  color: ${(props) => themeGet('dropdown.option.disabled.color', 'gray')(props)};
`

const cursorStyle = css`
  color: ${(props) => themeGet('dropdown.option.cursor.color', 'gray')(props)};
  background: ${(props) => themeGet('dropdown.option.cursor.bg', 'gray')(props)};
  border-radius: ${(props) => themeGet('dropdown.option.radius', '2px')(props)};
`

const selectedStyleDefault = css`
  color: ${(props) => themeGet('dropdown.option.selected.color', 'gray')(props)};
  background: ${(props) => themeGet('dropdown.option.selected.bg', 'gray')(props)};
  border-radius: ${(props) => themeGet('dropdown.option.radius', '2px')(props)};
  font-weight: ${(props) => themeGet('dropdown.option.selected.fontWeight', 'gray')(props)};
`

const selectedStyleWithCheckbox = css`
  background: none;
  color: ${(props) => themeGet('dropdown.option.default.color', 'gray')(props)};
  font-weight: ${(props) => themeGet('dropdown.option.selected.fontWeight', 'gray')(props)};
`

const selectedStyleHasCursorWithCheckbox = css`
  color: ${(props) => themeGet('dropdown.option.hover.color', 'gray')(props)};
  background: ${(props) => themeGet('dropdown.option.hover.bg', 'gray')(props)};
  border-radius: ${(props) => themeGet('dropdown.option.radius', '2px')(props)};
  font-weight: ${(props) => themeGet('dropdown.option.selected.fontWeight', 'gray')(props)};
`

export const LabelText = styled.span.withConfig({
  shouldForwardProp: (props) => !excludeProps.includes(props),
})`
  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}

  align-items: center;
  background: ${(props) => themeGet('dropdown.option.default.bg', '#fff')(props)};
  color: ${(props) => themeGet('dropdown.option.default.color', 'gray')(props)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  min-width: 100%;
  padding: ${(props) => themeGet('dropdown.option.py', '#fff')(props)}
    ${(props) => themeGet('dropdown.option.px', '#fff')(props)};
  width: max-content;

  ${(props) =>
    !props.disabled &&
    `&:hover {
        color: ${themeGet('dropdown.option.hover.color', 'gray')(props)};
        background: ${themeGet('dropdown.option.hover.bg', 'gray')(props)};
        border-radius: ${themeGet('dropdown.option.radius', '2px')(props)};
    }`}

  ${(props) =>
    props.disabled
      ? disabledStyle
      : props.checked
        ? props.withCheckbox
          ? props.hasCursor
            ? selectedStyleHasCursorWithCheckbox
            : selectedStyleWithCheckbox
          : selectedStyleDefault
        : props.hasCursor
          ? cursorStyle
          : defaultStyle}
`

const fontSizeDefault = [0, 1, 2, 3, 4]

export const OptionsLabelText = forwardRef(
  ({ children, checked = false, disabled = false, hasCursor = false, size = 'small', withCheckbox }, ref) => {
    const { typeStyle, typeScale } = typeMap[size]
    return (
      <LabelText
        checked={checked}
        disabled={disabled}
        hasCursor={hasCursor}
        ref={ref}
        typeScale={typeScale}
        typeStyle={typeStyle}
        withCheckbox={withCheckbox}
        fontFamily="whitneySSm"
        fontSize={fontSizeDefault}
        lineHeight="normal"
      >
        {children}
      </LabelText>
    )
  }
)

const withCheckbox =
  (Component) =>
  ({ children, ...props }) => {
    return (
      <Component withCheckbox {...props}>
        <PosRelative>
          <FlexRow alignItems="center">
            <PosAbsolute marginTop="-1px">
              <CheckboxCheckmark checked={props.checked} disabled={props.disabled} />
            </PosAbsolute>
            <PosRelative left="36px">{children}</PosRelative>
          </FlexRow>
        </PosRelative>
      </Component>
    )
  }

export const OptionsLabelTextCheckbox = withCheckbox(OptionsLabelText)
OptionsLabelText.propTypes = {
  checked: bool,
  disabled: bool,
  hasCursor: bool,
  size: string,
}
