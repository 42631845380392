import React, { useRef } from 'react'
import { styled, css } from 'styled-components'

import { useControlsSegmentSectionContext } from '@ui/ControlsSegment/SubComponents'
import { useMaxHeightOnTransition } from '@ui/ControlsSegment/useMaxHeightOnTransition'

const foldedStyle = css`
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.01s;
`

const unfoldedStyle = css`
  display: block;
  flex: 1 1 auto;
  max-height: ${(props) => props.maxHeight}px;
  opacity: 1;
  transition: all 0.3s;
`

const StyledComponent = styled.div`
  opacity: 1;
  ${(props) => (props.folded ? foldedStyle : unfoldedStyle)};
`

export const ControlsSegmentContent = ({ children }) => {
  const { folded, foldDisabled } = useControlsSegmentSectionContext()
  const refContainer = useRef()

  const maxHeight = useMaxHeightOnTransition({ refTarget: refContainer, folded })

  return (
    <StyledComponent maxHeight={maxHeight} folded={folded} foldDisabled={foldDisabled} ref={refContainer}>
      {children}
    </StyledComponent>
  )
}
