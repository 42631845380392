import React, { forwardRef, useLayoutEffect, useRef } from 'react'
import { styled } from 'styled-components'
import { typography } from 'styled-system'
import { bool, string } from 'prop-types'

import { HTMLButtonElementWithIcon } from '../SubComponents'
import { IconWrapper } from '@utilities/IconWrapper'

import { appearance, shape, size } from './variants'
import { typeMap, typeScale, typeStyle } from '@typography'

const StyledButton = styled(HTMLButtonElementWithIcon)`
  border: 0;
  padding: 0;
  background: none;
  display: block;
  ${appearance}
  ${shape}
  width: ${(props) => props.stretch && '100%'};
`

const StyledComponent = styled.span.withConfig({
  shouldForwardProp: (prop) => ['style', 'children', 'onMouseEnter', 'onMouseLeave'].includes(prop),
})`
  align-self: center;
  align-items: center;
  display: flex;
  justify-content: center;
  transform: ${(props) => props.transform};
  &::after {
    content: '0';
    width: 0;
    max-width: 0;
    overflow: hidden;
    visibility: hidden;
  }
  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  ${(props) => props.shape !== 'shapeless' && size}
  ${(props) => props.width && { width: props.width }}
  ${(props) => props.height && { height: props.height }}
`

const fontSizeDefault = [0, 1, 2, 3, 4]
const emptyFunc = () => {}

export const ButtonIcon = forwardRef(
  (
    {
      children,
      className,
      onClick = emptyFunc,
      fontFamily = 'whitneySSm',
      fontSize = fontSizeDefault,
      lineHeight = 'normal',
      appearance = 'lightgray',
      equalSides = true,
      iconAlign = 'left',
      iconVariantSourceName = 'buttonIcons',
      iconVariantSize = 'medium',
      size = 'medium',
      shape = 'oval',
      stretch = null,
      ...props
    },
    ref
  ) => {
    props = {
      ...props,
      fontFamily,
      fontSize,
      lineHeight,
      appearance,
      equalSides,
      iconAlign,
      iconVariantSourceName,
      iconVariantSize,
      size,
      shape,
    }

    const { typeStyle, typeScale } = typeMap[props.size]
    const innerRef = useRef()

    useLayoutEffect(() => {
      if (props.equalSides && !stretch) {
        innerRef.current.style.width = window.getComputedStyle(innerRef.current).height
      } else {
        innerRef.current.style.width = 'unset'
      }
    }, [innerRef, props.equalSides, stretch])

    return (
      <StyledButton className={className} onClick={onClick} {...props} ref={ref} stretch={stretch}>
        <StyledComponent {...props} typeScale={typeScale} typeStyle={typeStyle} ref={innerRef}>
          <IconWrapper
            icon={props.icon}
            iconPosition={props.iconPosition}
            iconWrapper={props.iconWrapper}
            iconVariantSourceName={props.iconVariantSourceName}
            iconVariantSize={props.iconVariantSize}
          />
        </StyledComponent>
      </StyledButton>
    )
  }
)
ButtonIcon.propTypes = {
  appearance: string,
  equalSides: bool,
  iconAlign: string,
  iconVariantSourceName: string,
  iconVariantSize: string,
  shape: string,
  size: string,
  stretch: bool,
}
