import React, { memo } from 'react'

export const RiwisToken = memo(
  ({
    width = 32,
    height = 32,
    riwisLogoScale = 'scale(1 1)',
    dropShadow = 'drop-shadow(0 4px 3px rgba(0, 0, 0, 0.25))',
  }) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ filter: dropShadow }}
      >
        <circle cx="16" cy="16" r="16" fill="#086E80" />
        <g transform={riwisLogoScale}>
          <path
            d="M24.7332 14.1225C25.3709 11.1221 23.9377 6.92892 18.5369 5.78095L10.1582 4L13.5127 9.47974L17.1705 10.2572C19.0768 10.6624 19.8002 11.9452 19.4941 13.3854C19.2645 14.4656 18.5724 14.9874 17.367 15.7768L13.2659 18.5859L17.8735 26.0867L24.5848 27.5133L20.0796 20.2C22.9016 18.249 24.1124 17.0431 24.7332 14.1225Z"
            fill="white"
          />
          <path d="M10.1582 4L17.367 15.7768L5.99997 23.563L10.1582 4Z" fill="#C4DA58" />
        </g>
      </svg>
    )
  }
)
