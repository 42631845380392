import React, { memo, useEffect, useCallback, useMemo, useState } from 'react'

import { useDebouncedUpdates, useControllableState } from 'lib/hooks'
import { isFunction } from 'lib/util'

import { Slider, FormElement, withFormElementProps } from '@forms'

import { defaultPropTypes } from './defaultPropTypes'

const groupStateToValue = (state) => {
  const [from, to] = state.value.slider.value
  return { from, to }
}

const valueToGroupState = (value) => {
  return { slider: { value: [value.from, value.to] } }
}

const defaultMin = 1975
const defaultMax = 2050
const defaultValueProp = { from: defaultMin, to: defaultMax }

export const SliderYearRangeComponent = ({
  name = 'SliderYearRangeComponent',
  min = defaultMin,
  max = defaultMax,
  defaultValue = defaultValueProp,
  debounce = 100, // default 100, set property to null to turn debounce off
  appearance = 'default', // 'allRail', 'allTrack' or 'default'

  formElementRefs,
  onChange,
  minLimit,
  maxLimit,
  value,
  ...props
}) => {
  const [initialValue] = useState(defaultValue ?? value ?? [])

  const onDebouncedUpdate = useCallback(
    (value) => {
      isFunction(onChange) && onChange({ id: props.id, name, value })
    },
    [onChange, props.id, name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [internalValue, setInternalValue] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const formState = useMemo(() => valueToGroupState(internalValue), [internalValue])

  const internalOnChange = useCallback(
    (target) => {
      const [from, to] = target.value.slider.value
      if (internalValue.from !== from || internalValue.to !== to) {
        setInternalValue(groupStateToValue(target))
      }
    },
    [setInternalValue, internalValue]
  )

  const onReset = useCallback(() => {
    setInternalValue(initialValue)
  }, [setInternalValue, initialValue])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = internalValue
    }
  }, [internalValue, formElementRefs])

  return (
    <FormElement {...props} name={name} value={formState} onChange={internalOnChange}>
      <Slider
        forElementName={name}
        name="slider"
        min={min}
        max={max}
        minLimit={minLimit}
        maxLimit={maxLimit}
        appearance={appearance}
        debounce={debounce}
        {...props}
      />
    </FormElement>
  )
}

SliderYearRangeComponent.type = 'SliderYear'
SliderYearRangeComponent.propTypes = defaultPropTypes

export const SliderYearRange = withFormElementProps(memo(SliderYearRangeComponent))
