import React, { useCallback, forwardRef } from 'react'
import { styled } from 'styled-components'
import { typography } from 'styled-system'

import { FlexContainerForwardedRef, FlexItem } from '@layout/BuildingBlocks'
import { IconLeft, IconRight } from './SubComponents'

import { typeMap, typeScale, typeStyle } from '@typography'
import { appearance, shape, size } from './variants'

import { useSlots } from 'lib/hooks/useSlots'
import { oneOfType, object, bool, string } from 'prop-types'

const fontSizeDefault = [0, 1, 2, 3, 4]

const StyledBadge = styled(FlexContainerForwardedRef)`
  align-self: center;
  display: flex;

  &::after {
    content: '0';
    width: 0;
    max-width: 0;
    overflow: hidden;
    visibility: hidden;
  }
  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  ${appearance}
  ${shape}
  ${(props) =>
    props.stretch
      ? {
          display: 'flex',
          flexGrow: 1,
        }
      : { display: 'inline-flex' }}
    ${(props) => props.shape !== 'shapeless' && size}
    ${(props) => props.width && { width: props.width }}
    ${(props) => props.clickable && { cursor: 'pointer' }}
`

export const Badge = forwardRef(
  (
    {
      children,
      fontFamily = 'whitneySSm',
      fontSize = fontSizeDefault,
      lineHeight = 'normal',
      appearance = 'dark',
      iconVariantSourceName = 'badgeIcons',
      iconLeft = null,
      iconRight = null,
      size = 'medium',
      shape = 'square',
      stretch = false,
      tag = 'div',
      text = null,
      disabled = false,
      clickable = true,
      isActive,
      onBlur,
      onClick,
      onFocus,
      tabIndex,
      ...props
    },
    ref
  ) => {
    props = {
      ...props,
      fontFamily,
      fontSize,
      lineHeight,
      appearance,
      iconVariantSourceName,
      iconLeft,
      iconRight,
      size,
      shape,
      stretch,
    }
    const { typeStyle, typeScale } = typeMap[size]
    const [label, content] = useSlots(['label', 'content'], children)

    const handleBlur = useCallback(
      (event) => {
        typeof onBlur === 'function' && onBlur(event)
      },
      [onBlur]
    )

    const handleClick = useCallback(
      (event) => {
        typeof onClick === 'function' && onClick(event)
      },
      [onClick]
    )

    const handleFocus = useCallback(
      (event) => {
        typeof onFocus === 'function' && onFocus(event)
      },
      [onFocus]
    )

    return (
      <StyledBadge
        appearance={appearance}
        clickable={clickable}
        onBlur={handleBlur}
        onClick={handleClick}
        onFocus={handleFocus}
        ref={ref}
        tabIndex={tabIndex}
        tag={tag}
        typeScale={typeScale}
        typeStyle={typeStyle}
        disabled={disabled}
        size={size}
        {...props}
      >
        {iconLeft && <IconLeft {...props} />}
        {text && <FlexItem flexGrow="1">{text}</FlexItem>}
        {label && label}
        {content && content}

        {iconRight && (
          <FlexItem justifySelf="flex-end">
            <IconRight {...props} />
          </FlexItem>
        )}
      </StyledBadge>
    )
  }
)

Badge.Label = ({ children }) => children
Badge.Label.displayName = 'label'

Badge.Content = ({ children }) => children
Badge.Content.displayName = 'content'
Badge.propTypes = {
  appearance: string,
  shape: string,
  size: string,
  stretch: bool,
  tag: string,
  text: oneOfType([string, object]),
  disabled: bool,
  clickable: bool,
}
