import React, { forwardRef } from 'react'
import { styled, useTheme } from 'styled-components'
import { system, grid, layout } from 'styled-system'

import {
  gridAutoRows,
  gridColumnGap,
  gridRowGap,
  gridTemplateAreas,
  gridTemplateColumns,
  gridTemplateRows,
  marginLeft,
  marginRight,
} from './defaultPropTypes'
import { getThemeValue } from 'theming'

export const StyledElement = styled('div').withConfig({
  shouldForwardProp: (prop) => ['style', 'children'].includes(prop),
})(
  () => ({
    display: 'grid',
  }),
  grid,
  layout,
  system({
    gridColumnGap: {
      property: 'gridColumnGap',
      scale: 'gridColumnGaps',
    },
    gridRowGap: {
      property: 'gridRowGap',
      scale: 'gridRowGaps',
    },
    gridTemplateColumns: {
      property: 'gridTemplateColumns',
      scale: 'gridTemplateColumns',
    },
    gridTemplateRows: {
      property: 'gridTemplateRows',
      scale: 'gridTemplateRows',
    },
  })
)

export const GridContainer = ({
  children,
  gridAutoRows = null,
  gridTemplateAreas = null,
  gridTemplateColumns = 24,
  marginLeft = 0,
  marginRight = 0,
  ...props
}) => {
  props = { ...props, gridAutoRows, gridTemplateAreas, gridTemplateColumns, marginLeft, marginRight }
  const theme = useTheme()
  const gridRowGap = props.gridRowGap ?? getThemeValue(theme, 'spaces.gridGaps.row')
  const gridColumnGap = props.gridColumnGap ?? getThemeValue(theme, 'spaces.gridGaps.column')

  return (
    <StyledElement gridRowGap={gridRowGap} gridColumnGap={gridColumnGap} {...props}>
      {children}
    </StyledElement>
  )
}

GridContainer.propTypes = {
  gridAutoRows,
  gridColumnGap,
  gridRowGap,
  gridTemplateAreas,
  gridTemplateColumns,
  gridTemplateRows,
  marginLeft,
  marginRight,
}

export const GridContainerRef = forwardRef(
  (
    {
      children,
      gridAutoRows = null,
      gridTemplateAreas = null,
      gridTemplateColumns = 24,
      marginLeft = 0,
      marginRight = 0,
      ...props
    },
    ref
  ) => {
    props = { ...props, gridAutoRows, gridTemplateAreas, gridTemplateColumns, marginLeft, marginRight }
    return (
      <StyledElement ref={ref} {...props}>
        {children}
      </StyledElement>
    )
  }
)

GridContainerRef.propTypes = {
  gridAutoRows,
  gridColumnGap,
  gridRowGap,
  gridTemplateAreas,
  gridTemplateColumns,
  gridTemplateRows,
  marginLeft,
  marginRight,
}
