import React from 'react'
import { styled } from 'styled-components'
import { typography, border, space, layout, compose } from 'styled-system'
import { bool, node, string } from 'prop-types'

import { typeScale, typeStyle, defaultPropTypes as textPropTypes } from '@typography'

import { appearanceVariants, sizeVariants } from './variants'

const excludeProps = [
  'color',
  'height',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'scale',
  'tag',
  'typeScale',
  'typeStyle',
  'width',
]

export const SizeWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => ['style', 'children'].includes(prop),
})`
  display: inline-block;
  position: relative;
  ${sizeVariants};
  ${appearanceVariants};
`

export const DiskWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !excludeProps.includes(prop),
})`
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  display: flex;
  ${compose(layout, border, space)};
  ${sizeVariants};
`

const StyledText = styled.span.withConfig({
  shouldForwardProp: (prop) => !excludeProps.includes(prop),
})`
  display: flex;
  align-items: center;
  justify-content: center;
  ${typography};
  ${(props) => typeScale(props.typeScale)};
  ${(props) => typeStyle(props.typeStyle)};
`

const textMap = {
  small: {
    typeScale: 'pica',
    typeStyle: 'picaSemiBold',
  },
  default: {
    typeScale: 'copy',
    typeStyle: 'copyBold',
  },

  large: {
    typeScale: 'canon',
    typeStyle: 'canonBlack',
  },
}

export const DiskLetter = ({
  children,
  appearance = 'gray',
  checked = false,
  size = 'default',
  ...props
}) => {
  return (
    <SizeWrapper appearance={appearance} checked={checked} size={size} {...props}>
      <StyledText {...textMap[props.size]} className="text">
        <DiskWrapper className="disk">{children}</DiskWrapper>
      </StyledText>
    </SizeWrapper>
  )
}
StyledText.propTypes = {
  ...textPropTypes,
}
DiskLetter.propTypes = {
  appearance: string,
  children: node,
  checked: bool,
  size: string,
}
