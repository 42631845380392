import React, { memo, useEffect, useCallback, useMemo, useState } from 'react'

import { useControllableState, useDebouncedUpdates } from 'lib/hooks'
import { isFunction } from 'lib/util'

import { FormElement, withFormElementProps } from '@forms'

const groupStateToValue = (state) =>
  Object.entries(state)
    .filter((entry) => entry[1].checked)
    .map((entry) => entry[0])

const valueToGroupState = (value) => Object.fromEntries(value.map((key) => [key, { checked: true }]))

export const CheckboxGroupComponent = ({
  value,
  onChange,
  defaultValue,
  children,
  formElementRefs,
  debounce = null,
  allowMultiple = true,
  ...props
}) => {
  const [initialValue] = useState(defaultValue ?? value ?? [])

  const onDebouncedUpdate = useCallback(
    (checkedChilds, changeEmitter) => {
      isFunction(onChange) &&
        onChange({ id: props.id, name: props.name, value: checkedChilds, changeEmitter })
    },
    [onChange, props.id, props.name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [checkedChilds, setCheckedChilds] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const formState = useMemo(() => valueToGroupState(checkedChilds), [checkedChilds])

  const internalOnChange = useCallback(
    (evt) =>
      allowMultiple
        ? setCheckedChilds(groupStateToValue(evt.value), evt.changeEmitter)
        : setCheckedChilds(evt.checked ? evt.value : [], evt.changeEmitter),
    [setCheckedChilds, allowMultiple]
  )

  const onReset = useCallback(() => {
    setCheckedChilds(initialValue)
  }, [setCheckedChilds, initialValue])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = checkedChilds
    }
  }, [checkedChilds, formElementRefs])

  return (
    <FormElement {...props} value={formState} onChange={internalOnChange}>
      {children}
    </FormElement>
  )
}
CheckboxGroupComponent.type = 'CheckboxGroup'

export const CheckboxGroup = withFormElementProps(memo(CheckboxGroupComponent))
