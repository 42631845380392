import React, { useMemo } from 'react'
import { IconWrapper } from '@utilities/IconWrapper'
import { BudiconLocationPin } from 'bgag-budicons'
import { styled } from 'styled-components'
import { appearance } from './variants'

const appearanceMapping = {
  0: 'locationPinOne',
  1: 'locationPinTwo',
  2: 'locationPinThree',
  3: 'locationPinFour',
  4: 'locationPinFive',
}

const LocationPinWrapper = styled(IconWrapper)`
  ${appearance}
`

export const LocationPin = ({
  locationId = null,
  color = 'currentColor',
  checked = true,
  width = null,
  height = null,
  ...props
}) => {
  const type = useMemo(() => {
    return checked ? 'solid' : 'outline'
  }, [checked])

  const strokeWidth = checked ? 0 : '0.3'

  const appearance =
    locationId === null ? null : appearanceMapping[locationId % Object.keys(appearanceMapping).length]

  const options =
    width || height
      ? {
          iconWrapper: {
            width,
            height,
          },
        }
      : {
          iconVariantSourceName: 'buttonIcons',
        }

  return (
    <LocationPinWrapper
      appearance={appearance}
      icon={{
        Icon: BudiconLocationPin,
        stroke: color,
        strokeWidth,
        fill: color,
        type,
        width: props.iconWidth,
      }}
      {...options}
      {...props}
    />
  )
}
