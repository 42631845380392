import { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const checkboxAppearance = (props) => css`
  background: ${(props) =>
    props.disabled
      ? themeGet(`uiActionElements.${props.appearance}.disabled.bg`, 'gray')(props)
      : props.checked
        ? themeGet(`uiActionElements.${props.appearance}.checked.bg`, 'gray')(props)
        : themeGet(`uiActionElements.${props.appearance}.default.bg`, 'gray')(props)};

  border-color: ${(props) =>
    props.disabled
      ? themeGet(`uiActionElements.${props.appearance}.disabled.bg`, 'gray')(props)
      : props.checked
        ? themeGet(`uiActionElements.${props.appearance}.checked.borderColor`, 'gray')(props)
        : themeGet(`uiActionElements.${props.appearance}.default.borderColor`, 'gray')(props)};

  color: ${(props) =>
    props.disabled
      ? themeGet(`uiActionElements.${props.appearance}.disabled.color`, '#000')(props)
      : props.checked
        ? themeGet(`uiActionElements.${props.appearance}.checked.color`, '#000')(props)
        : themeGet(`uiActionElements.${props.appearance}.default.color`, '#000')(props)};

  &:hover {
    background: ${(props) =>
      props.disabled
        ? themeGet(`uiActionElements.${props.appearance}.disabled.bg`, 'gray')(props)
        : props.checked
          ? themeGet(`uiActionElements.${props.appearance}.checked.bg`, 'gray')(props)
          : themeGet(`uiActionElements.${props.appearance}.hover.bg`, 'gray')(props)};

    border-color: ${(props) =>
      props.disabled
        ? themeGet(`uiActionElements.${props.appearance}.disabled.bg`, 'gray')(props)
        : props.checked
          ? themeGet(`uiActionElements.${props.appearance}.checked.borderColor`, 'gray')(props)
          : themeGet(`uiActionElements.${props.appearance}.default.borderColor`, 'gray')(props)};

    color: ${(props) =>
      props.disabled
        ? themeGet(`uiActionElements.${props.appearance}.disabled.color`, '#000')(props)
        : props.checked
          ? themeGet(`uiActionElements.${props.appearance}.checked.color`, '#000')(props)
          : themeGet(`uiActionElements.${props.appearance}.hover.color`, '#000')(props)};
  }
`
