import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'

import { getThemeValue } from 'theming'

import { FlexColumn, FlexRow } from '@layout/BuildingBlocks'
import { CopySemiBold } from '@typography'

export const withMaxLengthIndicator = (WrappedComponent) => (props) => {
  const { value, maxLength } = props

  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')

  const valueLength = useMemo(() => value?.length || 0, [value])
  const isMaxLength = useMemo(() => valueLength === maxLength, [valueLength, maxLength])

  return (
    <FlexColumn width="100%">
      <WrappedComponent {...props} />
      <FlexRow width="100%" justifyContent="flex-end" alignItems="right" mt={spaces.rhythm.horizontal.tiny}>
        {isMaxLength ? (
          <CopySemiBold>
            {valueLength}/{maxLength}
          </CopySemiBold>
        ) : (
          <>
            {valueLength}/{maxLength}
          </>
        )}
      </FlexRow>
    </FlexColumn>
  )
}
