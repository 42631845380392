import React, { forwardRef, useMemo } from 'react'
import { styled, css, useTheme } from 'styled-components'

import { getThemeValue } from 'theming'

import { compose, typography } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { CheckboxCheckmark } from '@utilities/Switches'
import { FlexRow, FlexItem, PosRelative } from '@layout/BuildingBlocks'

import { Copy, typeScale, typeStyle } from '@typography'

import { checked, disabled, onFocus, label } from './defaultPropTypes'

const fontSizeDefault = [0, 1, 2, 3, 4]

const textProps = {
  fontFamily: 'whitneySSm',
  fontSize: fontSizeDefault,
  lineHeight: 'normal',
}

const excludeProps = [
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'scale',
  'tag',
  'typeScale',
  'typeStyle',
]

const defaultStyle = css`
  ${typeStyle('copy')}
  color:${(props) => themeGet('colors.base.gray.900', 'gray')(props)};
`

const disabledStyle = css`
  ${typeStyle('copy')}
  color:${(props) => themeGet('colors.base.gray.400', 'gray')(props)};
`

const selectedStyle = css`
  ${typeStyle('copyMedium')}
`

const LabelText = styled.span.withConfig({
  shouldForwardProp: (props) => !excludeProps.includes(props),
})`
  ${compose(typography)};
  ${typeScale('copy')};
  ${typeStyle('copy')};
  ${(props) => (props.disabled ? disabledStyle : props.checked ? selectedStyle : defaultStyle)};

  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  width: inherit;
`

const defaultOnFocus = () => true

export const Default = forwardRef(
  ({ checked = false, disabled = false, onFocus = defaultOnFocus, label = '' }, ref) => {
    const theme = useTheme()
    const spaces = getThemeValue(theme, 'spaces')

    const tabIndex = useMemo(() => (disabled ? -1 : 0), [disabled])

    return (
      <LabelText
        checked={checked}
        disabled={disabled}
        onFocus={onFocus}
        ref={ref}
        tabIndex={tabIndex}
        {...textProps}
      >
        <PosRelative>
          <FlexRow alignItems="center" flexColumnGap={spaces.rhythm.horizontal.small}>
            <FlexItem>
              <CheckboxCheckmark checked={checked} disabled={disabled} />
            </FlexItem>
            {label && (
              <FlexItem alignItems="center" alignSelf="stretch">
                <Copy>{label}</Copy>
              </FlexItem>
            )}
          </FlexRow>
        </PosRelative>
      </LabelText>
    )
  }
)
Default.propTypes = {
  checked,
  disabled,
  onFocus,
  label,
}
