import React, { useEffect, useState } from 'react'

import { Navigate, useLocation } from 'react-router'

import { useAuthzChecks } from 'lib/hooks'
import { useWebtrackingParams } from 'lib/hooks/useWebtrackingParams'

import { useAppStore } from 'stores/AppStore'
import { useAuthStore } from 'stores/AuthStore'
import api from 'stores/api'

export const PublicAccessGuard = ({ Component }) => {
  const location = useLocation()
  useWebtrackingParams()

  const [currentUser, pullCurrentUser] = useAuthStore((state, actions) => [
    state.currentUser,
    actions.pullCurrentUser,
  ])
  const [, { loadState: loadAppState }] = useAppStore()
  const [isLoggedIn, setIsLoggedIn] = useState(null)

  const { getDefaultRoute } = useAuthzChecks()

  useEffect(() => {
    if (currentUser === 'init' && isLoggedIn === null) {
      api.Auth.isLoggedIn().then((isLoggedIn) => {
        setIsLoggedIn(isLoggedIn.data.data)
      })
    }
    if (currentUser === 'init' && isLoggedIn) {
      pullCurrentUser()
      loadAppState()
    }
  }, [isLoggedIn, currentUser, pullCurrentUser, loadAppState])

  useEffect(() => {
    if (location.pathname.startsWith('/sso/')) {
      const [, , redirectAfterLogin] = location.pathname.match(/^(\/sso\/[^/]+\/?)(.*)/)
      if (redirectAfterLogin.length) {
        let search = location.search.replace(/(&|\?)(chk=|uid=|ts=)[^&]*/g, '')
        if (!search.startsWith('?')) {
          search = '?' + search
        }
        localStorage.setItem('ssoRedirect', '/' + redirectAfterLogin + search)
      }
    }
  }, [location])

  if (
    currentUser === 'init' &&
    (isLoggedIn === null || isLoggedIn === true) /* <-- wait for currentUser to be loaded!*/
  ) {
    return null
  } else if (isLoggedIn) {
    const ssoRedirect = localStorage.getItem('ssoRedirect')
    let to
    if (typeof ssoRedirect === 'string') {
      localStorage.removeItem('ssoRedirect')
      to = ssoRedirect
    } else {
      to = getDefaultRoute()
    }
    return <Navigate replace to={to} />
  } else {
    return <Component />
  }
}
