import React from 'react'

import { BudiconChevronTop } from 'bgag-budicons'
import { ButtonPrimitive } from '../../SubComponents'

import { appearance, Icon } from './defaultPropTypes'

export const ButtonUp = ({
  appearance = 'light',
  Icon = BudiconChevronTop,
  direction,
  onClick,
  onMouseLeave,
  size,
  shape,
}) => {
  return (
    <ButtonPrimitive
      appearance={appearance}
      direction={direction}
      icon={{ Icon, type: 'shady' }}
      iconPosition={{ top: '1px' }}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      size={size}
      shape={shape}
      tabIndex="-1"
    />
  )
}
ButtonUp.propTypes = {
  appearance,
  Icon,
}
