import React, { memo, forwardRef, useCallback, useRef, useImperativeHandle, useEffect } from 'react'
import { bool, string } from 'prop-types'
import { useLocation, useNavigate } from 'react-router'

import { styled } from 'styled-components'
import { typography } from 'styled-system'

import { getThemeValue } from 'theming'

import { typeMap, typeScale, typeStyle } from '@typography'

import { getScrollParents } from 'lib/util/getScrollParents'

import { LinkIndicator } from './SubComponents'
import { Box } from '@layout/BuildingBlocks'

const allowedProps = [
  'children',
  'href',
  'hreflang',
  'id',
  'name',
  'onClick',
  'onMouseOut',
  'onMouseOver',
  'onMouseLeave',
  'onFocus',
  'onBlur',
  'rel',
  'target',
  'type',
  'style',
]

const StyledComponent = styled.a.withConfig({
  shouldForwardProp: (prop) => allowedProps.includes(prop),
})`
  cursor: pointer;
  outline: none;
  text-decoration: none;

  height: ${({ height }) => height};

  & > div {
    line-height: normal;
  }

  &:hover {
    text-decoration: ${({ textDecorationOnHover }) => textDecorationOnHover || 'underline'};
  }

  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}

  color: ${(props) => getThemeValue(props.theme, 'colors.base.gray.900')};

  ${(props) =>
    props.disabled && {
      cursor: 'not-allowed',
      '&:hover': { textDecoration: 'none' },
      color: getThemeValue(props.theme, 'colors.base.gray.200'),
    }}
  ${(props) => props.width && { width: props.width }}
  ${(props) => props.height && { height: props.height }}
  ${(props) => props.stretch && { width: '100%' }};
`

const scrollTo = ({ id, offset, location, navigate, forceInstant }) => {
  const calcOffset = typeof offset === 'undefined' ? () => 0 : () => parseInt(offset)

  const target = document.getElementById(id)
  if (!target) {
    return
  }
  const container = getScrollParents(target)[0]
  if (!container) {
    return
  }

  const offsetTop = target.offsetTop - calcOffset()

  if (!forceInstant.current && typeof container.scroll === 'function') {
    navigate(location.pathname + '#' + id, { replace: true })

    container.scroll({
      top: offsetTop,
      behavior: 'smooth',
    })
  } else {
    if (!forceInstant.current) {
      navigate(location.pathname + '#' + id, { replace: true })
    }
    forceInstant.current = false
    container.scrollTop = offsetTop
  }
}

const fontSizeDefault = [0, 1, 2, 3, 4]

export const AnchorLink = memo(
  forwardRef(
    (
      {
        fontFamily = 'whitneySSm',
        fontSize = fontSizeDefault,
        lineHeight = 'normal',
        size = 'medium',
        stretch = null,
        href = null,
        to = '/',
        text = null,
        showLinkIndicator = false,
        linkIndicatorAbsolute = true,
        children,
        onClick,
        disabled,
        offset,
        boxProps = {},
        ...props
      },
      forwardedRef
    ) => {
      props = { ...props, fontFamily, fontSize, lineHeight, size, stretch, href, to }
      const navigate = useNavigate()
      const location = useLocation()
      const { typeStyle, typeScale } = typeMap[props.size]

      const hash = useRef(location.hash.slice(1))
      const forceInstant = useRef(false)
      const ref = useRef()
      useImperativeHandle(forwardedRef, () => ref.current)

      useEffect(() => {
        hash.current = location.hash.slice(1)
      }, [location.hash])

      useEffect(() => {
        let targetHash = props.href?.split?.('#') ?? []
        targetHash = targetHash.length > 1 ? targetHash[1] : null
        if (targetHash === hash.current) {
          hash.current = null
          forceInstant.current = true
          setTimeout(() => ref.current.click(), 0)
        }
      }, [props.href])

      const handleClick = useCallback(
        (e) => {
          if (disabled) {
            e.preventDefault()
            return false
          }
          const id = e.currentTarget.getAttribute('href')?.split?.('#')?.[1] ?? null
          if (id) {
            e.preventDefault()
            scrollTo({ id, offset, location, navigate, forceInstant })
          }
          typeof onClick === 'function' && onClick(e)
        },
        [disabled, offset, onClick, location, navigate]
      )

      return (
        <StyledComponent
          ref={ref}
          onClick={handleClick}
          typeScale={typeScale}
          typeStyle={typeStyle}
          disabled={disabled}
          {...props}
        >
          <Box paddingRight={showLinkIndicator && linkIndicatorAbsolute ? '30px' : null} {...boxProps}>
            {text !== null && text}
            {!!children && children}
            {showLinkIndicator && <LinkIndicator absolute={linkIndicatorAbsolute} />}
          </Box>
        </StyledComponent>
      )
    }
  )
)

AnchorLink.propTypes = {
  size: string,
  stretch: bool,
  href: string,
  to: string,
  text: string,
  showLinkIndicator: bool,
  linkIndicatorAbsolute: bool,
}
