import React, { memo, useEffect, useCallback, useMemo, useState } from 'react'

import { useControllableState, useDebouncedUpdates } from 'lib/hooks'
import { isFunction } from 'lib/util'

import { FormElement, withFormElementProps } from '@forms'

const groupStateToValue = (state, name) => {
  return state[name]
}

const valueToGroupState = (value, name) => {
  return { [name]: value }
}

export const RadioGroupComponent = ({
  value,
  onChange,
  defaultValue,
  children,
  formElementRefs,
  debounce = null,
  ...props
}) => {
  const [initialValue] = useState(defaultValue ?? value ?? '')

  const onDebouncedUpdate = useCallback(
    (value) => {
      isFunction(onChange) && onChange({ id: props.id, name: props.name, value })
    },
    [onChange, props.id, props.name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [internalValue, setInternalValue] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const formState = useMemo(() => valueToGroupState(internalValue, props.name), [internalValue, props.name])

  const updateControllableState = useCallback(
    (evt) => {
      setInternalValue(groupStateToValue(evt.value, props.name))
    },
    [setInternalValue, props.name]
  )

  const onReset = useCallback(() => {
    setInternalValue(initialValue)
  }, [setInternalValue, initialValue])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = internalValue
    }
  }, [internalValue, formElementRefs])

  return (
    <FormElement {...props} value={formState} onChange={updateControllableState}>
      {children}
    </FormElement>
  )
}
RadioGroupComponent.type = 'RadioGroup'

export const RadioGroup = withFormElementProps(memo(RadioGroupComponent))
