import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import api from 'stores/api'
import useApi from 'stores/useApi'

import { Minion, MinionMedium, MetalineWithText } from '@typography'
import {
  FlexColumn,
  FlexContainer,
  FlexItemForwardedRef,
  FlexRow,
  GridContainer,
} from '@layout/BuildingBlocks'
import { useTheme } from 'styled-components'
import { getThemeValue } from 'theming'
import { BudiconTaskDownload } from 'bgag-budicons'
import { AnchorLink } from '@ui/AnchorLink'
import { ControlsSegment } from '@ui/ControlsSegment'
import { ControlsFold } from '@ui/ControlsSegment/SubComponents'
import appConfig from 'config/appConfig'
import { ScrollContainer } from '@layout/Layout/SubComponents/ScrollContainer'
import { IconWrapper } from '@utilities/IconWrapper'
import { GridItem } from '@layout/Grid'
import { DropdownSelect } from '@forms'

const DownloadListItem = ({ download }) => {
  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')
  const fileName = download.fileName?.replace(/^[0-9-\s]*/, '') || '<no name>'

  return (
    <FlexRow width="100%">
      <FlexColumn flexRowGap={spaces.rhythm.vertical.nano} width="100%">
        <AnchorLink href={`${appConfig.bgagServicesUrl}reports/on-demand/${download.id}`} target="_blank">
          <MinionMedium tag="span">{fileName}</MinionMedium>
        </AnchorLink>
        <FlexRow justifyContent="space-between" width="100%" flexWrap={'wrap'}>
          <Minion>{download.email}</Minion>
          <Minion>
            {new Date(download.createdAt).toLocaleDateString('de-DE', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}
          </Minion>
        </FlexRow>
      </FlexColumn>
    </FlexRow>
  )
}

export const DownloadList = ({
  userId = null,
  groupId = null,
  sectionName,
  folded,
  toggleFoldedSection,
  foldedSections,
}) => {
  const { t } = useTranslation()
  const apiCall = userId
    ? api.Reports.OnDemnd.getReportsByUser
    : groupId
      ? api.Reports.OnDemnd.getReportsByGroup
      : api.Reports.OnDemnd.getReports
  const [getReports, reportsList] = useApi(apiCall, null)
  const [currentSort, setCurrentSort] = useState('date')
  const [scrollHeight, setScrollHeight] = useState('100%')
  const heightRefElement = useRef(null)
  const isFirstRender = useRef(true)

  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')

  const sortedReportsList = useMemo(() => {
    if (!reportsList || !reportsList?.length) {
      return []
    }
    return reportsList.sort((a, b) => {
      if (currentSort === 'date') {
        return new Date(b.createdAt) - new Date(a.createdAt)
      } else if (currentSort === 'email') {
        return a.email.localeCompare(b.email)
      } else if (currentSort === 'name') {
        const aFileName = a.fileName.replace(/^[0-9-\s]*/, '')
        const bFileName = b.fileName.replace(/^[0-9-\s]*/, '')
        return aFileName.localeCompare(bFileName)
      }

      return 1
    })
  }, [reportsList, currentSort])

  const onDropdownSelect = useCallback(({ value }) => {
    setCurrentSort(value)
  }, [])

  const toggleFold = useCallback(() => {
    if (typeof toggleFoldedSection === 'function') {
      toggleFoldedSection(sectionName)
    }
  }, [toggleFoldedSection, sectionName])

  useEffect(() => {
    if (reportsList && heightRefElement?.current && foldedSections) {
      const timeout = isFirstRender.current === true ? 0 : 305
      isFirstRender.current = false
      setTimeout(() => {
        setScrollHeight(
          'calc(100vh - ' +
            heightRefElement.current?.getBoundingClientRect().top +
            'px - ' +
            heightRefElement.current?.getBoundingClientRect().height +
            'px - ' +
            spaces.rhythm.vertical.large +
            ' - ' +
            spaces.rhythm.vertical.large +
            ' - ' +
            spaces.rhythm.vertical.large +
            ')'
        )
      }, timeout)
    }
  }, [reportsList, heightRefElement, spaces, foldedSections])

  useEffect(() => {
    if (userId) {
      getReports({ userId })
    } else if (groupId) {
      getReports({ groupId })
    } else {
      getReports()
    }
  }, [getReports, userId, groupId])

  if (!reportsList) return null

  return (
    <GridContainer width="100%" gridTemplateColumns="32px 1fr" gridGap={spaces.rhythm.horizontal.large}>
      <GridItem gridColumn="_1_1">
        <IconWrapper
          appearance="bare"
          icon={{
            Icon: BudiconTaskDownload,
            width: ['20px', '25px', null, '32px'],
            type: 'outline',
          }}
          iconWrapper={{
            width: ['20px', '25px', null, '32px'],
            height: ['20px', '25px', null, '32px'],
          }}
          size={'medium'}
        />
      </GridItem>

      <GridItem gridColumn="_2_1">
        <ControlsSegment folded={folded} initialFolded={folded} controlled={true} width="100%">
          <ControlsSegment.HeaderControlsStart tag="div">
            <FlexItemForwardedRef alignSelf="start" ref={heightRefElement}>
              <MetalineWithText
                align="left"
                metaline={`Downloads`}
                text={t('userprofile.downloadlist.title')}
                typeScaleMeta="pica"
                typeScaleText="greatPrimer"
                typeStyleText="semibold"
                textTransform={'none'}
                metalineTextTransform={'none'}
              />
            </FlexItemForwardedRef>
          </ControlsSegment.HeaderControlsStart>
          <ControlsSegment.HeaderControls>
            <ControlsFold onClick={toggleFold} />
          </ControlsSegment.HeaderControls>
          <ControlsSegment.Body>
            <FlexColumn marginTop={spaces.rhythm.vertical.small} flexRowGap={spaces.rhythm.vertical.tiny}>
              <FlexContainer maxWidth="100px">
                <DropdownSelect
                  placeholder={t('userprofile.downloadlist.sort.placeholder')}
                  name="downloadlist-sort"
                  value={currentSort}
                  defaultValue={t('userprofile.downloadlist.sort.email')}
                  size="small"
                  items={[
                    { label: t('userprofile.downloadlist.sort.date'), value: 'date' },
                    { label: t('userprofile.downloadlist.sort.name'), value: 'name' },
                    { label: t('userprofile.downloadlist.sort.email'), value: 'email' },
                  ]}
                  onChange={onDropdownSelect}
                  appearance="white"
                  stretch={true}
                />
              </FlexContainer>

              <ScrollContainer maxHeight={scrollHeight}>
                <FlexColumn
                  flexRowGap={spaces.rhythm.vertical.medium}
                  justifyContent="space-around"
                  width="100%"
                  mt={spaces.rhythm.vertical.medium}
                >
                  {sortedReportsList.map((download) => (
                    <DownloadListItem key={download.id} download={download} />
                  ))}
                </FlexColumn>
              </ScrollContainer>
            </FlexColumn>
          </ControlsSegment.Body>
        </ControlsSegment>
      </GridItem>
    </GridContainer>
  )
}
