import React, { forwardRef } from 'react'
import { styled } from 'styled-components'

import { allowedProps } from './config'
import { string } from 'prop-types'

const StyledAnchorElement = styled.a.withConfig({
  shouldForwardProp: (prop) => allowedProps.includes(prop),
})`
  cursor: pointer;
  outline: none;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  ${(props) =>
    props.disabled && {
      cursor: 'not-allowed',
      '&:hover': { textDecoration: 'none' },
    }}
`

export const HTMLAnchorElement = forwardRef(({ children, text = 'Text', ...props }, ref) => {
  const handleClick = props.disabled
    ? (e) => {
        e.preventDefault()
        return false
      }
    : null

  return (
    <StyledAnchorElement className={props.className} ref={ref} onClick={handleClick} {...props}>
      {props.iconAlign === 'left' && children}
      {text}
      {props.iconAlign === 'right' && children}
    </StyledAnchorElement>
  )
})

const withIcon = (Component) => {
  const WithIcon = ({ children, ...props }) => {
    return (
      <Component ref={props.forwardedRef} {...props} text={null} iconAlign="left">
        {children}
      </Component>
    )
  }
  return forwardRef((props, ref) => {
    return <WithIcon {...props} forwardedRef={ref} />
  })
}

const withIconAndText = (Component) => {
  const WithIconAndText = ({ children, ...props }) => {
    return (
      <Component ref={props.forwardedRef} {...props}>
        {children}
      </Component>
    )
  }
  return forwardRef((props, ref) => {
    return (
      <WithIconAndText {...props} forwardedRef={ref}>
        {props.children}
      </WithIconAndText>
    )
  })
}

export const HTMLAnchorElementWithIcon = withIcon(HTMLAnchorElement)
export const HTMLAnchorElementWithIconAndText = withIconAndText(HTMLAnchorElement)
HTMLAnchorElement.propTypes = {
  text: string,
}
HTMLAnchorElementWithIcon.propTypes = {
  text: string,
}
