import React, { memo, useEffect, useCallback, useMemo, useState, useRef, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { FormElement, withFormElementProps, RadioBar } from '@forms'
import { Pica } from '@typography'
import { isFunction } from 'lib/util'
import { useDebouncedUpdates, useControllableState } from 'lib/hooks'

import { defaultPropTypes } from './defaultPropTypes'
import { BudiconStar } from 'bgag-budicons'
import { FlexRow } from '@layout/BuildingBlocks'

const groupStateToValue = (state) => {
  const {
    selectedStars: { value: selectedStars = 0 },
  } = state.value
  return selectedStars
}
const valueToGroupState = (value) => {
  const newState = {
    selectedStars: value?.selectedStars ?? 0,
  }
  return newState
}

export const StarsComponent = ({
  name = 'StarsComponent',
  defaultValue = 3,
  starsNbr = 5,
  andMore = true,
  debounce = 150, // default 100, set property to null to turn debounce off
  appearance = 'light',
  size = '1.6em',
  formElementRefs,
  onChange,
  value,
  ...props
}) => {
  const { t } = useTranslation(['translation', 'objects'])
  const [initialValue] = useState(defaultValue ?? value ?? [])
  const onDebouncedUpdate = useCallback(
    (value) => {
      isFunction(onChange) && onChange({ id: props.id, name, value })
    },
    [onChange, props.id, name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [internalValue, setInternalValue] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const formState = useMemo(() => valueToGroupState(internalValue), [internalValue])

  const starsItems = useMemo(
    () =>
      Array.from({ length: starsNbr }, (_, i) => i).map((nr) => ({
        value: nr + 1,
        renderChild:
          nr < internalValue ? (
            <BudiconActiveStar size={size} className="icon" />
          ) : (
            <BudiconInactiveStar size={size} className="icon" />
          ),
      })),
    [starsNbr, internalValue, size]
  )

  const internalOnChange = useCallback(
    (target) => {
      setInternalValue(groupStateToValue(target))
    },
    [setInternalValue]
  )

  const onReset = useCallback(() => {
    setInternalValue(initialValue)
  }, [setInternalValue, initialValue])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = internalValue
    }
  }, [internalValue, formElementRefs])

  return (
    <FormElement {...props} name={name} value={formState} onChange={internalOnChange}>
      <FlexRow alignItems="center" flexColumnGap=".5rem">
        <RadioBar items={starsItems} name="selectedStars" forElementName={name} />
        {andMore && <Pica tag="span"> & {t('objects:filter.more')}</Pica>}
      </FlexRow>
    </FormElement>
  )
}

StarsComponent.type = 'Stars'
StarsComponent.propTypes = defaultPropTypes

export const Stars = withFormElementProps(memo(StarsComponent))

const BudiconActiveStar = (props) => {
  const node = useRef(null)
  useLayoutEffect(() => {
    const svg = node.current.firstChild
    const g = svg.firstChild
    const path = g.childNodes[1]
    path.setAttribute('fill', '#F1CC09')
    const d = path.getAttribute('d')
    path.setAttribute('d', 'M' + d.split('M')[2])
  }, [])
  return (
    <span ref={node}>
      <BudiconStar type="shady" {...props} />
    </span>
  )
}

const BudiconInactiveStar = (props) => {
  const node = useRef(null)
  useLayoutEffect(() => {
    const svg = node.current.firstChild
    const g = svg.firstChild
    const path = g.childNodes[1]
    path.setAttribute('fill', '#D9D9D7')
    const d = path.getAttribute('d')
    path.setAttribute('d', 'M' + d.split('M')[2])
  }, [])
  return (
    <span ref={node}>
      <BudiconStar type="shady" {...props} />
    </span>
  )
}
