import React, { useMemo } from 'react'
import { styled, useTheme } from 'styled-components'
import { system, typography, layout, color, compose, space } from 'styled-system'

import { getThemeValue } from 'theming'

import { defaultPropTypes } from './defaultPropTypes'

const StyledTextComponent = styled.div.withConfig({
  shouldForwardProp: (prop) => ['style', 'children', 'dangerouslySetInnerHTML', 'id'].includes(prop),
})`
  ${compose(color, typography, layout, space)}
  ${(props) =>
    system({
      fontSize: {
        property: 'fontSize',
        scale: props.scale,
      },
      columnCount: {
        property: 'columnCount',
      },
      columnGap: {
        property: 'columnGap',
      },
      columnWidth: {
        property: 'columnWidth',
      },
      columnRule: {
        property: 'columnRule',
      },
      columnSpan: {
        property: 'columnSpan',
      },
      columnRuleStyle: {
        property: 'columnRuleStyle',
      },
      columnRuleWidth: {
        property: 'columnRuleWidth',
      },
      columnRuleColor: {
        property: 'columnRuleColor',
      },
      whiteSpace: {
        property: 'whiteSpace',
      },
    })}
`

const fontSizeDefault = [0, 1, 2, 3, 4]

export const StyledText = ({
  children,
  fontFamily = 'whitneySSm',
  fontSize = fontSizeDefault,
  lineHeight = 'normal',
  ...props
}) => {
  const theme = useTheme()
  const defaultColor = getThemeValue(theme, 'colors.theme.app.color')

  const color = useMemo(() => {
    return props.color ? props.color : defaultColor
  }, [props, defaultColor])

  return (
    <StyledTextComponent
      color={color}
      fontFamily={fontFamily}
      fontSize={fontSize}
      lineHeight={lineHeight}
      {...props}
    >
      {children}
    </StyledTextComponent>
  )
}
StyledText.propTypes = defaultPropTypes
