import React, {
  memo,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment,
  isValidElement,
  cloneElement,
} from 'react'
import { styled } from 'styled-components'
import { hideVisually } from 'polished'

import { isUndefined, isArray, isFunction } from 'lib/util'
import { useControllableState, useDebouncedUpdates } from 'lib/hooks'

import { withFormElementProps } from '@forms'

import { Default } from './SubComponents/Default'

const CheckboxHtml = styled.input.attrs({ type: 'checkbox' })`
  ${hideVisually()}
`

export const CheckboxComponent = forwardRef(
  (
    {
      checked,
      defaultChecked,
      disabled,
      value,
      onChange,
      children,
      title,
      label,
      formElementRefs,
      debounce = null,
      labelStyle,
      ...props
    },
    ref
  ) => {
    const [initialChecked] = useState(defaultChecked ?? checked ?? false)
    const onDebouncedUpdate = useCallback(
      (checked) => {
        isFunction(onChange) && onChange({ id: props.id, name: props.name, value: value, checked })
      },
      [onChange, props.id, props.name, value]
    )

    const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
      checked,
      onDebouncedUpdate,
      debounce
    )

    const [localChecked, setChecked] = useControllableState(
      initialChecked,
      internalValueDebouncer,
      onControllableStateChange
    )

    const internalOnChange = useCallback(
      (evt) => {
        setChecked(evt.target.checked)
      },
      [setChecked]
    )

    const onReset = useCallback(() => {
      setChecked(false)
    }, [setChecked])

    useEffect(() => {
      if (formElementRefs) {
        formElementRefs.reset.current = onReset
      }
    }, [onReset, formElementRefs])

    useEffect(() => {
      if (formElementRefs) {
        formElementRefs.value.current = localChecked ? value : null
      }
    }, [localChecked, formElementRefs, value])

    const localLabelStyle = useMemo(() => {
      return {
        cursor: disabled ? 'auto' : 'pointer',
        marginBottom: 0,
        display: 'inline-block',
        position: 'relative',
        ...labelStyle,
      }
    }, [disabled, labelStyle])

    const childProps = useMemo(
      () => ({ ...props, checked: localChecked, disabled: disabled }),
      [props, localChecked, disabled]
    )

    const childrenArray = useMemo(() => {
      const childrenArray = isUndefined(children) ? [] : !isArray(children) ? [children] : children
      if (!childrenArray.length) {
        childrenArray.push(<Default key="0" {...childProps} label={label ?? ''} />)
      }
      return childrenArray
    }, [children, childProps, label])

    return (
      <label style={localLabelStyle} title={title}>
        <CheckboxHtml
          type="checkbox"
          checked={localChecked}
          value={value}
          disabled={disabled}
          onChange={internalOnChange}
          ref={ref}
          tabIndex="-1"
        />
        {childrenArray.map((child, idx) => {
          return (
            <Fragment key={idx}>
              {isFunction(child)
                ? child(childProps)
                : isValidElement(child)
                  ? cloneElement(child, childProps)
                  : child}
            </Fragment>
          )
        })}
      </label>
    )
  }
)
CheckboxComponent.type = 'Checkbox'

export const Checkbox = withFormElementProps(memo(CheckboxComponent))
