import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router'
import api from 'stores/api'

const utmParametersEnum = {
  utm_campaign: 'utmCampaign',
  utm_source: 'utmSource',
  utm_medium: 'utmMedium',
  utm_term: 'utmTerm',
  utm_content: 'utmContent',
}

export const useWebtrackingParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [existingUtmParameters, setExistingUtmParameters] = useState(
    searchParams.entries().reduce((existingUtmParameters, [key, value]) => {
      if (typeof utmParametersEnum[key] !== 'undefined') {
        existingUtmParameters[utmParametersEnum[key]] = value
      }
      return existingUtmParameters
    }, {})
  )

  useEffect(() => {
    if (searchParams.size > 0 && Object.keys(existingUtmParameters).length) {
      setExistingUtmParameters({})
      const url = window.location.origin + window.location.pathname
      const trackingParams = { ...existingUtmParameters, url }
      api.Webtracking.record(trackingParams)
        .then(() => {
          Object.keys(existingUtmParameters).forEach((param) =>
            searchParams.delete(
              Object.entries(utmParametersEnum).find(([key, value]) => value === param)?.[0]
            )
          )
          setSearchParams(searchParams)
        })
        .catch((err) => console.warn(err))
    }
  }, [searchParams, setSearchParams, existingUtmParameters])
}
