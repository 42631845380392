import React, { useRef, useEffect, useCallback } from 'react'
import { styled } from 'styled-components'

import { ModalDialog, ModalOverlay, useModalContext } from '.'

import { size as sizeVariant } from './variants'

const StyledSizeWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['size', 'active'].includes(prop),
})`
  display: ${(props) => (props.active ? 'block' : 'none')};
  transition:
    height 0.3s,
    width 0.3s;
  transition-delay: 0.1s;
  ${(props) => props.size && sizeVariant}
`

const SizeWrapper = () => {
  const {
    state: { activeModal, size },
    dispatch,
  } = useModalContext()

  const ref = useRef(null)

  const handleTransitionStart = useCallback(
    (evt) => {
      if (evt.target === ref.current) {
        dispatch({
          type: 'setIsAnimationRunning',
          payload: true,
        })
      }
    },
    [dispatch]
  )

  const handleTransitionEnd = useCallback(
    (evt) => {
      if (evt.target === ref.current) {
        dispatch({
          type: 'setIsAnimationRunning',
          payload: false,
        })
      }
    },
    [dispatch]
  )

  useEffect(() => {
    const domElement = ref.current
    if (domElement) {
      domElement.addEventListener('transitionstart', handleTransitionStart)
      domElement.addEventListener('transitionend', handleTransitionEnd)
      return () => {
        domElement.removeEventListener('transitionstart', handleTransitionStart)
        domElement.removeEventListener('transitionend', handleTransitionEnd)
      }
    }
  }, [handleTransitionStart, handleTransitionEnd, ref])

  return <StyledSizeWrapper active={activeModal !== null} size={size} ref={ref} id="modal-container" />
}

export const ModalContainer = () => {
  const {
    state: { activeModal },
    dispatch,
  } = useModalContext()

  const handlerAnimationStart = useCallback(
    (evt) => {
      if (evt.target.classList.contains('modal-dialog')) {
        dispatch({ type: 'setInitialAnimationRunning', payload: true })
      }
    },
    [dispatch]
  )

  const handlerAnimationEnd = useCallback(
    (evt) => {
      if (evt.target.classList.contains('modal-dialog')) {
        dispatch({ type: 'setInitialAnimationRunning', payload: false })
      }
    },
    [dispatch]
  )

  const onClose = useCallback(() => {
    if (activeModal !== null) {
      dispatch({ type: 'setCloseActiveModal' })
    }
  }, [activeModal, dispatch])

  return (
    <ModalOverlay active={activeModal !== null} onClick={onClose}>
      <ModalDialog handlerAnimationStart={handlerAnimationStart} handlerAnimationEnd={handlerAnimationEnd}>
        <SizeWrapper />
      </ModalDialog>
    </ModalOverlay>
  )
}
