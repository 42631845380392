import createStore from 'lib/flux-store'
import api from 'stores/api'

const computePrice = (hedonic) => {
  if (!hedonic) return null
  const price = hedonic.office
  //const price = hedonic ? hedonic.riwis + hedonic.location + hedonic.plot + hedonic.house : 0

  return price
}

const initialState = {
  isLoading: true,
  loadingMarketData: true,
  loadingHedonicData: true,
  isFailure: false,
  messages: [],
  hedonic: null,
  canFetchHedonicData: false,
  canFetchMarketData: false,
  currentOfficeType: null,
  officeMarketPriceData: null,
  officeAreaPriceData: null,
  officeAreaGac: null,
  marketDataBounds: null,
  officeAreaDataBounds: null,
}

const __NAME__ = 'HedonicOfficeDataStore'

const actions = {
  fetchHedonic: (params, location) => (dispatch) => {
    dispatch({ type: 'setLoadingHedonicData', loadingHedonicData: true })
    const [long, lat] = location.geometry.coordinates
    api.Hedonic.getOfficeProspectAtLocation({ lat, long, params })
      .then((result) => {
        const hedonic = result.data.data
        hedonic.price = computePrice(hedonic)
        dispatch({ type: 'setHedonic', payload: hedonic })
      })
      .catch((error) => {
        throw error
      })
  },
  fetchLocationData: (coordinates) => (dispatch) => {
    api.Hedonic.getOfficeLocationData(coordinates)
      .then((result) => {
        dispatch({ type: 'setLocationData', payload: result.data.data })
      })
      .catch((error) => {
        throw error
      })
  },
  fetchMarketData: (gac) => (dispatch) => {
    dispatch({ type: 'setLoadingMarketData', loadingMarketData: true })
    api.Hedonic.getOfficeMarketData(gac)
      .then((result) => {
        dispatch({ type: 'setMarketData', payload: result.data.data })
      })
      .catch((error) => {
        throw error
      })
  },
  setCanFetchHedonicData: (canFetchHedonicData) => ({ canFetchHedonicData }),
  setHedonic: (hedonic) => ({ hedonic }),
  setLoadingMarketData: (loadingMarketData) => ({ loadingMarketData }),
  setMarketData: (marketData) => ({ marketData }),
}

const reducer = {
  setFailure: (state, { payload }) => ({ ...state, isLoading: false, isFailure: true, messages: payload }),
  setLoadingHedonicData: (state, { loadingHedonicData }) => ({ ...state, loadingHedonicData }),
  setLoadingMarketData: (state, { loadingMarketData }) => ({ ...state, loadingMarketData }),
  setCanFetchHedonicData: (state, { canFetchHedonicData }) => ({ ...state, canFetchHedonicData }),
  setCanFetchMarketData: (state, { canFetchMarketData }) => ({ ...state, canFetchMarketData }),
  setHedonic: (state, { payload }) => ({
    ...state,
    hedonic: payload,
    canFetchHedonicData: false,
    loadingHedonicData: false,
  }),
  setLocationData: (state, { payload: { gac, typ } }) => {
    return {
      ...state,
      //canFetchHedonicData: true,
      canFetchMarketData: true,
      currentOfficeType: typ,
      officeAreaGac: gac,
    }
  },
  setMarketData: (
    state,
    { payload: { officeMarketData, officeAreaData, marketDataBounds, officeAreaDataBounds } }
  ) => {
    return {
      ...state,
      loadingMarketData: false,
      canFetchMarketData: false,
      canFetchHedonicData: true,
      officeMarketPriceData: officeMarketData,
      officeAreaPriceData: officeAreaData,
      marketDataBounds,
      officeAreaDataBounds,
    }
  },
}

export const [HedonicOfficeDataContext, HedonicOfficeDataProvider, useHedonicOfficeDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  __NAME__
)
