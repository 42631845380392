import { variant } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import {
  bare,
  dark,
  ghost,
  gray,
  light,
  lightgray,
  primary,
  primaryGhost,
  primaryShallow,
  success,
  error,
  white,
} from 'theming/systemHelper/appearance'
import { oval, square, shapeless } from 'theming/systemHelper/shape'

export const appearance = (props) =>
  variant({
    prop: 'appearance',
    variants: {
      bare,
      dark,
      ghost,
      gray,
      light,
      lightgray,
      primary,
      primaryGhost,
      primaryShallow,
      success,
      error,
      white,
    },
  })

export const size = (props) =>
  variant({
    prop: 'size',
    variants: {
      nano: props.equalSides
        ? props.shape === 'oval'
          ? themeGet('buttonIcons.spaces.nano.oval.equalSides', null)(props)
          : themeGet('buttonIcons.spaces.nano.square.equalSides', null)(props)
        : props.shape === 'oval'
          ? themeGet('buttonIcons.spaces.nano.oval.oblong', null)(props)
          : themeGet('buttonIcons.spaces.nano.square.oblong', null)(props),
      tiny: props.equalSides
        ? props.shape === 'oval'
          ? themeGet('buttonIcons.spaces.tiny.oval.equalSides', null)(props)
          : themeGet('buttonIcons.spaces.tiny.square.equalSides', null)(props)
        : props.shape === 'oval'
          ? themeGet('buttonIcons.spaces.tiny.oval.oblong', null)(props)
          : themeGet('buttonIcons.spaces.tiny.square.oblong', null)(props),
      small: props.equalSides
        ? props.shape === 'oval'
          ? themeGet('buttonIcons.spaces.small.oval.equalSides', null)(props)
          : themeGet('buttonIcons.spaces.small.square.equalSides', null)(props)
        : props.shape === 'oval'
          ? themeGet('buttonIcons.spaces.small.oval.oblong', null)(props)
          : themeGet('buttonIcons.spaces.small.square.oblong', null)(props),
      medium: props.equalSides
        ? props.shape === 'oval'
          ? themeGet('buttonIcons.spaces.medium.oval.equalSides', null)(props)
          : themeGet('buttonIcons.spaces.medium.square.equalSides', null)(props)
        : props.shape === 'oval'
          ? themeGet('buttonIcons.spaces.medium.oval.oblong', null)(props)
          : themeGet('buttonIcons.spaces.medium.square.oblong', null)(props),
      large: props.equalSides
        ? props.shape === 'oval'
          ? themeGet('buttonIcons.spaces.large.oval.equalSides', null)(props)
          : themeGet('buttonIcons.spaces.large.square.equalSides', null)(props)
        : props.shape === 'oval'
          ? themeGet('buttonIcons.spaces.large.oval.oblong', null)(props)
          : themeGet('buttonIcons.spaces.large.square.oblong', null)(props),
    },
  })

export const shape = (props) =>
  variant({
    prop: 'shape',
    variants: {
      oval,
      shapeless,
      square,
    },
  })
