import { variant } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const size = (props) =>
  variant({
    prop: 'size',
    variants: {
      taglist: {
        width: ['704px', null, null, '768px'],
        height: ['528px', null, null, '576px'],
        minHeight: ['528px', null, null, '576px'],
      },
      default: {
        maxWidth: themeGet('modal.dialog.space.maxWidth', '100vw')(props),
        maxHeight: themeGet('modal.dialog.space.maxHeight', '100vh')(props),
      },
      fullscreen: {
        width: themeGet('modal.dialog.space.maxWidth', '100vw')(props),
        height: themeGet('modal.dialog.space.maxHeight', '100vh')(props),
        maxWidth: themeGet('modal.dialog.space.maxWidth', '100vw')(props),
        maxHeight: themeGet('modal.dialog.space.maxHeight', '100vh')(props),
      },
      tiny: {
        landscape: {
          aspcr_16_9: {
            width: ['640px'],
            height: ['225px'],
          },
          aspcr_4_3: {
            width: ['400px'],
            height: ['300px'],
          },
        },
        portrait: {
          aspcr_3_4: {
            width: ['300px'],
            height: ['400px'],
          },
          aspcr_quad: {
            width: ['300px'],
            height: ['300px'],
          },
        },
      },
      small: {
        landscape: {
          aspcr_16_9: {
            width: ['640px'],
            height: ['360px'],
          },
          aspcr_4_3: {
            width: ['640px'],
            height: ['480px'],
          },
        },
        portrait: {
          aspcr_3_4: {
            width: ['480px'],
            height: ['640px'],
          },
          aspcr_quad: {
            width: ['480px'],
            height: ['480px'],
          },
        },
      },
      medium: {
        landscape: {
          aspcr_16_9: {
            width: ['960px'],
            height: ['560px'],
          },
          aspcr_4_3: {
            width: ['960px'],
            height: ['720px'],
          },
        },
        portrait: {
          aspcr_3_4: {
            width: ['720px'],
            height: ['960px'],
          },
          aspcr_quad: {
            width: ['720px'],
            height: ['720px'],
          },
        },
      },
      large: {
        landscape: {
          aspcr_16_9: {
            width: ['1280px'],
            height: ['720px'],
          },
          aspcr_4_3: {
            width: ['1280px'],
            height: ['960px'],
          },
        },
        portrait: {
          aspcr_3_4: {
            width: ['960px'],
            height: ['1280px'],
          },
          aspcr_quad: {
            width: ['1280px'],
            height: ['1280px'],
          },
        },
      },
      l3plus: {
        base: {
          width: ['960px'],
          minHeight: ['550px'],
        },
        comment: {
          width: ['1088px'],
          height: ['596px'],
        },
        images: {
          width: ['768px'],
          height: ['576px'],
        },
      },
      priceTableModal: {
        default: {
          maxWidth: themeGet('modal.dialog.space.maxWidth', '100vw')(props),
          maxHeight: themeGet('modal.dialog.space.maxHeight', '100vh')(props),
          height: ['730px'],
          width: ['1050px'],
        },
      },
      priceTableModalBillingAddress: {
        default: {
          height: ['768x'],
          width: ['576px'],
        },
      },
      priceInfoTableModal: {
        default: {
          maxWidth: themeGet('modal.dialog.space.maxWidth', '100vw')(props),
          maxHeight: themeGet('modal.dialog.space.maxHeight', '100vh')(props),
          height: ['750px'],
          width: ['1050px'],
        },
      },
    },
  })
