import React from 'react'
import { useTheme } from 'styled-components'

import { FlexColumn } from '@layout/BuildingBlocks'
import { Text } from '@typography/ContentElements'

import { getThemeValue } from 'theming'
import { oneOfType, string, number, element, elementType } from 'prop-types'

export const MetalineWithText = ({
  align = 'left',
  metaline = 'Metaline',
  metalineColor = null,
  text = '-',
  textColor = null,
  typeScaleMeta = 'minion',
  typeStyleMeta = 'minionLight',
  typeScaleText = 'copy',
  typeStyleText = 'copyMedium',
  textTransform = 'inherit',
  metalineTextTransform = 'inherit',
}) => {
  const theme = useTheme()
  const space = getThemeValue(theme, 'spaces.rhythm.vertical.nano')
  const defaultMetalineColor = getThemeValue(theme, 'colors.base.grayScale.800')
  const defaultTextColor = getThemeValue(theme, 'colors.base.grayScale.900')

  return (
    <FlexColumn alignItems="flex-start" flexRowGap={space}>
      <Text
        align={align}
        text={metaline}
        color={metalineColor || defaultMetalineColor}
        typeStyle={typeStyleMeta}
        typeScale={typeScaleMeta}
        textTransform={metalineTextTransform}
      />
      <Text
        align={align}
        breakWord={true}
        text={text}
        color={textColor || defaultTextColor}
        typeStyle={typeStyleText}
        typeScale={typeScaleText}
        textTransform={textTransform}
      />
    </FlexColumn>
  )
}
MetalineWithText.propTypes = {
  align: string,
  metaline: string,
  metalineColor: string,
  text: oneOfType([string, number, element, elementType]),
  textColor: string,
  typeScaleMeta: string,
  typeStyleMeta: string,
  typeScaleText: string,
  typeStyleText: string,
  textTransform: string,
  metalineTextTransform: string,
}
