import React, { useMemo } from 'react'

import { useArrayValueByBreakpoint } from 'lib/hooks'

const PlaceholderIcon = ({ ...props }) => {
  return (
    <svg viewBox="0 0 24 124" xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height}>
      <circle cx="50%" cy="50%" r="10" fill="lightgray" stroke="gray" strokeWidth="2px" />
    </svg>
  )
}

const defaultPropsIcon = {
  icon: {
    Icon: PlaceholderIcon,
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: '0px',
    type: 'solid',
    height: '100%',
    width: '100%',
  },
}

export const IconComponent = ({ icon }) => {
  const { Icon, fill, height, stroke, strokeWidth, type, width } = useMemo(
    () => ({ ...defaultPropsIcon.icon, ...icon }),
    [icon]
  )

  const widthValue = useArrayValueByBreakpoint(width)

  return (
    <Icon
      fill={fill}
      height={height}
      stroke={stroke}
      strokeWidth={strokeWidth}
      type={type}
      width={widthValue}
    />
  )
}
