import React from 'react'
import { styled, css, useTheme } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import {
  CardSubTitle,
  CardTitleContainer,
  CardButtonBar,
  CardHeaderTabs,
  CardHeaderAddOn,
} from '../../SubComponents'
import { FlexColumn, FlexRow, FlexItem } from '@layout/BuildingBlocks'

import { useSlots, useSlotsAsArray } from 'lib/hooks/useSlots'
import { getThemeValue } from 'theming'

const headerWithTabs = css`
  background-color: ${themeGet('card.colors.tab.bg', 'lightgray')};
  border-radius: ${themeGet('card.border.radius', '0px')} ${themeGet('card.border.radius', '0px')} 0px 0px;
`
const headerWithoutTabs = css`
  padding-bottom: ${themeGet('card.header.space.bottom', '0')};
`

const Container = styled(FlexColumn)`
  ${(props) => (props.hasTabs ? headerWithTabs : headerWithoutTabs)};
  align-items: flex-start;
`

export const CardHeader = ({
  children,
  highlighted,
  px,
  borderRadius,
  titleGroupFlex,
  flexWrap = 'wrap',
}) => {
  const theme = useTheme()
  const [tabs] = useSlotsAsArray(['tab'], children)
  const [title, subTitle, titleButton, buttonBar, addOn, headerEnd] = useSlots(
    ['title', 'subTitle', 'titleButton', 'buttonBar', 'addOn', 'headerEnd'],
    children
  )
  const card = getThemeValue(theme, 'card')
  const titleColor = highlighted ? card.colors.header.highlighted : card.colors.header.color
  const buttonBarX = card.buttonBar.space.right

  const hasTabs = tabs.length

  const pl = hasTabs ? 0 : px
  const pr = buttonBar ? buttonBarX : px
  return (
    <Container hasTabs={hasTabs}>
      <FlexRow
        pl={pl}
        pr={pr}
        justifyContent="space-between"
        alignItems="start"
        width="100%"
        height="100%"
        flexWrap={flexWrap}
        flexColumnGap={card.header.columnGap}
      >
        <FlexColumn flex={titleGroupFlex}>
          {hasTabs ? (
            <FlexItem height="100%">
              {tabs.map((tab) => (
                <CardHeaderTabs key={tab.props.index} px={px} borderRadius={borderRadius} {...tab.props}>
                  {tab.props.children}
                </CardHeaderTabs>
              ))}
            </FlexItem>
          ) : (
            <CardTitleContainer title={title} color={titleColor} titleButton={titleButton} />
          )}
          {subTitle && <CardSubTitle subTitle={subTitle} />}
        </FlexColumn>
        {buttonBar && (
          <FlexItem
            flex={buttonBar.props.flex || '1'}
            justifyContent="flex-end"
            alignSelf={buttonBar.props.alignSelf}
          >
            <CardButtonBar stretch={buttonBar.props.stretch} alignItems={buttonBar.props.alignItems}>
              {buttonBar}
            </CardButtonBar>
          </FlexItem>
        )}
        {addOn && <CardHeaderAddOn>{addOn}</CardHeaderAddOn>}
        {headerEnd && headerEnd}
      </FlexRow>
    </Container>
  )
}

CardHeader.Tab = ({ children }) => children
CardHeader.Tab.displayName = 'tab'

CardHeader.Title = ({ children }) => children
CardHeader.Title.displayName = 'title'

CardHeader.SubTitle = ({ children }) => children
CardHeader.SubTitle.displayName = 'subTitle'

CardHeader.TitleButton = ({ children }) => children
CardHeader.TitleButton.displayName = 'titleButton'

CardHeader.ButtonBar = ({ children }) => children
CardHeader.ButtonBar.displayName = 'buttonBar'

CardHeader.AddOn = ({ children }) => children
CardHeader.AddOn.displayName = 'addOn'

CardHeader.HeaderEnd = ({ children }) => children
CardHeader.HeaderEnd.displayName = 'headerEnd'
