import React from 'react'
import { styled, useTheme } from 'styled-components'
import { string, node, oneOfType } from 'prop-types'

import { GreatPrimerMedium as Headline } from '@typography'
import { getThemeValue } from 'theming'

const HeadlineWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`

export const ControlsSegmentHeadline = ({ children = 'Segment Title', ...props }) => {
  const theme = useTheme()
  const color = getThemeValue(theme, 'controlsSegment.headline.color')

  return (
    <HeadlineWrapper>
      <Headline color={color} padding="0" margin="0" tag="h3" {...props}>
        {children}
      </Headline>
    </HeadlineWrapper>
  )
}
ControlsSegmentHeadline.propTypes = {
  children: oneOfType([string, node]),
}
