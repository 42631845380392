import React, { useMemo } from 'react'

import { NavLink } from 'react-router'

import { LogoRiwis, LogoRiwisText } from '@utilities/SVG/Logos'
import { FlexContainer, FlexCenter, PosRelative } from '@layout/BuildingBlocks'

import { useMediaQuery } from 'lib/hooks/useMediaQuery'

export const HeaderAppLogo = () => {
  const shouldUseLargeSize = useMediaQuery([false, false, false, false, true])
  const shouldUseMediumSize = useMediaQuery([false, false, true, true, false])
  const shouldUseLogoText = useMediaQuery([false, false, true, true, true])

  const size = useMemo(() => {
    return shouldUseMediumSize ? '.925rem' : shouldUseLargeSize ? '1.125rem' : '1rem'
  }, [shouldUseLargeSize, shouldUseMediumSize])

  return (
    <FlexContainer alignItems="center">
      <PosRelative>
        <FlexCenter>
          <NavLink style={{ display: 'flex' }} to="/home">
            {shouldUseLogoText ? (
              <LogoRiwisText size={size} />
            ) : (
              <PosRelative top="3px">
                <LogoRiwis size="1.1rem" />
              </PosRelative>
            )}
          </NavLink>
        </FlexCenter>
      </PosRelative>
    </FlexContainer>
  )
}
