import { string, bool, element } from 'prop-types'

const appearance = string
const borderRadius = string
const direction = string
const disabled = bool
const forElementName = string
const name = string
const buttonDown = element
const buttonUp = element

export { appearance, borderRadius, direction, disabled, forElementName, name, buttonDown, buttonUp }
