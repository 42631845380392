import React from 'react'

import { BudiconMinusUi } from 'bgag-budicons'
import { ButtonPrimitive } from '../../SubComponents'

import { appearance, Icon } from './defaultPropTypes'

export const ButtonMinus = ({
  appearance = 'light',
  Icon = BudiconMinusUi,
  direction,
  onClick,
  onMouseLeave,
  size,
  shape,
}) => {
  return (
    <ButtonPrimitive
      appearance={appearance}
      direction={direction}
      icon={{ Icon, type: 'shady' }}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      size={size}
      shape={shape}
    />
  )
}
ButtonMinus.propTypes = {
  appearance,
  Icon,
}
