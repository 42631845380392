import React, { useMemo, useEffect, useCallback, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { styled, css } from 'styled-components'
import { layout } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { useLocation, useResolvedPath } from 'react-router'

import { PosRelative, PosAbsolute, FlexItem } from '@layout/BuildingBlocks'
import { ScrollContainerNotHidden } from '@layout/Layout/SubComponents/ScrollContainer'
import { ButtonIcon } from '@ui/Buttons/ButtonIcon'
import { BudiconCrossUi } from 'bgag-budicons'
import { Flyout } from '@utilities/Flyout'
import { OnClickOutside } from '@utilities/OnClickOutside'

import { NavigationBadge, ParentLinkItem, SubLinkItem } from './SubComponents'

import { containerHeight, containerWidth, navtree, parentListWidth } from './defaultPropTypes'

const listStyles = css`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const Container = styled.div.withConfig({
  shouldForwardProp: (props) => !['width', 'height'].includes(props),
})`
  background: ${themeGet('navigationMain.flyout.colors.container.bg', 'gray')};
  border-radius: ${themeGet('navigationMain.flyout.radius', '0')};
  padding: 38px 0;
  ${layout}
`

const ParentList = styled.ul.withConfig({
  shouldForwardProp: (props) => !['width'].includes(props),
})`
  ${listStyles}
  ${layout}
`

const SubList = styled.ul.withConfig({
  shouldForwardProp: (props) => !['height', 'parentListWidth', 'containerHeight'].includes(props),
})`
  ${listStyles}
  width: ${(props) => `calc(100% - ${props.parentListWidth})`};
  height: ${(props) => props.containerHeight};
  background: ${themeGet('navigationMain.flyout.colors.sublist.bg', 'lightgray')};
  border: 1px solid ${themeGet('navigationMain.flyout.colors.border.active', 'lightgray')};
  border-top-right-radius: ${themeGet('flyout.radius', '0px')};
  border-bottom-right-radius: ${themeGet('flyout.radius', '0px')};
  border-width: 0 0 0 1px;
  display: none;
  padding: 30px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  ${layout};
`

const ButtonClose = styled(ButtonIcon)`
  &:hover {
    background: transparent;
    border-color: transparent;
  }
`

export const NavigationMainComponent = ({
  containerHeight = '428px',
  containerWidth = '580px',
  parentListWidth = '180px',
  navtree = null,
}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const location = useLocation()
  const resolvedPath = useResolvedPath(location.pathname)

  const parentRefs = useRef([])
  const subListRefs = useRef([])

  const refNavigationBadge = useRef()

  const { currentParent, currentSubtree, activeParent } = useMemo(() => {
    const currentParent =
      navtree.find(({ parent }) => {
        return parent.subtree.find((subitem) => {
          const pathToArray = resolvedPath.pathname.split(/\//)
          const appPath = pathToArray.slice(1, 3).join('/')

          return subitem.link.path.startsWith('/' + appPath)
        })
      }) ?? null

    const currentSubtree =
      currentParent === null
        ? null
        : currentParent?.parent?.subtree.find((item) => {
            const pathToArray = resolvedPath.pathname.split(/\//)
            const appPath = pathToArray.slice(1, 3).join('/')

            return item.link.path.includes(appPath)
          })

    const activeParent = currentParent === null ? 0 : navtree.indexOf(currentParent)

    return { currentParent, currentSubtree, activeParent }
  }, [navtree, resolvedPath])

  const toggleFlyout = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const closeFlyout = useCallback(() => {
    setIsOpen(false)
  }, [])

  const focusParent = useCallback(
    (index) => {
      parentRefs.current.forEach((item) => {
        item?.classList?.remove?.('active')
      })
      parentRefs.current?.[index]?.classList?.add?.('active')
    },
    [parentRefs]
  )

  useEffect(() => {
    isOpen && parentRefs?.current?.[activeParent] && parentRefs.current[activeParent].classList.add('active')
  }, [isOpen, activeParent, parentRefs])

  useEffect(() => {
    closeFlyout()
  }, [resolvedPath, closeFlyout])

  const label = useMemo(() => {
    const moduleMappings = {
      'market-analysis': 'marketAnalysis',
      makro: 'macro',
    }
    if (currentParent && currentSubtree) {
      return ['features'].includes(currentParent?.parent.label.toLowerCase())
        ? currentSubtree?.label
        : currentParent?.parent.label
    } else {
      const path = resolvedPath.pathname.split('/')
      if (path.length > 1) {
        const module = path[1]
        return t(
          'modules.' +
            (typeof moduleMappings[module] !== 'undefined' ? moduleMappings[module] : module) +
            '.name'
        )
      } else {
        return ''
      }
    }
  }, [currentParent, currentSubtree, resolvedPath, t])

  return (
    <OnClickOutside handler={closeFlyout}>
      <NavigationBadge ref={refNavigationBadge} onClick={toggleFlyout} isOpen={isOpen} label={label} />
      <Flyout refOpener={refNavigationBadge} active={isOpen}>
        <PosAbsolute zIndex="12" right="-2px" top="-4px">
          <FlexItem justifyContent="flex-end">
            <ButtonClose
              appearance="bare"
              size="small"
              shape="square"
              icon={{ Icon: BudiconCrossUi, size: '18px', type: 'shady', strokeWidth: '1.5' }}
              onClick={closeFlyout}
            />
          </FlexItem>
        </PosAbsolute>
        <PosRelative>
          <Container height={containerHeight} width={containerWidth}>
            <ParentList width={parentListWidth}>
              {navtree.map((item, index) => (
                <ParentLinkItem
                  itemProps={item.parent}
                  key={index}
                  onMouseOver={() => {
                    focusParent(index)
                  }}
                  onClick={() => {
                    focusParent(index)
                  }}
                  ref={(element) => (parentRefs.current[index] = element)}
                >
                  <SubList
                    containerHeight={containerHeight}
                    parentListWidth={parentListWidth}
                    ref={(element) => (subListRefs.current[index] = element)}
                  >
                    <ScrollContainerNotHidden paddingRight="20px">
                      {item.parent.subtree.map((subItem, index) => (
                        <SubLinkItem key={index} itemProps={subItem} />
                      ))}
                    </ScrollContainerNotHidden>
                  </SubList>
                </ParentLinkItem>
              ))}
            </ParentList>
          </Container>
        </PosRelative>
      </Flyout>
    </OnClickOutside>
  )
}
NavigationMainComponent.propTypes = {
  containerHeight,
  containerWidth,
  navtree,
  parentListWidth,
}
