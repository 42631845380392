import { styled } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const DropdownList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`

export const ListItemWrapper = styled.div`
  padding: 3px 6px;

  &:first-of-type {
    padding-top: 6px;
    border-radius: ${themeGet('navigationMeta.flyout.radius', '0')}
      ${themeGet('navigationMeta.flyout.radius', '0')} 0 0;
  }

  &:last-of-type {
    padding-bottom: 6px;
    border-radius: 0 0 ${themeGet('navigationMeta.flyout.radius', '0')}
      ${themeGet('navigationMeta.flyout.radius', '0')};
  }
`
export const ListItem = styled.li`
  background: ${(props) =>
    props.background || themeGet('navigationMeta.flyout.colors.item.default.bg', 'lightgray')(props)};
  border-radius: 4px;
  color: ${(props) => themeGet('navigationMeta.flyout.colors.item.default.color', 'lightgray')(props)};
  transition: all 0.2s;
  min-width: max-content;

  &:focus-within {
    background: ${(props) => themeGet('navigationMeta.flyout.colors.item.active.bg', 'gray')(props)};
    outline: none;
  }
`
