// comments are leaflet's default panes
export const zIndices = {
  // tilePane: 200
  // overlayPane: 400
  districts: 401,
  federalStates: 402,
  riwisCities: 403,
  buildingblocks: 404,
  kgs12: 405,
  l3plusSubmarkets: 406,
  l3plusRetailUnits: 407,
  locationSearchSubmarkets: 408,
  /**Hedonic Layers */
  hedonicOfficeBauBlockMapdata: 416,
  hedonicOfficeAreas: 418,
  hedonicColorPercentageLegend: 425,
  /**------------------------------------*/
  currentLocation: 430,
  highstreetReportLocations: 431,
  zones: 470,
  backdrop: 480,
  areas: 482,
  retailMarketsAdditionalLayers: 482,
  // shadowPane: 500
  // markerPane: 600
  poi: 601,
  objects: 602,
  portfolios: 603,
  cityLabels: 605,
  retailMarketsAdditionalLayersLabels: 606,
  locationPins: 610,
  locationSearchPin: 611,
  // toolTipPane: 650
  draftZone: 670,
  editZone: 670,
  vertices: 672,
  collectionMarker: 680,
  // popupPane: 700
  scrollContainer: 700,
  layoutHeader: 750,
  highchartsTooltip: 800,
  overlayRight: 5000000,
  modalOverlay: 10000000,
  bodyAnchor: 20000000,
  dndPreviewLayer: 25000000,
  tableEllipsis: 30000000,
  impersonateUser: 40000000,
  notifications: 60000000,
}
