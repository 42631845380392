import React, { useCallback } from 'react'

import { ButtonIcon } from '@ui/Buttons/ButtonIcon'

import { appearance, direction, icon, iconPosition, onClick, shape, size } from './defaultPropTypes'

export const ButtonPrimitive = ({
  appearance = null,
  direction = 'left',
  icon = null,
  iconPosition = null,
  onClick = null,
  shape = 'shapeless',
  size = 'tiny',
  ...rest
}) => {
  const onMouseLeave = useCallback((event) => {
    event.target.blur()
  }, [])

  return (
    <ButtonIcon
      appearance={appearance}
      direction={direction}
      icon={icon}
      iconPosition={iconPosition}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      size={size}
      iconVariantSize={size}
      shape={shape}
      style={{ flex: 1, height: '100%' }}
      {...rest}
    />
  )
}
ButtonPrimitive.propTypes = {
  appearance,
  direction,
  icon,
  iconPosition,
  onClick,
  shape,
  size,
}
