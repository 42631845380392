import React from 'react'
import { styled } from 'styled-components'
import { system, grid, layout, space, flexbox, background, compose, border, color } from 'styled-system'

import { propsCheck } from './defaultPropTypes'

const StyledGridItem = styled.div.withConfig({
  shouldForwardProp: (prop) => ['style', 'children'].includes(prop),
})`
  ${compose(background, border, color, flexbox, grid, layout, space)};
  ${system({
    gridColumn: {
      property: 'gridColumn',
      scale: 'gridColumns',
    },
    gridRow: {
      property: 'gridRow',
      scale: 'gridRows',
    },
  })}
`

export const GridItem = ({
  children,
  gridColumn = null,
  gridRow = null,
  gridArea = null,
  variant = null,
  ...props
}) => {
  props = { ...props, gridColumn, gridRow, gridArea, variant }
  return <StyledGridItem {...props}>{children}</StyledGridItem>
}
GridItem.propTypes = {
  gridArea: propsCheck,
  gridColumn: propsCheck,
  gridRow: propsCheck,
}
