import React from 'react'

import {
  ControlsSegmentBody,
  ControlsSegmentHeader,
  ControlsSegmentContainer,
  ControlsSegmentHeadline,
} from '@ui/ControlsSegment/SubComponents'
import { FlexRow, FlexItem } from '@layout/BuildingBlocks'

import { ControlsSegmentContextProvider } from '.'
import { useSlots } from 'lib/hooks/useSlots'

import { bool } from 'prop-types'

export const ControlsSegment = ({
  children,
  checked,
  controlled = false,
  folded = false,
  foldDisabled = false,
  openLayOver,
  preventPaddingX = false,
  ...props
}) => {
  const [headerControls, headerControlsStart, headline, body] = useSlots(
    ['headerControls', 'headerControlsStart', 'headline', 'body'],
    children
  )

  return (
    <ControlsSegmentContextProvider
      initialFolded={controlled ? null : headerControlsStart ? !(checked || folded) : folded}
      initialChecked={controlled ? null : checked}
      folded={controlled ? folded : null}
      checked={controlled ? checked : null}
      foldDisabled={foldDisabled}
    >
      <ControlsSegmentContainer preventPaddingX={preventPaddingX} {...props}>
        {(headerControlsStart || headerControls || headline) && (
          <ControlsSegmentHeader
            justifyContent={headline || headerControlsStart ? 'space-between' : 'flex-end'}
            segmentsAsGroup={props.segmentsAsGroup}
          >
            {headline && !headerControlsStart && (
              <ControlsSegmentHeadline {...headline.props}>{headline}</ControlsSegmentHeadline>
            )}

            {headline && headerControlsStart && (
              <FlexRow flexColumnGap=".75rem" alignItems="center" justifyContent="space-between">
                {!!headerControlsStart && headerControlsStart}
                <ControlsSegmentHeadline {...headline.props}>{headline}</ControlsSegmentHeadline>
              </FlexRow>
            )}

            {!headline && !!headerControlsStart && (
              <FlexRow
                {...headerControlsStart.props}
                flexColumnGap=".75rem"
                alignItems="center"
                justifyContent="space-between"
              >
                {headerControlsStart}
              </FlexRow>
            )}

            {headerControls && <FlexItem flex="0">{headerControls}</FlexItem>}
          </ControlsSegmentHeader>
        )}
        {body && <ControlsSegmentBody {...body.props}>{body}</ControlsSegmentBody>}
      </ControlsSegmentContainer>
    </ControlsSegmentContextProvider>
  )
}

ControlsSegment.Body = ({ children }) => children
ControlsSegment.Body.displayName = 'body'

ControlsSegment.Headline = ({ children }) => children
ControlsSegment.Headline.displayName = 'headline'

ControlsSegment.HeaderControls = ({ children }) => children
ControlsSegment.HeaderControls.displayName = 'headerControls'

ControlsSegment.HeaderControlsStart = ({ children }) => children
ControlsSegment.HeaderControlsStart.displayName = 'headerControlsStart'
ControlsSegment.propTypes = {
  folded: bool,
  foldDisabled: bool,
  preventPaddingX: bool,
}
