import React, { memo } from 'react'
import { useTheme } from 'styled-components'

import { BudiconStar } from 'bgag-budicons'

import { getThemeValue } from 'theming'

import { checked } from './defaultPropTypes'

const ButtonMyFavorites = memo(() => {
  const theme = useTheme()
  const color = getThemeValue(theme, 'colors.base.gray.500')

  return <BudiconStar type="shady" strokeWidth="0" color={color} size="1.8rem" />
})

const ButtonMyFavoritesActive = memo(() => {
  const theme = useTheme()
  const color = getThemeValue(theme, 'colors.base.brand.secondary.default')

  return <BudiconStar type="solid" strokeWidth="0" color={color} size="1.8rem" />
})

export const CheckboxMyFavorite = ({ checked = false }) => {
  return checked ? <ButtonMyFavoritesActive /> : <ButtonMyFavorites />
}
CheckboxMyFavorite.propTypes = {
  checked,
}
