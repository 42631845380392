import React from 'react'

import { PosRelative } from '@layout/BuildingBlocks'
import { Disc } from '@utilities/SVG/Disc'

import { array, bool, oneOfType, string } from 'prop-types'
import { colors } from './variants'

const sizes = ['18px', '18px', '18px', '18px', '20px']

export const Radio = ({
  height = sizes,
  width = sizes,
  checked = false,
  disabled = false,
  appearance = 'default',
  ...props
}) => {
  const { stroke, fill, innerFill = null } = colors[appearance][checked ? 'checked' : 'unchecked']

  return (
    <PosRelative>
      <Disc
        height={height}
        width={width}
        stroke={stroke}
        fill={fill}
        innerStroke={innerFill}
        innerFill={innerFill}
        {...props}
      />
    </PosRelative>
  )
}
Radio.propTypes = {
  height: oneOfType([string, array]),
  width: oneOfType([string, array]),
  checked: bool,
  disabled: bool,
  appearance: string,
}
