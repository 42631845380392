import React, { forwardRef, useCallback } from 'react'
import { styled } from 'styled-components'
import { layout } from 'styled-system'

const StyledOptionsListItem = styled.li`
  align-items: center;
  display: flex;
  margin-right: 5px;
  margin-bottom: 11px;
  &:last-of-type {
    margin-bottom: 0;
  }

  label {
    box-sizing: border-box;
    height: inherit;
    width: 100%;
  }
  ${layout}
`

export const OptionsListItem = forwardRef(({ children, optionsMinWidth, onClick }, ref) => {
  const internalOnClick = useCallback(
    (evt) => {
      if (typeof onClick === 'function') {
        onClick(evt)
      }
    },
    [onClick]
  )
  return (
    <StyledOptionsListItem onClick={internalOnClick} minWidth={optionsMinWidth} ref={ref}>
      {children}
    </StyledOptionsListItem>
  )
})
