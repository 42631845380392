import React, { createContext, useContext, useReducer } from 'react'

const LayoutContext = createContext()

const initialState = {
  controlsPanelIsOpen: true,
  overlayRightIsOpen: false,
  OverlayRightComponent: null,
}

function reducer(state, action) {
  switch (action.type) {
    case 'setControlsPanelIsOpen': {
      if (state.controlsPanelIsOpen === !!action.payload) {
        return state
      }
      return {
        ...state,
        ...{ controlsPanelIsOpen: !!action.payload },
      }
    }

    case 'toggleControlsPanel': {
      return {
        ...state,
        ...{ controlsPanelIsOpen: !state.controlsPanelIsOpen },
      }
    }

    case 'setOverlayRightIsOpen': {
      return {
        ...state,
        ...{ overlayRightIsOpen: action.payload.isOpen, OverlayRightComponent: action.payload.component },
      }
    }

    case 'toggleOverlayRight': {
      return {
        ...state,
        ...{ overlayRightIsOpen: !state.overlayRightIsOpen },
      }
    }

    default: {
      console.log('action.type', action.type, 'not found', action.payload)
      return state
    }
  }
}

const LayoutContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <LayoutContext.Provider value={{ state, dispatch }}>{children}</LayoutContext.Provider>
}

const useLayoutContext = () => {
  const context = useContext(LayoutContext)

  if (!context) throw new Error('Error in creating the context')

  return context
}

export { useLayoutContext, LayoutContextProvider }
