import React, { memo, useEffect, useCallback, useMemo, useState, useRef } from 'react'
import { useTheme } from 'styled-components'

import { getThemeValue } from 'theming'

import { useControllableState, useDebouncedUpdates } from 'lib/hooks'
import { isFunction } from 'lib/util'

import { FormElement, withFormElementProps, Radio, Checkbox, CheckboxGroup, RadioGroup } from '@forms'

import { FlexColumn, FlexItem } from '@layout/BuildingBlocks'

import { name } from './defaultPropTypes'
import { DeleteButton, VisibilityCheckbox } from '@forms/LocationBar/SubComponents'
import { getLocationAppearance } from '@forms/LocationBar'
import { LocationBadge } from '@utilities/LocationBadge'

const groupStateToValue = (state) => {
  return { visibleLocations: state.visibleLocations.value, mainLocation: state.mainLocation.value }
}

const valueToGroupState = (value) => {
  return {
    visibleLocations: { value: value.visibleLocations ?? [] },
    mainLocation: { value: value.mainLocation ?? null },
  }
}

const mainLocation = {
  mainLocation: null,
}

const visibleLocations = []

const labelStyle = { display: 'inline-flex' }

export const LocationVerticalBarComponent = ({
  defaultValue,
  value,
  formElementRefs,
  countryCode,
  locations,
  name,
  onChange,
  debounce = null,
  justifyContent,
  alignItems = 'center',
  flexColumnGap = '0.625rem',
  onDelete,
  ignoreMainLocation = false,
  ignoreVisibleLocation = false,
  ...props
}) => {
  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')

  const [initialValue] = useState(defaultValue ?? value ?? { mainLocation, visibleLocations })

  const onDebouncedUpdate = useCallback(
    (value) => {
      isFunction(onChange) && onChange({ id: props.id, name, value })
    },
    [onChange, props.id, name]
  )

  const radioRef = useRef()

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [internalValue, setInternalValue] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const formState = useMemo(() => valueToGroupState(internalValue, name), [internalValue, name])

  const updateControllableState = useCallback(
    (evt) => {
      setInternalValue(groupStateToValue(evt.value, name, evt.changeEmitter, countryCode))
    },
    [setInternalValue, name, countryCode]
  )

  const onReset = useCallback(() => {
    setInternalValue(initialValue)
  }, [setInternalValue, initialValue])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = internalValue
    }
  }, [internalValue, formElementRefs])

  return (
    <FormElement
      {...props}
      name={name}
      value={formState}
      onChange={updateControllableState}
      marginRight="20px"
    >
      <RadioGroup forElementName={name} name={'mainLocation'}>
        <CheckboxGroup forElementName={name} name={'visibleLocations'}>
          {locations && (
            <FlexColumn flexRowGap={spaces.rhythm.horizontal.small}>
              {Object.entries(locations).map(([locationId, location]) => (
                <Radio
                  ref={radioRef}
                  key={locationId}
                  value={locationId}
                  forElementName={'mainLocation'}
                  disabled={ignoreMainLocation}
                  labelStyle={labelStyle}
                >
                  <FlexItem width="100%">
                    <LocationBadge
                      stretch={true}
                      locationId={locationId}
                      locationName={location.displayedName}
                      checked={
                        ignoreMainLocation || Number(locationId) === Number(internalValue.mainLocation)
                      }
                      appearance={getLocationAppearance(
                        locationId,
                        ignoreMainLocation || Number(locationId) === Number(internalValue.mainLocation)
                      )}
                    >
                      <LocationBadge.SlotRight>
                        {!ignoreVisibleLocation && (
                          <Checkbox value={locationId} forElementName={'visibleLocations'}>
                            <VisibilityCheckbox
                              iconPosition={{
                                marginTop: ['-2px', null, null, '-1px', '0'],
                              }}
                              iconWrapper={{ marginLeft: '8px', marginRight: '13px' }}
                              size="small"
                              iconWidth={['19px', null, null, '19px', '21px']}
                            />
                          </Checkbox>
                        )}
                        <DeleteButton
                          locationId={locationId}
                          onClick={onDelete}
                          iconWidth={['19px', null, '21px', '21px']}
                          iconPosition={{
                            marginTop: ['-2px', null, null, '-1px', '0'],
                          }}
                          appearance="bare"
                          shape="shapeless"
                          size="small"
                        />
                      </LocationBadge.SlotRight>
                    </LocationBadge>
                  </FlexItem>
                </Radio>
              ))}
            </FlexColumn>
          )}
        </CheckboxGroup>
      </RadioGroup>
    </FormElement>
  )
}

LocationVerticalBarComponent.type = 'LocationBar'
LocationVerticalBarComponent.propTypes = {
  name,
}

export const LocationVerticalBar = withFormElementProps(memo(LocationVerticalBarComponent))
