import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { isFunction } from 'lib/util'
import { useLocation } from 'react-router'

const ControlsPanelContext = createContext()

const initialState = {
  isLayOverOpen: false,
  LayOverComponent: null,
  title: '',
  componentProps: {},
  scrollToId: null,
  onClose: null,
}

function reducer(state, action) {
  switch (action.type) {
    case 'openLayOver': {
      return {
        ...state,
        ...{
          isLayOverOpen: true,
          LayOverComponent: action.payload.LayOverComponent,
          title: action.payload.title,
          componentProps: action.payload.componentProps,
          scrollToId: action.payload.scrollToId,
          onClose: action.payload?.onClose,
        },
      }
    }

    case 'closeLayOver': {
      return {
        ...state,
        ...{
          isLayOverOpen: false,
          LayOverComponent: null,
          title: '',
          componentProps: {},
          scrollToId: null,
          onClose: null,
        },
        ...action.payload,
      }
    }

    case 'setLayOver': {
      return {
        ...state,
        ...{
          isLayOverOpen: action.payload.isLayOverOpen,
          LayOverComponent: action.payload.LayOverComponent,
          title: action.payload.title,
          componentProps: action.payload.componentProps,
          scrollToId: action.payload.scrollToId,
          onClose: action.payload?.onClose,
        },
      }
    }

    case 'resetLayOver': {
      return { ...initialState }
    }

    default: {
      console.log('action.type', action.type, 'not found', action.payload)
      return state
    }
  }
}

const ControlsPanelContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const location = useLocation()

  const closeLayOver = () => {
    if (state.onClose && isFunction(state.onClose)) {
      state.onClose()
    }
    dispatch({
      type: 'closeLayOver',
      payload: { scrollToId: state?.scrollToId },
    })
  }

  useEffect(() => {
    dispatch({
      type: 'resetLayOver',
    })
  }, [location])

  const resetLayOver = () => {
    dispatch({
      type: 'resetLayOver',
    })
  }

  const context = {
    state,
    dispatch,
    closeLayOver,
    resetLayOver,
  }

  return <ControlsPanelContext.Provider value={context}>{children}</ControlsPanelContext.Provider>
}

const useControlsPanelContext = () => {
  const context = useContext(ControlsPanelContext)

  if (!context) throw new Error('Error in creating the context')

  return context
}

export { useControlsPanelContext, ControlsPanelContextProvider }
