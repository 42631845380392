import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { getThemeValue } from 'theming'

import { FlexColumn, FlexRow, GridContainer } from '@layout/BuildingBlocks'
import { MetalineWithText } from '@typography'
import { useAuthStore } from 'stores/AuthStore'
import { useTheme } from 'styled-components'
import { BudiconProfilePicture } from 'bgag-budicons'
import { ControlsSegment } from '@ui/ControlsSegment'
import { ControlsFold } from '@ui/ControlsSegment/SubComponents'
import { IconWrapper } from '@utilities/IconWrapper'
import { GridItem } from '@layout/Grid'

export const UserData = ({ sectionName, folded, toggleFoldedSection }) => {
  const { t } = useTranslation()

  const [{ currentUser }] = useAuthStore()

  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')
  const quotaListPaddingRight = getThemeValue(theme, 'layout.areaMain.pr')

  const toggleFold = useCallback(() => {
    if (typeof toggleFoldedSection === 'function') {
      toggleFoldedSection(sectionName)
    }
  }, [toggleFoldedSection, sectionName])

  // equals padding + margin of ControlsSegmentHeader
  const rowGap = spaces[4]

  return (
    <GridContainer gridTemplateColumns="32px 1fr" gridGap={spaces.rhythm.horizontal.large}>
      <GridItem gridColumn="_1_1">
        <IconWrapper
          appearance="bare"
          icon={{
            Icon: BudiconProfilePicture,
            width: ['20px', '25px', null, '32px'],
            type: 'outline',
          }}
          iconWrapper={{
            width: ['20px', '25px', null, '32px'],
            height: ['20px', '25px', null, '32px'],
          }}
          size={'medium'}
        />
      </GridItem>
      <GridItem gridColumn="_2_1">
        <ControlsSegment folded={folded} controlled={true} width="100%">
          <ControlsSegment.HeaderControlsStart tag="div">
            <FlexRow flexColumnGap={spaces.rhythm.horizontal.large} width="100%">
              <MetalineWithText
                align="left"
                metaline={`${t('userprofile.data.firstname')}`}
                text={currentUser.data.firstname}
                typeScaleMeta="pica"
                typeScaleText="greatPrimer"
                typeStyleText="semibold"
                textTransform={'none'}
                metalineTextTransform={'none'}
              />
              <MetalineWithText
                align="left"
                metaline={`${t('userprofile.data.lastname')}`}
                text={currentUser.data.name}
                typeScaleMeta="pica"
                typeScaleText="greatPrimer"
                typeStyleText="semibold"
                textTransform={'none'}
                metalineTextTransform={'none'}
              />
            </FlexRow>
          </ControlsSegment.HeaderControlsStart>
          <ControlsSegment.HeaderControls>
            <ControlsFold onClick={toggleFold} />
          </ControlsSegment.HeaderControls>
          <ControlsSegment.Body>
            <FlexColumn flexRowGap={rowGap} paddingRight={quotaListPaddingRight}>
              <FlexRow>
                <MetalineWithText
                  align="left"
                  metaline={`${t('userprofile.data.email')}`}
                  text={currentUser.data.email}
                  typeScaleMeta="pica"
                  typeScaleText="greatPrimer"
                  typeStyleText="semibold"
                />
              </FlexRow>
              <FlexRow>
                {currentUser.data.phone && (
                  <MetalineWithText
                    align="left"
                    metaline={`${t('userprofile.data.phone')}`}
                    text={currentUser.data.phone}
                    typeScaleMeta="pica"
                    typeScaleText="greatPrimer"
                    typeStyleText="semibold"
                  />
                )}
                {/* callback button */}
              </FlexRow>
            </FlexColumn>
          </ControlsSegment.Body>
        </ControlsSegment>
      </GridItem>
    </GridContainer>
  )
}
