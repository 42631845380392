import React, { useCallback, forwardRef } from 'react'
import { styled } from 'styled-components'
import { typography } from 'styled-system'

import { FlexContainerForwardedRef, FlexItem } from '@layout/BuildingBlocks'

import { typeMap, typeScale, typeStyle } from '@typography'
import { appearance, shape, size } from './variants'

import { useSlots } from 'lib/hooks/useSlots'
import { bool, string } from 'prop-types'
import { SlotLeft, SlotRight } from './SubComponents'

const fontSizeDefault = [0, 1, 2, 3, 4]

const StyledBadge = styled(FlexContainerForwardedRef)`
  align-self: ${(props) => props.alignSelf};
  align-items: ${(props) => props.alignItems};
  max-height: 50px;
  cursor: pointer;
  display: flex;

  &::after {
    content: '0';
    width: 0;
    max-width: 0;
    overflow: hidden;
    visibility: hidden;
  }
  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  ${appearance}
  ${shape}
   ${(props) =>
    props.stretch
      ? {
          display: 'flex',
          flexGrow: 1,
        }
      : { display: 'inline-flex' }}
  ${(props) => props.shape !== 'shapeless' && size}
  ${(props) => props.width && { width: props.width }}
  ${(props) => props.borderRadius && { borderRadius: props.borderRadius }}
`

export const BadgeWithSlotsLeftAndRight = forwardRef(
  (
    {
      children,
      clickable,
      isActive,
      onBlur,
      onClick,
      onFocus,
      tabIndex,
      fontFamily = 'whitneySSm',
      fontSize = fontSizeDefault,
      lineHeight = 'normal',
      appearance = 'dark',
      iconVariantSourceName = 'badgeIcons',
      size = 'medium',
      shape = 'square',
      stretch = false,
      tag = 'div',
      text = null,
      alignSelf = 'center',
      alignItems = 'inherit',
      ...props
    },
    ref
  ) => {
    const { typeStyle, typeScale } = typeMap[size]
    const [label, slotRight, slotLeft] = useSlots(['label', 'slotRight', 'slotLeft'], children)

    const handleBlur = useCallback(
      (event) => {
        typeof onBlur === 'function' && onBlur(event)
      },
      [onBlur]
    )

    const handleClick = useCallback(
      (event) => {
        typeof onClick === 'function' && onClick(event)
      },
      [onClick]
    )

    const handleFocus = useCallback(
      (event) => {
        typeof onFocus === 'function' && onFocus(event)
      },
      [onFocus]
    )

    const { ...slotLeftProps } = slotLeft?.props ?? {}
    const { ...slotRightProps } = slotRight?.props ?? {}

    return (
      <StyledBadge
        appearance={appearance}
        clickable={clickable}
        onBlur={handleBlur}
        onClick={handleClick}
        onFocus={handleFocus}
        ref={ref}
        tabIndex={tabIndex}
        tag={tag}
        typeScale={typeScale}
        typeStyle={typeStyle}
        checked={isActive}
        fontFamily={fontFamily}
        fontSize={fontSize}
        lineHeight={lineHeight}
        iconVariantSourceName={iconVariantSourceName}
        size={size}
        shape={shape}
        stretch={stretch}
        alignSelf={alignSelf}
        alignItems={alignItems}
        {...props}
      >
        {slotLeft && <SlotLeft {...slotLeftProps}>{slotLeft}</SlotLeft>}
        {text && <FlexItem flexGrow="1">{text}</FlexItem>}
        {label && label}
        {slotRight && <SlotRight {...slotRightProps}>{slotRight}</SlotRight>}
      </StyledBadge>
    )
  }
)

BadgeWithSlotsLeftAndRight.Label = ({ children }) => children
BadgeWithSlotsLeftAndRight.Label.displayName = 'label'

BadgeWithSlotsLeftAndRight.SlotLeft = ({ children }) => children
BadgeWithSlotsLeftAndRight.SlotLeft.displayName = 'slotLeft'

BadgeWithSlotsLeftAndRight.SlotRight = ({ children }) => children
BadgeWithSlotsLeftAndRight.SlotRight.displayName = 'slotRight'
BadgeWithSlotsLeftAndRight.propTypes = {
  appearance: string,
  shape: string,
  size: string,
  stretch: bool,
  tag: string,
  text: string,
  alignSelf: string,
  alignItems: string,
}
