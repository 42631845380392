import React from 'react'

import { StyledText } from './SubComponents'

import { defaultPropTypes as textPropTypes } from '@typography'
import { string, number, element, elementType, oneOfType } from 'prop-types'

const fontSizeDefault = [0, 1, 2, 3, 4]

export const Text = ({
  align = null,
  color = null,
  fontFamily = 'whitneySSm',
  fontSize = fontSizeDefault,
  lineHeight = 'normal',
  fontWeight,
  typeScale = 'copy',
  typeStyle = 'medium',
  text = 'Lrem Ipsum',
  height,
  ...props
}) => {
  return (
    <StyledText
      color={color}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      textAlign={align}
      typeScale={typeScale}
      typeStyle={typeStyle}
      height={height}
      {...props}
    >
      {text}
    </StyledText>
  )
}
Text.propTypes = {
  ...textPropTypes,
  align: string,
  color: string,
  text: oneOfType([string, number, element, elementType]),
  typeScale: string,
  typeStyle: string,
  height: string,
}
