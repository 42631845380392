import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import { styled } from 'styled-components'

import { AlignmentBox } from 'components/AlignmentBox'

const Container = styled.div`
  width: 200px;
  background-color: white;
  border-radius: 4px;
  padding: 8px 16px;
  box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.2);
  margin: 8px 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
`

const openerStyle = {
  cursor: 'pointer',
}

export const useAlignmentBoxOnClick = () => {
  const [containerVisible, setContainerVisible] = useState(false)

  const [openerNode, setOpenerNode] = useState(null)

  const eventsSet = useRef(false)
  const domContainer = useRef(null)

  const handleClick = useCallback(() => {
    setContainerVisible((prev) => !prev)
  }, [])

  const onRefChange = useCallback((node) => {
    setOpenerNode(node)
  }, [])

  const testOuterClick = useCallback(
    (evt) => {
      if (domContainer.current === null) {
        return
      }
      if (!domContainer.current.contains(evt.target) && !openerNode.contains(evt.target)) {
        setContainerVisible(false)
      }
      /*
      if (domAlignmentBox.current.contains(evt.target)) {
        shouldClose.current = false
        setTimeout(() => {
          shouldClose.current = true
        }, 1000)
      }
      */
    },
    [openerNode]
  )

  useEffect(() => {
    if (!eventsSet.current) {
      eventsSet.current = true
      if (containerVisible) {
        document.addEventListener('mousedown', testOuterClick)
      }
      return () => {
        eventsSet.current = false
        if (containerVisible) {
          document.removeEventListener('mousedown', testOuterClick)
        }
      }
    }
  }, [containerVisible, testOuterClick])

  const withOnClick = useCallback(
    (Opener) => {
      return () => {
        return (
          <div onClick={handleClick} ref={onRefChange} style={openerStyle}>
            <Opener />
          </div>
        )
      }
    },
    [handleClick, onRefChange]
  )

  const AlignmentBoxOnClick = useMemo(
    () =>
      ({ alignments, children }) => {
        if (!containerVisible) {
          return null
        } else {
          return (
            <AlignmentBox parent={openerNode} alignments={alignments} zIndex={1001}>
              <Container ref={domContainer}>{children}</Container>
            </AlignmentBox>
          )
        }
      },
    [containerVisible, openerNode]
  )

  return [withOnClick, AlignmentBoxOnClick, setContainerVisible]
}
