import { array, string } from 'prop-types'

const fontFamily = string
const fontSize = array
const tag = string

export const defaultPropTypes = {
  fontFamily,
  fontSize,
  tag,
}
