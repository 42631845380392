import React, { useCallback, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { FlexContainer } from '@layout/BuildingBlocks'
import { Minion, Pica, PicaMedium } from '@typography'
import { themeGet } from '@styled-system/theme-get'

import { useNumbersFormats } from 'config/numbersFormats'
import { Radio } from '@forms'

export const priceList = {
  10: 290.0,
  25: 712.5,
  50: 1400.0,
  100: 2700.0,
  250: 6500.0,
  500: 12500.0,
  1000: 17250.0,
  flat: 21000.0,
}

const dataRows = [
  [10, 'quota.licenceOverview.pack', priceList[10], 10.0, 3.3, 1.0, 0.7, 1.0, 0.7],
  [25, 'quota.licenceOverview.pack', priceList[25], 25.0, 8.3, 2.5, 1.7, 2.5, 1.7],
  [50, 'quota.licenceOverview.pack', priceList[50], 50.0, 16.7, 5.0, 3.3, 5.0, 3.3],
  [100, 'quota.licenceOverview.pack', priceList[100], 100.0, 33.3, 10.0, 6.7, 10.0, 6.7],
  [250, 'quota.licenceOverview.pack', priceList[250], 250.0, 83.3, 25.0, 16.7, 25.0, 16.7],
  [500, 'quota.licenceOverview.pack', priceList[500], 500.0, 166.7, 50.0, 33.3, 50.0, 33.3],
  [1000, 'quota.licenceOverview.pack', priceList[1000], 750.0, 250.0, 75.0, 50.0, 75.0, 50.0],
  [
    'flat',
    'quota.licenceOverview.flat',
    priceList['flat'],
    { content: 'quota.licenceOverview.rows.price.unlimited', style: { fontStyle: 'italic' } },
    { content: 'quota.licenceOverview.rows.price.unlimited', style: { fontStyle: 'italic' } },
    { content: 'quota.licenceOverview.rows.price.unlimited', style: { fontStyle: 'italic' } },
    { content: 'quota.licenceOverview.rows.price.unlimited', style: { fontStyle: 'italic' } },
    { content: 'quota.licenceOverview.rows.price.unlimited', style: { fontStyle: 'italic' } },
    { content: 'quota.licenceOverview.rows.price.unlimited', style: { fontStyle: 'italic' } },
  ],
]

const StyledTable = styled.table`
  background-color: white;
  table-layout: fixed;
  width: 100%;
  min-width: 800px;

  &,
  th,
  td {
    background-color: white;
    border-collapse: collapse;
  }

  thead {
    border: 1px solid white;
  }
  thead > tr {
    height: 24px;
  }

  tbody > tr {
    height: 46px;
  }

  ${(props) =>
    !props.hideRadioButtons &&
    `tr:not(:first-child, :nth-child(1), :nth-child(2), :nth-child(3)) > th:nth-child(-n + 3),
  tr > td:first-child {
    position: sticky;
    top: 0;
  } 

  tbody > tr:last-child > td:nth-child(2),
  tbody > tr:nth-last-child(2) > td:nth-child(2) {
    position: sticky;
    top: 0;
    left: 120px;
  }

  tr:not(:first-child) > th:nth-child(1),
  tr > td:nth-child(1) {
    z-index: 1;
    left: -1px;
  }
`}

  thead > tr:nth-child(-n + 3) > th {
    font-weight: normal;
    background-color: #007c8f;
    color: white;
  }

  thead > tr:last-child {
    z-index: 2;
    position: sticky;
    top: 0;
  }
  thead > tr:last-child > th {
    border-bottom: 1px solid ${themeGet('colors.base.grayScale.200')};
  }

  tbody > tr {
    td {
      text-align: center;
    }
  }
  ${(props) =>
    !props.hideRadioButtons &&
    `td:nth-child(3) {
      text-align: end;
      padding-right: 4px;
    }
  

tbody > tr:last-child,
  tbody > tr:nth-last-child(2) {
    td:nth-child(2) {
      text-align: end;
      padding-right: 4px;
    }
  };
`}

  //checkbox fix
  tbody > tr > td > label {
    text-align: start;
  }

  tbody > tr:nth-child(odd) td {
    background-color: ${themeGet('table.data.odd.bg')};
  }

  ${(props) =>
    !props.hideRadioButtons &&
    `tbody > tr:nth-last-child(2) > td,
  tbody > tr:nth-last-child(2):hover > td {
    background-color: ${themeGet('colors.base.grayScale.150')};
  } 
`}
  tbody > tr:last-child {
    height: 24px;
  }
  tbody > tr:nth-child(even) td {
    background-color: ${themeGet('table.data.even.bg')};
  }

  tbody > tr {
    &:hover > td {
      background-color: ${themeGet('table.data.hover.bg')};
    }
  }
`

const columnGroupStyle1 = {
  backgroundColor: '#32C1D7',
}
const columnGroupStyle2 = {
  backgroundColor: '#009EB4',
}

const Cell = ({ data, index, rowIndex, onClick, checked = false, hideRadioButtons }) => {
  const { t } = useTranslation()
  const numbersFormats = useNumbersFormats()
  const style = typeof data === 'object' ? data.style : {}
  let content = typeof data === 'object' ? data.content : data
  if (index === 0) {
    if (hideRadioButtons) {
      return null
    }
    return <Radio checked={checked} name={content} value={content} onChange={onClick} />
  }
  if (index === 2) {
    content = `${numbersFormats.roundedFraction2.format(content)} ${t(
      'quota.licenceOverview.rows.price.unit'
    )}`
  }
  if (index === 1) {
    content = t(content, { value: dataRows[rowIndex][0] })
  } else {
    content = isNaN(content) ? t(content) : numbersFormats.roundedFraction1.format(content)
  }

  return <Pica style={style}>{content}</Pica>
}

export const PriceTable = ({ onChange, initialSelected = null, hideRadioButtons }) => {
  const [selected, setSelected] = useState(initialSelected)
  const numbersFormats = useNumbersFormats()
  const { t } = useTranslation()

  const price = useMemo(() => {
    if (!selected) {
      return '-'
    }
    return priceList[selected]
  }, [selected])

  const onClick = useCallback(
    ({ value }) => {
      setSelected(value)

      if (typeof onChange === 'function') {
        onChange({ value })
      }
    },
    [onChange]
  )

  return (
    <FlexContainer style={{ overflow: 'auto' }}>
      <StyledTable hideRadioButtons={hideRadioButtons}>
        <thead>
          <tr>
            <th width={hideRadioButtons ? '1' : '70'} scope="col" rowSpan="3"></th>
            <th width="100" scope="col" rowSpan="3"></th>
            <th width="145" scope="col" rowSpan="3" style={{ verticalAlign: 'bottom' }}>
              <Minion color="white">{t('quota.licenceOverview.colHeaders.priceInTokens')}</Minion>
            </th>
            <th style={columnGroupStyle1} scope="col" colSpan="2">
              <Pica color="white">{t('quota.licenceOverview.colHeaders.valuationReports')}</Pica>
            </th>
            <th style={columnGroupStyle2} scope="col" colSpan="2">
              <Pica color="white">{t('quota.licenceOverview.colHeaders.marketReports')}</Pica>
            </th>
            <th scope="col" colSpan="2">
              <Pica color="white">{t('quota.licenceOverview.colHeaders.locationReports')}</Pica>
            </th>
          </tr>
          <tr>
            <th style={columnGroupStyle1} scope="col">
              <Minion color="white">{t('quota.licenceOverview.colHeaders.residential.title')}</Minion>
            </th>
            <th style={columnGroupStyle1} scope="col">
              <Minion color="white">{t('quota.licenceOverview.colHeaders.others.title')}</Minion>
            </th>
            <th style={columnGroupStyle2} scope="col">
              <Minion color="white">{t('quota.licenceOverview.colHeaders.briefing.title')}</Minion>
            </th>
            <th style={columnGroupStyle2} scope="col">
              <Minion color="white">{t('quota.licenceOverview.colHeaders.detail.title')}</Minion>
            </th>
            <th scope="col">
              <Minion color="white">{t('quota.licenceOverview.colHeaders.briefing.title')}</Minion>
            </th>
            <th scope="col">
              <Minion color="white">{t('quota.licenceOverview.colHeaders.detail.title')}</Minion>
            </th>
          </tr>
          <tr>
            <th style={columnGroupStyle1} scope="col">
              <Minion color="white">{t('quota.licenceOverview.colHeaders.residential.price')}</Minion>
            </th>
            <th style={columnGroupStyle1} scope="col">
              <Minion color="white">{t('quota.licenceOverview.colHeaders.others.price')}</Minion>
            </th>
            <th style={columnGroupStyle2} scope="col">
              <Minion color="white">{t('quota.licenceOverview.colHeaders.briefing.price')}</Minion>
            </th>
            <th style={columnGroupStyle2} scope="col">
              <Minion color="white">{t('quota.licenceOverview.colHeaders.detail.price')}</Minion>
            </th>
            <th scope="col">
              <Minion color="white">{t('quota.licenceOverview.colHeaders.briefing.price')}</Minion>
            </th>
            <th scope="col">
              <Minion color="white">{t('quota.licenceOverview.colHeaders.detail.price')}</Minion>
            </th>
          </tr>
          <tr height="30"></tr>
          <tr>
            <th scope="col">
              {hideRadioButtons ? null : (
                <PicaMedium>{t('quota.licenceOverview.colHeaders.select')}</PicaMedium>
              )}
            </th>
            <th scope="col">
              <PicaMedium>{t('quota.licenceOverview.colHeaders.tokenQuantity')}</PicaMedium>
            </th>
            <th scope="col">
              <PicaMedium>{t('quota.licenceOverview.colHeaders.price')}</PicaMedium>
            </th>
            <th scope="col" colSpan="6">
              <PicaMedium>{t('quota.licenceOverview.colHeaders.reportsPerPackage')}</PicaMedium>
            </th>
          </tr>
        </thead>
        <tbody>
          {dataRows.map((dataRow, rowIndex) => (
            <tr key={rowIndex}>
              {dataRow.map((data, index) => (
                <td key={index}>
                  <Cell
                    data={data}
                    onClick={onClick}
                    rowIndex={rowIndex}
                    index={index}
                    checked={dataRow[0] === selected}
                    hideRadioButtons={hideRadioButtons}
                  />
                </td>
              ))}
            </tr>
          ))}
          {!hideRadioButtons && (
            <tr>
              <td colSpan="2">{t('quota.licenceOverview.rows.price.title')}</td>
              <td colSpan="1">
                {isNaN(price) ? price : numbersFormats.roundedFraction2.format(price)}
                {' ' + t('quota.licenceOverview.rows.price.unit')}
              </td>
              <td colSpan="6"></td>
            </tr>
          )}
          <tr>
            <td colSpan="2"></td>
            <td colSpan="1">
              <Minion>{t('quota.licenceOverview.rows.price.hint')}</Minion>
            </td>
            <td colSpan="6"></td>
          </tr>
        </tbody>
      </StyledTable>
    </FlexContainer>
    //     </Modal.Section>
    //     <Modal.Footer>
    //       <FlexRow>
    //         <a href={href}>
    //           <Button appearance="primary" text={'Anfrage senden'} />
    //         </a>
    //       </FlexRow>
    //     </Modal.Footer>
    //   </Modal.View>
    // </Modal>
  )
}
