import React, { forwardRef } from 'react'
import { styled } from 'styled-components'
import { typography } from 'styled-system'

import { bool, string, oneOf } from 'prop-types'

import { HTMLAnchorElement, ReactRouterDomLink } from '../SubComponents'

import { appearance, shape, size } from './variants'
import { typeMap, typeScale, typeStyle } from '@typography'

const StyledComponent = styled(HTMLAnchorElement)`
  align-self: center;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  ${appearance}
  ${shape}
  ${(props) => props.shape !== 'shapeless' && size}
  ${(props) => props.minWidth && { minWidth: props.minWidth }}
  ${(props) => props.width && { width: props.width }}
  ${(props) => props.height && { height: props.height }}
  ${(props) => props.stretch && { width: '100%' }}
  ${(props) => props.leftAlign && { justifyContent: 'flex-start' }}
`

const fontSizeDefault = [0, 1, 2, 3, 4]

export const ButtonLink = forwardRef(
  (
    {
      children,
      fontFamily = 'whitneySSm',
      fontSize = fontSizeDefault,
      lineHeight = 'normal',
      appearance = 'lightgray',
      href = null,
      leftAlign = null,
      linkType = 'htmlAnchorElement',
      size = 'medium',
      shape = 'oval',
      stretch = null,
      to = '/',
      ...props
    },
    ref
  ) => {
    props = {
      ...props,
      fontFamily,
      fontSize,
      lineHeight,
      appearance,
      href,
      leftAlign,
      linkType,
      size,
      shape,
      stretch,
      to,
    }
    const { typeStyle, typeScale } = typeMap[size]

    ButtonLink.propTypes = {
      appearance: string,
      leftAlign: bool,
      linkType: oneOf(['htmlAnchorElement', 'reactRouterDomLink']),
      shape: string,
      size: string,
      text: string,
      href: string,
      stretch: bool,
    }
    return linkType === 'htmlAnchorElement' ? (
      <StyledComponent typeScale={typeScale} typeStyle={typeStyle} ref={ref} {...props} />
    ) : (
      <StyledComponent
        as={ReactRouterDomLink}
        typeScale={typeScale}
        typeStyle={typeStyle}
        ref={ref}
        {...props}
      />
    )
  }
)
