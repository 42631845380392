import React, { useCallback } from 'react'
import { styled } from 'styled-components'
import { func } from 'prop-types'

import { ButtonIcon } from '@ui/Buttons/ButtonIcon'
import { BudiconChevronBottom } from 'bgag-budicons'

import { useControlsSegmentSectionContext } from '@ui/ControlsSegment/SubComponents'

const Button = styled(ButtonIcon)`
  transform: ${(props) => (props.folded ? 'rotate(0)' : ' rotate(180deg)')};
  transition: transform 0.2s ease-out;
`

export const ControlsSubFold = ({ onClick }) => {
  const { toggle, folded } = useControlsSegmentSectionContext()
  const onLocalClick = useCallback(() => {
    toggle((prev) => !prev)
    if (typeof onClick === 'function') {
      onClick()
    }
  }, [toggle, onClick])

  return (
    <Button
      appearance="bare"
      shape="shapeless"
      size="small"
      folded={folded}
      icon={{ Icon: BudiconChevronBottom, width: '18px', strokeWidth: '0.5' }}
      onClick={onLocalClick}
    />
  )
}
ControlsSubFold.propTypes = {
  onClick: func,
}
