import bgagColors from 'config/colors/bgag-colors.json'

// import numbersFormats from 'config/numbersFormats'

import { isRIWISCity, findAreaByMarketCategory } from 'lib/helper/locationHelpers'
import { getQuarterByMonth } from 'lib/util/Formatters'
import { colorAllocator, colorCalculator, dataColorAllocator } from 'lib/util/colorAllocator'

const GLOBAL_ACCUMULATION_RANGE = 5
const dataSourcesCache = {}

const dashStyles = ['Solid', 'ShortDash', 'ShortDot', 'ShortDashDot', 'ShortDashDotDot']

export const fallbackObjectFilters = [
  { licence: 'pipeline', option: 'pipeline' },
  { licence: 'pipelinePlus', option: 'pipeline' },
  { licence: 'comparablesTenant', option: 'tenant' },
  { licence: 'comparablesSales', option: 'sales' },
  { licence: 'tenure', option: 'tenure' },
]

export const gacNamePrefixes = { 30: 'district', 200: 'logisticsRegion' }

const getShowInLegend = (data) =>
  data.some((data) => {
    if (Array.isArray(data)) {
      return data.some((value) => value)
    }
    return data
  })

const arraySum = (array) => {
  let sum = 0
  for (let i = 0, l = array.length; i < l; i++) {
    sum += array[i]
  }
  return sum
}

const formatQuarterTitle = (quarters, short = false) => {
  const formatted = quarters.map(
    (unit) =>
      'Q' + unit.split('-')[1] + '/' + (short ? unit.split('-')[0].substring(2, 4) : unit.split('-')[0])
  )
  return formatted
}

const numbersRange = (start, stop, stepSize = 1) => {
  // from https://bit.ly/2KKMiSl
  if (stop === null) {
    stop = start
    start = 1
  }
  let steps = (stop - start) / stepSize
  let set = []
  for (let step = 0; step <= steps; step++) set.push(start + step * stepSize)
  return set
}

const numbersRangeQuarter = (start, stop, maxMonth, maxYear, startMonth, stopMonth) => {
  if (stop === null) {
    stop = start
    start = 1
  }
  maxMonth = maxYear && maxYear > stop ? 12 : (stopMonth ?? maxMonth) || 12
  const stopQuarter = getQuarterByMonth(maxMonth)
  const startQuarter = getQuarterByMonth(startMonth ?? 3)
  const steps = stop - start
  const set = []
  for (let step = 0; step <= steps; step++) {
    let year = start + step
    for (let q = 1; q <= 4; q++) {
      if (step === 0 && q < startQuarter) {
        continue
      }
      if (step < steps || q <= stopQuarter) {
        set.push(year + '-' + q)
      }
    }
  }
  return set
}

export const getCountryCodeByGac = (gac) => {
  return typeof gac === 'undefined' || gac.match(/(^[0-9]|^L_[0-9])/) || gac.length < 8
    ? 'DE'
    : gac.substring(0, 2).toUpperCase()
}
// from column line combo fiddle
//   http://jsfiddle.net/BlackLabel/t6gzd8u7
const calculateColumnMetrics = (numColumns) => {
  const metrics = []
  const categoryWidth = 1
  const groupPadding = categoryWidth * 0.2
  const groupWidth = categoryWidth - 2 * groupPadding
  const pointOffsetWidth = groupWidth / numColumns

  for (let colIndex = 0; colIndex < numColumns; colIndex++) {
    const pointXOffset = groupPadding + colIndex * pointOffsetWidth - categoryWidth / 2
    metrics.push({
      width: pointOffsetWidth,
      offset: pointXOffset,
      center: Math.round((pointXOffset + pointOffsetWidth / 2.0) * 1000) / 1000,
    })
  }

  return metrics
}

export const extractDataSources = (
  topicKey,
  topicConfig,
  permissions = null,
  dataSourcesUser = null,
  countryCodes,
  submarketEquivalents,
  submarketChartAlternative
) => {
  const includeSubmarkets = permissions === null || permissions?.licence?.modules?.includes('submarket')

  const cashKey = topicKey + countryCodes.join('-')

  if (typeof dataSourcesCache[cashKey] === 'undefined') {
    dataSourcesCache[cashKey] = topicConfig.dataSources.reduce((acc, sourceObject) => {
      const addValues = new Set()
      for (const [countryCode, source] of Object.entries(sourceObject.source)) {
        if (source === null || !countryCodes.includes(countryCode)) {
          continue
        }
        if (source?.type === 'key' || (source?.type === 'submarketOnly' && includeSubmarkets)) {
          addValues.add(source.key)
        }
      }

      for (const countryCode of countryCodes) {
        if (!countryCodes.includes(countryCode)) {
          continue
        }
        addValues.forEach((value) => {
          if (dataSourcesUser.includes(value)) {
            acc.push(value)
          }
          if (includeSubmarkets) {
            if (
              typeof submarketEquivalents[value] !== 'undefined' &&
              !acc.includes(submarketEquivalents[value]) &&
              dataSourcesUser.includes(submarketEquivalents[value])
            ) {
              acc.push(submarketEquivalents[value])
            }
            if (
              typeof submarketChartAlternative[value] !== 'undefined' &&
              !acc.includes(submarketChartAlternative[value]) &&
              dataSourcesUser.includes(submarketChartAlternative[value])
            ) {
              acc.push(submarketChartAlternative[value])
            }
          }
        })
      }
      return acc
    }, [])
  }
  return dataSourcesCache[cashKey]
}

const testGacs = (gac, gacs, includeSubmarkets, includeRegionGac) => {
  if (gacs === null) {
    return true
  }
  // data requested for gac of federalState is accessible if any location in this federalState is authorized!
  // Hamburg 02, Bremen 04 and Berlin 11 have to be tested on complete gac
  if (gac.length === 8 && gac.match(/0{6}$/) && !['02', '04', '11'].includes(gac.substring(0, 2))) {
    return testGacs(gac.substring(0, 2), gacs, includeSubmarkets, includeRegionGac)
  }
  const isSubmarketGac = gac.length > 8
  const isRegionGac = gac.startsWith('L_')
  return gacs.some((testGac) => {
    if (isRegionGac && includeRegionGac) {
      return true
    }
    if (isRegionGac && !includeRegionGac) {
      return false
    }
    const testIsSubmarketGac = testGac.startsWith('sub')
    if (isSubmarketGac !== testIsSubmarketGac || (isSubmarketGac && !includeSubmarkets)) {
      return false
    }
    if (typeof testGac === 'string') {
      if (testIsSubmarketGac) {
        testGac = testGac.substring(3)
      }
      testGac = gac.length === 2 ? testGac.substring(0, 2) : testGac
      return gac.startsWith(testGac)
    }
    return false
  })
}

export const findFittingType = (feature, topicKey, topicConfig, permissions = null, availableGacs = null) => {
  let fittingType = null

  const includeSubmarkets = permissions === null || permissions?.licence?.modules?.includes('submarket')
  const includeLogisticsRegions = permissions?.licence?.modules?.includes('logisticsRegion')

  const availableAreas = Object.values(feature.areas).filter((area) =>
    testGacs(area.gac, availableGacs, includeSubmarkets, includeLogisticsRegions, topicKey)
  )
  if (!Array.isArray(topicConfig.availableAreaTypes)) {
    console.error('Property availableAreaTypes is missing on topic: ', topicKey)
    return fittingType
  }
  if (!Array.isArray(topicConfig.availableAreaFlags)) {
    console.error('Property availableAreaFlags is missing on topic: ', topicKey)
    return fittingType
  }

  const topicsAreaTypes = topicConfig.availableAreaTypes.map((type) => Number(type))
  const topicsAreaFlags = [...topicConfig.availableAreaFlags]
  const featuresAreaTypes = availableAreas.map((area) => Number(area.type))

  if (topicConfig.isQuarter) {
    // Quarter
    const foundACity = availableAreas.find((area) => area.market_category === 'A')
    if (foundACity) {
      fittingType = foundACity.type
    }
  } else if (topicConfig.isForecast) {
    // Forecast
    if (topicConfig.availableForCityTypes.length) {
      const foundResult = availableAreas.find((area) =>
        topicConfig.availableForCityTypes.includes(area.market_category)
      )
      if (foundResult) fittingType = foundResult.type
    } else if (topicConfig.availableForAreaTags.includes('office_forecast_available')) {
      const foundResult = availableAreas.find((area) => area.office_forecast_available === true)
      if (foundResult) fittingType = foundResult.type
    } else if (topicConfig.availableForAreaTags.includes('retail_forecast_available')) {
      const foundResult = availableAreas.find((area) => area.retail_forecast_available === true)
      if (foundResult) fittingType = foundResult.type
    } else {
      const availableTypes = featuresAreaTypes.filter((type) => topicsAreaTypes.includes(type))
      fittingType = !availableTypes.length ? null : String(Math.min(...availableTypes))
    }
  } else {
    // Normal (not quarter or forecast)
    const identifier = feature.identifier
    const addressLevel = feature.addressLevel || identifier
    const availableTypes = featuresAreaTypes.filter((type) => {
      let available
      if (Array.isArray(topicConfig.availableForAreaTags) && topicConfig.availableForAreaTags.length) {
        available =
          topicConfig.availableForAreaTags.some((tag) => {
            return availableAreas.some((area) => Number(area.type) === type && area[tag])
          }) ||
          (type > 100 && topicsAreaTypes.includes(type))
      } else {
        available = topicsAreaTypes.includes(type)
      }
      return available
    })

    if (
      parseInt(addressLevel) < 10 &&
      includeSubmarkets &&
      availableTypes.some((type) => type > 100 && type !== 200)
    ) {
      fittingType = !availableTypes.length ? null : String(Math.max(...availableTypes))
    } else if (parseInt(addressLevel) === 200 && includeLogisticsRegions && availableTypes.includes(200)) {
      fittingType = !availableTypes.length ? null : String(Math.max(...availableTypes))
    } else if (
      (feature.districtRelatedRiwisCity &&
        topicsAreaFlags.includes('districtRelatedRiwisCity' + feature.properties.countryCode)) ||
      (topicConfig.regionalDataGranular &&
        feature.properties.countryCode === 'DE' &&
        featuresAreaTypes.includes(10))
    ) {
      fittingType = '10'
    } else if (topicConfig.regionalDataAvailable && findAreaByMarketCategory(feature, 'R')) {
      // feature includes an area, which is NOT a riwis city (a|b|c|d) and has flag market_data_regional or type 30
      const type = findAreaByMarketCategory(feature, 'R').type
      if (availableTypes.includes(Number(type))) {
        fittingType = type
      }
    } else if (topicsAreaTypes.includes(Number(identifier)) && availableTypes.includes(Number(identifier))) {
      fittingType = identifier
    } else if (topicConfig?.availableForCityTypes?.length) {
      const foundResult = availableAreas.find((area) =>
        topicConfig.availableForCityTypes.includes(area.market_category)
      )
      if (foundResult) fittingType = foundResult.type
    } else {
      fittingType = !availableTypes.length ? null : String(Math.min(...availableTypes))
    }
  }

  return fittingType
}

export const findFittingAreaType = (location, fittingType) => {
  const area = location?.areas?.[fittingType]
  if (area) {
    if (area.gac.match(/^at/)) {
      if (area.market_category === 'AT') {
        return 'riwisCitiesAustria'
      }
      return 'districtWithoutRiwisCitiesAustria'
    } else {
      if (parseInt(fittingType) > 100) {
        if (parseInt(fittingType) === 200) {
          return 'logisticsRegion'
        }
        return 'riwisCityA'
      }
      if (area.market_data_regional === true && !isRIWISCity(area.market_category)) {
        return 'riwisCitiesRegio'
      }
      if (area.market_category !== null && area.market_category.match(/^[A-D]$/)) {
        return 'riwisCity' + area.market_category
      }
      return 'districtWithoutRiwisCities'
    }
  }
  return null
}

export const transformQuarterResponse = (resData) => {
  const testDate = new RegExp(/^[0-9]+$/)
  return Object.entries(resData).reduce((acc, [gac, data]) => {
    const transformedData = Object.entries(data).reduce((acc, [date, rows]) => {
      let key
      if (testDate.exec(date)) {
        key = date
      } else {
        date = new Date(date)
        key = date.getFullYear() + '-' + Math.ceil((date.getMonth() + 1) / 3)
      }
      acc[key] = rows
      return acc
    }, {})
    acc[gac] = transformedData
    return acc
  }, {})
}

export const setTopicColors = ({
  config,
  locations = null,
  locationData = {},
  submarketChartAlternative,
}) => {
  const colors = []
  if (locations) {
    const countryCodes = Array.from(new Set(Object.values(locations).map((gac) => getCountryCodeByGac(gac))))
    if (countryCodes.length > 1) {
      console.warn('Patch neededed to support multiple countries for topicColors')
    }
    config.chartViews[countryCodes[0]].forEach((chartView, chartViewIndex) => {
      let indexLine = 0
      chartView.yAxis.forEach((yAxisObject, yAxisIndex) => {
        let indexColumn = 0
        let colorAllocatorIndex = null
        let colorLineIndex = null
        let opacity = 1
        let colorReplacmentIndexMap = yAxisObject.colorReplacmentIndexMap || null

        const series = yAxisObject.series || [
          {
            seriesType: yAxisObject.seriesType || 'column',
            sources: yAxisObject.axisSources,
          },
        ]

        series.forEach((serie, serieIndex) => {
          if (serie.seriesType === 'columnrange') {
            colorAllocatorIndex = indexColumn
            colorLineIndex = null
            indexColumn++
          }
          serie.sources.forEach((dataSourceID, dataSourceIndex) => {
            if (serie.seriesType === 'line') {
              colorAllocatorIndex = 2
              colorLineIndex = indexLine
              indexLine++
            } else if (serie.seriesType === 'scatter' && !serie.recalcXPositions) {
              colorAllocatorIndex = indexColumn
              colorLineIndex = null
              indexColumn++
            } else if (serie.seriesType === 'column') {
              colorAllocatorIndex = indexColumn
              colorLineIndex = null
              if (typeof yAxisObject.colorAllocatorIndexMap === 'object') {
                const colorAllocatorIndexMapLength = Object.keys(yAxisObject.colorAllocatorIndexMap).length
                colorAllocatorIndex =
                  yAxisObject.colorAllocatorIndexMap[indexColumn % colorAllocatorIndexMapLength]
              }
              if (typeof yAxisObject.opacityIndexMap === 'object') {
                const opacityIndexMapLength = Object.keys(yAxisObject.opacityIndexMap).length
                opacity = yAxisObject.opacityIndexMap[indexColumn % opacityIndexMapLength]
              }
              indexColumn++
            }

            Object.entries(locations).forEach(([locationID, gac]) => {
              const isSubmarketGac = typeof gac === 'string' && gac.length > 8
              let altId = -1
              if (isSubmarketGac) {
                // the given dataSourceId for this chart might be replaced in the table by an alternative
                // if so, here we find out and set the appropriate source id for the topic-colors:
                const source = config.dataSources[dataSourceID].source
                const altSource = submarketChartAlternative[source]
                if (altSource) {
                  altId = config.dataSources.findIndex((item) => {
                    let source = item.source
                    if (typeof source === 'object' && source.type === 'submarketOnly') {
                      source = source.source
                    }
                    return source === altSource
                  })
                }
              }
              colors.push({
                chartViewIndex,
                locationID: parseInt(locationID),
                dataSourceID: dataSourceID,
                allocatorIndex: colorAllocatorIndex,
                lineIndex: colorLineIndex,
                altDataSourceID: altId >= 0 ? altId : null,
                color: yAxisObject.dataColors
                  ? dataColorAllocator(dataSourceIndex, yAxisObject.dataColors)
                  : locationData[locationID].baseColor === null
                    ? null
                    : typeof locationData[locationID]?.baseColor !== 'undefined'
                      ? colorCalculator(locationData[locationID].baseColor, colorAllocatorIndex)
                      : colorAllocator(locationID, colorAllocatorIndex, colorReplacmentIndexMap),
                opacity,
              })
            })
          })
        })
      })
    })
  }
  return colors
}

const numDigits = (x) => (Math.log10((x ^ (x >> 31)) - (x >> 31)) | 0) + 1
const getDivider = (val) => {
  const length = numDigits(val)
  return length > 1 ? 10 ** (length - 1) : 1
}

export const calcAxisBorders = (min, max) => {
  const minDivisor = getDivider(min)
  const maxDivisor = getDivider(max)

  let bottom = Math.floor(min / minDivisor) * minDivisor
  let top = Math.ceil(max / maxDivisor) * maxDivisor

  if (min / bottom < 1.1) bottom = Math.floor(bottom * 0.9)
  if (top / max < 1.06) top = Math.ceil(top * 1.06)

  return [bottom, top]
}

const getValue = (gac, year, choosenSource, cluster) => {
  if (!cluster[gac] || !cluster[gac][year]) return null

  return typeof cluster[gac][year][choosenSource] === 'undefined' ? null : cluster[gac][year][choosenSource]
}

const hasSubmarketEquivalents = (source, dataSources, countryCode, submarketEquivalents) => {
  let hasEquivalents = true
  for (let key in source) {
    if (key !== 'type' && key !== 'multiplicator' && key !== 'name') {
      const testKey =
        typeof source[key] === 'string' ? source[key] : dataSources[source[key]].source[countryCode]
      hasEquivalents =
        hasEquivalents &&
        ((typeof testKey === 'object' &&
          hasSubmarketEquivalents(testKey, dataSources, countryCode, submarketEquivalents)) ||
          (typeof testKey === 'string' && typeof submarketEquivalents[testKey] !== 'undefined'))
    }
  }
  return hasEquivalents
}

export const getTimeUnits = (topicConfig, time) => {
  let timeUnits = []
  let timeUnitTitles
  if (
    topicConfig.key === 'office_space_by_yr_of_completion' ||
    topicConfig.key === 'office_space_by_yr_of_completion_old'
  ) {
    timeUnits.push(time.from.toString())
    let cur = time.from - 1
    while (cur + 5 <= time.to) {
      timeUnits.push(cur + 1 + '-' + (cur + 5))
      cur += 5
    }
    timeUnits.push((cur + 1).toString())
    timeUnitTitles = timeUnits.map((unit, index) =>
      index === 0 ? 'vor ' + unit : index === timeUnits.length - 1 ? 'ab ' + unit : unit
    )

    if (topicConfig.key === 'office_space_by_yr_of_completion') {
      timeUnits = timeUnits.map((unit, index) => {
        return index === 0 || index === timeUnits.length - 1 ? unit.split('-')[0] : unit.split('-')[1]
      })
    }
  } else if (
    [
      'census_households',
      'census_residential_buildings',
      'census_sociodemographic',
      'census_units_residential_buildings',
    ].includes(topicConfig.key)
  ) {
    timeUnits = numbersRange(time.from, time.to)
    timeUnitTitles = timeUnits.map((time) => `marketData:timeUnits.census${time}`)
  } else if (topicConfig.isQuarter) {
    timeUnits = numbersRangeQuarter(
      time.from,
      time.to,
      time.maxMonth,
      time.maxYear,
      time.fromMonth,
      time.toMonth
    )
    timeUnitTitles = formatQuarterTitle(timeUnits, true)
  } else {
    timeUnits = numbersRange(time.from, time.to)
    timeUnitTitles = timeUnits
  }
  return [timeUnits, timeUnitTitles]
}

export const generateChartData = ({
  topicConfig,
  submarketEquivalents,
  submarketChartAlternative,
  locations,
  time,
  cluster,
  clusterReference,
  colors,
  chartView,
  axisSource,
  t,
  isReport,
}) => {
  const [years] = getTimeUnits(topicConfig, time)

  const countryCodes = Array.from(
    Object.values(locations).reduce((set, gac) => {
      set.add(getCountryCodeByGac(gac))
      return set
    }, new Set())
  )

  const chartDataOfLocations = {}
  let newChartData = []
  if (chartView === undefined) return newChartData

  if (countryCodes.length > 1) {
    console.warn('Patch neededed to support multiple countries in one Chart')
    return newChartData
  }

  const chartViewConfig = topicConfig?.chartViews?.[countryCodes[0]]?.[chartView] ?? null
  if (chartViewConfig === null) return newChartData

  const showReferenceCurve = chartViewConfig?.showReferenceCurve ?? true

  const shiftValue = (val, multiplicator) =>
    val === null ? null : multiplicator ? (val *= multiplicator) : val

  const getChosenSource = (
    source,
    countryCode,
    isSubmarketGac,
    submarketEquivalents,
    submarketEquivalentNeeded,
    submarketChartAlternative
  ) => {
    let chosenSource
    if (
      source?.key &&
      submarketEquivalentNeeded &&
      isSubmarketGac &&
      typeof submarketEquivalents[source.key] !== 'undefined'
    ) {
      chosenSource = submarketEquivalents[source.key]
    } else if (
      source?.key &&
      submarketEquivalentNeeded &&
      isSubmarketGac &&
      typeof submarketChartAlternative[source.key] !== 'undefined'
    ) {
      chosenSource = submarketChartAlternative[source.key]
    } else {
      chosenSource = source.key
    }

    if (
      submarketEquivalentNeeded &&
      isSubmarketGac &&
      !hasSubmarketEquivalents(source, topicConfig.dataSources, countryCode, submarketEquivalents) &&
      typeof submarketChartAlternative[source?.key] === 'undefined'
    ) {
      chosenSource = null
    }
    return chosenSource
  }

  chartViewConfig.yAxis.forEach((yAxisObject, yAxisIndex) => {
    let newYAxis = {
      id: `yAxis${chartView}_${yAxisIndex}`,
      title: yAxisObject.axisLabel ? t(`marketData:axisLabels.${yAxisObject.axisLabel}`) : '',
      opposite: yAxisIndex === 0 ? false : true,
      labels: {
        format: yAxisObject.pointLabel || '{value}',
      },
      series: [],
    }
    let yAxisMinValue = undefined
    let yAxisMaxValue = undefined

    const series = yAxisObject.series || [
      {
        seriesType: yAxisObject.seriesType,
        sources: yAxisObject.axisSources,
      },
    ]

    const recalcPositionSeriesLength = series.reduce((length, serie) => {
      if (serie.seriesType !== 'scatter' || !serie.recalcXPositions) {
        length++
      }
      return length
    }, 0)

    let columnMetrics = []
    const locationEntries = Object.entries(locations)

    if (yAxisObject.seriesType === 'pie') {
      locationEntries.forEach(([locationID, gac], locationIndex) => {
        const chartData = []
        const countryCode = getCountryCodeByGac(gac)
        chartData[yAxisIndex] = { ...newYAxis }
        series.forEach((serie, serieIndex) => {
          const seriesOfLocation = [
            {
              id: `chartView${chartView}${
                chartViewConfig.useAxisSourceSelector && chartViewConfig.chartType === 'dataSourceOfTime'
                  ? '-dataSource' + axisSource
                  : ''
              }-location${locationID}`,
              type: serie.seriesType,
              colorByPoint: true,

              gac: gac,
              locationID: locationID,
              data: [],
              dataSources: [],
              dataSourcesKeys: [],
              isSubmarketGac: false,
              dataLabels: [
                {
                  enabled: false,
                },
              ],
            },
          ]
          chartData[yAxisIndex].series = seriesOfLocation
          serie.sources.forEach((dataSourceID, dataSourceIndex) => {
            const source = topicConfig.dataSources[dataSourceID].source[countryCode]
            if (source === null) {
              return
            }
            const sourceName = source.key
            const { multiplicator } = topicConfig.dataSources[dataSourceID]

            // Check if gac is submarket because of length > 8 ...
            const isSubmarketGac = gac.length > 8
            const submarketEquivalentNeeded =
              typeof source?.key === 'string' ? !source.key.includes('.virtual.') : true

            const chosenSource = getChosenSource(
              source,
              countryCode,
              isSubmarketGac,
              submarketEquivalents,
              submarketEquivalentNeeded,
              submarketChartAlternative
            )
            if (chosenSource === null) {
              // .... and avoid empty rows where dataSource hasnt a submarket flag
              return
            } else {
              let color = colors.find((color) => {
                return (
                  color.chartViewIndex === parseInt(chartView) &&
                  color.locationID === parseInt(locationID) &&
                  color.dataSourceID === parseInt(dataSourceID)
                )
              })

              if (chartViewConfig.chartType === 'dataSourceOfTime') {
                const value = shiftValue(getValue(gac, axisSource, chosenSource, cluster), multiplicator)

                const data = {
                  y: value,
                  color: color?.color || null,
                  opacity: color ? color.opacity : 1,
                  year: axisSource,
                }
                seriesOfLocation[serieIndex].id += `-dataSource${dataSourceID}`
                seriesOfLocation[serieIndex].data.push(data)
                seriesOfLocation[serieIndex].dataSources.push(dataSourceID)
                seriesOfLocation[serieIndex].dataSourcesKeys.push(sourceName)
              }
            }
          })
        })
        chartDataOfLocations[locationID] = chartData
      })
    } else {
      locationEntries.forEach(([locationID, gac], locationIndex) => {
        const countryCode = getCountryCodeByGac(gac)
        let recalcPositionSeriesIndex = 0
        series.forEach((serie, serieIndex) => {
          serie.sources.forEach((dataSourceID, dataSourceIndex) => {
            const source = topicConfig.dataSources[dataSourceID].source[countryCode]
            if (source === null) {
              return
            }
            const sourceName = source.key
            const { multiplicator } = topicConfig.dataSources[dataSourceID]

            const topicGacsLength = locationEntries.length

            // Check if gac is submarket because of length > 8 ...
            const isSubmarketGac = gac.length > 8
            const submarketEquivalentNeeded =
              typeof source?.key === 'string' ? !source.key.includes('.virtual.') : true

            const chosenSource = getChosenSource(
              source,
              countryCode,
              isSubmarketGac,
              submarketEquivalents,
              submarketEquivalentNeeded,
              submarketChartAlternative
            )
            if (chosenSource === null) {
              // .... and avoid empty rows where dataSource hasnt a submarket flag
              return
            } else {
              const firstLocationSeries = newYAxis.series.find((serie) =>
                serie.id.includes(`location${locationID}`)
              )
              const columnrangeParent = newYAxis.series.find(
                (serie) =>
                  serie.id.includes(`location${locationID}`) &&
                  serie.type === 'columnrange' &&
                  serie.dataSources.length === 1
              )

              let color = colors.find((color) => {
                return (
                  color.chartViewIndex === parseInt(chartView) &&
                  color.locationID === parseInt(locationID) &&
                  color.dataSourceID === parseInt(dataSourceID)
                )
              })

              let pattern = yAxisObject?.patterns?.[dataSourceIndex] || null

              let newSerie = {}
              if (serie.seriesType === 'columnrange' && columnrangeParent) {
                columnrangeParent.data = years.map((year, index) => {
                  const value = shiftValue(getValue(gac, year, chosenSource, cluster), multiplicator)
                  const parentValue = columnrangeParent.data[index]

                  // if any value is null, the tooltip gets not positioned properly
                  let first = parentValue
                  let second = value
                  if (typeof first !== 'number') {
                    columnrangeParent.zeroToNull = true
                    first = 0
                  }
                  if (typeof second !== 'number') {
                    columnrangeParent.zeroToNull = true
                    second = 0
                  }

                  return [first, second]
                })
                columnrangeParent.dataSources.push(dataSourceID)
                columnrangeParent.dataSourcesKeys.push(sourceName)
              } else {
                const data = years.map((year, index) => {
                  let value
                  let reassign
                  if (
                    yAxisObject.sourcesDisplayFirstOnlyIfSecondIsNull &&
                    (reassign = yAxisObject.sourcesDisplayFirstOnlyIfSecondIsNull.find(
                      (item) => item[0] === dataSourceID
                    )) &&
                    reassign
                  ) {
                    // exception to allow proper display of percentage columns for gross_value_added
                    // some locations (e.g. Bremen) only have "dienstleistungsgewerbe" for some years, whereas most have 3 subcategories of "dienstleistungsgewerbe"
                    const isNull = reassign[1].some((sourceId) => {
                      const source = topicConfig.dataSources[sourceId].source[countryCode]
                      const sourceName = source.key
                      return (cluster?.[gac]?.[year]?.[sourceName] ?? null) === null
                    })
                    value = isNull
                      ? shiftValue(getValue(gac, year, chosenSource, cluster), multiplicator)
                      : null
                  } else {
                    value = shiftValue(getValue(gac, year, chosenSource, cluster), multiplicator)
                  }
                  if (serie.recalcXPositions) {
                    if (!columnMetrics.length) {
                      columnMetrics = calculateColumnMetrics(topicGacsLength * recalcPositionSeriesLength)
                    }
                    return {
                      x:
                        index +
                        columnMetrics[locationIndex * recalcPositionSeriesLength + recalcPositionSeriesIndex]
                          .center,
                      y: value,
                      year,
                    }
                  } else {
                    return value
                  }
                })

                const showInLegend = isReport ? getShowInLegend(data) : null

                newSerie = {
                  id: `chartView${chartView}-dataSource${dataSourceID}-location${locationID}`,
                  yAxis:
                    typeof yAxisObject.yAxis === 'number'
                      ? yAxisObject.yAxis
                      : `yAxis${chartView}_${yAxisIndex}`,
                  type: serie.seriesType || 'column',
                  dashStyle: dashStyles[color?.lineIndex] || 'Solid',
                  zIndex:
                    serie.seriesType === 'line'
                      ? dataSourceIndex + 10
                      : serie.seriesType === 'scatter'
                        ? dataSourceIndex + 20
                        : dataSourceIndex,
                  linkedTo: topicGacsLength > 0 && firstLocationSeries ? firstLocationSeries.id : undefined,
                  color: color ? color.color : null,
                  opacity: color ? color.opacity : 1,
                  pattern,
                  gac: gac,
                  locationID: locationID,
                  data,
                  showInLegend,
                  dataSources: [dataSourceID],
                  dataSourcesKeys: [sourceName],
                  isSubmarketGac,
                }
                if (yAxisObject.yAxisAutoShrink === true) {
                  if (typeof yAxisMinValue === 'undefined' || Math.min(...newSerie.data) < yAxisMinValue) {
                    yAxisMinValue = Math.min(...newSerie.data)
                  }
                  if (typeof yAxisMaxValue === 'undefined' || Math.max(...newSerie.data) > yAxisMaxValue) {
                    yAxisMaxValue = Math.max(...newSerie.data)
                  }
                }
                newYAxis.series.push(newSerie)
              }
            }
          })
          if (serie.seriesType === 'scatter' && serie.recalcXPositions) {
            recalcPositionSeriesIndex++
          }
        })
      })
    }

    showReferenceCurve &&
      Object.keys(clusterReference).forEach((cityType) => {
        yAxisObject.axisSources.forEach((dataSourceID, dataSourceIndex) => {
          // Patch needed: Reference Curve right now only available für ABCD city types
          const source = topicConfig.dataSources[dataSourceID].source['DE']
          const { multiplicator } = topicConfig.dataSources[dataSourceID]

          const firstLocationSeries = newYAxis.series.find((serie) =>
            serie.id.includes(`location${cityType}`)
          )

          const colorObj = colors.find((color) => {
            return (
              color.chartViewIndex === parseInt(chartView) && color.dataSourceID === parseInt(dataSourceID)
            )
          })

          const colorKeys = ['main', 'dark', 'bright']
          let color
          let dashStyle

          if (colorObj.lineIndex !== null) {
            color = bgagColors['bgag-orange'].main
            dashStyle = dashStyles[colorObj.lineIndex]
          } else {
            color = bgagColors['bgag-orange'][colorKeys[colorObj.allocatorIndex]]
            dashStyle = dashStyles[0]
          }

          const choosenSource = source.key

          const data = years.map(
            (year, index) =>
              shiftValue(getValue(cityType, year, choosenSource, clusterReference), multiplicator) || null
          )
          const showInLegend = getShowInLegend(data)

          const newSerie = {
            id: `chartView${chartView}-dataSource${dataSourceID}-location${cityType}`,
            yAxis: `yAxis${chartView}_${yAxisIndex}`,
            type: 'line',
            dashStyle,
            zIndex: dataSourceIndex + 10,
            linkedTo: firstLocationSeries ? firstLocationSeries.id : undefined,
            color,
            locationID: cityType,
            cityType,
            visible: true,
            data,
            dataSources: [dataSourceID],
            dataSourcesKeys: [choosenSource],
            showInLegend,
          }
          if (yAxisObject.yAxisAutoShrink === true) {
            if (typeof yAxisMinValue === 'undefined' || Math.min(...newSerie.data) < yAxisMinValue) {
              yAxisMinValue = Math.min(...newSerie.data)
            }
            if (typeof yAxisMaxValue === 'undefined' || Math.max(...newSerie.data) > yAxisMaxValue) {
              yAxisMaxValue = Math.max(...newSerie.data)
            }
          }
          newYAxis.series.push(newSerie)
        })
      })

    if (yAxisObject.yAxisAutoShrink === true) {
      if (yAxisMinValue || yAxisMaxValue) {
        const [axisBottom] = calcAxisBorders(yAxisMinValue, yAxisMaxValue)
        if (typeof axisBottom === 'number') {
          newYAxis.min = axisBottom
        }
        // newYAxis.max = axisTop
      }
    } else {
      newYAxis.maxPadding = 0.2
    }
    newChartData.push(newYAxis)
  })
  // return newChartData

  return { clusterChart: newChartData, clusterChartOfLocations: chartDataOfLocations }
}

const generateEmptyRowDataSources = (dataSourcesFiltered, countryCodes) => {
  if (countryCodes.length > 1) {
    console.warn('Patch neededed to support multiple countries in one Table with "locations inside"')
  }

  return dataSourcesFiltered.map((dataSource) => {
    const { multiplicator } = dataSource
    let titleSuffix = null
    if (multiplicator) {
      titleSuffix = multiplicator === 0.001 ? 'thousand' : multiplicator === 0.000001 ? 'million' : null
    }
    return {
      key: `dataSource${dataSource.sourceId}`,
      titleSuffix,
      locationID: null,
      dataSourceID: dataSource.sourceId,
      parentId: null,
      rowHeader: countryCodes
        .filter((countryCode) => typeof dataSource.source[countryCode] !== 'undefined')
        .map((countryCode) => dataSource.source[countryCode].key)
        .join('|'),
    }
  })
}

const generateEmptyRowLocations = (locations, locationData) => {
  return Object.entries(locations).map(([id, gac]) => {
    const customLocationColor = locationData?.[id].locationColor
    return {
      key: `location${id}`,
      locationColor:
        typeof customLocationColor !== 'undefined'
          ? customLocationColor === null
            ? null
            : colorCalculator(locationData?.[id].locationColor)
          : colorAllocator(id),
      locationID: id,
      dataSourceID: null,
      parentId: null,
      rowHeader: gac,
      location: gac,
    }
  })
}

const generateEmptyRowReferences = (references) =>
  references.map((cityType) => ({
    key: `location${cityType}`,
    color: bgagColors['bgag-orange'].main,
    locationID: cityType,
    dataSourceID: null,
    parentId: null,
    rowHeader: cityType,
  }))

export const getHint = (countryCode, gac, topicConfig, source) => {
  source = typeof source === 'string' ? source : source?.key || null

  if (!countryCode || !gac || !topicConfig) {
    return null
  }
  // if no specific datasource is given, try "global" settings
  const hint = source ? topicConfig?.hint?.[countryCode]?.[source] : topicConfig?.hint?.[countryCode]

  if (!source && !hint?.regexp && !hint?.sign && !hint?.text && !hint?.type) {
    return null
  }

  if (hint?.regexp && typeof hint.regexp === 'string') {
    const regexp = new RegExp(hint.regexp)
    if (!gac.match(regexp)) {
      return null
    }
  } else if (hint?.regexp && typeof hint.regexp === 'object') {
    const flags = hint.regexp.flags || 'g'
    const regexp = new RegExp(hint.regexp.pattern, flags)
    if (!gac.match(regexp)) {
      return null
    }
  }

  return hint || null
}

export const generateTableData = ({
  topicConfig,
  submarketEquivalents,
  locations,
  time,
  cluster,
  clusterReference,
  colors,
  chartView,
  axisSource,
  locationsInside,
  numbersFormats,
  locationData,
  isTreeView,
  hideCityNames,
  colorDiscAsOwnColumn = false,
}) => {
  const countryCodes = Array.from(new Set(Object.values(locations).map((gac) => getCountryCodeByGac(gac))))
  const dataSources = topicConfig.dataSources

  const includeSubmarketDataSources = Object.values(locations).reduce((bool, gac) => {
    if (!bool && typeof gac === 'string' && gac.length > 8) {
      bool = true
    }
    return bool
  }, false)

  const dataSourcesFiltered = dataSources
    .map((dataSource, index) => {
      dataSource.sourceId = index
      if (dataSource?.delta && dataSource?.delta?.deltaSrc) {
        dataSource.delta.srcs = dataSource.delta.deltaSrc.map((index) => dataSources[index]['source'])
      }
      return dataSource
    })
    .filter((dataSource) => {
      let isSubmarketDatasource = false
      countryCodes.forEach((countryCode) => {
        if (dataSource.source[countryCode]?.key?.includes('submarket')) {
          isSubmarketDatasource = true
        }
      })
      if (!includeSubmarketDataSources && isSubmarketDatasource) {
        return false
      }
      return !dataSource.tableHidden
    })

  let timeIdentifier = dataSources.xAxis || 'year'

  const [timeUnits, timeUnitTitles] = getTimeUnits(topicConfig, time)

  const dataSourcesWithLargerColumnWidth = [
    'data_de.st_tourism.hotel_overnight_stays',
    'data_de.st_tourism.hotel_arrivals',
    'data_de.st_tourism.overnight_stays_total',
    'data_de.st_tourism.arrivals_total',
    'data_de.fc_pop_district.total',
    'data_de.st_population.population_total',
    'data_de.md_residential_market.price_detached_house_max',
    'data_de.md_residential_market.price_detached_house_average',
  ]

  const timeColumnsMinWidth = topicConfig.dataSources.reduce((minWidth, { source }) => {
    if (source?.DE?.key && dataSourcesWithLargerColumnWidth.includes(source?.DE?.key)) {
      minWidth = 75
    }
    return minWidth
  }, 60)

  const timeColumns = timeUnits.map((timeUnit, idx) => ({
    key: `${timeIdentifier}-${timeUnit}`,
    dataKey: `${timeIdentifier}-${timeUnit}`,
    title: timeUnitTitles[idx],
    fixed: idx === timeUnits.length - 1 ? 'right' : idx === 0 ? true : false,
    align: 'center',
    flexGrow: 1,
    minWidth: timeColumnsMinWidth,
  }))

  const colorDiscColumn = {
    key: 'colorDisc',
    dataKey: 'colorDisc',
    title: '',
    fixed: true,
    minWidth: 18,
    flexGrow: 1,
  }

  const locationColumn = {
    key: `location`,
    dataKey: `location`,
    title: '',
    fixed: true,
    flexGrow: 3,
    minWidth: 240,
  }

  const dataSourceColumn = {
    key: `dataSource`,
    dataKey: `dataSource`,
    title: '',
    fixed: true,
    flexGrow: 3,
    minWidth: 240,
  }

  const columns = hideCityNames
    ? isTreeView
      ? [dataSourceColumn, []]
      : [dataSourceColumn]
    : [dataSourceColumn, locationColumn]

  if (!locationsInside) {
    columns.reverse()
  }
  if (colorDiscAsOwnColumn) {
    console.log(colorDiscAsOwnColumn)
    columns.unshift(colorDiscColumn)
  }

  columns.push(...timeColumns)

  // Add accumulation column  if any dataSource has flag
  if (dataSourcesFiltered.some((sourceObject) => sourceObject.accumulation))
    columns.push({
      key: `accumulation`,
      dataKey: `accumulation`,
      title: `5J-Entw.`,
      align: 'center',
      minWidth: 90,
      fixed: 'right',
      flexGrow: 1,
    })

  // Add accumulation column  if any dataSource has flag
  if (dataSourcesFiltered.some((sourceObject) => sourceObject.delta)) {
    let title = 'Δ'
    let minWidth = 90
    if (
      [
        'census_households',
        'census_residential_buildings',
        'census_sociodemographic',
        'census_units_residential_buildings',
      ].includes(topicConfig.key)
    ) {
      title = 'marketData:table.column.census_delta'
      minWidth = 165
    }

    columns.push({
      key: `delta`,
      dataKey: `delta`,
      title,
      align: 'center',
      minWidth,
      fixed: 'right',
      flexGrow: 1,
    })
  }

  // Generate empty rows for headline rows (e.g. "Nürnberg -")
  const emptyRows = locationsInside
    ? generateEmptyRowDataSources(dataSourcesFiltered, countryCodes)
    : hideCityNames
      ? generateEmptyRowReferences(Object.keys(clusterReference))
      : generateEmptyRowLocations(locations, locationData).concat(
          generateEmptyRowReferences(Object.keys(clusterReference))
        )

  let dataRows = dataSourcesFiltered.flatMap((dataSource) => {
    const { multiplicator, numbersFormat, accumulation, delta } = dataSource

    const formatValueNew = (val, hintSign) => {
      if (val === null) return null
      if (multiplicator) val *= multiplicator
      if (numbersFormat?.includes('percentage')) val = val / 100
      if (numbersFormat) val = numbersFormats[numbersFormat].format(val)
      if (hintSign) val = hintSign + ' ' + val
      return val
    }

    let titleSuffix = null
    if (multiplicator) {
      titleSuffix = multiplicator === 0.001 ? 'thousand' : multiplicator === 0.000001 ? 'million' : null
    }

    const calcAccumulationQuarter = (key, choosenSource, cluster) => {
      if (!accumulation.type || !accumulation.type) return null
      if (accumulation.type === 'average') {
        let values = timeUnits
          .slice(-(GLOBAL_ACCUMULATION_RANGE * 4))
          .map((year) => getValue(key, year, choosenSource, cluster))
        return values.length > 0 && 'Ø ' + formatValueNew(arraySum(values) / values.length)
      } else if (accumulation.type === 'delta') {
        const startKey = timeUnits[timeUnits.length - 1 - GLOBAL_ACCUMULATION_RANGE * 4]
        const endKey = timeUnits[timeUnits.length - 1]
        let startValue = getValue(key, startKey, choosenSource, cluster)
        let endValue = getValue(key, endKey, choosenSource, cluster)
        if (!startValue || !endValue) return null
        return 'Δ ' + numbersFormats['percentageFraction1'].format(endValue / startValue - 1)
      }
    }

    const calcAccumulation = (key, endYear, choosenSource, cluster) => {
      if (!accumulation.type || !accumulation.type) return null
      if (accumulation.type === 'average') {
        let values = timeUnits
          .slice(-GLOBAL_ACCUMULATION_RANGE)
          .map((year) => getValue(key, year, choosenSource, cluster))
        return values.length > 0 && 'Ø ' + formatValueNew(arraySum(values) / values.length)
      } else if (accumulation.type === 'delta') {
        let startValue = getValue(key, endYear - GLOBAL_ACCUMULATION_RANGE, choosenSource, cluster)
        let endValue = getValue(key, endYear, choosenSource, cluster)
        if (!startValue || !endValue) return null
        return 'Δ ' + numbersFormats['percentageFraction1'].format(endValue / startValue - 1)
      }
    }

    const calcDelta = (key, endYear, choosenSource, choosenSourcesDelta, cluster, numberFormat) => {
      if (!delta.type || !delta.type) return null
      let startValue = getValue(key, endYear, choosenSource, cluster)
      let deltaDiff = choosenSourcesDelta.reduce(
        (acc, curr) => acc + getValue(key, endYear, curr, cluster) || 0,
        0
      )
      // let endValue = getValue(key, endYear, cluster)
      if (!startValue || !deltaDiff) return null
      return 'Δ ' + numbersFormats[numberFormat || 'roundedFraction1'].format(startValue - deltaDiff)
    }

    return (
      Object.entries(locations)
        .map(([id, gac]) => {
          const countryCode = getCountryCodeByGac(gac)
          const source = dataSource.source[countryCode]

          if (source === null) {
            return null
          }
          let hint = getHint(countryCode, gac, topicConfig, source)
          // Check if gac is submarket because of length > 8 ...
          const isSubmarketGac = gac.length > 8
          const submarketEquivalentNeeded =
            typeof source?.key === 'string' ? !source.key.includes('.virtual.') : true

          if (
            submarketEquivalentNeeded &&
            isSubmarketGac &&
            !hasSubmarketEquivalents(
              dataSource.source[countryCode],
              dataSources,
              countryCode,
              submarketEquivalents
            )
          ) {
            // .... and avoid empty rows where dataSource hasnt a submarket flag
            return null
          } else {
            let choosenSource
            if (isSubmarketGac && typeof submarketEquivalents[source.key] !== 'undefined') {
              choosenSource = submarketEquivalents[source.key]
              hint = getHint(countryCode, gac, topicConfig, choosenSource)
            } else if (source.type === 'key' || source.type === 'submarketOnly') {
              choosenSource = source.key
              hint = getHint(countryCode, gac, topicConfig, choosenSource)
            }
            let choosenSourcesDelta
            if (delta && delta.srcs) {
              choosenSourcesDelta = delta.srcs.map((obj) => obj[countryCode].key)
            }
            let deltaNumberFormat
            if (delta && delta.numbersFormat) {
              deltaNumberFormat = delta.numbersFormat
            }

            const titleSource =
              isSubmarketGac && typeof submarketEquivalents[source.key] !== 'undefined'
                ? submarketEquivalents[source.key]
                : source.key

            const currentChartView = topicConfig?.chartViews?.[countryCode]?.[chartView]
            const useSourceSelector = currentChartView?.useAxisSourceSelector || false

            // allow multiple colors despite having the useSourceSelector set to true, due to the nature of pie charts having slices with multiple colors and therefore axisSource should be ignored
            const allowMultipleColors =
              currentChartView.chartType === 'dataSourceOfTime' &&
              currentChartView.yAxis.some((axis) => axis.seriesType === 'pie')

            let color = colors.find((color) => {
              return (
                color.chartViewIndex === parseInt(chartView) &&
                (!useSourceSelector || color.dataSourceID === axisSource || allowMultipleColors) &&
                color.locationID === parseInt(id) &&
                ((color.altDataSourceID === null && color.dataSourceID === parseInt(dataSource.sourceId)) ||
                  (color.altDataSourceID !== null && color.altDataSourceID === parseInt(dataSource.sourceId)))
              )
            })

            const opacity = color ? color.opacity : 1
            color = color ? color.color : 'initial'

            const customLocationColor = locationData?.[id]?.locationColor

            const colorDisc = color

            return timeUnits.reduce(
              (rowObject, timeUnit) => {
                let value = getValue(gac, timeUnit, choosenSource, cluster)
                rowObject[`${timeIdentifier}-${timeUnit}`] = formatValueNew(value, hint?.sign)

                return rowObject
              },
              {
                key: locationsInside
                  ? `dataSource${dataSource.sourceId}-location${id}`
                  : `location${id}-dataSource${dataSource.sourceId}`,
                locationID: id,
                locationColor:
                  isTreeView || customLocationColor === null
                    ? null
                    : typeof customLocationColor !== 'undefined'
                      ? colorCalculator(customLocationColor)
                      : colorAllocator(id),
                dataSourceID: dataSource.sourceId,
                parentId: locationsInside ? `dataSource${dataSource.sourceId}` : `location${id}`,
                location: gac,
                dataSource: titleSource,
                // rowHeader: locationsInside ? gac : titleSource,
                accumulation: accumulation
                  ? topicConfig.isQuarter
                    ? calcAccumulationQuarter(gac, choosenSource, cluster)
                    : calcAccumulation(gac, time.to, choosenSource, cluster)
                  : null,
                delta: delta
                  ? calcDelta(gac, time.to, choosenSource, choosenSourcesDelta, cluster, deltaNumberFormat)
                  : null,
                color,
                titleSuffix,
                opacity,
                colorDisc,
              }
            )
          }
        })
        .concat(
          Object.keys(clusterReference).map((cityType) => {
            const countryCode = 'DE'
            // Patch needed: Reference Curve right now only available für ABCD city types
            let hasColor = colors.find((color) => {
              return (
                color.chartViewIndex === parseInt(chartView) &&
                color.dataSourceID === parseInt(dataSource.sourceId)
              )
            })
            let color
            if (hasColor) {
              color =
                hasColor.allocatorIndex === 0
                  ? bgagColors['bgag-orange'].main
                  : bgagColors['bgag-orange'].dark
            } else {
              color = 'initial'
            }

            const source = dataSource.source[countryCode]
            const sourceKey = source.key

            let deltaNumberFormat
            if (delta && delta.numbersFormat) {
              deltaNumberFormat = delta.numbersFormat
            }
            let sourcesDelta
            if (delta && delta.srcs) {
              sourcesDelta = delta.srcs.map((obj) => obj[countryCode].key)
            }

            return timeUnits.reduce(
              (rowObject, timeUnit) => {
                let value = getValue(cityType, timeUnit, sourceKey, clusterReference)
                rowObject[`${timeIdentifier}-${timeUnit}`] = formatValueNew(value)
                return rowObject
              },
              {
                key: locationsInside
                  ? `dataSource${dataSource.sourceId}-location${cityType}`
                  : `location${cityType}-dataSource${dataSource.sourceId}`,
                locationID: cityType,
                dataSourceID: dataSource.sourceId,
                parentId: locationsInside ? `dataSource${dataSource.sourceId}` : `location${cityType}`,
                dataSource: source.key,
                location: cityType,
                locationColor: bgagColors['bgag-orange'].main,
                // rowHeader: locationsInside ? cityType : source.key,
                accumulation: accumulation
                  ? topicConfig.isQuarter
                    ? calcAccumulationQuarter(cityType, sourceKey, clusterReference)
                    : calcAccumulation(cityType, time.to, sourceKey, clusterReference)
                  : null,
                delta: delta
                  ? calcDelta(cityType, time.to, sourceKey, sourcesDelta, clusterReference, deltaNumberFormat)
                  : null,
                titleSuffix,
                color,
              }
            )
          })
        )
        // Filter completely empty row objects:
        .filter((rowObject) => {
          return rowObject !== null
        })
        // Filter row objects with only null values:
        .filter((rowObject) =>
          Object.entries(rowObject).some(
            ([key, value]) => timeUnits.some((unit) => `${timeIdentifier}-${unit}` === key) && value !== null
          )
        )
    )
  })

  const locationRowSpans = dataRows.reduce((obj, { locationID }) => {
    if (typeof obj[locationID] === 'undefined') {
      obj[locationID] = 0
    }
    obj[locationID]++
    return obj
  }, {})

  const dataSourceRowSpans = dataRows.reduce((obj, { dataSource }) => {
    if (typeof obj[dataSource] === 'undefined') {
      obj[dataSource] = 0
    }
    obj[dataSource]++
    return obj
  }, {})

  if (locationsInside === false && !hideCityNames) {
    Object.entries(locationRowSpans)
      .sort(([locationIdA], [locationIdB]) => {
        if (isNaN(locationIdA) || isNaN(locationIdB)) {
          return locationIdA.localeCompare(locationIdB)
        }
        return Number(locationIdA) - Number(locationIdB)
      })
      .forEach(([locationId, rowSpan], index) => {
        const evenGroup = (index + 1) % 2 === 0
        const filteredDataRows = dataRows.filter(({ locationID }) => locationID === locationId)
        filteredDataRows[0].rowSpan = rowSpan
        // needed to get the rowspan of the first column in any other column
        filteredDataRows.forEach((dataRow) => {
          dataRow.groupedRowSpan = rowSpan
          dataRow.evenGroup = evenGroup
        })
      })

    dataRows.sort((a, b) => {
      if (isNaN(a.locationID) || isNaN(b.locationID)) {
        return a.locationID.localeCompare(b.locationID)
      }
      return Number(a.locationID) - Number(b.locationID)
    })
  } else {
    Object.entries(dataSourceRowSpans).forEach(([dataSource, rowSpan], index) => {
      const evenGroup = (index + 1) % 2 === 0
      const filteredDataRows = dataRows.filter(({ dataSource: dataSourceB }) => dataSource === dataSourceB)
      filteredDataRows[0].rowSpan = rowSpan
      // needed to get the rowspan of the first column in any other column
      filteredDataRows.forEach((dataRow) => {
        dataRow.groupedRowSpan = rowSpan
        dataRow.evenGroup = evenGroup
      })
    })
  }

  return {
    columns: columns,
    data: isTreeView
      ? hideCityNames
        ? [...dataRows]
        : unflattenTree([...emptyRows, ...dataRows])
      : [...dataRows],
    defaultExpanded: emptyRows.map((row) => row.key),
  }
}

const unflattenTree = (array, dataKey = 'key', parentKey = 'parentId') => {
  let tree = []
  let childrenMap = {}
  let parentMap = {}

  for (let i = 0; i < array.length; i++) {
    const item = { ...array[i] }
    const id = item[dataKey]
    const parentId = item[parentKey]

    parentMap[id] = item
    if (!childrenMap[id]) childrenMap[id] = []
    if (childrenMap[id].length > 0) item.children = childrenMap[id]

    if (parentId) {
      if (!childrenMap[parentId]) childrenMap[parentId] = []
      childrenMap[parentId].push(item)
      if (typeof parentMap[parentId] !== 'undefined' && typeof parentMap[parentId].children === 'undefined') {
        parentMap[parentId].children = childrenMap[parentId]
      }
    } else {
      tree.push(item)
    }
  }

  return tree
}
