import React from 'react'
import { styled } from 'styled-components'
import { typography } from 'styled-system'

import { IconWrapper } from '@utilities/IconWrapper'
import { FlexContainer } from '@layout/BuildingBlocks'

import { appearance, shape, size } from './variants'
import { checkboxAppearance } from 'theming/systemHelper/appearance-checkbox'
import { typeMap, typeScale, typeStyle } from '@typography'

import { bool, string, func, oneOfType } from 'prop-types'

const fontSizeDefault = [0, 1, 2, 3, 4]
const emptyFunc = () => {}

export const Checkbox = styled.div`
  display: flex;
`

const Badge = styled(FlexContainer)`
  cursor: pointer;

  ${(props) => props.disabled && { cursor: 'not-allowed' }};
  ${(props) => props.disabled && { '> div': { pointerEvents: 'none' } }};

  ${typography};
  ${(props) => typeScale(props.typeScale)};
  ${(props) => typeStyle(props.typeStyle)};

  ${appearance};
  ${shape};
  ${size};
  ${checkboxAppearance};
  border-top-right-radius: ${({ firstItem, groupedView }) => firstItem && groupedView && '0px'};
  border-right-style: ${({ groupedView, lastItem }) => (!lastItem && groupedView ? 'none' : 'solid')};
  border-bottom-right-radius: ${({ firstItem, groupedView }) => firstItem && groupedView && '0px'};
  border-top-left-radius: ${({ lastItem, groupedView }) => lastItem && groupedView && '0px'};
  border-bottom-left-radius: ${({ lastItem, groupedView }) => lastItem && groupedView && '0px'};
  border-radius: ${({ firstItem, lastItem, groupedView }) =>
    groupedView && firstItem === false && lastItem === false && '0px'};
`

export const CheckboxIconBadge = ({
  icon,
  iconPosition,
  iconWrapper,
  iconVariantSourceName,
  iconVariantSize,
  groupedView,
  firstItem,
  lastItem,
  fontFamily = 'whitneySSm',
  fontSize = fontSizeDefault,
  lineHeight = 'normal',
  appearance = 'lightgray',
  checked = false,
  disabled = false,
  handleMouseMove = emptyFunc,
  handleMouseLeave = emptyFunc,
  handleMouseOver = emptyFunc,
  id = 'checkbox-id',
  label = 'checkbox label',
  labelGap = null,
  name = 'checkbox-name',
  shape = 'square',
  size = 'medium',
  tag = 'div',
  ...props
}) => {
  props = { ...props, fontFamily, fontSize, lineHeight, tag }

  const { typeStyle, typeScale } = typeMap[size]

  return (
    <Badge
      appearance={appearance}
      checked={checked}
      disabled={disabled}
      onMouseMove={handleMouseMove}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      shape={shape}
      size={size}
      typeScale={typeScale}
      typeStyle={typeStyle}
      groupedView={groupedView}
      firstItem={firstItem}
      lastItem={lastItem}
      {...props}
    >
      <Checkbox name={name} id={id} />
      <FlexContainer alignItems="center" flexColumnGap={labelGap}>
        {icon && (
          <IconWrapper
            icon={icon}
            iconPosition={iconPosition}
            iconWrapper={iconWrapper}
            iconVariantSourceName={iconVariantSourceName}
            iconVariantSize={iconVariantSize}
          />
        )}

        {label}
      </FlexContainer>
    </Badge>
  )
}
CheckboxIconBadge.propTypes = {
  appearance: string,
  checked: bool,
  disabled: bool,
  handleMouseMove: func,
  handleMouseLeave: func,
  handleMouseOver: func,
  id: string,
  label: oneOfType([bool, string]),
  labelGap: string,
  name: string,
  shape: string,
  size: string,
  tag: string,
}
