import React from 'react'
import { styled, useTheme } from 'styled-components'
import { space } from 'styled-system'

import { GreatPrimer } from '@typography'
import { getThemeValue } from 'theming'

export const StyledText = styled(GreatPrimer)`
  ${space}
`

export const StyledComponent = styled.div`
  ${space}
`

export const ModalSubHeadline = ({ children, ...props }) => {
  const theme = useTheme()
  const spaceX = getThemeValue(theme, 'modal.body.space.x')
  const spaceBottom = getThemeValue(theme, 'modal.subHeadline.space.bottom')

  return typeof children == 'string' ? (
    <StyledText tag="h4" {...props} px={spaceX} mt="0" mb={spaceBottom}>
      {children}
    </StyledText>
  ) : (
    <StyledComponent {...props} px={spaceX} mt="0" mb={spaceBottom}>
      {children}
    </StyledComponent>
  )
}

ModalSubHeadline.displayName = 'modalSubHeadline'
