import React, { useState, useRef, forwardRef, useEffect, useCallback, useMemo } from 'react'

import { useDrag, useDrop, useDragDropManager, useDragLayer, DndProvider } from 'react-dnd'
import { HTML5Backend, getEmptyImage } from 'react-dnd-html5-backend'

import { Table, enableOverlay } from '.'

import { zIndices } from 'config/zIndices'

let reorderInProcess = false
let isScrolling = false
let scrollStart = null
let timer = null

const scrollInterval = 50
const scrollMinRows = 0.5
const maxPagesPerSecond = 3
const accelerate = (x) => (x < 1 ? 1 : 1 + Math.pow(0.75 * (x - 1), 2))

const stopScroll = () => {
  isScrolling = false
  if (timer !== null) {
    clearTimeout(timer)
    timer = null
  }
}
const setTableScroll = (scrollTo, method) => {
  // without timeout drag and drop would not start, if the row to be dragged is in a scrollTrigger-area
  setTimeout(() => method(scrollTo), 0)
}
const scroll = (direction, rowHeight, table) => {
  if (!isScrolling) {
    isScrolling = true
    scrollStart = Date.now()
  }

  const currentScrollTop = table.scrollPosition.top
  const tableContainerHeight = table.body.parentNode.clientHeight
  const tableScrollHeight = table.body.clientHeight

  const scrollMaxRows = (maxPagesPerSecond * (tableContainerHeight / rowHeight)) / (1000 / scrollInterval)
  const seconds = (Date.now() - scrollStart) / 1000
  const scrollRows = accelerate(seconds) * scrollMinRows

  const scrollTo =
    currentScrollTop +
    Math.floor(direction * rowHeight * (scrollRows > scrollMaxRows ? scrollMaxRows : scrollRows))

  if (scrollTo <= 0) {
    setTableScroll(0, table.scrollTop)
    stopScroll()
  } else if (scrollTo >= tableScrollHeight - tableContainerHeight) {
    setTimeout(() => table.scrollTop(), 0)
    setTableScroll(tableScrollHeight - tableContainerHeight, table.scrollTop)
    stopScroll()
  } else {
    setTableScroll(scrollTo, table.scrollTop)
    timer = setTimeout(() => {
      scroll(direction, rowHeight, table)
    }, scrollInterval)
  }
}
const inArea = (area, cursor) =>
  cursor.y > area.top && cursor.y < area.bottom && cursor.x < area.right && cursor.x > area.left

const useScrollWhileDragging = ({ tableRef, tableProps, isDragging, dndMonitor }) => {
  useEffect(() => {
    if (!isDragging) {
      stopScroll()
    }
  }, [isDragging])

  useEffect(() => {
    const unsubscribe = dndMonitor.subscribeToOffsetChange(() => {
      const cursor = dndMonitor?.getClientOffset?.() ?? null
      const tableRect = tableRef.current?.body?.parentNode?.getBoundingClientRect?.() ?? null
      const rowHeight = tableProps.current?.rowHeight ?? 30

      if (!cursor || !tableRect) {
        return
      }

      const scrollTriggerTop = {
        top: -Infinity,
        right: tableRect.right,
        bottom: tableRect.top + rowHeight,
        left: tableRect.left,
      }
      const scrollTriggerBottom = {
        top: tableRect.bottom - rowHeight,
        right: tableRect.right,
        bottom: Infinity,
        left: tableRect.left,
      }

      const scrollUp = inArea(scrollTriggerTop, cursor)
      const scrollDown = !scrollUp && inArea(scrollTriggerBottom, cursor)

      if (isScrolling) {
        if (!scrollUp && !scrollDown) {
          stopScroll()
        }
        return
      }
      if (scrollUp || scrollDown) {
        scroll(scrollUp ? -1 : 1, rowHeight, tableRef.current)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [dndMonitor, tableRef, tableProps])
}

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: zIndices.dndPreviewLayer,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

function getItemStyles(initialOffset = null, currentOffset = null) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    }
  }

  let { x, y } = currentOffset

  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform,
  }
}

const CustomDragLayer = () => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  const dragItem = useRef(null)

  useEffect(() => {
    if (itemType === 'row') {
      const row = item.dom.cloneNode(true)
      row.style.opacity = 1
      dragItem.current.innerHTML = ''
      dragItem.current.appendChild(row)
    }
  }, [itemType, item])

  if (!isDragging) {
    return null
  }
  return (
    <div style={layerStyles}>
      <div ref={dragItem} style={getItemStyles(initialOffset, currentOffset)} />
    </div>
  )
}

const DndRow = ({ onDrop, onHover, rowData, rowKey, keyIndices, children, ...rest }) => {
  const ref = useRef(null)

  const [{ handlerId }, drop] = useDrop({
    accept: 'row',
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    drop: (item, monitor) => {
      onDrop()
    },
    hover: (item, monitor) => {
      if (!ref.current || reorderInProcess) {
        return false
      }
      const dragKey = item.key
      const hoverKey = rowData[rowKey]
      const dragIndex = keyIndices.current[dragKey]
      const hoverIndex = keyIndices.current[hoverKey]
      const renderedHoverIndex = rowData.__index

      // don't replace items with themselves, and dont replace items if it is still not done with replacing the previous (then ref keyIndices differs from rowData!)
      if (dragIndex === hoverIndex || hoverIndex !== renderedHoverIndex) {
        return false
      }

      // don't replace if mouse is not over a certain threshold in hovered row
      const { y: mouseY } = monitor.getClientOffset()
      const { top: curRowTop, bottom: curRowBottom } = ref.current?.getBoundingClientRect() ?? {
        curRowTop: -Infinity,
        curRowBottom: Infinity,
      }
      const threshold = (curRowBottom - curRowTop) / 2

      if (
        (mouseY < curRowTop && mouseY > curRowTop - threshold) ||
        (mouseY > curRowBottom && mouseY < curRowBottom + threshold)
      ) {
        return false
      }

      reorderInProcess = true

      onHover(dragIndex, hoverIndex)
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    item: { key: rowData[rowKey], dom: ref.current },
    type: 'row',
    isDragging: (monitor) => {
      return rowData[rowKey] === monitor.getItem().key
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  const styles = {
    cursor: 'grab',
    opacity: isDragging ? 0 : 1,
    width: '100%',
    height: '100%',
  }

  return (
    <div ref={ref} style={styles} data-handler-id={handlerId} {...rest}>
      {children}
    </div>
  )
}

const WrappedTableDnd = forwardRef(({ rowKey = null, data, onDrop, ...rest }, ref) => {
  let propMissing = false
  if (
    rowKey === null ||
    (data.length && data.some((item) => typeof item[rowKey] === 'undefined' || item[rowKey] === null))
  ) {
    console.warn('TableDnd needs valid rowKey definition!')
    propMissing = true
  }
  if (typeof onDrop !== 'function') {
    console.warn('TableDnd needs onDrop handler function!')
    propMissing = true
  }

  const [isDragging, setIsDragging] = useState(false)

  const tableProps = useRef(null)

  // keyIndices will be used to avoid finding of indices by key
  const keyIndices = useRef({})
  const keyIndicesSource = useRef({})
  const [localOrder, setLocalOrder] = useState([])

  const dragDropManager = useDragDropManager()
  const dndMonitor = useMemo(() => dragDropManager.getMonitor(), [dragDropManager])

  useScrollWhileDragging({ tableRef: ref, tableProps, isDragging, dndMonitor })

  useEffect(() => {
    let prevIsDragging = null
    const unsubscribe = dndMonitor.subscribeToStateChange(() => {
      const liveIsDragging = dndMonitor.isDragging()
      if (prevIsDragging === liveIsDragging) {
        return
      }
      if (liveIsDragging) {
        prevIsDragging = true
        enableOverlay(false)
        setIsDragging(true)
      } else {
        prevIsDragging = false
        enableOverlay(true)
        setIsDragging(false)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [dndMonitor])

  useEffect(() => {
    if (!isDragging) {
      setLocalOrder(
        data.map((item, index) => {
          keyIndicesSource.current[item[rowKey]] = index
          return item
        })
      )
    }
  }, [data, rowKey, isDragging])

  const handleDrop = useCallback(() => {
    const reorder = localOrder.map((item) => {
      return keyIndicesSource.current[item[rowKey]]
    })
    onDrop(reorder)
  }, [onDrop, localOrder, rowKey])

  const handleHover = useCallback((indexSource, indexTarget) => {
    setLocalOrder((prev) => {
      if (indexTarget === indexSource) {
        return prev
      }
      return prev.toSpliced(indexSource, 1).toSpliced(indexTarget, 0, prev[indexSource])
    })
  }, [])

  useEffect(() => {
    reorderInProcess = false
  }, [localOrder])

  const localData = useMemo(() => {
    if (propMissing) {
      return []
    } else if (isDragging) {
      return localOrder.map((item, __index) => {
        keyIndices.current[item[rowKey]] = __index
        return { __index, ...item }
      })
    } else {
      return data
    }
  }, [propMissing, localOrder, data, rowKey, isDragging])

  const localRenderRow = useCallback(
    (children, rowData) => {
      return rowData ? (
        <DndRow
          id={rowData[rowKey]}
          key={rowData[rowKey]}
          rowKey={rowKey}
          rowData={rowData}
          onDrop={handleDrop}
          onHover={handleHover}
          keyIndices={keyIndices}
        >
          {children}
        </DndRow>
      ) : (
        children
      )
    },
    [handleDrop, handleHover, rowKey]
  )

  return (
    <>
      <Table
        className={isDragging ? 'dragging' : ''}
        renderRow={localRenderRow}
        data={localData}
        dataIsIndexed={isDragging}
        propRef={tableProps}
        ref={ref}
        {...rest}
      />
      <CustomDragLayer />
    </>
  )
})

const withDnd = (Component) => (props, ref) => (
  <DndProvider backend={HTML5Backend} context={window}>
    {/*
        context window needed to avoid random error "Cannot have two HTML5 backends at the same time."
        see https://github.com/react-dnd/react-dnd/issues/3257
      */}
    <Component {...props} ref={ref} />
  </DndProvider>
)

export const TableDnd = forwardRef(withDnd(WrappedTableDnd))
