export const typeStyles = {
  minionLight: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 300,
  },
  minion: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  minionMedium: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  picaLight: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 300,
  },
  pica: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  picaItalic: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'italic',
    fontWeight: 400,
  },
  picaMedium: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  picaSemiBold: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  picaBlack: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 800,
  },
  copy: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  copyMedium: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  copyMediumItalic: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'italic',
    fontWeight: 500,
  },
  copySemiBold: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  copyBold: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 700,
  },
  copyBlack: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 800,
  },
  copyAbstract: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: [1.45, null, null, 1.3825],
  },
  greatPrimer: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  greatPrimerMedium: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  greatPrimerSemiBold: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  greatPrimerAbstract: {
    fontFamily: 'Whitney SSm A, Whitney SSm B',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: [1.48, null, null, 1.44],
  },
  canon: {
    fontFamily: 'Whitney A, Whitney B',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  canonMedium: {
    fontFamily: 'Whitney A, Whitney B',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  canonSemiBold: {
    fontFamily: 'Whitney A, Whitney B',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  canonBlack: {
    fontFamily: 'Whitney A, Whitney B',
    fontStyle: 'normal',
    fontWeight: 800,
  },
  royal: {
    fontFamily: 'Whitney A, Whitney B',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  royalMedium: {
    fontFamily: 'Whitney A, Whitney B',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  royalSemiBold: {
    fontFamily: 'Whitney A, Whitney B',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  royalBold: {
    fontFamily: 'Whitney A, Whitney B',
    fontStyle: 'normal',
    fontWeight: 700,
  },
  imperial: {
    fontFamily: 'Whitney A, Whitney B',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  imperialMedium: {
    fontFamily: 'Whitney A, Whitney B',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  imperialSemiBold: {
    fontFamily: 'Whitney A, Whitney B',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  imperialBold: {
    fontFamily: 'Whitney A, Whitney B',
    fontStyle: 'normal',
    fontWeight: 700,
  },
}
