import { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

/**
 * Override rsuite-tables classes. Will be injected in GlobalStyle.js.
 * Take care when updating library!
 */

const rsTableStylesheetOverrides = css`
  .rs-table {
    border: 0;
  }

  /**
   * Flex-Positionierung für Zelleninhalt
   **/
  .rs-table-cell-content {
    display: flex;
    align-items: center;
    padding: 0px 8px;
    flex-wrap: nowrap !important;
  }

  .rs-table-cell-first > div {
    padding-left: 16px;
  }

  /** header cells wrap */
  .rs-table-cell-header .rs-table-cell-content {
    white-space: normal;
  }

  /** first cell left indent (should align with card header headline indent) */
  .rs-table-cell.rs-table-cell-first .rs-table-cell-content {
    padding-left: ${(props) => themeGet('spaces.rhythm.horizontal.large', 'lightgray')(props)} !important;
  }
  /** last cell right indent (should align with card header headline indent) */
  .rs-table-cell.rs-table-cell-last .rs-table-cell-content {
    padding-right: ${(props) => themeGet('spaces.rhythm.horizontal.large', 'lightgray')(props)} !important;
  }

  /** border last cell in row */
  .rs-table-row .rs-table-cell:last-of-type {
    border-right: none;
  }

  /**
   * ----------------------------
   * Dropdown Input Suggestions |
   * ----------------------------
   */

  .rs-table-dropdown,
  .rs-table-taglist {
    /* scrollbar */
    .rs-table-scrollbar {
      &.rs-table-scrollbar-vertical,
      &.rs-table-scrollbar-vertical:hover {
        background: ${(props) => themeGet('scrollbar.small.track.bg', 'lightgray')(props)};
        border-radius: ${(props) => themeGet('scrollbar.small.track.radius', '0px')(props)};
        box-shadow: none;
        right: 5px;
        width: ${(props) => themeGet('scrollbar.small.track.width', '5px')(props)};

        .rs-table-scrollbar-handle {
          border-radius: ${(props) => themeGet('scrollbar.small.thumb.radius', '0px')(props)};
          background: ${(props) => themeGet('scrollbar.small.thumb.bg', 'lightgray')(props)};
          left: 0;
          width: ${(props) => themeGet('scrollbar.small.thumb.width', '5px')(props)};
        }
      }
    }

    .rs-table-row {
      &.dropdown-option {
        &.dropdown-option-default,
        &.dropdown-option-selected,
        &.dropdown-option-disabled,
        &.dropdown-option-cursor {
          .rs-table-cell {
            background: ${(props) => themeGet('dropdown.bg', '#fff')(props)};
          }
        }
      }
    }

    .rs-table-row.dropdown-option .rs-table-cell-first > div {
      padding-left: 0px !important;
    }

    .rs-table-cell.rs-table-cell-first.rs-table-cell-last {
      background: transparent;
    }

    .rs-table-cell-group.rs-table-cell-group-scroll {
      background: ${(props) => themeGet('dropdown.option.bg', '#fff')(props)};
    }

    .rs-table-row {
      &.dropdown-option {
        background: ${(props) => themeGet('dropdown.option.bg', '#fff')(props)};

        .rs-table-cell-content > span {
          margin: ${(props) => themeGet('dropdown.spaceX.rsTable.hasOverflow', 0)(props)};
          padding: ${(props) => themeGet('dropdown.option.py', 0)(props)}
            ${(props) => themeGet('dropdown.option.px', 0)(props)};
          width: 100%;
        }

        &:not(.dropdown-option-disabled):not(.dropdown-option-selected) {
          .rs-table-cell-content > span:hover {
            color: ${(props) => themeGet('dropdown.option.hover.color', 'gray')(props)};
            background: ${(props) => themeGet('dropdown.option.hover.bg', 'gray')(props)};
            border-radius: ${(props) => themeGet('dropdown.option.radius', '2px')(props)};
          }
        }

        &.dropdown-option-cursor {
          .rs-table-cell-content > span {
            color: ${(props) => themeGet('dropdown.option.hover.color', 'gray')(props)};
            background: ${(props) => themeGet('dropdown.option.hover.bg', 'gray')(props)};
            border-radius: ${(props) => themeGet('dropdown.option.radius', '2px')(props)};
          }
        }

        &.dropdown-option-disabled {
          .rs-table-cell-content > span {
            color: ${(props) => themeGet('dropdown.option.disabled.color', 'gray')(props)};
            background: ${(props) => themeGet('dropdown.option.disabled.bg', 'gray')(props)};
          }
        }

        &.dropdown-option-selected {
          .rs-table-cell-content > span {
            color: ${(props) => themeGet('dropdown.option.selected.color', 'gray')(props)};
            background: ${(props) => themeGet('dropdown.option.selected.bg', 'gray')(props)};
            font-weight: ${(props) => themeGet('dropdown.option.selected.fontWeight', 'gray')(props)};
            border-radius: ${(props) => themeGet('dropdown.option.radius', '2px')(props)};
          }
        }
      }
    }

    .rs-table-cell {
      border-bottom: 0px;
    }
  }

  /**
   * --------------------------------
   * Dropdown Input Suggestions END |
   * --------------------------------
   */

  .rs-table-taglist {
    .rs-table-row {
      .rs-table-cell-content > span {
        padding-left: 16px;
        padding-right: 16px;
        margin-left: 16px;
        margin-right: 12px;
      }
    }
  }

  /**
   * Table Objects
   */

  .rs-table-objects {
    .rs-table-row {
      &:hover {
        .rs-table-cell-content {
          span {
            text-decoration: underline;
          }
        }
        .rs-table-cell-location-pin {
          svg {
            fill: ${(props) => themeGet('colors.base.brand.primary.lighten', 'black')(props)};
          }
        }
      }
    }
  }

  /**
   *  data cells background
   */
  .rs-table:not(.rs-table-dropdown):not(.rs-table-taglist),
  .rs-table-hover.dragging {
    .rs-table-body-row-wrapper {
      .rs-table-row.odd {
        background: ${(props) => themeGet('table.data.odd.bg', '#fff')(props)};
        color: ${(props) => themeGet('table.data.odd.color', '#fff')(props)};
        .rs-table-cell {
          background: ${(props) => themeGet('table.data.odd.bg', '#fff')(props)};
          color: ${(props) => themeGet('table.data.odd.color', '#fff')(props)};
          border-bottom: 0px;
        }
      }

      .rs-table-row.even {
        background: ${(props) => themeGet('table.data.even.bg', '#fff')(props)};
        color: ${(props) => themeGet('table.data.even.color', '#fff')(props)};
        .rs-table-cell {
          background: ${(props) => themeGet('table.data.even.bg', '#fff')(props)};
          color: ${(props) => themeGet('table.data.even.color', '#fff')(props)};
          border-bottom: 0px;
        }
      }

      .rs-table-row.parent.odd {
        .rs-table-cell {
          background: ${(props) => themeGet('table.data.oddParent.bg', '#fff')(props)};
          background: hsl(0, 0%, 100%);
          color: ${(props) => themeGet('table.data.odd.color', '#fff')(props)};
          border-right: 0;
        }
      }

      .rs-table-row.parent.even {
        .rs-table-cell {
          background: ${(props) => themeGet('table.data.evenParent.bg', '#fff')(props)};
          color: ${(props) => themeGet('table.data.odd.color', '#fff')(props)};
          border-right: 0;
        }
      }
    }
  }

  /**
    * header cells background
    **/

  .rs-table-row-header .rs-table-cell {
    background: ${(props) => themeGet('table.header.bg', '#fff')(props)};
  }

  /**
    *  hover
    **/
  .rs-table-hover:not(.dragging):not(.rs-table-dropdown):not(.rs-table-taglist) {
    .rs-table-body-row-wrapper .rs-table-row:hover,
    .rs-table-body-row-wrapper .rs-table-row:not(.rs-table-row-header):hover .rs-table-cell {
      background: ${(props) => themeGet('table.data.hover.bg', '#fff')(props)};
      color: ${(props) => themeGet('table.data.hover.color', '#000')(props)};
    }
  }
  /*
  .rs-table-hover.dragging {
    .rs-table-body-row-wrapper .rs-table-row:not(.rs-table-row-header):hover .rs-table-cell {
    }
  }
*/

  /**
   * header column group cells background
   **/

  .rs-table-column-group .rs-table-cell-header .rs-table-cell-content span {
    position: relative;
    top: -4px;
  }
  .rs-table-column-group .rs-table-column-group-cell-content span {
    width: 100%;
  }

  /**
   * Border Behavior
   **/
  .rs-table {
    border: 0;
  }

  .rs-table-row {
    border: 0;
  }

  .rs-table-bordered {
    .rs-table-cell {
      border-bottom: 1px solid ${(props) => themeGet('colors.theme.table.border.color', '#000')(props)};
      border-right: 1px solid ${(props) => themeGet('colors.theme.table.border.color', '#000')(props)};
    }
  }

  /**
   * ColumnGroup
   **/
  .rs-table-column-group-header {
    border: none;
  }

  .rs-table-column-group-header-content {
    padding-top: 16px;
  }

  /** Override default background colors */
  .rs-table-cell-group-fixed-left,
  .rs-table-cell-group-fixed-right {
    // background: ${(props) => props.theme.bodyBackground};
  }

  /** Override standard bottom border of rs-table-row in case of tree for top level rows */
  .rs-table-row[depth='0'] {
    border-bottom: 1px solid #ddd;
  }

  /** Show scrollbar only on table hover */
  .rs-table-scrollbar {
    opacity: 0;
    transition: opacity ease-out 0.5s;
  }
  .rs-table:hover .rs-table-scrollbar {
    opacity: 1;
  }

  /* .rs-table .rs-table-row:not(.rs-table-row-rowspan):hover :not(.rs-table-cell-group) .rs-table-cell-content,
  .rs-table .rs-table-row:not(.rs-table-row-header):hover .rs-table-cell-content {
    filter: brightness(0.96);
  }

  .rs-table-cell-first.rs-table-cell-rowspan .rs-table-cell-content,
  .rs-table-cell-group-fixed-left .rs-table-cell-rowspan .rs-table-cell-content {
    filter: unset !important;
  } */

  .rs-table-cell-rowspan {
    // pointer-events: none;
  }

  /** Show Cursor as pointer */
  .rs-table.pointer .rs-table-row:hover .rs-table-cell,
  .rs-table.pointer .rs-table-row:hover .rs-table-cell-group,
  .rs-table.pointer .rs-table-row:not(.rs-table-row-header):hover {
    cursor: pointer;
  }

  /** Expand icon and expanded cells */

  .rs-table-treetable.cell-expand-wrapper-start {
    .rs-table-cell-expand-wrapper {
      order: 0;
    }
  }

  .rs-table-treetable.cell-expand-wrapper-end {
    .rs-table-cell-expand-wrapper {
      order: 100;
    }
  }

  .rs-table-cell-expand-wrapper {
    order: 0;
  }

  .rs-table-cell-expand-icon.rs-icon {
    height: 14px;
    width: 14px;
  }

  /** treetable first cell indent (tree icon should align with card header headline indent) */
  .rs-table-treetable
    .rs-table-body-row-wrapper
    .rs-table-row[data-depth='0']
    .rs-table-cell-group
    .rs-table-cell-first
    .rs-table-cell-content {
    padding-left: ${(props) => themeGet('spaces.rhythm.horizontal.large', 'lightgray')(props)} !important;
  }

  .rs-table-treetable
    .rs-table-row[data-depth='1']
    .rs-table-cell.rs-table-cell-first
    .rs-table-cell-content {
    padding-left: ${(props) => themeGet('spaces.12', 'lightgray')(props)} !important;
  }

  /** fix rowspan fixed */
  .rs-table-row.rs-table-row-rowspan {
    z-index: 9999;
  }

  /**
   * -----------------------
   * Input Suggestions     |
   * -----------------------
   */

  .rs-table-input {
    border-radius: 4px;

    /* scrollbar */
    .rs-table-scrollbar {
      &.rs-table-scrollbar-vertical,
      &.rs-table-scrollbar-vertical:hover {
        background: ${(props) => themeGet('scrollbar.small.track.bg', 'lightgray')(props)};
        border-radius: ${(props) => themeGet('scrollbar.small.track.radius', '0px')(props)};
        box-shadow: none;
        right: 5px;
        width: ${(props) => themeGet('scrollbar.small.track.width', '5px')(props)};

        .rs-table-scrollbar-handle {
          border-radius: ${(props) => themeGet('scrollbar.small.thumb.radius', '0px')(props)};
          background: ${(props) => themeGet('scrollbar.small.thumb.bg', 'lightgray')(props)};
          left: 0;
          width: ${(props) => themeGet('scrollbar.small.thumb.width', '5px')(props)};
        }
      }
    }

    .rs-table-row {
      &.dropdown-option {
        &.dropdown-option-default,
        &.dropdown-option-selected,
        &.dropdown-option-disabled,
        &.dropdown-option-cursor {
          .rs-table-cell {
            background: ${(props) => themeGet('dropdown.bg', '#fff')(props)};
          }
        }
      }
    }

    // Row STATES
    .rs-table-row {
      // STATE: Hover
      &:hover {
        .rs-table-cell-content {
          background: ${(props) => themeGet('dropdown.option.hover.bg', 'gray')(props)};
        }
      }
      &:not(.dropdown-option-disabled):not(.dropdown-option-selected) {
        // overwrite span hover from dropdown suggestions
        .rs-table-cell-content > span:hover {
          background: none;
        }
        .rs-table-cell-content {
          color: ${(props) => themeGet('dropdown.option.hover.color', 'gray')(props)};
          border-radius: unset;
        }
      }

      // STATE: Selected
      &.dropdown-option-selected {
        .rs-table-cell-content {
          background: ${(props) => themeGet('dropdown.option.selected.bg', 'gray')(props)};
          font-weight: ${(props) => themeGet('dropdown.option.selected.fontWeight', 'gray')(props)};
          color: ${(props) => themeGet('dropdown.option.selected.color', 'gray')(props)};
        }
        .rs-table-cell-content > span {
          background: ${(props) => themeGet('dropdown.option.selected.bg', 'gray')(props)};
          border-radius: unset;
        }
      }

      // STATE: Cursor position
      &.dropdown-option-cursor {
        .rs-table-cell-content {
          font-weight: ${(props) => themeGet('dropdown.option.selected.fontWeight', 'gray')(props)};
        }
        .rs-table-cell-content > span {
          background: none;
          border-radius: unset;
        }
      }
    }

    .rs-table-body-row-wrapper {
      .rs-table-row.odd {
        .rs-table-cell {
          background: ${(props) => themeGet('table.data.odd.bg', '#fff')(props)};
          color: ${(props) => themeGet('table.data.odd.color', '#fff')(props)};
          border-bottom: 0px;
        }
      }

      .rs-table-row.even {
        .rs-table-cell {
          background: ${(props) => themeGet('table.data.even.bg', '#fff')(props)};
          border-bottom: 0px;
          color: ${(props) => themeGet('table.data.even.color', '#fff')(props)};
        }
      }
    }
  }

  /**
   * -----------------------
   * Input Suggestions End |
   * -----------------------
   */

  /**
   * --------------------------------
   * Cursor config                   |
   * --------------------------------
   */

  .rs-table-dropdown,
  .rs-table-taglist,
  .rs-table-objects,
  .rs-table-input {
    .rs-table-row {
      &:not(.dropdown-option-disabled) {
        cursor: pointer;
      }

      &.dropdown-option-disabled {
        cursor: not-allowed;
      }
    }
  }

  /**
   * --------------------------------
   * Cursor config END               |
   * --------------------------------
   */
`

export default rsTableStylesheetOverrides
