import React from 'react'

import { PosRelative, FlexContainer } from '@layout/BuildingBlocks'

import { children } from './defaultPropTypes'

export const HeaderGlobalControls = ({ children = null }) => (
  <FlexContainer alignItems="center" justifyContent="flex-end" marginRight="36px">
    <PosRelative>{children}</PosRelative>
  </FlexContainer>
)
HeaderGlobalControls.propTypes = {
  children,
}
