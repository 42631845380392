import React, { memo, useEffect, useCallback, useMemo, useState } from 'react'

import { useDebouncedUpdates, useControllableState } from 'lib/hooks'
import { isFunction } from 'lib/util'

import { Slider, FormElement, withFormElementProps } from '@forms'

import { defaultPropTypes } from './defaultPropTypes'

const groupStateToValue = (state) => {
  return state.value.slider.value
}

const valueToGroupState = (value) => {
  return { slider: { value } }
}

export const SliderYearComponent = ({
  formElementRefs,
  name = 'SliderYearComponent',
  min = 1975,
  max = 2050,
  defaultValue = 1975,
  debounce = 100,
  appearance = 'allRail', // 'allRail', 'allTrack' or 'default'
  minLimit,
  maxLimit,
  value,
  onChange,
  ...props
}) => {
  const [initialValue] = useState(defaultValue ?? value ?? [])

  const onDebouncedUpdate = useCallback(
    (value) => {
      isFunction(onChange) && onChange({ id: props.id, name, value })
    },
    [onChange, props.id, name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [internalValue, setInternalValue] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const formState = useMemo(() => valueToGroupState(internalValue), [internalValue])

  const internalOnChange = useCallback(
    (value) => {
      setInternalValue(groupStateToValue(value))
    },
    [setInternalValue]
  )

  const onReset = useCallback(() => {
    setInternalValue(initialValue)
  }, [setInternalValue, initialValue])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = internalValue
    }
  }, [internalValue, formElementRefs])

  return (
    <FormElement {...props} name={name} value={formState} onChange={internalOnChange}>
      <Slider
        forElementName={name}
        name="slider"
        min={min}
        max={max}
        minLimit={minLimit}
        maxLimit={maxLimit}
        appearance={appearance}
        debounce={debounce}
        {...props}
      />
    </FormElement>
  )
}

SliderYearComponent.type = 'SliderYear'
SliderYearComponent.propTypes = defaultPropTypes

export const SliderYear = withFormElementProps(memo(SliderYearComponent))
