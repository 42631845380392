import React from 'react'
import { styled } from 'styled-components'

import { Link } from '@utilities/SVG/Link'
import { useMediaQuery } from 'lib/hooks'

const IconWrapper = styled.span`
  display: inline-block;
  position: relative;
`

const Position = styled.span.withConfig({
  shouldForwardProp: (prop) => ['style', 'children'].includes(prop),
})`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content center;
  ${(props) =>
    props.absolute === true
      ? `
      position: absolute;
      top: 0px;
      left: 8px;
    `
      : `
      position: relative;
      top: ${props.top};
    `}
`

export const LinkIndicator = ({ absolute = true }) => {
  const width = useMediaQuery(['18px', '18px', '20px', '22px', '22px'])
  const height = useMediaQuery(['14px', '14px', '16px', '18px', '18px'])

  return (
    <IconWrapper>
      {absolute && <span>&nbsp;</span>}
      <Position absolute={absolute}>
        <Link height={height} width={width} fill="currentColor" />
      </Position>
    </IconWrapper>
  )
}
