import React, { isValidElement, cloneElement, Fragment } from 'react'

import { isArray, isUndefined, isFunction } from 'lib/util'

/** Add props to JSX child components
 *
 * Also allows to use functions as child components that get called with the
 * specified props as first argument.
 *
 * Known issue:
 * These functions will be called when building the JSX to a component tree,
 * even when they are not actually rendered in the end. If it depends on
 * specific props that are set conditionally either build your function with
 * that in mind or use a custom component instead.
 */
export const useEnhancedChildren = (children, props) => {
  if (isUndefined(children)) return null
  if (!isArray(children)) children = [children]

  return children.map((child, idx) => {
    const childProps = isValidElement(child) ? { ...child.props, ...props } : props
    return (
      <Fragment key={idx}>
        {isFunction(child)
          ? child(childProps)
          : isValidElement(child)
            ? cloneElement(child, childProps)
            : child}
      </Fragment>
    )
  })
}
