import React from 'react'
import { useTheme } from 'styled-components'

import { FlexContainer } from '@layout/BuildingBlocks'

import { getThemeValue } from 'theming'
import { children } from './defaultPropTypes'

export const HeaderTabs = ({ children = null }) => {
  const theme = useTheme()
  const marginLeft = getThemeValue(theme, 'header.space.inner')

  return (
    <FlexContainer alignItems="center" marginLeft={marginLeft}>
      {children}
    </FlexContainer>
  )
}
HeaderTabs.propTypes = {
  children,
}
