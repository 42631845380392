import React, { useMemo } from 'react'
import { styled, css, useTheme } from 'styled-components'
import { layout, space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { getThemeValue } from 'theming'
import { useControlsSegmentContext } from '@ui/ControlsSegment'
import { bool } from 'prop-types'

const segmentsAsGroupStyles = css`
  background-color: ${(props) =>
    !props.folded && themeGet('controlsSegment.segmentContentHighlighted.bg', null)};
  margin-bottom: ${themeGet('controlsSegment.segmentsAsGroup.spaceBottom', 0)};
  padding-bottom: ${(props) =>
    props.folded ? 0 : themeGet('controlsSegment.segmentContentHighlighted.spaceBottom', 0)};
  padding-top: ${(props) =>
    props.folded
      ? themeGet('controlsSegment.segmentsAsGroup.spaceTopBorderEquation', 0)
      : themeGet('controlsSegment.segmentsAsGroup.spaceTop', 0)};
`

const segmentsAsGroupStylesBorderStyles = css`
  border-width: 0 0 1px 0;
  border-style: dashed;
  border-color: ${(props) =>
    props.folded ? 'transparent' : themeGet('controlsSegment.segmentContentHighlighted.border.color')};
`

export const ContainerStyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  ${layout}
  ${space}
  ${(props) => props.segmentsAsGroup && segmentsAsGroupStyles}
  ${(props) => props.segmentsAsGroup && segmentsAsGroupStylesBorderStyles}
`

export const ControlsSegmentGroupedWrapper = styled.div`
  > :first-of-type {
    margin-top: ${themeGet('controlsSegment.segmentsAsGroup.firstItem.spaceTop', null)};
  }

  > :last-of-type {
    margin-bottom: ${themeGet('controlsSegment.segmentsAsGroup.lastItem.spaceBottom', 0)};
  }

  .unfolded {
    border-width: 1px 0 1px 0;
    + .unfolded {
      border-top-color: transparent;
    }
  }
`

export const ControlsSegmentContainer = ({
  children,
  preventPaddingX,
  segmentsAsGroup = false,
  segmentContentHighlighted = false,
  ...props
}) => {
  const { folded } = useControlsSegmentContext()
  const theme = useTheme()

  const px = useMemo(
    () => !preventPaddingX && getThemeValue(theme, 'controlsSegment.paddingPanel'),
    [preventPaddingX, theme]
  )
  const mb = useMemo(
    () => (segmentsAsGroup ? null : getThemeValue(theme, 'controlsSegment.spaceBottom')),
    [segmentsAsGroup, theme]
  )

  return segmentContentHighlighted || segmentsAsGroup ? (
    <ContainerStyledComponent
      segmentsAsGroup={segmentsAsGroup}
      segmentContentHighlighted={segmentContentHighlighted}
      folded={folded}
      className={
        folded
          ? segmentsAsGroup
            ? 'segmentsAsGroup folded'
            : 'folded'
          : segmentsAsGroup
            ? 'segmentsAsGroup unfolded'
            : 'unfolded'
      }
      mb={mb}
      paddingRight={px}
      {...props}
    >
      {children}
    </ContainerStyledComponent>
  ) : (
    <ContainerStyledComponent folded={folded} mb={mb} paddingRight={px} {...props}>
      {children}
    </ContainerStyledComponent>
  )
}
ControlsSegmentContainer.propTypes = {
  segmentsAsGroup: bool,
  segmentContentHighlighted: bool,
}
