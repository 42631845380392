import React, { useCallback, useMemo } from 'react'
import { useTheme } from 'styled-components'
import { string, object } from 'prop-types'

import { CheckboxIconBadge } from '@utilities/Switches'
import { Checkbox, Radio } from '@forms'

import { useControlsSegmentContext } from '@ui/ControlsSegment'
import { getThemeValue } from 'theming'
import { FlexRow, PosRelative } from '@layout/BuildingBlocks'
import { IconWrapper } from '@utilities/IconWrapper'

const LabelWithIcon = ({ icon, iconWrapper, label }) => {
  const theme = useTheme()
  return (
    <FlexRow flexColumnGap={getThemeValue(theme, 'spaces.labelIconGap')}>
      <IconWrapper icon={icon} iconWrapper={iconWrapper} />
      {label}
    </FlexRow>
  )
}

export const ControlsActive = ({ icon = null, iconWrapper, label = null }) => {
  const theme = useTheme()
  const { checked, setChecked, toggle } = useControlsSegmentContext()
  const onLocalClick = useCallback(
    (target) => {
      const isChecked = target.checked
      setChecked(isChecked)
      toggle(() => !isChecked)
    },
    [toggle, setChecked]
  )

  const labelGap = getThemeValue(theme, 'spaces.labelIconGap')

  return (
    <PosRelative>
      <Checkbox value="active" checked={checked} onChange={onLocalClick}>
        <CheckboxIconBadge
          appearance="white"
          icon={icon}
          iconWrapper={iconWrapper}
          iconVariantSourceName="buttonIcons"
          iconVariantSize="medium"
          label={label}
          labelGap={labelGap}
          shape="square"
          size="medium"
        />
      </Checkbox>
    </PosRelative>
  )
}

export const ControlsActiveWithCheckbox = ({ icon = null, iconWrapper, label = null }) => {
  const { checked, setChecked, toggle } = useControlsSegmentContext()
  const onLocalClick = useCallback(
    (target) => {
      const isChecked = target.checked
      setChecked(isChecked)
      toggle(() => !isChecked)
    },
    [toggle, setChecked]
  )

  const renderLabel = useMemo(() => {
    if (icon && iconWrapper) {
      return <LabelWithIcon icon={icon} label={label} iconWrapper={iconWrapper} />
    } else {
      return label
    }
  }, [icon, iconWrapper, label])

  return (
    <PosRelative>
      <Checkbox value="active" label={renderLabel} checked={checked} onChange={onLocalClick} />
    </PosRelative>
  )
}

export const ControlsActiveWithRadio = ({
  icon = null,
  iconWrapper,
  label = null,
  onClick = null,
  checked = null,
  name,
}) => {
  const { checked: checkedContext, setChecked, toggle } = useControlsSegmentContext()
  const onLocalClick = useCallback(
    (target) => {
      if (onClick !== null) {
        onClick(target)
      } else {
        const isChecked = target.checked
        setChecked(isChecked)
        toggle(() => !isChecked)
      }
    },
    [toggle, setChecked, onClick]
  )

  const localChecked = checked === null ? checkedContext : checked

  const renderLabel = useMemo(() => {
    if (icon && iconWrapper) {
      return <LabelWithIcon icon={icon} label={label} iconWrapper={iconWrapper} />
    } else {
      return label
    }
  }, [icon, iconWrapper, label])

  return (
    <PosRelative>
      <Radio value="active" label={renderLabel} checked={localChecked} name={name} onChange={onLocalClick} />
    </PosRelative>
  )
}

ControlsActive.type = 'ControlsActive'
ControlsActive.propTypes = {
  label: string,
  icon: object,
}

ControlsActiveWithCheckbox.type = 'ControlsActiveWithCheckbox'
ControlsActiveWithCheckbox.propTypes = {
  label: string,
  icon: object,
}
ControlsActiveWithRadio.type = 'ControlsActiveWithRadio'
ControlsActiveWithRadio.propTypes = {
  label: string,
  icon: object,
}
