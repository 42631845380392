import React, { useMemo } from 'react'
import { styled } from 'styled-components'
import { position, space } from 'styled-system'

const defaultPropsIconPosition = {
  iconPosition: {
    top: null,
    right: null,
    bottom: null,
    left: null,
    marginTop: null,
    marginRight: null,
    marginBottom: null,
    marginLeft: null,
  },
}

const Position = styled.span`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  height: inherit;
  position: absolute;
  width: inherit;

  ${position}
  ${space}
`

export const PositionComponent = ({ children, iconPosition }) => {
  const { top, right, bottom, left, marginTop, marginRight, marginBottom, marginLeft } = useMemo(
    () => ({ ...defaultPropsIconPosition.iconPosition, ...iconPosition }),
    [iconPosition]
  )

  return (
    <Position
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
    >
      {children}
    </Position>
  )
}
