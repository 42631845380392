import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTheme } from 'styled-components'

import { getThemeValue } from 'theming'

import useApi from 'stores/useApi'
import api from 'stores/api'

import appConfig from 'config/appConfig'
import { companyRoles } from 'config/companyRoles'

import { Checkbox, DropdownSelect, Form, Input } from '@forms/index'
import { Button } from '@ui/Buttons'
import { FlexColumn, FlexItem } from '@layout/BuildingBlocks'

import { CopyMedium, GreatPrimer, MinionLight } from 'stories/typography'

const ErrorBlock = ({ message, critical }) => {
  const theme = useTheme()
  const colors = getThemeValue(theme, 'colors')

  if (!message) {
    return null
  }

  if (critical) {
    return (
      <CopyMedium mt="8px" color={colors.base.error.default}>
        {message}
      </CopyMedium>
    )
  }

  return (
    <MinionLight mt="8px" color={colors.base.error.default}>
      {message}
    </MinionLight>
  )
}

export const RegistrationForm = ({ type }) => {
  // dependent on type
  const registrationApi =
    type === 'l3plus'
      ? api.Auth.registerTestAccountL3Plus
      : type === 'developer'
        ? api.Auth.registerTestAccountDeveloper
        : api.Auth.registerTestAccount

  const translationTypes = ['translation']
  if (type === 'developer') translationTypes.push('developer')

  const { t } = useTranslation(translationTypes)
  const [sendForm, formSent, isSubmitting, failedSubmitting] = useApi(registrationApi, null)

  // state
  const [registrationError, setRegistrationError] = useState(null)
  const [formData, setFormData] = useState({
    email: '',
    firstname: '',
    name: '',
    phone: '',
    company: '',
    companyRole: '',
    acceptTermsOfUse: false,
    newsletter: false,
    properties: { language: 'de' },
  })

  const companyRolesSelect = useMemo(
    () => companyRoles.map((item) => ({ ...item, label: t(item.label) })),
    [t]
  )

  // theme
  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')

  // check api response
  useEffect(() => {
    let error = {}

    if (failedSubmitting && Array.isArray(formSent?.messages) && formSent.messages.length) {
      if (formSent.statusCode === 500) {
        error.serverError = true
        error.serverErrorMessage = t('errorCodes.serverError')
      } else {
        formSent.messages.forEach(
          (message) => (error[message.param] = t(`validations.${message.param}.${message.msg}`))
        )
      }
      setRegistrationError(error)
    }
  }, [formSent, failedSubmitting, t])

  const register = async () => {
    sendForm(formData)
  }

  const checkBoxAccept = useMemo(
    () => (
      <div>
        {t('registerTestAccount.acceptTermsOfUse')}{' '}
        <a
          href={`${appConfig.appBaseUrl}/RIWIS-Nutzungsbedingungen-Test-Zugang.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('registerTestAccount.acceptTermsOfUseLink')}
        </a>
      </div>
    ),
    [t]
  )

  // Registration success -> Return just text
  if (formSent === true) {
    return (
      <GreatPrimer dangerouslySetInnerHTML={{ __html: t('registerTestAccount.testAccountRegistered') }} />
    )
  }

  return (
    <Form onSubmit={register}>
      <FlexColumn>
        <ErrorBlock message={registrationError?.serverErrorMessage} critical={true} />
      </FlexColumn>
      <FlexColumn flexRowGap={spaces.rhythm.vertical.medium} alignItems="stretch">
        <FlexItem>
          <CopyMedium lineHeight={1.66}>{t('registerTestAccount.intro')}</CopyMedium>
        </FlexItem>
        <FlexItem flexDirection="column">
          <Input
            appearance={registrationError?.email ? 'errorGhost' : 'white'}
            value={formData.email}
            placeholder={t('registerTestAccount.email')}
            autoFocus={true}
            onChange={(target) => {
              setFormData({ ...formData, email: target.value })
            }}
            resetable={true}
          />
          <ErrorBlock message={registrationError?.email} />
        </FlexItem>
        <FlexItem>
          <Input
            appearance={registrationError?.firstName ? 'errorGhost' : 'white'}
            value={formData.firstname}
            placeholder={t('registerTestAccount.firstname')}
            autoFocus={true}
            onChange={(target) => {
              setFormData({ ...formData, firstname: target.value })
            }}
            resetable={true}
          />
          <ErrorBlock message={registrationError?.firstName} />
        </FlexItem>
        <FlexItem flexDirection="column">
          <Input
            appearance={registrationError?.name ? 'errorGhost' : 'white'}
            value={formData.name}
            placeholder={t('registerTestAccount.name')}
            autoFocus={true}
            onChange={(target) => {
              setFormData({ ...formData, name: target.value })
            }}
            resetable={true}
          />
          <ErrorBlock message={registrationError?.name} />
        </FlexItem>
        <FlexItem flexDirection="column">
          <Input
            appearance={registrationError?.phone ? 'errorGhost' : 'white'}
            value={formData.phone}
            placeholder={t('registerTestAccount.phone')}
            autoFocus={true}
            onChange={(target) => {
              setFormData({ ...formData, phone: target.value })
            }}
            resetable={true}
          />
          <ErrorBlock message={registrationError?.phone} />
        </FlexItem>
        <FlexItem flexDirection="column">
          <Input
            appearance={registrationError?.company ? 'errorGhost' : 'white'}
            value={formData.company}
            placeholder={t('registerTestAccount.company')}
            autoFocus={true}
            onChange={(target) => {
              setFormData({ ...formData, company: target.value })
            }}
            resetable={true}
          />
          <ErrorBlock message={registrationError?.company} />
        </FlexItem>
        <FlexItem flex="1" flexDirection="column">
          <DropdownSelect
            appearance={registrationError?.companyRole ? 'errorGhost' : 'white'}
            id="registerTestAccount.companyRole"
            name="companyRole"
            placeholder={t('registerTestAccount.companyRole')}
            value={formData.companyRole}
            items={companyRolesSelect}
            onChange={(target) => {
              setFormData({ ...formData, companyRole: target.value })
            }}
            size="medium"
            stretch={true}
          />
          <ErrorBlock message={registrationError?.companyRole} />
        </FlexItem>
        <FlexItem flexDirection="column">
          <Checkbox
            id="registerTestAccount.acceptTermsOfUse"
            name="acceptTermsOfUse"
            checked={formData.acceptTermsOfUse}
            label={checkBoxAccept}
            onChange={(target) => {
              setFormData({ ...formData, acceptTermsOfUse: target.checked })
            }}
          ></Checkbox>
          <ErrorBlock message={registrationError?.acceptTermsOfUse} />
        </FlexItem>
        <FlexItem>
          <Checkbox
            id="registerTestAccount.newsletter"
            name="newsletter"
            checked={formData.newsletter}
            onChange={(target) => {
              setFormData({ ...formData, newsletter: target.checked })
            }}
            label={t('registerTestAccount.newsletter')}
          ></Checkbox>
        </FlexItem>
        <FlexItem width="100%">
          <Button
            type="submit"
            appearance="primaryShallow"
            shape="square"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            disabled={isSubmitting}
            stretch={true}
            text={t('registerTestAccount.submit')}
          />
        </FlexItem>
        {type !== 'developer' && (
          <FlexItem>
            <CopyMedium>{t('registerTestAccount.info.postscript')}</CopyMedium>
          </FlexItem>
        )}
      </FlexColumn>
    </Form>
  )
}
