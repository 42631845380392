import { useEffect } from 'react'
import { useLocation } from 'react-router'
import api from 'stores/api'
import useApi from 'stores/useApi'
import { useAuthStore } from 'stores/AuthStore'

const removeQueryStringOn = ['/features/l3plus/standalone/map', '/registertestaccount']
const removeParamFromPath = [
  '/admin/user/edit',
  '/admin/group/edit',
  '/admin/role/edit',
  '/features/l3plus/standalone/map-print',
  '/features/retail-markets/projects',
  '/sso',
]

const sanitizeLocation = (location) => {
  let { pathname, hash, search } = location

  if (removeQueryStringOn.some((path) => pathname.startsWith(path))) {
    search = null
  }

  if (
    (typeof hash === 'string' && hash.length) ||
    pathname.startsWith('/export') ||
    (typeof search === 'string' && search.length)
  ) {
    return { pathname: null, hash: null, search: null }
  } else {
    const replacement = removeParamFromPath.find((path) => pathname.startsWith(path)) ?? null
    if (replacement !== null) {
      pathname = replacement
    }

    return { pathname, hash, search }
  }
}

export const NavigationStatistics = () => {
  const location = useLocation()
  const [logNavigation] = useApi(api.AppStats.logNavigation, null)
  const [{ currentUser }] = useAuthStore()

  useEffect(() => {
    if (!currentUser || currentUser === 'init') {
      return
    }

    // has to be constructed this way to be able to set the correct dependency array
    // otherwise it will get difficult to count correctly, since the efect triggers
    // e.g. on location.key change!
    const { pathname, hash, search } = sanitizeLocation({
      hash: location.hash,
      pathname: location.pathname,
      search: location.search,
    })

    if (pathname !== null) {
      logNavigation({
        hash,
        pathname,
        search,
      })
    }
  }, [currentUser, location.hash, location.pathname, location.search, logNavigation])

  return null
}
