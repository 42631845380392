import React, { memo, useEffect, useCallback, useMemo, useState } from 'react'
import { string, number, bool } from 'prop-types'
import { useTheme } from 'styled-components'

import { getThemeValue } from 'theming'

import { useControllableState, useDebouncedUpdates } from 'lib/hooks'
import { isFunction } from 'lib/util'

import { FormElement, Checkbox, withFormElementProps } from '@forms'
import { FlexColumn, FlexItem } from '@layout/BuildingBlocks'

const groupStateToValue = (state) =>
  Object.entries(state)
    .filter((entry) => entry[1].checked)
    .map((entry) => entry[0])

const valueToGroupState = (value) => Object.fromEntries(value.map((key) => [key, { checked: true }]))

export const CheckboxListComponent = ({
  name = 'checkboxListComponent',
  debounce = null,
  fullWidth = false,
  defaultValue,
  formElementRefs,
  items,
  itemCheckAll,
  onChange,
  value,
  disabled = false,
  ...props
}) => {
  const theme = useTheme()

  const [initialValue] = useState(defaultValue ?? value ?? [])
  const [checkedAll, setCheckedAll] = useState(items.every((item) => initialValue.includes(item.value)))

  const onDebouncedUpdate = useCallback(
    (checkedChilds, changeEmitter) => {
      isFunction(onChange) && onChange({ id: props.id, name, value: checkedChilds, changeEmitter })
    },
    [onChange, props.id, name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [checkedChilds, setCheckedChilds] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const allValues = useMemo(() => items.map((item) => item.value), [items])
  const formState = useMemo(() => valueToGroupState(checkedChilds), [checkedChilds])

  const internalOnChange = useCallback(
    (evt) => {
      const nextCheckedChilds = groupStateToValue(evt.value)
      setCheckedChilds(nextCheckedChilds, evt.changeEmitter)
    },
    [setCheckedChilds]
  )

  const onReset = useCallback(() => {
    setCheckedChilds(initialValue)
  }, [setCheckedChilds, initialValue])

  const onChangeAll = useCallback(
    (target) => {
      setCheckedChilds(target.checked ? [...new Set([...checkedChilds, ...allValues])] : [])
    },
    [allValues, checkedChilds, setCheckedChilds]
  )

  useEffect(() => {
    const currentChecked = checkedChilds.filter((item) => allValues.includes(item))
    setCheckedAll(currentChecked.length === allValues.length)
  }, [checkedChilds, allValues])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = checkedChilds
    }
  }, [checkedChilds, formElementRefs])

  const labelStyle = useMemo(() => {
    if (fullWidth) {
      return { width: '100%' }
    } else {
      return {}
    }
  }, [fullWidth])

  return (
    <FormElement {...props} name={name} value={formState} onChange={internalOnChange}>
      <FlexColumn flexRowGap={getThemeValue(theme, 'spaces.rhythm.vertical.small')}>
        {itemCheckAll && (
          <Checkbox disabled={disabled} checked={checkedAll} onChange={onChangeAll}>
            {itemCheckAll.renderChild}
          </Checkbox>
        )}
        {items.map((item, index) => {
          const props = typeof item.renderChild === 'undefined' ? { label: item.label } : {}
          return (
            <FlexItem key={index}>
              <Checkbox
                value={item.value}
                disabled={disabled || item.disabled}
                forElementName={name}
                labelStyle={labelStyle}
                {...props}
              >
                {item.renderChild}
              </Checkbox>
            </FlexItem>
          )
        })}
      </FlexColumn>
    </FormElement>
  )
}

CheckboxListComponent.type = 'CheckboxList'
CheckboxListComponent.propTypes = {
  name: string,
  debounce: number,
  fullWidth: bool,
}

export const CheckboxList = withFormElementProps(memo(CheckboxListComponent))
