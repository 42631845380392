import React, { useRef, useEffect, useState, useCallback, memo, forwardRef } from 'react'
import { styled } from 'styled-components'
import { typography } from 'styled-system'

import { BudiconCrossUi } from 'bgag-budicons'
import { PosRelative } from '@layout/BuildingBlocks'

import { isFunction } from 'lib/util'
import { useControllableState, useDebouncedUpdates } from 'lib/hooks'
import { withFormElementProps } from '@forms'

import { appearance, shape, size } from '@forms/Input/variants'
import { typeMap, typeScale, typeStyle } from '@typography'
import { allowedProps } from '@forms/Input/config'

import { bool, number, object, string } from 'prop-types'
import { useTranslation } from 'react-i18next'

const IconWrapper = styled.div`
  cursor: pointer;
  height: 18px;
  margin-top: -9px;
  position: absolute;
  right: 8px;
  top: 50%;
  width: 18px;

  > svg {
    pointer-events: none;
  }
`

const IconCross = () => {
  return <BudiconCrossUi type="shady" size="18px" strokeWidth=".5" />
}

const StyledComponent = styled.input.withConfig({
  shouldForwardProp: (prop) => allowedProps.includes(prop),
})`
  width: 100%;

  ::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  ::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }

  + div {
    color: inherit;
  }

  + div > svg {
    fill: currentColor;
  }
  outline: none;

  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  ${appearance}
  ${shape}
  ${(props) => props.shape !== 'shapeless' && size}
  ${(props) => props.width && { width: props.width }}
  ${(props) => props.height && { height: props.height }}
`

const Wrapper = styled(PosRelative)`
  ${appearance}
  border:0;
  background: transparent;
`

const fontSizeDefault = [0, 1, 2, 3, 4]

export const InputSearchComponent = forwardRef(
  (
    {
      onChange,
      value,
      inputOverwrite,
      setInputOverwrite,
      onInputReset,
      showInputResetButton = true,
      shallowInputValue,
      setShallowInputValue,
      inputChangeIndicator,
      placeholder,
      formElementRefs = null,
      fontFamily = 'whitneySSm',
      fontSize = fontSizeDefault,
      lineHeight = 'normal',
      appearance = 'gray',
      size = 'medium',
      shape = 'square',
      debounce = null,
      disabled = false,
      ...props
    },
    ref
  ) => {
    props = { ...props, fontFamily, fontSize, lineHeight, appearance, size, shape }
    const { typeStyle, typeScale } = typeMap[props.size]
    const [defaultValue] = useState(props.defaultValue || value || '')
    const refCrossUi = useRef()
    const { t } = useTranslation()

    const onDebouncedUpdate = useCallback(
      (value) => {
        isFunction(onChange) && onChange({ id: props.id, name: props.name, value })
      },
      [onChange, props.id, props.name]
    )

    const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
      value,
      onDebouncedUpdate,
      debounce
    )

    const [localValue, setValue] = useControllableState(
      defaultValue,
      internalValueDebouncer,
      onControllableStateChange
    )

    const internalOnChange = useCallback(
      (evt) => {
        if (typeof setInputOverwrite === 'function') {
          setInputOverwrite(null)
        }
        if (typeof setShallowInputValue === 'function') {
          setShallowInputValue(null)
        }
        if (typeof inputChangeIndicator === 'function') {
          // only used to indicate that something has changed shoudl not be used as onChange!
          inputChangeIndicator(evt)
        }
        setValue(evt.target.value)
      },
      [setValue, setInputOverwrite, setShallowInputValue, inputChangeIndicator]
    )

    const onEmpty = useCallback(() => {
      setValue('')
      if (typeof setInputOverwrite === 'function') {
        setInputOverwrite(null)
      }
      if (typeof setShallowInputValue === 'function') {
        setShallowInputValue(null)
      }
      if (typeof onInputReset === 'function') {
        onInputReset()
      }
    }, [setValue, setInputOverwrite, onInputReset, setShallowInputValue])

    const onReset = useCallback(() => {
      setValue(defaultValue || '')
    }, [setValue, defaultValue])

    useEffect(() => {
      if (formElementRefs) {
        formElementRefs.reset.current = onReset
      }
    }, [onReset, formElementRefs])

    useEffect(() => {
      if (formElementRefs) {
        formElementRefs.value.current = localValue
      }
    }, [localValue, formElementRefs])

    return (
      <Wrapper appearance={props.appearance}>
        <StyledComponent
          disabled={disabled}
          typeScale={typeScale}
          typeStyle={typeStyle}
          onChange={internalOnChange}
          ref={ref}
          type="search"
          value={
            typeof shallowInputValue === 'string'
              ? shallowInputValue
              : typeof inputOverwrite === 'string'
                ? inputOverwrite
                : localValue
          }
          placeholder={placeholder || t('actions.actionSearch')}
          {...props}
        />
        {showInputResetButton && (
          <IconWrapper ref={refCrossUi} onClick={onEmpty}>
            <IconCross />
          </IconWrapper>
        )}
      </Wrapper>
    )
  }
)

InputSearchComponent.type = 'Input'
InputSearchComponent.propTypes = {
  formElementRefs: object,
  appearance: string,
  shape: string,
  size: string,
  debounce: number,
  disabled: bool,
}

export const InputSearch = withFormElementProps(memo(InputSearchComponent))
