import React, { createRef, useMemo, memo } from 'react'
import { styled, useTheme } from 'styled-components'
import { layout, space } from 'styled-system'

import { customScrollbar } from 'theming/styles/CustomScrollbar'
import { getThemeValue } from 'theming'

import { useOverflowObserver } from 'lib/hooks'

import { overflowY, paddingRight } from './defaultPropTypes'

const StyledScrollContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => ['style', 'children'].includes(prop),
})`
  ${customScrollbar}
  ${layout}
  ${space}
`

const defaultOverflow = ['auto', null, 'auto']

export const ModalScrollContainer = memo(
  ({
    children,
    containerPaddingRight,
    displayBottomCover = 'none',
    overflowY = defaultOverflow,
    paddingRight = null,
  }) => {
    const theme = useTheme()
    const ref = createRef()

    const scrollbarThumbWidth = getThemeValue(theme, 'sizes.scrollbar.default.thumb.width')
    const [hasOverflow] = useOverflowObserver(ref)
    const computedPaddingRight = useMemo(
      () =>
        hasOverflow
          ? `calc(${containerPaddingRight} - ${scrollbarThumbWidth})`
          : (paddingRight ?? containerPaddingRight),
      [hasOverflow, containerPaddingRight, paddingRight, scrollbarThumbWidth]
    )

    return (
      <StyledScrollContainer
        height="100%"
        overflowY={overflowY}
        paddingRight={computedPaddingRight}
        ref={ref}
      >
        {children}
      </StyledScrollContainer>
    )
  }
)
ModalScrollContainer.propTypes = {
  overflowY,
  paddingRight,
}
