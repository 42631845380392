import React, { forwardRef, useCallback } from 'react'
import { styled, useTheme } from 'styled-components'
import { typography } from 'styled-system'
import { bool, string } from 'prop-types'

import { HTMLButtonElementWithIconAndText } from '../SubComponents'
import { IconWrapper } from '@utilities/IconWrapper'

import { useButtonProgressbar } from 'lib/hooks'
import { getThemeValue } from 'theming'

import { appearance, shape, size } from './variants'
import { typeMap, typeScale, typeStyle } from '@typography'

const ButtonElement = styled(HTMLButtonElementWithIconAndText)`
  align-self: center;
  align-items: center;
  display: flex;
  justify-content: center;
  &::after {
    content: '0';
    width: 0;
    max-width: 0;
    overflow: hidden;
    visibility: hidden;
  }
  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  ${appearance}
  ${shape}
  ${(props) => props.shape !== 'shapeless' && size}
  ${(props) => props.width && { width: props.width }}
  ${(props) => props.height && { height: props.height }}
  ${(props) => props.stretch && { width: '100%' }}
  ${(props) => props.leftAlign && { justifyContent: 'flex-start' }}
`

const fontSizeDefault = [0, 1, 2, 3, 4]

export const ButtonWithIconProgressbar = forwardRef(
  (
    {
      children,
      fontFamily = 'whitneySSm',
      fontSize = fontSizeDefault,
      lineHeight = 'normal',
      appearance = 'lightgray',
      iconAlign = 'left',
      iconVariantSourceName = 'buttonIcons',
      leftAlign = null,
      size = 'medium',
      shape = 'oval',
      stretch = null,
      ...props
    },
    ref
  ) => {
    props = {
      ...props,
      fontFamily,
      fontSize,
      lineHeight,
      appearance,
      iconAlign,
      iconVariantSourceName,
      leftAlign,
      size,
      shape,
      stretch,
    }

    const theme = useTheme()
    const iconSpaceX = getThemeValue(theme, 'sizes.icons.button.space.x')
    const { typeStyle, typeScale } = typeMap[props.size]

    const progressbarStyles = useButtonProgressbar(props)
    const disabled = props.disabled || typeof props.progress === 'number'

    const attachIconMargin = useCallback(
      (direction) => {
        const mr = props.iconAlign === 'left' ? iconSpaceX : null
        const ml = props.iconAlign === 'right' ? iconSpaceX : null

        return direction === 'right' ? mr : ml
      },
      [props.iconAlign, iconSpaceX]
    )
    return (
      <ButtonElement
        {...props}
        typeScale={typeScale}
        typeStyle={typeStyle}
        ref={ref}
        style={progressbarStyles}
        disabled={disabled}
      >
        <IconWrapper
          height={props.iconHeight}
          icon={props.icon}
          iconPosition={props.iconPosition}
          iconVariantSourceName={props.iconVariantSourceName}
          ml={attachIconMargin('left')}
          mr={attachIconMargin('right')}
          size={props.size}
          width={props.iconWidth}
        />
      </ButtonElement>
    )
  }
)
ButtonWithIconProgressbar.propTypes = {
  appearance: string,
  iconAlign: string,
  iconVariantSourceName: string,
  leftAlign: bool,
  shape: string,
  size: string,
  text: string,
  stretch: bool,
}
