import React from 'react'
import { createPortal } from 'react-dom'
import { styled, useTheme, css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { useMediaQuery } from 'lib/hooks'

import { FlexContainer } from '@layout/BuildingBlocks'
import { useLayoutContext } from '@layout/Layout'

import { getThemeValue } from 'theming'
import { zIndices } from 'config/zIndices'
import { UserProfile } from 'pages/UserProfile/UserProfile'

const StyledComponent = styled(FlexContainer)`
  display: flex;

  position: fixed;
  bottom: 0;

  border-top-left-radius: ${themeGet('radii.small', '0')};
  border-bottom-left-radius: ${themeGet('radii.small', '0')};

  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.22);

  background-color: #fff;
  backdrop-filter: blur(2px);
  // background-color: rgba(255, 255, 255, 0.99);

  top: ${(props) => props.top};
  width: 400px;
  // border-top: 1px solid ${themeGet('colors.theme.app.bg')};

  z-index: ${zIndices.overlayRight};
  ${(props) => css`
    right: ${props.isOpen ? '0' : '-500px'};
    opacity: ${props.isOpen ? '1' : '0'};
    transition:
      right 0.3s ${props.isOpen ? 'ease-in' : 'ease-out'},
      opacity 0.3s ${props.isOpen ? 'ease-in' : 'ease-out'};
  `}
`

export const OverlayRight = () => {
  const theme = useTheme()
  const {
    state: { overlayRightIsOpen },
  } = useLayoutContext()
  const headerHeight = useMediaQuery(getThemeValue(theme, 'layout.header.height'))

  return createPortal(
    <StyledComponent top={headerHeight} isOpen={overlayRightIsOpen}>
      <FlexContainer width="100%" height="100%">
        <UserProfile />
      </FlexContainer>
    </StyledComponent>,
    document.body
  )
}
