import { styled } from 'styled-components'
import { color, space, layout, flexbox, typography as typographyStyledSystem } from 'styled-system'
import typography from 'components/theming/shared/typography'

export const TextHighlight = styled.div`
  font-family: ${typography.fonts.headingNew};
  font-weight: 600;
  font-size: 1.3rem;
  color: ${(props) => props.theme.colors.secondary};
  ${typographyStyledSystem}
  ${space}
  ${color}
`

export const TextHeader = styled.div`
  font-family: ${typography.fonts.heading};
  font-size: 1.1rem;
  color: ${(props) => props.theme.colors.darker};
  ${typographyStyledSystem}
  ${space}
  ${color}
`

export const TextSubHeader = styled.div`
  font-family:
    'whitney-medium-2',
    'whitney-medium-1' ${typography.fonts.sansSerif};
  font-size: 0.95rem;
  color: ${(props) => props.theme.colors.darker};
  ${typographyStyledSystem}
  ${space}
  ${color}
`

export const TextGroupHeader = styled.div`
  font-family: ${typography.fonts.sansSerif};
  font-size: 0.875rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.secondary};
  text-transform: uppercase;
  ${typographyStyledSystem}
  ${space}
  ${color}
`

export const TextSectionHeader = styled(TextGroupHeader)`
  font-family: ${typography.fonts.heading};
  font-size: 0.95rem;
  font-weight: normal;
  color: ${(props) => props.theme.colors.secondary};
  ${typographyStyledSystem}
  ${color}
  ${space}
`

export const TextParagraph = styled.p`
  font-family: ${typography.fonts.serif};
  font-size: 0.85rem;
  color: ${(props) => props.theme.colors.dark};
  ${typographyStyledSystem}
  ${space}
  ${color}
`

export const TextFormLabel = styled.label`
  cursor: pointer;
  font-family: ${typography.fonts.sansSerif};
  font-size: 0.8rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.dark};
  ${typographyStyledSystem}
  ${space}
  ${color}
  ${layout}
  ${flexbox}

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

export const TextTag = styled.div`
  font-family: ${typography.fonts.sansSerif};
  font-size: 0.75rem;
  color: ${(props) => props.theme.colors.dark};
  ${typographyStyledSystem}
  ${space}
  ${color}
`
