import React from 'react'
import { useTheme } from 'styled-components'

import { BudiconArrowTopRightA, BudiconArrowBottomRightA } from 'bgag-budicons'
import { FlexColumn, FlexRow } from '@layout/BuildingBlocks'
import { IconWrapper } from '@utilities/IconWrapper'
import { Text } from '@typography/ContentElements'

import { getThemeValue } from 'theming'
import { string, element, elementType, oneOfType, oneOf } from 'prop-types'

export const BadgeTendency = ({
  align = 'left',
  metaline = 'Metaline',
  metalineColor = null,
  text = 'Lorem Ipsum',
  tendency = null,
  typeScaleMeta = 'minion',
  typeScaleText = 'greatPrimer',
  typeStyleMeta = 'minion',
  typeStyleText = 'semibold',
}) => {
  const theme = useTheme()

  const bg = getThemeValue(theme, 'colors.base.lightgray.530')
  const colorDown = getThemeValue(theme, 'colors.base.error.default')
  const colorUp = getThemeValue(theme, 'colors.base.success.default')
  const radius = getThemeValue(theme, 'radii.small')
  const spaces = getThemeValue(theme, 'spaces')

  return (
    <FlexColumn
      alignItems="flex-start"
      flexRowGap={spaces.rhythm.vertical.nano}
      background={bg}
      borderRadius={radius}
      padding={`${spaces[1]} ${spaces[5]}`}
    >
      <Text
        align={align}
        color={metalineColor}
        text={metaline}
        typeStyle={typeStyleMeta}
        typeScale={typeScaleMeta}
      />
      <FlexRow flexColumnGap={spaces.rhythm.horizontal.tiny} alignItems="center">
        <IconWrapper
          icon={{
            Icon: tendency === 'up' ? BudiconArrowTopRightA : BudiconArrowBottomRightA,
            width: '24px',
            fill: tendency === 'up' ? colorUp : colorDown,
          }}
          iconWrapper={{ height: '24px', width: '24px' }}
        />

        <Text
          align="right"
          color={tendency === 'up' ? colorUp : colorDown}
          text={text}
          typeStyle={typeStyleText}
          typeScale={typeScaleText}
        />
      </FlexRow>
    </FlexColumn>
  )
}
BadgeTendency.propTypes = {
  align: string,
  metaline: string,
  metalineColor: string,
  tendency: oneOf(['up', 'down']),
  text: oneOfType([string, element, elementType]),
  typeScaleMeta: string,
  typeStyleMeta: string,
  typeScaleText: string,
  typeStyleText: string,
}
