import { array, arrayOf, oneOfType, string, shape, object } from 'prop-types'

export const iconShape = shape({
  component: object,
  color: string,
  size: oneOfType([array, string]),
  iconPosition: shape({
    left: oneOfType([array, string]),
    top: oneOfType([array, string]),
  }),
})

export const linkItemShape = shape({
  path: string,
  destination: string,
  target: string,
})

const navtreeShape = arrayOf(
  shape({
    parent: shape({
      icon: iconShape,
      label: string,
      subtree: arrayOf(
        shape({
          label: string,
          description: string,
          link: linkItemShape,
          image: object,
        })
      ),
    }),
  })
)

const containerHeight = string
const containerWidth = string
const navtree = navtreeShape
const parentListWidth = string

export { containerHeight, containerWidth, navtree, parentListWidth }
