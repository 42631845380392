import React, { forwardRef, useMemo } from 'react'
import { styled, css, useTheme } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { BudiconChevronTop } from 'bgag-budicons'
import { ButtonIcon } from '@ui/Buttons'

import { onClick, isOpen } from './defaultPropTypes'
import { getThemeValue } from 'theming'

const activeStyle = css`
  background-color: ${themeGet('navigationMain.badge.colors.hover.bg', 'black')};
  color: ${themeGet('navigationMain.badge.colors.hover.color', 'lightgray')};
  svg {
    transform: rotate(0deg);
    position: relative;
    top: -1px;
  }
`

const StyledButton = styled(ButtonIcon)`
  display: flex;
  align-self: stretch;
  background-color: ${themeGet('navigationMain.badge.colors.default.bg', 'lightgray')};
  border-radius: ${themeGet('navigationMain.badge.radius', '0')} 0 0
    ${themeGet('navigationMain.badge.radius', '0')};
  border: 0;
  color: ${themeGet('navigationMain.badge.colors.default.color', 'black')};
  margin-right: 2px;

  padding: 0 1px 0 2px;
  overflow: hidden;
  transition: background-color 0.2s;

  svg {
    fill: currentColor;
    stroke: currentColor;
    transform: rotate(180deg);
    transition: transform 0.2s;
  }

  &:hover {
    background-color: ${themeGet('navigationMain.badge.colors.hover.bg', 'lightgray')};
  }
  ${(props) => props.isOpen && activeStyle}
`

const _icon = { Icon: BudiconChevronTop, type: 'solid', width: '16px', strokeWidth: '2px' }

const emptyFunc = () => {}

export const FlyoutOpener = forwardRef(({ isOpen = false, onClick = emptyFunc }, ref) => {
  const theme = useTheme()

  const iconColor = getThemeValue(theme, 'navigationMain.badge.colors.default.color')

  const icon = useMemo(() => ({ ..._icon, stroke: iconColor }), [iconColor])

  return (
    <StyledButton
      appearance="bare"
      icon={icon}
      iconWrapper={{ width: '26px', height: '26px' }}
      iconPosition={{ top: '1px' }}
      isOpen={isOpen}
      onClick={onClick}
      shape="shapeless"
      size="small"
      ref={ref}
    />
  )
})
FlyoutOpener.propTypes = {
  onClick,
  isOpen,
}
