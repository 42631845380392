import React from 'react'

import { PosRelative, FlexContainer, FlexCenter } from '@layout/BuildingBlocks'

import { children } from './defaultPropTypes'

export const HeaderTitleLogo = ({ children = null }) => (
  <FlexContainer alignItems="center">
    <PosRelative marginLeft="36px" position="relative">
      <FlexCenter>{children}</FlexCenter>
    </PosRelative>
  </FlexContainer>
)
HeaderTitleLogo.propTypes = {
  children,
}
