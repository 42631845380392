import React from 'react'

import { children } from './defaultPropTypes'

export const HeaderNavigationMeta = ({ children = null }) => {
  return <div>{children}</div>
}
HeaderNavigationMeta.propTypes = {
  children,
}
