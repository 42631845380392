/*
 *  !!!
 *  same functionality in services: models/object.filter.js
 *  !!!
 *
 */

const MEASUREMENT_KEY_VAL_MAP = {
  AN: 'new',
  N: 'new',
  S: 'modernisation',
  U: 'useChange',
  EW: 'other',
  B: 'other',
  '': 'other',
}
const tenureFilterKeysMap = {
  retail: 'EH',
  hotel: 'HO',
  office: 'BU',
  logistic: 'LO',
  seniorLiving: 'SE',
  microLiving: 'ML',
}
const testRange = (value, filter) => {
  const filterFrom =
    !!filter.from && typeof filter.from === 'object' && 'value' in filter.from
      ? filter.from.value
      : filter.from

  if (!value && !filterFrom) {
    return true
  }

  value = parseFloat(value)
  if (isNaN(value)) {
    return false
  }

  const filterTo =
    !!filter.to && typeof filter.to === 'object' && 'value' in filter.to ? filter.to.value : filter.to
  return (!filterFrom || value >= filterFrom) && (!filterTo || value <= filterTo)
}
const testDate = (date, filter) => {
  if (date === null) {
    return false
  }
  date = new Date(date)
  return (!filter.from || date >= filter.from) && (!filter.to || date < filter.to)
}

export const checkMeasurement = (object, filter) => {
  return filter.includes(MEASUREMENT_KEY_VAL_MAP[object?.properties?.measureType])
}

const tenureSectionFilter = (object, filterKey, filter, overridable = null, sectionFilters = null) => {
  const fieldDef = objectFilterDef.find(
    (def) => def.key === filterKey && (!def.conditional || def.conditional('tenure', filter, sectionFilters))
  )
  if (
    fieldDef &&
    fieldDef.type === 'fct' &&
    typeof fieldDef.filterIsSet === 'function' &&
    typeof fieldDef.filter === 'function'
  ) {
    if (!fieldDef.filterIsSet(filter) && overridable) {
      filter = overridable
    }
    if (!fieldDef.filterIsSet(filter) && filter && 'whenFilterNotSet' in filter) {
      filter = filter.whenFilterNotSet
    }

    if (fieldDef.filterIsSet(filter) || (filter && 'whenFilterNotSet' in filter)) {
      const transformedFilter =
        typeof fieldDef.transformFilter === 'function' ? fieldDef.transformFilter(filter) : filter

      return fieldDef.filter(object, transformedFilter, 'tenure')
    }
  }
  return undefined
}

const applyTenureFilter = (object, tenureSectionKey, tenureSection, globalFilter, previousFilter) => {
  if (!tenureSection.active) {
    return previousFilter
  }
  const useKey = tenureFilterKeysMap[tenureSectionKey]
  const useRegEx = new RegExp(`(^|\\s|\\;)${useKey === 'ML' ? '(WO|HO)' : useKey}[^a-zA-Z]`, 'mg')
  const use = useRegEx.test(object.properties.use)

  if (!use || (useKey === 'ML' && !microLivingUseFilter(object))) {
    return previousFilter
  }

  if (!tenureSection.value) {
    return true
  }

  const sectionFilters = Object.entries(tenureSection.value).reduce((validation, [key, value]) => {
    const overridableFilterKey = Object.keys(globalFilter).find(
      (globalFilterKey) => globalFilterKey === 'overridable.'.concat(key)
    )

    const tenureFilter = tenureSectionFilter(
      object,
      key,
      value,
      globalFilter[overridableFilterKey],
      tenureSection.filter
    )
    return tenureFilter === undefined ? validation : validation && tenureFilter
  }, true)

  return previousFilter || sectionFilters
}

/**
 * applyTenureGlobalFilter: filter function to apply for the tenure Global Filter
 * @param object: tenure object
 * @param globalFilter:  filter object
 * @param includeOverridableFilters:  indicate if overridable filters should be considered or ignored
 * @returns {boolean}
 */
const applyTenureGlobalFilter = (object, globalFilter, includeOverridableFilters = true) => {
  return Object.entries(globalFilter).reduce((validation, [filterKey, filterValue]) => {
    if (!includeOverridableFilters && filterKey.indexOf('overridable.') > -1) {
      return validation
    }
    filterKey = filterKey.replace(/^overridable\./, '')
    const tenureFilter = tenureSectionFilter(object, filterKey, filterValue)

    return tenureFilter === undefined ? validation : validation && tenureFilter
  }, true)
}

const microLivingUseFilter = (object) => {
  const isMicroLiving = ['Businessapartments', 'Serviced Appartments', 'Studentenwohnen'].some((item) =>
    object.properties.propertyTypes?.includes?.(item)
  )
  return (
    isMicroLiving && (object?.properties?.use?.includes?.('WO') || object?.properties?.use?.includes?.('HO'))
  )
}

// configuration for filter algorithms.
const objectFilterDef = [
  {
    key: 'name',
    type: 'string',
    caseSensitive: false,
  },
  {
    key: 'address',
    type: 'string',
    caseSensitive: false,
  },
  {
    key: 'investor',
    conditional: (objectType, filter) => objectType === 'pipeline',
    type: 'string',
    caseSensitive: false,
  },
  {
    key: 'salesBuyerSeller',
    conditional: (objectType, filter) => objectType === 'sales',
    filterIsSet: (filter) => typeof filter === 'string' && filter.length,
    transformFilter: (filter) => filter.toLowerCase(),
    typeSub: 'fct',
    filterSub: (row, filter) =>
      (row.buyer && row.buyer.toLowerCase().indexOf(filter) >= 0) ||
      (row.seller && row.seller.toLowerCase().indexOf(filter) >= 0),
  },
  {
    key: 'tenantName',
    conditional: (objectType, filter) => objectType === 'tenant',
    filterIsSet: (filter) => typeof filter === 'string' && filter.length,
    transformFilter: (filter) => filter.toLowerCase(),
    typeSub: 'fct',
    filterSub: (row, filter) => row.name && row.name.toLowerCase().indexOf(filter) >= 0,
  },
  {
    key: 'pipelineUse',
    conditional: (objectType, filter) => objectType === 'pipeline',
    type: 'fct',
    filterIsSet: (filter) => typeof filter === 'string' && filter.length,
    filter: (object, filter) => {
      if (filter === 'ML') {
        return microLivingUseFilter(object)
      }
      return object.properties.use && object.properties.use.indexOf(filter) >= 0
    },
  },
  {
    key: 'measureType',
    type: 'fct',
    filterIsSet: (filter) => Array.isArray(filter) && filter.length,
    filter: (object, filter) => checkMeasurement(object, filter),
  },
  {
    key: 'salesUse',
    conditional: (objectType, filter) => objectType === 'sales',
    type: 'fct',
    filterIsSet: (filter) => typeof filter === 'string' && filter.length,
    filter: (object, filter) => {
      if (filter === 'ML') {
        return microLivingUseFilter(object)
      }
      return object.properties.use && object.properties.use.indexOf(filter) >= 0
    },
  },
  {
    key: 'tenantUse',
    conditional: (objectType, filter) => objectType === 'tenant',
    filterIsSet: (filter) => typeof filter === 'string' && filter.length,
    typeSub: 'fct',
    filterSub: (row, filter) => row.use.indexOf(filter) >= 0,
  },
  {
    key: 'tenantCategory',
    conditional: (objectType, filter) => objectType === 'tenant',
    filterIsSet: (filter) => typeof filter === 'string' && filter.length,
    typeSub: 'fct',
    filterSub: (row, filter) => row.category === filter,
  },
  {
    key: 'propertyType',
    conditional: (objectType, filter) => objectType === 'sales',
    filterIsSet: (filter) => typeof filter === 'string' && filter.length,
    typeSub: 'fct',
    filterSub: (row, filter) => row.propertytype === filter,
  },
  {
    key: 'tenurePropertyType',
    conditional: (objectType, filter) => objectType === 'tenure',
    type: 'fct',
    filterIsSet: (filter) => filter && filter.length,
    filter: (object, filter, objectType = 'tenure') => {
      const objPropertyTypes = object.properties.propertyTypes
      let composite = false
      if (filter === 'composite' || (Array.isArray(filter) && filter.includes('composite'))) {
        composite =
          Array.isArray(objPropertyTypes) &&
          objPropertyTypes.includes('Pflegeheim') &&
          objPropertyTypes.includes('Seniorenwohnen')
      }
      return (
        composite ||
        (!objPropertyTypes || Array.isArray(filter)
          ? filter.some((element) => objPropertyTypes?.length && objPropertyTypes.includes(element))
          : objPropertyTypes?.length && objPropertyTypes.includes(filter))
      )
    },
  },
  {
    key: 'status',
    // conditional: (objectType, filter) => objectType === 'tenure',
    type: 'string',
    caseSensitive: false,
    filterIsSet: (filter) => typeof filter === 'string' && filter.length,
  },
  {
    key: 'rent',
    conditional: (objectType, filter) => objectType === 'tenant',
    filterIsSet: (filter) =>
      (!!filter.from && (!isNaN(filter.from) || !isNaN(filter.from.value))) ||
      (!!filter.to && (!isNaN(filter.to) || !isNaN(filter.to.value))),
    typeSub: 'fct',
    filterSub: (row, filter) => testRange(row.rent, filter),
  },
  {
    key: 'price',
    conditional: (objectType, filter) => objectType === 'sales',
    filterIsSet: (filter) =>
      (!!filter.from && (!isNaN(filter.from) || !isNaN(filter.from.value))) ||
      (!!filter.to && (!isNaN(filter.to) || !isNaN(filter.to.value))),
    typeSub: 'fct',
    filterSub: (row, filter) => testRange(row.price, filter),
  },
  {
    key: 'yield',
    conditional: (objectType, filter) => objectType === 'sales',
    filterIsSet: (filter) =>
      (!!filter.from && (!isNaN(filter.from) || !isNaN(filter.from.value))) ||
      (!!filter.to && (!isNaN(filter.to) || !isNaN(filter.to.value))),
    typeSub: 'fct',
    filterSub: (row, filter) => testRange(row.yield, filter),
  },
  {
    key: 'space',
    type: 'fct',
    filterIsSet: (filter) => {
      return (
        (!!filter.from && (!isNaN(filter.from) || !isNaN(filter.from.value))) ||
        (!!filter.to && (!isNaN(filter.to) || !isNaN(filter.to.value)))
      )
    },

    filter: (object, filter, objectType) => {
      if (objectType === 'pipeline' || objectType === 'tenure') {
        return testRange(object.properties[filter.surfaceKey ?? 'spaceTotal'], filter)
      }
      return true
    },
    typeSub: 'fct',
    filterSub: (row, filter) => {
      return testRange(row.space, filter)
    },
  },
  {
    key: 'date',
    conditional: (objectType, filter) => objectType === 'pipeline',
    type: 'fct',
    filterIsSet: (filter) =>
      (filter.from && typeof filter.from.asString === 'string' && filter.from.asString.length) ||
      (filter.to && typeof filter.to.asString === 'string' && filter.to.asString.length),
    transformFilter: (filter) => {
      const from =
        filter.from && typeof filter.from.asString === 'string' && filter.from.asString.length
          ? new Date(filter.from.asString)
          : null
      const to =
        filter.to && typeof filter.to.asString === 'string' && filter.to.asString.length
          ? new Date(filter.to.asString)
          : null
      return { from, to }
    },
    filter: (object, filter, objectType) => {
      return testDate(object.properties.completed, filter)
    },
  },
  {
    key: 'dateTenant',
    filterIsSet: (filter) =>
      (filter.from && typeof filter.from.asString === 'string' && filter.from.asString.length) ||
      (filter.to && typeof filter.to.asString === 'string' && filter.to.asString.length),
    transformFilter: (filter) => {
      const from =
        filter.from && typeof filter.from.asString === 'string' && filter.from.asString.length
          ? new Date(filter.from.asString)
          : null
      const to =
        filter.to && typeof filter.to.asString === 'string' && filter.to.asString.length
          ? new Date(filter.to.asString)
          : null
      return { from, to }
    },
    typeSub: 'fct',
    filterSub: (row, filter, objectType) => {
      if (objectType === 'tenant') {
        return testDate(row['contractsigning'], filter)
      }
      return true
    },
  },
  {
    key: 'dateSales',
    filterIsSet: (filter) =>
      (filter.from && typeof filter.from.asString === 'string' && filter.from.asString.length) ||
      (filter.to && typeof filter.to.asString === 'string' && filter.to.asString.length),
    transformFilter: (filter) => {
      const from =
        filter.from && typeof filter.from.asString === 'string' && filter.from.asString.length
          ? new Date(filter.from.asString)
          : null
      const to =
        filter.to && typeof filter.to.asString === 'string' && filter.to.asString.length
          ? new Date(filter.to.asString)
          : null
      return { from, to }
    },
    typeSub: 'fct',
    filterSub: (row, filter, objectType) => {
      if (objectType === 'sales') {
        return testDate(row[objectType === 'sales' ? 'date' : 'contractsigning'], filter)
      }
      return true
    },
  },
  {
    key: 'stars',
    conditional: (objectType, filter) => objectType === 'tenure',
    type: 'fct',
    filterIsSet: (filter) => {
      return filter && !isNaN(filter) && parseInt(`${filter}`) > 0
    },
    filter: (object, filter, objectType) => {
      const stars = object.properties.hotelStars
      if (stars === null) {
        return true
      }
      if (stars && !isNaN(stars)) {
        return stars >= filter
      }

      if (stars && isNaN(stars)) {
        const regEx = new RegExp(/\*{1,5}/)
        return regEx.test(stars) && stars.length >= filter
      }
      return false
    },
  },
  {
    key: 'units',
    conditional: (objectType, filter) => objectType === 'tenure',
    type: 'fct',
    filterIsSet: (filter) => {
      return (!!filter.from && !isNaN(filter.from)) || (!!filter.to && !isNaN(filter.to))
    },
    filter: (object, filter) => {
      return testRange(object.properties[filter.unitsKey ?? 'units'], filter)
    },
  },
  {
    key: 'beds',
    conditional: (objectType, filter, otherFilter) => {
      return objectType === 'tenure' && 'depends' in filter
        ? filter.depends.every((item) => {
            const fn = item.type === 'function' ? new Function(item.arguments, item.body) : () => {}
            return fn(otherFilter)
          })
        : true
    },
    type: 'fct',
    filterIsSet: (filter) => {
      return (!!filter.from && !isNaN(filter.from)) || (!!filter.to && !isNaN(filter.to))
    },
    filter: (object, filter, objectType) => {
      return testRange(object.properties[filter.bedsKey ?? 'beds'], filter)
    },
  },
  {
    key: 'daycareMarker',
    conditional: (objectType, filter) => objectType === 'tenure',
    type: 'fct',
    filterIsSet: (filter) => 'checked' in filter,
    filter: (object, filter) => {
      if (filter.checked === true) return object.properties.daycareMarker === 1
      return true
    },
  },
  {
    key: 'rooms',
    conditional: (objectType, filter) => objectType === 'tenure',
    type: 'fct',
    filterIsSet: (filter) => {
      return (filter.from && !isNaN(filter.from)) || (filter.to && !isNaN(filter.to))
    },
    filter: (object, filter, objectType) => {
      return testRange(object.properties.hotelRooms, filter)
    },
  },
  {
    key: 'hotelBrand',
    conditional: (objectType, filter) => objectType === 'tenure',
    type: 'fct',
    filterIsSet: (filter) => {
      return !!filter && filter.length
    },
    filter: (object, filter, objectType) => {
      return filter && filter.length && filter.includes(object.properties)
    },
  },
  {
    key: 'hotelTyp',
    conditional: (objectType, filter) => objectType === 'tenure',
    type: 'fct',
    filterIsSet: (filter) => {
      return !!filter && filter.length
    },
    filter: (object, filter, objectType) => {
      return filter && filter.length && filter.includes(object.properties.hotel_type)
    },
  },
  {
    key: 'yearRange',
    conditional: (objectType, filter) => {
      return objectType === 'tenure'
    },
    type: 'fct',
    filterIsSet: (filter) => {
      return filter.from || filter.to
    },
    filter: (object, filter, objectType = 'tenure', dateType = 'completed') => {
      const objectdate = object.properties[dateType]
      const objectYear = objectdate ? new Date(objectdate).getFullYear() : null
      return (
        (!!objectYear || (!filter.from && !objectYear)) &&
        (!filter.from || objectYear >= filter.from) &&
        (!filter.to || objectYear <= filter.to)
      )
    },
  },
  {
    key: 'tenure',
    conditional: (objectType, filter) => objectType === 'tenure',
    type: 'fct',
    filterIsSet: (tenureFilter) => {
      const globalFilter = tenureFilter.global
      return (
        Object.values(tenureFilter || {}).some((item) => item.active) ||
        (!!globalFilter &&
          Object.entries(globalFilter.filter || {}).some(([key, value]) => {
            const filter = objectFilterDef.find((item) => item.key === key.replace(/^overridable\./, ''))
            return !!filter?.filterIsSet && !!filter.filterIsSet(value)
          }))
      )
    },
    filter: (object, tenureFilter, objectType) => {
      const { global: globalSection, ...usesSection } = tenureFilter

      const isSectionFilterActive = Object.values(usesSection).some((item) => item.active === true)

      if (!isSectionFilterActive && globalSection && 'value' in globalSection) {
        return applyTenureGlobalFilter(object, globalSection.value)
      }

      return (
        (globalSection?.value ? applyTenureGlobalFilter(object, globalSection.value, false) : true) &&
        Object.entries(usesSection).reduce((res, [key, value]) => {
          return applyTenureFilter(object, key, value, globalSection.value, res)
        }, false)
      )
    },
  },
]

export function filterObjects({ filter, objects, objectType }) {
  let render = []
  let renderTable = []
  const fieldDef = objectFilterDef.filter((def) => {
    if (def.conditional) {
      return def.conditional(objectType, filter)
    } else {
      return true
    }
  })

  // prepare curFilters for usage in features-loop
  const curFilters = {}
  fieldDef.forEach((def) => {
    // all set filters should be arrays, for easier looping in case an array is really needed
    curFilters[def.key] = (!Array.isArray(filter[def.key]) ? [filter[def.key]] : [...filter[def.key]])
      .filter(
        (curFilter) =>
          curFilter &&
          ((def.type === 'string' && typeof curFilter === 'string' && curFilter.length) ||
            ((def.type === 'fct' || def.typeSub === 'fct') && def.filterIsSet(curFilter)))
      )
      .map((curFilter) => {
        // prepare filter for case insensitivity if needed
        if (
          def.type !== 'fct' &&
          def.typeSub !== 'fct' &&
          !def.caseSensitive &&
          typeof curFilter === 'string' &&
          curFilter.length
        ) {
          return curFilter.toLowerCase()
        } else if (typeof def.transformFilter === 'function') {
          return def.transformFilter(curFilter)
        } else {
          return curFilter
        }
      })
  })

  // filtering performance depends e.g. on order of filters definitions.
  // fastest filter should be defined first (in objectFilterDef), so more
  // complex filter functions will not be executed if object is filtered already.
  //
  // uncomment this and timeEnd for debugging filtering performance:
  // console.time('filteringObjects')
  objects.forEach((feature, index) => {
    let pushFeature = true
    const subsToBeFiltered = {}
    // fieldDef array: _all_ conditions should be fullfilled
    fieldDef.forEach((filterDef) => {
      const key = filterDef.key
      // curFilters array: at least one condition should be fullfilled
      let curFullfilled = null
      let curSubsToBeFiltered = {}

      curFilters[key].forEach((curFilter) => {
        if (pushFeature) {
          if (!curFullfilled) {
            if (filterDef.type === 'string') {
              if (curFullfilled === null) {
                curFullfilled = false
              }
              let prop = feature.properties[key]
              if (!filterDef.caseSensitive) {
                prop = typeof prop === 'string' ? prop.toLowerCase() : ''
              }
              curFullfilled = curFullfilled || prop.indexOf(curFilter) >= 0
            } else if (filterDef.type === 'fct') {
              if (curFullfilled === null) {
                curFullfilled = false
              }
              try {
                curFullfilled = curFullfilled || filterDef.filter(feature, curFilter, objectType)
              } catch (err) {
                console.error(err, { feature, key, filter: curFilter })
              }
            }
          }
          if (filterDef.typeSub === 'fct' && typeof feature.properties[objectType] !== 'undefined') {
            feature.properties[objectType].forEach((row, indexSub) => {
              if (typeof curSubsToBeFiltered[indexSub] === 'undefined') {
                curSubsToBeFiltered[indexSub] = true
              }
              try {
                if (curSubsToBeFiltered[indexSub] && filterDef.filterSub(row, curFilter, objectType)) {
                  curSubsToBeFiltered[indexSub] = false
                }
              } catch (err) {
                console.error(err, { feature, row, key, filter: curFilter })
              }
            })
          }
        }
      })
      if (pushFeature && curFullfilled !== false && typeof feature.properties[objectType] !== 'undefined') {
        Object.entries(curSubsToBeFiltered).forEach(([indexSub, filtered]) => {
          if (filtered) {
            subsToBeFiltered[indexSub] = true
          }
        })
        curFullfilled =
          Object.keys(subsToBeFiltered).length === feature.properties[objectType].length ? false : true
      }
      if (curFullfilled !== null) {
        pushFeature = curFullfilled
      }
    })
    if (pushFeature) {
      render.push({ index, id: feature.properties.id })
      if (typeof feature.properties[objectType] !== 'undefined') {
        feature.properties[objectType].forEach((row, rowIndex) => {
          if (!subsToBeFiltered[rowIndex]) {
            renderTable.push({
              objIndex: index,
              index: rowIndex,
              id: row.id,
              countryCode: feature.properties.countryCode,
            })
          }
        })
      } else {
        renderTable.push({
          objIndex: index,
          id: feature.properties.id,
          countryCode: feature.properties.countryCode,
        })
      }
    }
  })
  // console.timeEnd('filteringObjects')

  return { render, renderTable }
}
