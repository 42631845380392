import React, { forwardRef } from 'react'
import { styled } from 'styled-components'
import {
  background,
  border,
  color,
  compose,
  flexbox,
  layout,
  position,
  space,
  system,
  typography,
} from 'styled-system'

import { allowedProps } from '../allowedProps'

const StyledComponent = styled.div.withConfig({
  shouldForwardProp: (prop) => allowedProps.includes(prop),
})`
  box-sizing: border-box;
  margin: 0;
  ${compose(background, border, color, compose, flexbox, layout, position, space, system, typography)}
  ${system({
    cursor: true,
    textWrap: {
      property: 'textWrap',
    },
  })}
`

export const Box = ({ children, tag, ...rest }) => {
  return (
    <StyledComponent as={tag} {...rest}>
      {children}
    </StyledComponent>
  )
}

export const BoxForwardedRef = forwardRef(({ children, tag, ...rest }, ref) => {
  return (
    <StyledComponent ref={ref} as={tag} {...rest}>
      {children}
    </StyledComponent>
  )
})
