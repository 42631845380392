import createStore from 'lib/flux-store'

import {
  getMarketDataTopics,
  getScoringTopicData,
  getTopicDefinitionList,
  getTopicDefinitions,
} from './Cache'
import { marketDataInitialGroups } from 'config/marketDataTopics'

const initialState = {
  dataIsLoading: true,
  topicDefinitionList: null,
  topicDefinitions: null,
  scoringTopicDefinitions: null,
  availableTopicGroups: [],
}

const actions = {
  fetchInitialData:
    (language = 'de', countryCodes) =>
    (dispatch) => {
      dispatch({ type: 'setLoading', payload: true })
      Promise.all([getMarketDataTopics(), getTopicDefinitionList(language, countryCodes)]).then(
        ([marketDataTopicRes, topicDefinitionListRes]) => {
          const topicDefinitionList = countryCodes.reduce((obj, countryCode) => {
            topicDefinitionListRes[countryCode] = topicDefinitionListRes[countryCode].filter(
              ({ topicHeading, topicId, id }) => topicHeading !== null && topicId !== null && id !== null
            )
            topicDefinitionListRes[countryCode].sort((a, b) => a.topicHeading.localeCompare(b.topicHeading))
            obj[countryCode] = topicDefinitionListRes[countryCode].reduce(
              (letterObj, { topicId, topicHeading, id, forceVisibility, topicGroups, isStandalone }) => {
                if (topicHeading === null || typeof topicHeading === 'undefined' || !topicHeading.length) {
                  return letterObj
                }

                if (forceVisibility) {
                  marketDataTopicRes.push({
                    key: topicId,
                    topicGroups,
                  })
                }

                const found = marketDataTopicRes.find(({ key }) => {
                  const regexString = `\\b${key}\\b`
                  const regex = new RegExp(regexString)
                  return topicId.match(regex)
                })

                if (
                  found &&
                  (typeof found.dataSources === 'undefined' ||
                    !found.dataSources.some((dataSource) => !dataSource.source[countryCode]))
                ) {
                  const letter = topicHeading[0].toUpperCase()
                  if (typeof letterObj[letter] === 'undefined') {
                    letterObj[letter] = []
                  }

                  letterObj[letter].push({
                    topicId: found.key,
                    topicHeading,
                    id,
                    category: found.group,
                    topicGroups,
                    isStandalone,
                  })
                }
                return letterObj
              },
              {}
            )
            return obj
          }, {})

          const availableTopicGroups = Object.keys(marketDataInitialGroups)
          dispatch({
            type: 'setInitialData',
            topicDefinitionList,
            availableTopicGroups,
          })
        }
      )
    },
  fetchTopicDefinitions:
    (id = null, topicId, language = 'de', countryCodes, ignoreStandalone = false) =>
    (dispatch) => {
      getTopicDefinitions(id, topicId, language, countryCodes, ignoreStandalone).then((res) => {
        dispatch({ type: 'setTopicDefinitions', topicDefinitions: res })
      })
    },
  fetchScoringTopicDefinitions: () => (dispatch) => {
    getScoringTopicData().then((res) => {
      dispatch({ type: 'setScoringTopicDefinitions', scoringTopicDefinitions: res })
    })
  },
  setAvailableTopicGroups: (availableTopicGroups) => ({ availableTopicGroups }),
}

const reducer = {
  setLoading: (state, { payload }) => {
    return { ...state, payload, isFailure: false }
  },
  setFailure: (state, { payload }) => {
    return {
      ...state,
      dataIsLoading: false,
      isFailure: true,
      messages: payload,
    }
  },
  setInitialData: (state, { marketDataTopics, topicDefinitionList, availableTopicGroups }) => {
    return { ...state, marketDataTopics, topicDefinitionList, dataIsLoading: false, availableTopicGroups }
  },
  setTopicDefinitions: (state, { topicDefinitions }) => {
    return { ...state, topicDefinitions }
  },
  setScoringTopicDefinitions: (state, { scoringTopicDefinitions }) => {
    return { ...state, scoringTopicDefinitions }
  },
  setAvailableTopicGroups: (state, { availableTopicGroups }) => {
    return { ...state, availableTopicGroups }
  },
}

export const [GlossarDataContext, GlossarDataProvider, useGlossarDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'GlossarDataStore'
)
