import React from 'react'
import { styled } from 'styled-components'
import { PosAbsolute } from '@layout/BuildingBlocks'

const StyledComponent = styled.span`
  left: 6px;
  position: relative;
`

export const CardTitleButtonContainer = ({ children }) => (
  <StyledComponent>
    <PosAbsolute top="0">{children}</PosAbsolute>
  </StyledComponent>
)
