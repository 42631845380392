import React from 'react'
import { styled } from 'styled-components'
import { color, typography, space, compose } from 'styled-system'

import { defaultPropTypes as textPropTypes, typeScale, typeStyle } from '@typography'
import { string } from 'prop-types'

const excludeProps = [
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'tag',
  'scale',
  'typeScale',
  'typeStyle',
  'marginBottom',
]

const StyledCell = styled.td
  .attrs((props) => ({
    colSpan: props.span,
  }))
  .withConfig({
    shouldForwardProp: (props) => !excludeProps.includes(props),
  })`
  ${compose(color, typography, space)};
  ${(props) => typeScale(props.typeScale)};
  ${(props) => typeStyle(props.typeStyle)};
  span {
    padding: 0 2px 0;
  }
`

const fontSizeDefault = [0, 1, 2, 3, 4]

export const Cell = ({ align, span, text, typeScale = 'copy', typeStyle = 'copy' }) => (
  <StyledCell
    align={align}
    typeScale={typeScale}
    typeStyle={typeStyle}
    span={span}
    fontSize={fontSizeDefault}
    fontFamily="whitneySSm"
    lineHeight="normal"
  >
    <span>{text}</span>
  </StyledCell>
)

Cell.propTypes = {
  typeScale: string,
  typeStyle: string,
}
StyledCell.propTypes = {
  ...textPropTypes,
}
