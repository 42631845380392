import React, { forwardRef, useCallback, useRef, useEffect, useMemo } from 'react'
import { string, bool, number, oneOf } from 'prop-types'

import { usePositionObserver } from 'lib/hooks'

import { reposFlyout } from './util.js'

import { FlyoutContainer, FlyoutContainerWithTriangle } from './SubComponents'
import { BodyAnchor } from '@utilities/BodyAnchor'

export const Flyout = forwardRef(
  (
    {
      style,
      children,
      distance,
      refOpener,
      leafletMap,
      minWidth = null,
      maxWidth = null,
      width = null,
      active = true,
      renderTriangle = false,
      placement = 'bottomStart',
      centerInOpener = false,
      alignOffset = 0,
      triangleSize = 8,
    },
    ref
  ) => {
    distance = distance ?? (centerInOpener ? 0 : 10)
    const localRef = useRef(null)
    const localRefOpener = useRef(null)

    ref = ref || localRef
    refOpener = refOpener || localRefOpener

    const side = placement.replace(/(Start|End|Center)/, '')
    const align = placement.replace(side, '').toLowerCase()

    const Component = useMemo(
      () => (renderTriangle ? FlyoutContainerWithTriangle : FlyoutContainer),
      [renderTriangle]
    )

    // calculate distance and alignOffset
    const triangleSizeCalculated = renderTriangle ? triangleSize : 0
    const distanceCalculated = centerInOpener ? distance + triangleSizeCalculated : distance
    const alignOffsetCalculated = centerInOpener ? alignOffset - triangleSizeCalculated * 2 : alignOffset

    const onChangePosition = useCallback(
      (clientRect, inView) => {
        let targetRect
        if (centerInOpener) {
          const vertical = clientRect.top + clientRect.height / 2
          const horizontal = clientRect.left + clientRect.width / 2
          targetRect = {
            top: vertical,
            right: horizontal,
            bottom: vertical,
            left: horizontal,
            width: 0,
            height: 0,
          }
        } else {
          targetRect = clientRect
        }

        reposFlyout({
          targetRect,
          centerInOpener,
          flyout: ref.current,
          inView,
          side,
          align,
          alignOffset: alignOffsetCalculated,
          distance: distanceCalculated,
          propWidth: width,
          propMinWidth: minWidth,
          propMaxWidth: maxWidth,
        })
      },
      [side, align, centerInOpener, alignOffsetCalculated, distanceCalculated, ref, width, minWidth, maxWidth]
    )
    usePositionObserver({ elementRef: refOpener, onChange: onChangePosition, active, leafletMap })

    useEffect(() => {
      if (!refOpener.current) {
        const div = document.body.appendChild(document.createElement('div'))
        div.style.position = 'absolute'
        div.style.top = '0px'
        localRefOpener.current = div
      }
    }, [refOpener])

    // effect needed e.g. for storybook, where renderTriangle or distance can change dynamically
    useEffect(() => {
      if (active && refOpener.current) {
        onChangePosition(refOpener.current.getBoundingClientRect(), true)
      }
    }, [active, renderTriangle, triangleSizeCalculated, onChangePosition, refOpener])

    return (
      <BodyAnchor id="UtilitiesFlyout">
        <Component
          ref={ref}
          side={side}
          align={align}
          active={active}
          style={style}
          triangleSize={triangleSizeCalculated}
        >
          {active && children}
        </Component>
      </BodyAnchor>
    )
  }
)
Flyout.propTypes = {
  minWidth: string,
  maxWidth: string,
  width: string,
  active: bool,
  renderTriangle: bool,
  placement: oneOf([
    'topStart',
    'rightStart',
    'bottomStart',
    'leftStart',
    'topEnd',
    'rightEnd',
    'bottomEnd',
    'leftEnd',
  ]),
  centerInOpener: bool,
  distance: number,
  alignOffset: number,
  triangleSize: number,
}
