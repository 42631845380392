import { func, object, string } from 'prop-types'

const appearance = string
const direction = string
const icon = object
const iconPosition = object
const onClick = func
const onMouseLeave = func
const shape = string
const size = string

export { appearance, direction, icon, iconPosition, onClick, onMouseLeave, shape, size }
