import React, { forwardRef, useState, useEffect, useRef } from 'react'

import { StyledText } from './SubComponents'

import { defaultPropTypes as textPropTypes } from '@typography'

export const EllipsisText = forwardRef(({ typeStyle, typeScale, children, ...props }, ref) => {
  const content = useRef(null)
  const [isEllipsed, setIsEllipsed] = useState(false)

  useEffect(() => {
    if (content.current !== null) {
      setTimeout(() => {
        setIsEllipsed(content.current?.scrollWidth > content?.current?.offsetWidth)
      }, 1000)
    }
  }, [content])

  return (
    <StyledText typeScale={typeScale} typeStyle={typeStyle} ref={content} {...props}>
      <span>{children}</span>
      <span className={`mouseOverSelector ${isEllipsed ? 'is-ellipsed' : ''}`}>{children}</span>
    </StyledText>
  )
})
EllipsisText.propTypes = {
  ...textPropTypes,
}
