import React from 'react'

import { StyledText } from '../StyledText'
import { typeStyles } from '../typeStyles'

import { defaultPropTypes } from '../defaultPropTypes'

export const Pica = ({ children, tag, ...props }) => (
  <StyledText scale="pica" {...props} {...typeStyles.pica} as={tag}>
    {children}
  </StyledText>
)

export const PicaLight = ({ children, tag, ...props }) => (
  <StyledText scale="pica" {...props} {...typeStyles.picaLight} as={tag}>
    {children}
  </StyledText>
)

export const PicaItalic = ({ children, tag, ...props }) => (
  <StyledText scale="pica" {...props} {...typeStyles.picaItalic} as={tag}>
    {children}
  </StyledText>
)

export const PicaMedium = ({ children, tag, ...props }) => (
  <StyledText scale="pica" {...props} {...typeStyles.picaMedium} as={tag}>
    {children}
  </StyledText>
)

export const PicaSemiBold = ({ children, tag, ...props }) => (
  <StyledText scale="pica" {...props} {...typeStyles.picaSemiBold} as={tag}>
    {children}
  </StyledText>
)

export const PicaBlack = ({ children, tag, ...props }) => (
  <StyledText scale="pica" {...props} {...typeStyles.picaBlack} as={tag}>
    {children}
  </StyledText>
)
Pica.propTypes = defaultPropTypes
PicaLight.propTypes = defaultPropTypes
PicaItalic.propTypes = defaultPropTypes
PicaMedium.propTypes = defaultPropTypes
PicaSemiBold.propTypes = defaultPropTypes
PicaBlack.propTypes = defaultPropTypes
