import React, { forwardRef, useCallback } from 'react'
import { styled, useTheme } from 'styled-components'
import { typography } from 'styled-system'
import { bool, string } from 'prop-types'

import { HTMLAnchorElementWithIconAndText, ReactRouterDomLinkWithIconAndText } from '../SubComponents'
import { IconWrapper } from '@utilities/IconWrapper'

import { getThemeValue } from 'theming'
import { appearance, shape, size } from './variants'
import { typeMap, typeScale, typeStyle } from '@typography'

const StyledComponent = styled(HTMLAnchorElementWithIconAndText)`
  align-self: center;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  ${appearance}
  ${shape}
  ${(props) => props.shape !== 'shapeless' && size}
  ${(props) => props.width && { width: props.width }}
  ${(props) => props.height && { height: props.height }}
  ${(props) => props.stretch && { width: '100%' }}
  ${(props) => props.leftAlign && { justifyContent: 'flex-start' }}
`

const fontSizeDefault = [0, 1, 2, 3, 4]

export const ButtonLinkWithIcon = forwardRef(
  (
    {
      children,
      wrappedContent,
      fontFamily = 'whitneySSm',
      fontSize = fontSizeDefault,
      lineHeight = 'normal',
      appearance = 'lightgray',
      href = null,
      iconAlign = 'left',
      iconVariantSourceName = 'buttonIcons',
      leftAlign = null,
      linkType = 'htmlAnchorElement',
      shape = 'oval',
      size = 'medium',
      stretch = null,
      to = '/',
      ...props
    },
    ref
  ) => {
    props = {
      ...props,
      fontFamily,
      fontSize,
      lineHeight,
      appearance,
      href,
      iconAlign,
      iconVariantSourceName,
      leftAlign,
      linkType,
      shape,
      size,
      stretch,
      to,
    }
    const theme = useTheme()
    const iconSpaceX = getThemeValue(theme, 'sizes.icons.button.space.x')
    const { typeStyle, typeScale } = typeMap[props.size]

    const attachIconMargin = useCallback(
      (direction) => {
        const mr = props.iconAlign === 'left' ? iconSpaceX : null
        const ml = props.iconAlign === 'right' ? iconSpaceX : null

        return direction === 'right' ? mr : ml
      },
      [props.iconAlign, iconSpaceX]
    )
    return props.linkType === 'htmlAnchorElement' ? (
      <>
        {wrappedContent && wrappedContent}
        <StyledComponent typeScale={typeScale} typeStyle={typeStyle} ref={ref} {...props}>
          <IconWrapper
            icon={props.icon}
            iconPosition={props.iconPosition}
            iconWrapper={props.iconWrapper}
            iconVariantSourceName={props.iconVariantSourceName}
            height={props.iconHeight}
            ml={attachIconMargin('left')}
            mr={attachIconMargin('right')}
            size={props.size}
            width={props.iconWidth}
          />
        </StyledComponent>
      </>
    ) : (
      <StyledComponent
        as={ReactRouterDomLinkWithIconAndText}
        typeScale={typeScale}
        typeStyle={typeStyle}
        ref={ref}
        {...props}
      >
        <IconWrapper
          icon={props.icon}
          iconPosition={props.iconPosition}
          iconWrapper={props.iconWrapper}
          iconVariantSourceName={props.iconVariantSourceName}
          height={props.iconHeight}
          ml={attachIconMargin('left')}
          mr={attachIconMargin('right')}
          size={props.size}
          width={props.iconWidth}
        />
      </StyledComponent>
    )
  }
)
ButtonLinkWithIcon.propTypes = {
  appearance: string,
  href: string,
  iconAlign: string,
  iconVariantSourceName: string,
  leftAlign: bool,
  linkType: string,
  shape: string,
  size: string,
  to: string,
  stretch: bool,
}
