import React from 'react'
import { styled, useTheme } from 'styled-components'
import { typography } from 'styled-system'
import { typeScale, typeStyle } from '@typography'
import { getThemeValue } from 'theming'

const fontSizeDefault = [0, 1, 2, 3, 4]

const textProps = {
  fontFamily: 'whitneySSm',
  fontSize: fontSizeDefault,
  lineHeight: 'normal',
}

const excludeProps = [
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'typeScale',
  'typeStyle',
  'spaceRight',
]

const BadgeWrapper = styled.span.withConfig({
  shouldForwardProp: (prop) => !excludeProps.includes(prop),
})`
  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  margin-right: ${(props) => props.spaceRight}
`

const BadgeStyled = styled.span.withConfig({
  shouldForwardProp: (props) => !['height', 'width', 'coloring'].includes(props),
})`
  background-color: ${(props) => props.coloring};
  border: 1px solid;
  border-color: ${(props) => props.coloring};
  border-radius: 50px;
  box-shadow: ${(props) => `0 0 1px 1px ${props.coloring}`};
  box-sizing: content-box;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`

export const Badge = ({ children, coloring, checked, disabled, height, typeScale, width }) => {
  const theme = useTheme()
  const spaceRight = getThemeValue(theme, 'spaces.rhythm.horizontal.small')
  return (
    <BadgeWrapper spaceRight={spaceRight} typeScale={typeScale} {...textProps}>
      <BadgeStyled
        checked={checked}
        coloring={coloring}
        disabled={disabled}
        height={`${height}em`}
        width={`${width}em`}
      >
        {children}
      </BadgeStyled>
    </BadgeWrapper>
  )
}
