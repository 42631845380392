import React, { memo, useEffect, useCallback, useMemo, useState, useRef } from 'react'

import { useDebouncedUpdates, useControllableState } from 'lib/hooks'
import { isFunction } from 'lib/util'

import { FormElement, withFormElementProps, Input } from '@forms'

import { dateStringToIso, dateStringToInput } from './util'

import { defaultPropTypes } from './defaultPropTypes'

const groupStateToValue = (state) => {
  return state.value.date.value.replace(/[^-/0-9.]+/g, '')
}

const valueToGroupState = (value, dateInputValue) => {
  return {
    internalValue: { value },
    date: { value: dateInputValue },
  }
}

export const InputDateComponent = ({
  name = 'InputDateComponent',
  defaultValue = null,
  debounce = null,
  appearance = 'ghost',
  size = 'small',
  formElementRefs,
  onChange,
  value,
  stretch,
  ...props
}) => {
  const [initialValue] = useState(defaultValue ?? value ?? '')

  const onDebouncedUpdate = useCallback(
    (value) => {
      isFunction(onChange) && onChange({ id: props.id, name, value })
    },
    [onChange, props.id, name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [internalValue, setInternalValue] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const onReset = useCallback(() => {
    setInternalValue(initialValue)
  }, [setInternalValue, initialValue])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = internalValue
    }
  }, [internalValue, formElementRefs])

  const [dateInputValue, setDateInputValue] = useState(dateStringToInput(internalValue))

  const internalOnChange = useCallback((target) => {
    setDateInputValue(groupStateToValue(target))
  }, [])

  const internalValueChanged = useRef(false)

  useEffect(() => {
    setDateInputValue((prev) => {
      const prevIso = dateStringToIso(prev)
      if (prevIso !== internalValue) {
        internalValueChanged.current = true
        return dateStringToInput(internalValue)
      }
      return prev
    })
  }, [internalValue])

  useEffect(() => {
    if (internalValueChanged.current) {
      internalValueChanged.current = false
      return
    }
    setInternalValue((prev) => {
      const next = dateStringToIso(dateInputValue)
      if (next !== dateStringToIso(prev)) {
        return next
      }
      return prev
    })
  }, [dateInputValue, setInternalValue])

  const formState = useMemo(
    () => valueToGroupState(internalValue, dateInputValue),
    [internalValue, dateInputValue]
  )

  return (
    <FormElement {...props} name={name} value={formState} onChange={internalOnChange}>
      <Input
        forElementName={name}
        name="date"
        placeholder="DD.MM.YYYY"
        appearance={appearance}
        size={size}
        width="100%"
        {...props}
      />
    </FormElement>
  )
}

InputDateComponent.type = 'InputDate'
InputDateComponent.propTypes = defaultPropTypes

export const InputDate = withFormElementProps(memo(InputDateComponent))
