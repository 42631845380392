import React, { useState, useCallback, useEffect } from 'react'
import { useTheme } from 'styled-components'

import { CardHeader, CardRimlessHeader, CardBody, CardRim, CardContainer } from './SubComponents'
import { useSlots, useSlotsAsArray } from 'lib/hooks/useSlots'

import { getThemeValue } from 'theming'

import { bool } from 'prop-types'
import { Box, PosAbsolute, PosRelative } from '@layout/BuildingBlocks'

const CardHeaderTab = ({ children, index }) => {
  const [title, titleButton] = useSlots(['title', 'titleButton'], children)

  return (
    <CardHeader.Tab index={index}>
      {title && <CardHeader.Title>{title}</CardHeader.Title>}
      {titleButton && <CardHeader.TitleButton>{titleButton}</CardHeader.TitleButton>}
    </CardHeader.Tab>
  )
}
CardHeaderTab.displayName = 'tab'

export const CardComponent = ({
  children,
  expandHeader,
  expandBody = false,
  headlineLarge,
  highlighted,
  id,
  initialTabIndex,
  forceTabIndex,
  onTabChange,
  rimless,
  padding,
  noBackground,
  titleGroupFlex,
}) => {
  const theme = useTheme()
  const [headers] = useSlotsAsArray(['header'], children)
  const hasTabs = headers?.length > 1

  const [cardBodies] = useSlotsAsArray(['cardBody'], children)
  const [title, titleButton, subTitle, buttonBar, addOn, headerEnd] = useSlots(
    ['title', 'titleButton', 'subTitle', 'buttonBar', 'addOn', 'headerEnd'],
    hasTabs ? null : headers[0]?.props.children
  )

  const [tabIndex, setTabIndex] = useState(initialTabIndex ?? (hasTabs ? headers[0].props?.index : ''))

  const px = getThemeValue(theme, 'card.space.x')
  const pb = getThemeValue(theme, 'card.space.bottom')
  const pt = getThemeValue(theme, 'card.space.top')

  const setIndex = useCallback(
    (index) => {
      setTabIndex(index)
      if (typeof onTabChange === 'function') {
        onTabChange(index)
      }
    },
    [onTabChange]
  )

  useEffect(() => {
    setIndex(initialTabIndex)
  }, [initialTabIndex, setIndex])
  return (
    <CardContainer
      id={id}
      rimless={rimless}
      highlighted={highlighted}
      padding={padding}
      noBackground={noBackground}
    >
      {headers.length > 0 && !hasTabs && !headlineLarge && (
        <CardHeader
          titleGroupFlex={headers[0].props.titleGroupFlex}
          px={expandHeader ? null : px}
          flexWrap={headers[0].props.flexWrap}
        >
          {title && <CardHeader.Title>{title}</CardHeader.Title>}
          {titleButton && <CardHeader.TitleButton>{titleButton}</CardHeader.TitleButton>}
          {buttonBar && (
            <CardHeader.ButtonBar
              flex={buttonBar.props.flex}
              stretch={buttonBar.props.stretch}
              alignSelf={buttonBar.props.alignSelf}
              alignItems={buttonBar.props.alignItems}
            >
              {buttonBar}
            </CardHeader.ButtonBar>
          )}
          {addOn && <CardHeader.AddOn>{addOn}</CardHeader.AddOn>}
          {subTitle && <CardHeader.SubTitle>{subTitle}</CardHeader.SubTitle>}
          {headerEnd && <CardHeader.HeaderEnd>{headerEnd}</CardHeader.HeaderEnd>}
        </CardHeader>
      )}
      {headers.length > 0 && !hasTabs && headlineLarge && (
        <CardRimlessHeader
          titleGroupFlex={headers[0].props.titleGroupFlex}
          px={expandHeader ? null : px}
          flexWrap={headers[0].props.flexWrap}
        >
          {title && <CardRimlessHeader.Title>{title}</CardRimlessHeader.Title>}
          {titleButton && <CardRimlessHeader.TitleButton>{titleButton}</CardRimlessHeader.TitleButton>}
          {buttonBar && (
            <CardRimlessHeader.ButtonBar
              flex={buttonBar.props.flex}
              stretch={buttonBar.props.stretch}
              alignSelf={buttonBar.props.alignSelf}
              alignItems={buttonBar.props.alignItems}
            >
              {buttonBar}
            </CardRimlessHeader.ButtonBar>
          )}
          {headerEnd && <CardRimlessHeader.HeaderEnd>{headerEnd}</CardRimlessHeader.HeaderEnd>}
        </CardRimlessHeader>
      )}
      {hasTabs && (
        <CardHeader titleGroupFlex={titleGroupFlex} px={expandHeader ? null : px}>
          {headers.map((tab) => (
            <CardHeaderTab
              titleGroupFlex={tab.props.titleGroupFlex}
              key={tab.props.index}
              active={tab.props.index === tabIndex}
              setIndex={setIndex}
              {...tab.props}
            >
              {tab?.props?.children}
            </CardHeaderTab>
          ))}
          {buttonBar && (
            <CardHeader.ButtonBar
              flex={buttonBar.props.flex}
              stretch={buttonBar.props.stretch}
              alignSelf={buttonBar.props.alignSelf}
              alignItems={buttonBar.props.alignItems}
            >
              {buttonBar}
            </CardHeader.ButtonBar>
          )}
        </CardHeader>
      )}
      <CardBody>
        {cardBodies.map((cardBody, index) => {
          if (!!cardBody.props.tabIndex && cardBody.props.tabIndex !== tabIndex) {
            return null
          }
          const expand = cardBody?.props?.expand ?? expandBody
          const flex = cardBody?.props?.flex ?? 'auto'
          return (
            <CardBody.Section
              key={index}
              flex={flex}
              px={expand ? null : px}
              pb={expand ? null : index < cardBodies.length - 1 ? pt : pb}
              pt={headers.length || index > 0 ? pt : null}
            >
              {cardBody}
            </CardBody.Section>
          )
        })}
      </CardBody>
    </CardContainer>
  )
}

const withRim =
  (CardComponent) =>
  ({ children, highlighted, id, ...props }) => {
    return (
      props.visible !== false && (
        <CardRim highlighted={highlighted} id={id}>
          <CardComponent highlighted={highlighted} {...props}>
            {children}
          </CardComponent>
        </CardRim>
      )
    )
  }

const RimlessHighlighWrapper = ({ children }) => {
  const theme = useTheme()
  return (
    <PosRelative>
      {children}
      <PosAbsolute left="0" right="0" top="0" bottom="0" style={{ pointerEvents: 'none' }}>
        <Box
          borderRadius="20px"
          height="100%"
          width="100%"
          border={getThemeValue(theme, 'card.borderRimless.highlighted.border')}
          borderColor={getThemeValue(theme, 'card.colors.rim.highlighted.color')}
        >
          <Box
            style={{ boxShadow: getThemeValue(theme, 'card.colors.rim.highlighted.shadow') }}
            borderRadius={getThemeValue(theme, 'card.borderRimless.highlighted.radius')}
            width="100%"
            height="100%"
          ></Box>
        </Box>
      </PosAbsolute>
    </PosRelative>
  )
}

const withoutRim =
  (CardComponent) =>
  ({ children, highlighted, ...props }) => {
    if (props.visible !== false && !highlighted) {
      return (
        <CardComponent {...props} rimless>
          {children}
        </CardComponent>
      )
    } else if (props.visible !== false && highlighted) {
      return (
        <RimlessHighlighWrapper>
          <CardComponent {...props} rimless>
            {children}
          </CardComponent>
        </RimlessHighlighWrapper>
      )
    }
    return null
  }

const withoutRimHeadlineLarge =
  (CardComponent) =>
  ({ children, ...props }) => {
    return (
      props.visible !== false && (
        <CardComponent {...props} headlineLarge rimless>
          {children}
        </CardComponent>
      )
    )
  }

const withoutBackground =
  (CardComponent) =>
  ({ children, ...props }) => {
    return (
      props.visible !== false && (
        <CardComponent {...props} noBackground={true}>
          {children}
        </CardComponent>
      )
    )
  }

export const Card = withRim(CardComponent)

export const CardRimless = withoutRim(CardComponent)

export const CardRimlessHeadlineLarge = withoutRimHeadlineLarge(CardComponent)

export const CardWithoutBackground = withoutBackground(CardComponent)

Card.AddOn = ({ children }) => children
Card.AddOn.displayName = 'addOn'

Card.Body = ({ children }) => children
Card.Body.displayName = 'cardBody'

Card.ButtonBar = ({ children }) => children
Card.ButtonBar.displayName = 'buttonBar'

Card.Header = ({ children }) => children
Card.Header.displayName = 'header'

Card.HeaderEnd = ({ children }) => children
Card.HeaderEnd.displayName = 'headerEnd'

Card.SubTitle = ({ children }) => children
Card.SubTitle.displayName = 'subTitle'

Card.Title = ({ children }) => children
Card.Title.displayName = 'title'

Card.TitleButton = ({ children }) => children
Card.TitleButton.displayName = 'titleButton'
Card.propTypes = {
  expandBody: bool,
}
