import { lighten, desaturate } from 'polished'
import bgagColors from 'config/colors/bgag-colors.json'

export const segments = ['office', 'commercial', 'residential', 'retailPeriodic', 'retailAperiodic']
export const extraSegments = ['hotel', 'microLiving', 'seniorAssistedLiving', 'seniorNursing']
export const segmentLicenceMapping = {
  office: 'office',
  commercial: 'commercial',
  residential: 'residential',
  retailPeriodic: 'retail',
  retailAperiodic: 'retail',
  hotel: 'hotel',
  microLiving: 'microLiving',
  seniorAssistedLiving: 'seniorLiving',
  seniorNursing: 'seniorLiving',
}

export const subsegments = [
  'retailPeriodic',
  'retailAperiodic',
  'microLiving',
  'seniorAssistedLiving',
  'seniorNursing',
]

export const assetClasses = segments.concat(extraSegments)

export const availableCluster = ['demography', 'economy', 'infrastructure', 'market']

export const getClusterTopics = (scores, options = {}) => {
  if (!scores.length) {
    return []
  }
  const clusters = Object.entries(scores[0].score)
    .filter(([key, value]) => value !== null && (options.includeTotal || key !== 'overall'))
    .map(([key, value]) => key)
    .toSorted((a, b) => {
      return availableCluster.indexOf(a) - availableCluster.indexOf(b)
    })
  return clusters
}

const clusterColors = {
  demography: {
    main: '#F8AA60',
    light: '#F7C89C',
    scaleBase: 'economy',
    lightenOnDesaturate: 0.09,
  },
  economy: {
    main: '#D46024',
    light: '#E19C78',
    lightenOnDesaturate: 0.15,
  },
  infrastructure: {
    main: '#70C4E3',
    light: '#B5DCE8',
    scaleBase: 'market',
    lightenOnDesaturate: 0.09,
  },
  market: {
    main: '#2F9DCC',
    light: '#7EC0DD',
    lightenOnDesaturate: 0.15,
  },
  overall: {
    main: '#003F6D',
    light: '#5C829D',
    scaleLighten: 0.16,
  },
}

Object.entries(clusterColors).forEach(([topic, values]) => {
  const { scaleBase = null, scaleLighten = null } = values
  values.gray = desaturate(0.3, lighten(values.lightenOnDesaturate ?? 0.015, values.light))
  values.transparent = values.main + '20'
  values.scale = new Array(5)
    .fill(null)
    .reduce((scale, _, idx) => {
      if (!scale.length) {
        scale.push(scaleBase ? clusterColors[scaleBase].main : values.main)
      } else {
        scale.push(lighten(scaleLighten ? scaleLighten : 0.1, scale.at(-1)))
      }
      return scale
    }, [])
    .reverse()
})

export function getColor(topic, variant = 'main') {
  return clusterColors[topic][variant]
}

const assetClassColors = {
  office: bgagColors['bgag-blue'].main,
  retailPeriodic: bgagColors['bgag-darkgreen'].main,
  retailAperiodic: bgagColors['bgag-lightgreen'].main,
  commercial: bgagColors['bgag-lightblue'].main,
  logistic: bgagColors['bgag-orange'].main,
  hotel: bgagColors['bgag-violet'].main,
  residential: bgagColors['bgag-yellow'].main,
  seniorAssistedLiving: bgagColors['bgag-yellow'].dark,
  microLiving: bgagColors['bgag-orange'].dark,
  seniorNursing: bgagColors['bgag-orange'].main,
}

export function getAssetClassColor(topic) {
  return assetClassColors[topic]
}

export function filterLicencedSegments(availableSegments, hasLicence) {
  return availableSegments.filter(
    (assetClass) =>
      availableSegments.includes(assetClass) && hasLicence(segmentLicenceMapping[assetClass], 'scoring')
  )
}
