import React, { useState, useRef } from 'react'
import { styled } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { ButtonIcon } from '@ui/Buttons'
import { BudiconChatInformation } from 'bgag-budicons'
import { Flyout } from '@utilities/Flyout'

export const ControlsInformationHover = ({ children, width, ...props }) => {
  const [isHovered, setHovered] = useState(false)
  const opener = useRef()
  return (
    <div>
      <ButtonIcon
        appearance="bare"
        shape="shapeless"
        size="tiny"
        icon={{ Icon: BudiconChatInformation, type: 'outline' }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        {...props}
        ref={opener}
      />
      <Flyout refOpener={opener} active={isHovered}>
        <InformationStyle width={width}>{children}</InformationStyle>
      </Flyout>
    </div>
  )
}

const InformationStyle = styled.div`
  width: ${({ width = '300px' }) => width};
  background: white;
  /*border: 1px solid black;*/
  padding: 8px;
  word-wrap: break-word;
  color: ${themeGet('colors.base.gray.900')};
`
