import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { styled } from 'styled-components'
import { color, compose, space, typography } from 'styled-system'
import { useTranslation } from 'react-i18next'

import { Button } from '@ui/Buttons'
import { GridContainer, GridItem } from '@layout/Grid'

import { TagListModalView } from '@forms/TagList/TagListModalView'
import { FormElement, withFormElementProps } from '@forms'
import SelectedTags from '@forms/TagList/SelectedTags'

import { useControllableState, useDebouncedUpdates } from 'lib/hooks'
import { isFunction } from 'lib/util'
import { appearanceVariants, borderVariants } from '@forms/TagList/variants'
import { array, bool, object, oneOfType, string } from 'prop-types'
import { TagListSimpleView } from '@forms/TagList/TagListSimpleView'
import { ScrollContainer } from '@layout/Layout/SubComponents/ScrollContainer'

const excludeProps = [
  'borderAppearance',
  'alignment',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'scale',
  'typeScale',
  'typeStyle',
]

const groupStateToValue = (state) => {
  return {
    value: state.value,
  }
}

const valueToGroupState = (value) => {
  return { value }
}

const emptyArrayEntries = []
const emptyArrayValue = []

const TagListComponent = ({
  name = 'TagListComponent',
  appearance = 'gray',
  view = 'useModal',
  disabled = false,
  entries = emptyArrayEntries,
  value = emptyArrayValue,
  defaultValue,
  formElementRefs,
  onChange,
  title,
  selectionsProps = { minRows: 1, maxRows: 4, rowHeight: 25 },
  tagsProps = { minRows: 2, maxRows: 4, rowHeight: 25 },
  selectedItemsTitle = null,
  suggestionsProps = { minRows: 4, rowHeight: 44 },
  ...props
}) => {
  const [initialValue] = useState([...(defaultValue || value || [])])
  const { t } = useTranslation()
  const onDebouncedUpdate = useCallback(
    (value) => {
      isFunction(onChange) && onChange({ id: props.id, name, value })
    },
    [onChange, props.id, name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    null
  )

  const [internValue, setInternValue] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )
  const selectedTagsRef = useRef()

  const formState = useMemo(() => valueToGroupState(internValue), [internValue])

  /**
   * Compute height for selected Tags Section
   * @type {number}
   */
  const selectedTagsHeight = useMemo(() => {
    const maxHeight = selectionsProps?.maxRows * selectionsProps?.rowHeight
    if (!value.length) {
      return 0
    }
    return maxHeight
  }, [selectionsProps, value])

  const onValueChange = useCallback(
    (val) => {
      setInternValue(val)
    },
    [setInternValue]
  )

  const onReset = useCallback(() => {
    setInternValue(initialValue)
  }, [setInternValue, initialValue])

  const onRemove = useCallback(
    (val) => {
      setInternValue(internValue.filter((item) => item !== val))
    },
    [internValue, setInternValue]
  )

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  const internalOnChange = useCallback(
    (target) => {
      const nextState = { ...target }
      nextState.value = { value: null }
      setInternValue(groupStateToValue(nextState))
    },
    [setInternValue]
  )
  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = internValue
    }
  }, [internValue, formElementRefs, onChange, name, props])

  return (
    <FormElement {...props} name={name} value={formState} onChange={internalOnChange}>
      {view === 'noModal' && (
        <TagListSimpleView
          noModal={view === 'noModal'}
          name={name}
          value={value}
          title={title || null}
          entries={entries}
          onRemove={onRemove}
          onChange={onValueChange}
          appearance={appearance}
          suggestionsProps={suggestionsProps}
          tagsProps={tagsProps}
          selectedItemsTitle={selectedItemsTitle}
          wrapperHeight={props?.wrapperHeight}
        />
      )}
      {view === 'useModal' && (
        <StyledComponent gridTemplateColumns={1} gridTemplateRows={`min-content auto`} height="100%">
          <GridItem gridColumn={'_1_1'}>
            <ScrollContainer overflowX="hidden" width="100%" maxHeight={selectedTagsHeight}>
              <SelectedTags
                ref={selectedTagsRef}
                value={value}
                entries={entries}
                onRemove={onRemove}
                disabled={disabled}
                appearance={appearance}
                {...selectionsProps}
              />
            </ScrollContainer>
          </GridItem>
          <GridItem gridColumn={'_1_1'}>
            <GridContainer gridTemplateColumns={2} gridColumnGap="16px">
              <GridItem gridColumn={'_1_1'}>
                <TagListModalView
                  disabled={disabled}
                  entries={entries}
                  modalTitle={title || null}
                  onChange={onValueChange}
                  onRemove={onRemove}
                  onReset={onReset}
                  value={internValue}
                  suggestionsProps={suggestionsProps}
                  tagsProps={tagsProps}
                  selectedItemsTitle={selectedItemsTitle}
                  appearance={appearance}
                />
              </GridItem>
              <GridItem gridColumn={'_2_1'}>
                <Button
                  onClick={onReset}
                  appearance="dark"
                  text={t('reset')}
                  type="button"
                  shape="oval"
                  size="tiny"
                  stretch={true}
                  disabled={disabled}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </StyledComponent>
      )}
    </FormElement>
  )
}

TagListComponent.type = 'TagList'
TagListComponent.propTypes = {
  title: string,
  name: string,
  appearance: string,
  view: string,
  disabled: bool,
  entries: array,
  value: oneOfType([array, string]),
  suggestionsProps: object,
  tagsProps: object,
}

export const TagList = withFormElementProps(memo(TagListComponent))

const StyledComponent = styled(GridContainer).withConfig({
  shouldForwardProp: (props) => !excludeProps.includes(props),
})`
  ${borderVariants}
  ${appearanceVariants}
  ${compose(color, space, typography)}
`
