import React from 'react'

import { DropdownMenu } from '@ui/DropdownMenu'
import { BudiconEqualizerA } from 'bgag-budicons'
import { useAuthzChecks } from 'lib/hooks/useAuthzChecks'
import { useTranslation } from 'react-i18next'

export const DropdownMenuSystemAdministration = ({ iconColor }) => {
  const { hasRouteAccess } = useAuthzChecks()
  const { t } = useTranslation()

  let entries = []

  const addItems = (items) => {
    items.forEach((item) => {
      entries.push(
        hasRouteAccess(item.route) && {
          label: item.label,
          to: item.route,
        }
      )
    })
  }

  addItems([
    {
      label: t('modules.translations.name'),
      route: '/sysadmin/i18n',
    },
    {
      label: t('modules.errorLog.name'),
      route: '/sysadmin/error-log',
    },
    {
      label: t('modules.matviews.name'),
      route: '/sysadmin/matviews',
    },
    {
      label: t('modules.reports.name'),
      route: '/sysadmin/reports',
    },
    {
      label: t('modules.mapEditor.name'),
      route: '/sysadmin/map-editor',
    },
    {
      label: t('modules.statistics.name'),
      route: '/statistics',
    },
  ])

  entries = entries.filter((item) => item !== false)

  return (
    entries.length > 0 && (
      <DropdownMenu
        icon={{
          icon: {
            Icon: BudiconEqualizerA,
            stroke: iconColor,
            strokeWidth: 1,
            width: ['14px', null, '16px', null, '19px'],
          },
          iconPosition: { top: ['-9px', null, '-9px', null, '-11px'] },
        }}
        pacement="bottomEnd"
        items={entries}
      />
    )
  )
}
