import React, { forwardRef } from 'react'
import { useTheme } from 'styled-components'

import { FlyoutOpener, NavigationLabel } from '../../SubComponents'
import { FlexRowForwardedRef } from '@layout/BuildingBlocks'
import { getThemeValue } from 'theming'

import { label } from './defaultPropTypes'

export const NavigationBadge = forwardRef(({ label = 'NavigationMain', onClick, isOpen }, ref) => {
  const theme = useTheme()
  const bagdeHeight = getThemeValue(theme, 'navigationMain.badge.height')

  return (
    <FlexRowForwardedRef ref={ref} height={bagdeHeight} ml="18px">
      <FlyoutOpener isOpen={isOpen} onClick={onClick} />
      {label && <NavigationLabel isOpen={isOpen} label={label} onClick={onClick} />}
    </FlexRowForwardedRef>
  )
})
NavigationBadge.propTypes = {
  label,
}
