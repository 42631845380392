import React from 'react'
import { styled, useTheme } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { CardTitleContainer, CardButtonBar } from '..'
import { FlexColumn, FlexItem, FlexRow } from '@layout/BuildingBlocks'

import { useSlots } from 'lib/hooks/useSlots'
import { getThemeValue } from 'theming'

const Container = styled(FlexColumn)`
  padding-top: ${themeGet('card.header.space.top', '0')};
  padding-bottom: ${themeGet('card.header.space.bottom', '0')};
  align-items: flex-start;
`

export const CardRimlessHeader = ({ children, px, titleGroupFlex, borderRadius, flexWrap = 'wrap' }) => {
  const theme = useTheme()

  const [title, titleButton, buttonBar, headerEnd] = useSlots(
    ['title', 'titleButton', 'buttonBar', 'headerEnd'],
    children,
    true
  )
  const card = getThemeValue(theme, 'card')
  const titleColor = card.colors.header.color
  const buttonBarX = card.buttonBar.space.right

  const pl = px
  const pr = buttonBar ? buttonBarX : px

  return (
    <Container>
      <FlexRow
        pl={pl}
        pr={pr}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="100%"
        flexWrap={flexWrap}
        flexColumnGap={card.header.columnGap}
      >
        <CardTitleContainer
          flex={titleGroupFlex}
          title={title}
          color={titleColor}
          titleButton={titleButton}
          type="large"
        />
        {buttonBar && (
          <FlexItem flex="1" justifyContent="flex-end" alignSelf={buttonBar.props.alignSelf}>
            <CardButtonBar
              isRimlessHeader={true}
              stretch={buttonBar.props.stretch}
              alignItems={buttonBar.props.alignItems}
            >
              {buttonBar}
            </CardButtonBar>
          </FlexItem>
        )}
        {headerEnd && headerEnd}
      </FlexRow>
    </Container>
  )
}

CardRimlessHeader.Title = ({ children }) => children
CardRimlessHeader.Title.displayName = 'title'

CardRimlessHeader.TitleButton = ({ children }) => children
CardRimlessHeader.TitleButton.displayName = 'titleButton'

CardRimlessHeader.ButtonBar = ({ children }) => children
CardRimlessHeader.ButtonBar.displayName = 'buttonBar'

CardRimlessHeader.HeaderEnd = ({ children }) => children
CardRimlessHeader.HeaderEnd.displayName = 'headerEnd'
