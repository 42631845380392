import React, { memo } from 'react'
import { styled } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { layout } from 'styled-system'
import { bool } from 'prop-types'

const StyledComponent = styled.main.withConfig({
  shouldForwardProp: (prop) => ['style', 'children', 'id'].includes(prop),
})`
  padding-top: ${(props) =>
    props.fullscreen ? 0 : props.hasMainPaddingTop ? themeGet('layout.areaMain.pt') : 0};
  padding-bottom: ${(props) =>
    props.fullscreen ? 0 : props.hasMainPaddingBottom ? themeGet('layout.areaMain.pb') : 0};
  padding-right: ${(props) =>
    props.fullscreen ? 0 : props.hasMainPaddingRight ? themeGet('layout.areaMain.pr') : 0};
  padding-left: ${(props) =>
    props.fullscreen ? 0 : props.hasMainPaddingLeft ? themeGet('layout.mainStage.ml') : 0};
  width: 100%;
  ${layout}
`

export const MainStage = memo(
  ({
    children,
    hasMainPaddingTop = false,
    hasMainPaddingRight = false,
    hasMainPaddingBottom = false,
    hasMainPaddingLeft = false,
    fullscreen = false,
  }) => {
    return (
      <StyledComponent
        id="main-stage"
        fullscreen={fullscreen}
        hasMainPaddingTop={hasMainPaddingTop}
        hasMainPaddingRight={hasMainPaddingRight}
        hasMainPaddingBottom={hasMainPaddingBottom}
        hasMainPaddingLeft={hasMainPaddingLeft}
        overflowY="hidden"
        maxHeight="inherit"
      >
        {children}
      </StyledComponent>
    )
  }
)
MainStage.propTypes = {
  hasMainPaddingTop: bool,
  hasMainPaddingRight: bool,
  hasMainPaddingBottom: bool,
  hasMainPaddingLeft: bool,
  fullscreen: bool,
}
