import React, { useEffect, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { getThemeValue } from 'theming'
import { useTheme } from 'styled-components'

import { useLayoutContext } from '@layout/Layout'

import { FlexColumn, FlexRow, GridContainer } from '@layout/BuildingBlocks'
import { GridItem } from '@layout/Grid'
import { MetalineWithText, GreatPrimer, Minion, MetalineWithComponents } from '@typography'
import { ControlsSegment } from '@ui/ControlsSegment'
import { ControlsFold } from '@ui/ControlsSegment/SubComponents'
import { IconWrapper } from '@utilities/IconWrapper'
import { RiwisToken } from '@utilities/SVG'
import { ButtonIcon } from '@ui/Buttons'

import api from 'stores/api'
import useApi from 'stores/useApi'
import { useAuthzChecks } from 'lib/hooks'

import { BudiconCart } from 'bgag-budicons'
import { OrderModal } from './SubComponents/OrderModal'
import { useNumbersFormats } from 'config/numbersFormats'

export const QuotaInfoList = ({ sectionName, folded, toggleFoldedSection }) => {
  const { t } = useTranslation()
  const numbersFormats = useNumbersFormats()
  const [getActiveQuota, activeQuota] = useApi(api.Quota.getUserActiveQuota)
  const [getContactInfo, contactInfo] = useApi(api.Quota.getContactInfo)
  const {
    state: { overlayRightIsOpen },
  } = useLayoutContext()

  const { hasAdminKey } = useAuthzChecks()
  // const [modalActive, setModalActive] = useState(false)

  const hasOrderPermission =
    hasAdminKey('reportsOrderQuota') ||
    hasAdminKey('isAdmin') ||
    hasAdminKey('isSuperAdmin') ||
    hasAdminKey('isSystemAdmin')

  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')
  const quotaListPaddingRight = getThemeValue(theme, 'layout.areaMain.pr')

  const sortedActiveQuota = useMemo(() => {
    if (!activeQuota || !activeQuota?.length) {
      return []
    }
    return activeQuota.sort((a, b) => new Date(a.validTo) - new Date(b.validTo))
  }, [activeQuota])

  const toggleFold = useCallback(() => {
    if (typeof toggleFoldedSection === 'function') {
      toggleFoldedSection(sectionName)
    }
  }, [toggleFoldedSection, sectionName])

  const { activeQuotaSum, nextExpiration } = useMemo(() => {
    if (!activeQuota || !activeQuota.length) {
      return { activeQuotaSum: '-', nextExpiration: null }
    }
    const { sum, total, nextExpiration } = activeQuota.reduce(
      (obj, quota) => {
        obj.nextExpiration =
          obj.nextExpiration === null || new Date(quota.validTo) < obj.nextExpiration
            ? new Date(quota.validTo)
            : obj.nextExpiration
        obj.sum += quota.availableValue
        obj.total += quota.startValue
        return obj
      },
      { sum: 0, total: 0, nextExpiration: null }
    )
    return {
      activeQuotaSum: numbersFormats.roundedFraction0.format(sum),
      activeQuotaTotal: total,
      nextExpiration: nextExpiration,
    }
  }, [activeQuota, numbersFormats])

  const enableQuotaRequest = useMemo(
    () => hasOrderPermission || (!contactInfo?.reportsOrderQuota?.length && !contactInfo?.isAdmin?.length),
    [hasOrderPermission, contactInfo]
  )

  useEffect(() => {
    getActiveQuota()
  }, [getActiveQuota])

  useEffect(() => {
    if (!hasOrderPermission) {
      getContactInfo()
    }
  }, [getContactInfo, hasOrderPermission])

  useEffect(() => {
    if (overlayRightIsOpen === true) {
      getActiveQuota()
    }
  }, [overlayRightIsOpen, getActiveQuota])

  if (!activeQuota) return null

  return (
    <GridContainer gridTemplateColumns="32px 1fr" gridGap={spaces.rhythm.horizontal.large}>
      <GridItem gridColumn="_1_1">
        <IconWrapper
          appearance="bare"
          icon={{
            Icon: RiwisToken,
            width: ['20px', '25px', null, '32px'],
            type: 'outline',
          }}
          iconWrapper={{
            width: ['20px', '25px', null, '32px'],
            height: ['20px', '25px', null, '32px'],
          }}
          size={'medium'}
          paddingTop="8px"
        />
      </GridItem>
      <GridItem gridColumn="_2_1">
        <ControlsSegment folded={folded} controlled={true} width="100%">
          <ControlsSegment.HeaderControlsStart tag="div" width="100%">
            <FlexRow flexColumnGap={spaces.rhythm.horizontal.large} width="100%">
              <MetalineWithText
                align="left"
                metaline={t('profile.quota.tokenBalance')}
                text={activeQuotaSum}
                typeScaleMeta="pica"
                typeScaleText="greatPrimer"
                typeStyleText="semibold"
                metalineTextTransform={'none'}
              />
              <MetalineWithText
                align="left"
                metaline={t('profile.quota.nextExpiration')}
                text={nextExpiration?.toLocaleDateString('de-DE', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}
                typeScaleMeta="pica"
                typeScaleText="greatPrimer"
                typeStyleText="semibold"
                metalineTextTransform={'none'}
              />
              {enableQuotaRequest && (
                <OrderModal>
                  <ButtonIcon
                    appearance="bare"
                    icon={{ Icon: BudiconCart, type: 'solid' }}
                    size="large"
                    shape="shapeless"
                    iconVariantSize="large"
                  />
                </OrderModal>
              )}
            </FlexRow>
          </ControlsSegment.HeaderControlsStart>
          <ControlsSegment.HeaderControls>
            <ControlsFold onClick={toggleFold} />
          </ControlsSegment.HeaderControls>
          <ControlsSegment.Body>
            <MetalineWithComponents
              metaline={t('profile.quota.availableQuotas')}
              typeScaleMeta="pica"
              typeScaleText="greatPrimer"
              typeStyleText="semibold"
              typeStyleMeta="picaLight"
            >
              <FlexColumn
                width="100%"
                flexRowGap={spaces.rhythm.vertical.medium}
                paddingRight={quotaListPaddingRight}
                marginTop={spaces.rhythm.vertical.tiny}
              >
                {sortedActiveQuota.length === 0 ? (
                  <GreatPrimer>-</GreatPrimer>
                ) : (
                  sortedActiveQuota.map((quota) => (
                    <FlexRow key={quota.id} width="100%" justifyContent="space-between" alignItems="center">
                      <GreatPrimer>
                        {quota.availableValue} {t('profile.quota.of')} {quota.startValue}
                      </GreatPrimer>
                      <Minion>
                        {t('profile.quota.valid')}{' '}
                        {new Date(quota.validTo)?.toLocaleDateString('de-DE', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        })}
                      </Minion>
                    </FlexRow>
                  ))
                )}
              </FlexColumn>
            </MetalineWithComponents>
          </ControlsSegment.Body>
        </ControlsSegment>
      </GridItem>
    </GridContainer>
  )
}
