import React, { useEffect, useState, useCallback, memo, forwardRef } from 'react'
import { styled } from 'styled-components'
import { border, color, compose, layout, space, typography } from 'styled-system'

import { isFunction } from 'lib/util'
import { useControllableState, useDebouncedUpdates } from 'lib/hooks'

import { withFormElementProps } from '@forms'

import { appearance, shape, size } from './variants'

import { bool } from 'prop-types'
import { defaultPropTypes as textPropTypes, typeScale, typeStyle } from '@typography'

const excludeProps = [
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'scale',
  'tag',
  'typeScale',
  'typeStyle',
  'stretch',
]

const StyledComponent = styled.textarea.withConfig({
  shouldForwardProp: (prop) => !excludeProps.includes(prop),
})`
  ${compose(border, color, space, layout, typography)}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  ${appearance}
  ${shape}
  ${(props) => props.shape !== 'shapeless' && size}
  ${(props) => props.stretch && { width: '100%' }}
  resize: none;
  padding: 8px;
`

const fontSizeDefault = [0, 1, 2, 3, 4]

export const TextareaComponent = forwardRef(
  (
    {
      value,
      onChange,
      formElementRefs,
      debounce = null,
      typeScale = 'copy',
      typeStyle = 'copyMedium',
      stretch = false,
      maxLength = null,
      appearance = 'lightgray',
      size = 'medium',
      shape = 'square',
      fontFamily = 'whitneySSm',
      fontSize = fontSizeDefault,
      lineHeight = 'normal',
      ...props
    },
    ref
  ) => {
    props = {
      ...props,
      typeScale,
      typeStyle,
      stretch,
      appearance,
      size,
      shape,
      fontFamily,
      fontSize,
      lineHeight,
    }
    const [defaultValue] = useState(props.defaultValue || value || '')

    const onDebouncedUpdate = useCallback(
      (value) => {
        isFunction(onChange) && onChange({ id: props.id, name: props.name, value })
      },
      [onChange, props.id, props.name]
    )

    const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
      value,
      onDebouncedUpdate,
      debounce
    )

    const [localValue, setValue] = useControllableState(
      defaultValue,
      internalValueDebouncer,
      onControllableStateChange
    )

    const updateControllableState = useCallback(
      (evt) => {
        setValue(evt.target.value)
      },
      [setValue]
    )

    const onReset = useCallback(() => {
      setValue(defaultValue || '')
    }, [setValue, defaultValue])

    useEffect(() => {
      if (formElementRefs) {
        formElementRefs.reset.current = onReset
      }
    }, [onReset, formElementRefs])

    useEffect(() => {
      if (formElementRefs) {
        formElementRefs.value.current = localValue
      }
    }, [localValue, formElementRefs])

    return (
      <StyledComponent
        ref={ref}
        maxLength={maxLength}
        {...props}
        value={localValue}
        onChange={updateControllableState}
      />
    )
  }
)
TextareaComponent.type = 'Textarea'
TextareaComponent.propTypes = { ...textPropTypes, stretch: bool }

export const Textarea = withFormElementProps(memo(TextareaComponent))
