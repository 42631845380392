import React from 'react'
import { styled } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { IconWrapper } from '@utilities/IconWrapper'
import { FlexContainer } from '@layout/BuildingBlocks'

import { appearance, shape, size } from './variants'
import { bool, string, func } from 'prop-types'

export const Checkbox = styled.div`
  display: flex;
`

const Container = styled(FlexContainer)`
  cursor: pointer;
  justify-content: center;
  padding: ${(props) => themeGet('switches.padding.container', '0px')(props)};

  ${(props) => props.disabled && { cursor: 'not-allowed' }}
  ${(props) => props.disabled && { '> div': { pointerEvents: 'none' } }}
  ${(props) => props.aspectRatio && { aspectRatio: props.aspectRatio }};

  ${appearance}
  ${shape}
  ${size}
  
  ${(props) => props.badgeWidth && { width: props.badgeWidth }};

  svg {
    fill: ${(props) =>
      props.disabled
        ? themeGet('colors.base.gray.130', 'gray')(props)
        : props.checked
          ? themeGet('colors.base.gray.1000', 'gray')(props)
          : themeGet('colors.base.gray.530', 'gray')(props)};

    stroke: ${(props) =>
      props.disabled
        ? themeGet('colors.base.gray.130', 'gray')(props)
        : props.checked
          ? themeGet('colors.base.gray.1000', 'gray')(props)
          : themeGet('colors.base.gray.530', 'gray')(props)};
  }

  background: ${(props) =>
    props.disabled
      ? 'transparent'
      : props.checked
        ? themeGet('colors.base.lightgray.400', 'gray')(props)
        : 'transparent'};
`

const emptyFunc = () => {}

export const CheckboxIconSolid = ({
  aspectRatio = null,
  appearance = 'light',
  badgeWidth = null,
  checked = false,
  disabled = false,
  handleMouseMove = emptyFunc,
  handleMouseLeave = emptyFunc,
  handleMouseOver = emptyFunc,
  icon,
  iconPosition,
  iconWrapper,
  iconVariantSourceName,
  iconVariantSize,
  id = 'checkbox-id',
  name = 'checkbox-name',
  shape = 'square',
  size = 'small',
}) => {
  return (
    <Container
      aspectRatio={aspectRatio}
      appearance={appearance}
      alignItems="center"
      badgeWidth={badgeWidth}
      checked={checked}
      disabled={disabled}
      onMouseMove={handleMouseMove}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      shape={shape}
      size={size}
    >
      <Checkbox name={name} id={id} />
      <FlexContainer alignItems="center">
        <IconWrapper
          icon={icon}
          iconPosition={iconPosition}
          iconWrapper={iconWrapper}
          iconVariantSourceName={iconVariantSourceName}
          iconVariantSize={iconVariantSize}
        />
      </FlexContainer>
    </Container>
  )
}
CheckboxIconSolid.propTypes = {
  appearance: string,
  aspectRatio: string,
  badgeWidth: string,
  checked: bool,
  disabled: bool,
  handleMouseMove: func,
  handleMouseLeave: func,
  handleMouseOver: func,
  id: string,
  name: string,
  shape: string,
  size: string,
}
