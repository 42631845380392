import React, { useMemo, useEffect, useCallback, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { styled, useTheme } from 'styled-components'
import { compose, typography, layout } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { NavLink, useLocation, useResolvedPath } from 'react-router'

import { getThemeValue } from 'theming'
import { useArrayValueByBreakpoint } from 'lib/hooks'

import { BudiconArrowLeft } from 'bgag-budicons'

import { Pica, typeScale, typeStyle } from '@typography'

import { FlexRow, FlexColumn, FlexItem, PosAbsolute, PosRelative } from '@layout/BuildingBlocks'

import { Modal } from '@ui/Modal'

import { NavigationBadge } from './SubComponents'

const excludeProps = [
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'scale',
  'tag',
  'typeScale',
  'typeStyle',
]

const Container = styled(FlexColumn).withConfig({
  shouldForwardProp: (props) => !['width', 'height'].includes(props),
})`
  padding: ${themeGet('spaces.rhythm.vertical.large', '16px')};
  ${layout};
`

const StyledItem = styled(FlexItem)`
  margin: 0;
  min-width: 200px;
  flex: 1 1 200px;
  border-radius: 8px;

  color: ${themeGet('navigationMain.flyout.colors.parent.default.color', 'gray')};
  background: ${themeGet('colors.base.white', 'white')};

  &:hover {
    background: ${themeGet('navigationMain.flyout.colors.sub.active.bg', 'lightgray')};
    color: ${themeGet('navigationMain.flyout.colors.sub.active.color', 'lightgray')};
  }
`

const StyledLink = styled.a.withConfig({
  shouldForwardProp: (props) => !excludeProps.includes(props),
})`
  cursor: pointer;
  display: block;
  height: 100%;
  padding: 19px 13px 16px 13px;
  position: relative;
  text-decoration: none;
  width: 100%;

  color: ${themeGet('navigationMain.flyout.colors.parent.default.color', 'gray')};
  &:hover {
    color: ${themeGet('navigationMain.flyout.colors.sub.active.color', 'lightgray')};
  }

  ${compose(typography)}

  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
`

const getIndex = (element, startWith) => {
  let index = null
  while (index === null && element !== null) {
    index = element?.id?.startsWith?.(startWith) ? element.id.replace(startWith, '') : null
    element = element?.parentNode ?? null
  }
  return index
}

const ItemWithIcon = ({ label, icon }) => {
  const theme = useTheme()

  const iconColor = getThemeValue(theme, 'navigationMain.flyout.colors.icon')
  const iconLeft = icon.iconPosition?.left || null
  const iconTop = icon.iconPosition?.top || null
  const iconSize = useArrayValueByBreakpoint(icon.size)

  return (
    <FlexRow alignItems="center">
      <PosRelative width="28px">
        <PosAbsolute marginTop={['-10px', null, null, '-12px']} top={iconTop} left={iconLeft}>
          {React.cloneElement(icon.component, {
            color: iconColor,
            size: iconSize,
            strokeWidth: '0.3',
          })}
        </PosAbsolute>
      </PosRelative>
      <FlexItem>{label}</FlexItem>
    </FlexRow>
  )
}

const ItemLink = ({ item, linkProps }) => {
  const theme = useTheme()
  const descriptionColor = getThemeValue(theme, 'navigationMain.flyout.colors.sub.description.color')

  const { label, icon = null, description } = item

  return (
    <StyledLink {...linkProps}>
      {icon !== null ? (
        <ItemWithIcon label={label} icon={icon} />
      ) : (
        <FlexColumn>
          <FlexItem>{label}</FlexItem>
          <FlexItem>
            <Pica marginTop="4px" color={descriptionColor} marginBottom="0px" tag="p">
              {description}
            </Pica>
          </FlexItem>
        </FlexColumn>
      )}
    </StyledLink>
  )
}

const Item = ({ path, item, index, selectParent, closeModal }) => {
  const { route, link, subtree } = item

  const linkProps =
    route && path === route
      ? {
          as: 'div',
          onClick: closeModal,
        }
      : subtree?.length || !(subtree || route || link)
        ? {
            as: 'div',
            onClick: selectParent,
            id: 'mainNavigationParent_' + index,
          }
        : route
          ? {
              as: NavLink,
              to: route,
            }
          : link?.path
            ? {
                rel: 'noopener noreferrer',
                target: '_blank',
                href: link.path,
              }
            : {}

  linkProps.typeScale = 'copy'
  linkProps.typeStyle = 'copy'

  return (
    <StyledItem key={index} item={item}>
      <FlexRow width="100%">
        <ItemLink item={item} linkProps={linkProps} />
      </FlexRow>
    </StyledItem>
  )
}

export const NavigationMainComponentFullscreen = ({ navtree = null }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedParent, setSelectedParent] = useState(null)

  const location = useLocation()
  const resolvedPath = useResolvedPath(location.pathname)

  const refNavigationBadge = useRef()

  const spaces = getThemeValue(theme, 'spaces')

  const label = useMemo(() => {
    const currentParent =
      navtree.find(({ parent }) => {
        return parent.subtree.find((subitem) => {
          const pathToArray = resolvedPath.pathname.split(/\//)
          const appPath = pathToArray.slice(1, 3).join('/')

          return subitem.link.path.startsWith('/' + appPath)
        })
      }) ?? null

    const currentSubtree =
      currentParent === null
        ? null
        : currentParent?.parent?.subtree.find((item) => {
            const pathToArray = resolvedPath.pathname.split(/\//)
            const appPath = pathToArray.slice(1, 3).join('/')

            return item.link.path.includes(appPath)
          })

    const moduleMappings = {
      'market-analysis': 'marketAnalysis',
      makro: 'macro',
    }
    if (currentParent && currentSubtree) {
      return ['features'].includes(currentParent?.parent.label.toLowerCase())
        ? currentSubtree?.label
        : currentParent?.parent.label
    } else {
      const path = resolvedPath.pathname.split('/')
      if (path.length > 1) {
        const module = path[1]
        return t(
          'modules.' +
            (typeof moduleMappings[module] !== 'undefined' ? moduleMappings[module] : module) +
            '.name'
        )
      } else {
        return ''
      }
    }
  }, [navtree, resolvedPath, t])

  const toggleFlyout = useCallback((active) => {
    setIsOpen(active)
  }, [])

  const closeFlyout = useCallback(() => {
    setIsOpen(false)
  }, [])

  useEffect(() => {
    closeFlyout()
  }, [resolvedPath, closeFlyout])

  const selectParent = useCallback((evt) => {
    const index = getIndex(evt.target, 'mainNavigationParent_')
    if (index !== null) {
      setSelectedParent(index)
    }
  }, [])

  const backItem = useMemo(
    () => ({
      label: t('actions.actionPrevious'),
      icon: {
        component: <BudiconArrowLeft />,
        color: 'colors.theme.navigationMain.parent.icon.foreground.default',
        iconPosition: {
          left: ['1px', null, null, '0px'],
          top: [null, null, null, '3px'],
        },
        size: ['19px', null, null, '20px'],
      },
    }),
    [t]
  )

  const renderItems = useMemo(() => {
    if (selectedParent) {
      return navtree[selectedParent].parent.subtree.map((item) => ({
        ...item,
        route: item.link.path,
      }))
    } else {
      return navtree.map((item) => item.parent)
    }
  }, [navtree, selectedParent])

  return (
    <Modal scrollable={true} active={isOpen} onChangeActive={toggleFlyout}>
      <Modal.Trigger>
        <NavigationBadge ref={refNavigationBadge} isOpen={isOpen} label={label} />
      </Modal.Trigger>
      <Modal.View size="fullscreen">
        <Modal.Section>
          <Container height="100%" width="100%" flexRowGap={spaces.rhythm.vertical.medium}>
            {selectedParent !== null && (
              <FlexRow
                flexWrap="wrap"
                flexRowGap={spaces.rhythm.vertical.medium}
                flexColumnGap={spaces.rhythm.vertical.large}
              >
                <Item item={backItem} index="0.0" selectParent={() => setSelectedParent(null)} />
              </FlexRow>
            )}
            <FlexRow
              flexWrap="wrap"
              flexRowGap={spaces.rhythm.vertical.medium}
              flexColumnGap={spaces.rhythm.vertical.large}
            >
              {renderItems.map((item, index) => (
                <Item
                  path={resolvedPath.pathname}
                  item={item}
                  index={index}
                  key={item.route ?? index}
                  selectParent={selectParent}
                  closeModal={closeFlyout}
                />
              ))}
            </FlexRow>
          </Container>
        </Modal.Section>
        <Modal.Footer></Modal.Footer>
      </Modal.View>
    </Modal>
  )
}
