import React, { memo, useEffect, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexColumn, FlexCenter, FlexRow } from '@layout/BuildingBlocks'
import { Minion } from '@typography'
import { Badge } from '@utilities/Badge'
import { FormElement, withFormElementProps, DropdownSelect, InputNumber } from '@forms'

import { isFunction } from 'lib/util'
import { useDebouncedUpdates, useControllableState } from 'lib/hooks'

import { defaultPropTypes } from './defaultPropTypes'

const quartersList = [
  { label: 'Q1', value: 1, asStringFrom: '01-01', asStringTo: '04-01' },
  { label: 'Q2', value: 2, asStringFrom: '04-01', asStringTo: '07-01' },
  { label: 'Q3', value: 3, asStringFrom: '07-01', asStringTo: '10-01' },
  { label: 'Q4', value: 4, asStringFrom: '10-01', asStringTo: '01-01' },
]

const quarterDateAsString = (quarter, year, direction = 'from') => {
  let stringDate = ''
  const stringDateKey = direction === 'from' ? 'asStringFrom' : 'asStringTo'
  const quarterMap = quartersList.find((item) => item.value === quarter)
  const yearModified =
    Number(year) && direction === 'to' && (quarter === 4 || !quarterMap) ? parseInt(year) + 1 : year
  console.log(yearModified)
  if (quarterMap) {
    stringDate = `${yearModified}-${quarterMap[stringDateKey]}`
  } else {
    const index = direction === 'from' ? 0 : 3
    stringDate = `${yearModified}-${quartersList[index][stringDateKey]}`
  }

  return stringDate
}

const quickSelectOptions = [
  { value: 'nowQuarter', timeStage: 'present' },
  { value: 'nowYear', timeStage: 'present' },
  { value: 'lastFourQuarters', timeStage: 'past' },
  { value: 'nextFourQuarters', timeStage: 'future' },
  { value: 'lastThreeYears', timeStage: 'past' },
  { value: 'nextThreeYears', timeStage: 'future' },
]

const checkQuarterRanges = (state) => {
  const fromQuarter = state.value.fromQuarter.value
  const fromYear = state.value.fromYear.value
  const toQuarter = state.value.toQuarter.value
  const toYear = state.value.toYear.value
  const compareQuarters = fromQuarter < toQuarter ? 1 : fromQuarter === toQuarter ? 0 : -1
  const compareYears = fromYear < toYear ? 1 : fromYear === toYear ? 0 : -1
  const compareDates = compareYears === 1 ? 1 : compareYears === 0 ? compareQuarters : -1

  if (compareDates === -1) {
    if (state?.changeEmitter?.name === 'fromQuarter') {
      if (compareQuarters === -1) {
        state.value.toQuarter.value = fromQuarter
      }
      state.value.toYear.value = fromYear
    }
    if (state?.changeEmitter?.name === 'toQuarter') {
      if (compareQuarters === -1) {
        state.value.fromQuarter.value = toQuarter
      }
      state.value.fromYear.value = toYear
    }
  }
}

const computeQuickSelectedOption = (option) => {
  const now = new Date()
  const mNow = now.getMonth() + 1
  const qNow = Math.ceil(mNow / 3)
  const yNow = now.getFullYear()

  switch (option) {
    case 'nowQuarter': {
      return {
        quickSelect: option,
        from: { quarter: qNow, year: yNow, asString: quarterDateAsString(qNow, yNow, 'from') },
        to: { quarter: qNow, year: yNow, asString: quarterDateAsString(qNow, yNow, 'to') },
      }
    }
    case 'nowYear': {
      return {
        quickSelect: option,
        from: { quarter: 1, year: yNow, asString: quarterDateAsString(1, yNow, 'from') },
        to: { quarter: 4, year: yNow, asString: quarterDateAsString(4, yNow, 'to') },
      }
    }
    case 'lastFourQuarters': {
      const qLast = qNow - 3
      const fromQuarter = qLast <= 0 ? qLast + 4 : qLast
      const fromYear = qLast <= 0 ? yNow - 1 : yNow
      return {
        quickSelect: option,
        from: {
          quarter: fromQuarter,
          year: fromYear,
          asString: quarterDateAsString(fromQuarter, fromYear, 'from'),
        },
        to: { quarter: qNow, year: yNow, asString: quarterDateAsString(qNow, yNow, 'to') },
      }
    }
    case 'nextFourQuarters': {
      const qNext = qNow + 3
      const toQuarter = qNext > 4 ? qNext - 4 : qNext
      const toYear = qNext > 4 ? yNow + 1 : yNow
      return {
        quickSelect: option,
        from: { quarter: qNow, year: yNow, asString: quarterDateAsString(qNow, yNow, 'from') },
        to: {
          quarter: toQuarter,
          year: toYear,
          asString: quarterDateAsString(toQuarter, toYear, 'to'),
        },
      }
    }
    case 'lastThreeYears': {
      return {
        quickSelect: option,
        from: { quarter: 1, year: yNow - 3, asString: quarterDateAsString(1, yNow - 3, 'from') },
        to: { quarter: 4, year: yNow, asString: quarterDateAsString(4, yNow, 'to') },
      }
    }
    case 'nextThreeYears': {
      return {
        quickSelect: option,
        from: { quarter: 1, year: yNow, asString: quarterDateAsString(1, yNow, 'from') },
        to: { quarter: 4, year: yNow + 3, asString: quarterDateAsString(4, yNow + 3, 'to') },
      }
    }
    default: {
      return {
        quickSelect: option,
        from: { quarter: '', year: null, asString: '' },
        to: { quarter: '', year: null, asString: '' },
      }
    }
  }
}

const groupStateToValue = (state) => {
  const {
    quickSelect: { value: quickSelect = null },
    fromQuarter: { value: fromQuarter = '' },
    fromYear: { value: fromYear = null },
    toQuarter: { value: toQuarter = '' },
    toYear: { value: toYear = null },
  } = state.value

  const newValue = {
    quickSelect,
    from: {
      quarter: fromQuarter > 0 ? fromQuarter : null,
      year: fromYear,
      asString: quarterDateAsString(fromQuarter, fromYear, 'from'),
    },
    to: {
      quarter: toQuarter > 0 ? toQuarter : null,
      year: toYear,
      asString: quarterDateAsString(toQuarter, toYear, 'to'),
    },
  }

  return newValue
}

const valueToGroupState = (value) => {
  const newState = {
    quickSelect: { value: value?.quickSelect ?? null },
    fromQuarter: { value: value.from.quarter ?? -1 },
    fromYear: { value: value.from.year },
    toQuarter: { value: value.to.quarter ?? -1 },
    toYear: { value: value.to.year },
  }
  return newState
}

const defaultValueProp = { from: { quarter: null, year: null }, to: { quarter: null, year: null } }
export const QuarterRangeComponent = ({
  name = 'QuarterRangeComponent',
  defaultValue = defaultValueProp,
  debounce = 200,
  appearance = 'white',
  shape = 'square',
  size = 'medium',
  formElementRefs,
  onChange,
  value,
  filterQuickSelect,
  filterTimeStage = [],
  filterTimeStageNotIn = false,
  ...props
}) => {
  const { t } = useTranslation()
  const [initialValue] = useState(defaultValue ?? value ?? [])

  const onDebouncedUpdate = useCallback(
    (value) => {
      isFunction(onChange) && onChange({ id: props.id, name, value })
    },
    [onChange, props.id, name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [internalValue, setInternalValue] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const translatedQuickSelect = useMemo(() => {
    let options = quickSelectOptions.map((opt) => ({
      ...opt,
      label: t(`datepicker.${opt.value}`),
    }))
    if (typeof filterQuickSelect === 'function') {
      options = options.filter(filterQuickSelect)
    }

    if (filterTimeStage && filterTimeStage.length > 0) {
      options = options.filter((option) => {
        return filterTimeStageNotIn
          ? !option.timeStage.includes(filterTimeStage)
          : option.timeStage.includes(filterTimeStage)
      })
    }

    return options
  }, [filterQuickSelect, t, filterTimeStage, filterTimeStageNotIn])

  const formState = useMemo(() => valueToGroupState(internalValue), [internalValue])

  const internalOnChange = useCallback(
    (target) => {
      if (
        target?.changeEmitter?.name === 'quickSelect' &&
        target?.changeEmitter?.value?.value !== null &&
        target?.changeEmitter?.value?.value !== ''
      ) {
        const newRange = computeQuickSelectedOption(target?.changeEmitter?.value?.value)
        setInternalValue(newRange)
      } else {
        const nextState = { ...target }
        nextState.value.quickSelect = { value: null }
        if (
          nextState?.changeEmitter?.name === 'fromQuarter' ||
          nextState?.changeEmitter?.name === 'toQuarter'
        ) {
          checkQuarterRanges(nextState)
        }
        setInternalValue(groupStateToValue(nextState))
      }
    },
    [setInternalValue]
  )

  const onReset = useCallback(() => {
    setInternalValue(initialValue)
  }, [setInternalValue, initialValue])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = internalValue
    }
  }, [internalValue, formElementRefs])

  return (
    <FormElement {...props} name={name} value={formState} onChange={internalOnChange}>
      <FlexColumn alignItems="stretch" flexRowGap="8px">
        <DropdownSelect
          appearance={appearance}
          size={size}
          shape={shape}
          items={translatedQuickSelect}
          forElementName={name}
          name="quickSelect"
          placeholder={t('datepicker.placeholder')}
        />

        <Badge appearance="bare" shape="shapeless">
          <Badge.Content>
            <FlexRow alignItems="center" flexColumnGap="4px">
              <DropdownSelect
                appearance="white"
                size="tiny"
                shape="square"
                items={quartersList}
                forElementName={name}
                name="fromQuarter"
                optionsSize="small"
                placeholder="QQ"
              />

              <InputNumber
                appearance="white"
                size="tiny"
                shape="square"
                width="100%"
                border="noBorder"
                forElementName={name}
                name="fromYear"
                placeholder="YYYY"
              />
              <FlexCenter height="10px">
                <Minion>{t('forms.placeholder.quarterRangeTo')}</Minion>
              </FlexCenter>
              <DropdownSelect
                appearance="white"
                size="tiny"
                shape="square"
                items={quartersList}
                forElementName={name}
                name="toQuarter"
                optionsSize="small"
                placeholder="QQ"
              />

              <InputNumber
                appearance="white"
                size="tiny"
                shape="square"
                width="100%"
                border="noBorder"
                forElementName={name}
                name="toYear"
                placeholder="YYYY"
              />
            </FlexRow>
          </Badge.Content>
        </Badge>
      </FlexColumn>
    </FormElement>
  )
}

QuarterRangeComponent.type = 'QuarterRange'
QuarterRangeComponent.propTypes = defaultPropTypes

export const QuarterRange = withFormElementProps(memo(QuarterRangeComponent))
