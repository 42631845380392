import React, { useRef, forwardRef, useLayoutEffect } from 'react'
import { styled } from 'styled-components'
import { typography } from 'styled-system'
import { string, bool } from 'prop-types'

import { HTMLAnchorElementWithIcon, ReactRouterDomLinkWithIcon } from '../SubComponents'
import { IconWrapper } from '@utilities/IconWrapper'

import { appearance, shape, size } from './variants'
import { typeMap, typeScale, typeStyle } from '@typography'

const StyledButton = styled(HTMLAnchorElementWithIcon)`
  border: 0;
  padding: 0;
  background: none;
  ${appearance}
  ${shape}
`

const StyledComponent = styled.span.withConfig({
  shouldForwardProp: (prop) => ['style', 'children', 'title'].includes(prop),
})`
  align-self: center;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  &::after {
    content: '0';
    width: 0;
    max-width: 0;
    overflow: hidden;
    visibility: hidden;
  }
  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  ${(props) => props.shape !== 'shapeless' && size}
  ${(props) => props.width && { width: props.width }}
  ${(props) => props.height && { height: props.height }}
`

const fontSizeDefault = [0, 1, 2, 3, 4]

export const ButtonLinkIcon = forwardRef(
  (
    {
      children,

      fontFamily = 'whitneySSm',
      fontSize = fontSizeDefault,
      lineHeight = 'normal',
      appearance = 'lightgray',
      equalSides = true,
      href = null,
      iconAlign = 'left',
      iconVariantSourceName = 'buttonIcons',
      iconVariantSize = 'medium',
      linkType = 'htmlAnchorElement',
      size = 'medium',
      shape = 'oval',
      to = null,
      ...props
    },
    ref
  ) => {
    props = {
      ...props,
      fontFamily,
      fontSize,
      lineHeight,
      appearance,
      equalSides,
      href,
      iconAlign,
      iconVariantSourceName,
      iconVariantSize,
      linkType,
      size,
      shape,
      to,
    }
    const { typeStyle, typeScale } = typeMap[props.size]
    const innerRef = useRef()

    useLayoutEffect(() => {
      if (props.equalSides) {
        const height = window.getComputedStyle(innerRef.current).height
        innerRef.current.style.width = height
      } else {
        innerRef.current.style.width = 'unset'
      }
    }, [innerRef, props.equalSides])

    ButtonLinkIcon.propTypes = {
      appearance: string,
      equalSides: bool,
      href: string,
      iconAlign: string,
      iconVariantSourceName: string,
      iconVariantSize: string,
      linkType: string,
      shape: string,
      size: string,
      to: string,
    }
    return props.linkType === 'htmlAnchorElement' ? (
      <StyledButton {...props} ref={ref}>
        <StyledComponent {...props} typeScale={typeScale} typeStyle={typeStyle} ref={innerRef}>
          <IconWrapper
            icon={props.icon}
            iconPosition={props.iconPosition}
            iconVariantSourceName={props.iconVariantSourceName}
            height={props.iconHeight}
            size={props.size}
            width={props.iconWidth}
          />
        </StyledComponent>
      </StyledButton>
    ) : (
      <StyledButton {...props} ref={ref} as={ReactRouterDomLinkWithIcon}>
        <StyledComponent typeScale={typeScale} typeStyle={typeStyle} ref={innerRef} {...props}>
          <IconWrapper
            icon={props.icon}
            iconPosition={props.iconPosition}
            iconWrapper={props.iconWrapper}
            iconVariantSourceName={props.iconVariantSourceName}
            iconVariantSize={props.iconVariantSize}
          />
        </StyledComponent>
      </StyledButton>
    )
  }
)
