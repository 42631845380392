import React, { memo } from 'react'
import { styled, useTheme } from 'styled-components'
import { layout, space, color, border } from 'styled-system'

import { FlexRow } from '@layout/BuildingBlocks'
import { SubHeaderControls, SubHeaderFullwidth, SubHeaderMain } from '@layout/SubHeader/SubComponents'

import { getThemeValue } from 'theming'
import { useSlots } from 'lib/hooks/useSlots'
import { useLayoutContext } from '@layout/Layout'

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => ['style', 'children'].includes(prop),
})`
  display: block;
  ${layout};
  ${color};
  ${space};
  ${border};
`

export const SubHeader = memo(({ children }) => {
  const theme = useTheme()

  const {
    state: { controlsPanelIsOpen },
  } = useLayoutContext()

  const height = getThemeValue(theme, 'layout.subheader.height')
  const pageMarginX = getThemeValue(theme, 'layout.page.mx')
  const marginTop = getThemeValue(theme, 'layout.subheader.mt')
  const marginBottom = getThemeValue(theme, 'layout.subheader.mb')
  const widthSubHeaderControls = controlsPanelIsOpen ? getThemeValue(theme, 'controlsPanel.width') : 'auto'
  const positionSubHeaderMain = controlsPanelIsOpen
    ? getThemeValue(theme, 'layout.subheader.positionMain')
    : getThemeValue(theme, 'spaces.rhythm.horizontal.extraLarge')

  const [subHeaderControls, subHeaderFullwidth, subHeaderMain] = useSlots(
    ['subHeaderControls', 'subHeaderFullwidth', 'subHeaderMain'],
    children
  )

  return (
    <Container
      height={height}
      minHeight={height}
      marginTop={marginTop}
      marginBottom={marginBottom}
      mx={pageMarginX}
    >
      {(subHeaderControls || subHeaderMain) && (
        <FlexRow alignItems="center" height="100%" justifyContent="space-between" width="100%">
          <FlexRow
            alignItems="center"
            height="100%"
            justifyContent="center"
            minWidth={widthSubHeaderControls}
            width={widthSubHeaderControls}
          >
            {subHeaderControls && <SubHeaderControls>{subHeaderControls}</SubHeaderControls>}
          </FlexRow>
          <FlexRow
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
            marginLeft={positionSubHeaderMain}
            minWidth="0%"
          >
            {subHeaderMain && <SubHeaderMain>{subHeaderMain.props.children}</SubHeaderMain>}
          </FlexRow>
        </FlexRow>
      )}
      {subHeaderFullwidth && (
        <FlexRow alignItems="center" height="100%" justifyContent="space-between" width="100%">
          <SubHeaderFullwidth>{subHeaderFullwidth.props.children}</SubHeaderFullwidth>
        </FlexRow>
      )}
    </Container>
  )
})

SubHeader.Controls = ({ children }) => children
SubHeader.Controls.displayName = 'subHeaderControls'

SubHeader.Main = ({ children }) => children
SubHeader.Main.displayName = 'subHeaderMain'

SubHeader.Fullwidth = ({ children }) => children
SubHeader.Fullwidth.displayName = 'subHeaderFullwidth'
