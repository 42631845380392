import React, { createRef } from 'react'

import { StyledText } from './SubComponents'
import { useTamingLineheight } from './hooks/useTamingLineheight'

import { string } from 'prop-types'
import { defaultPropTypes as textPropTypes } from '@typography'

const typeRowSpan = {
  minion: 2.5,
  pica: 2.8,
  copy: 3.5,
  greatPrimer: 4.5,
  canon: 5,
  royal: 7,
  imperial: 12.5,
}
const fontSizeDefault = [0, 1, 2, 3, 4]

export const TamedText = ({
  fontFamily = 'whitneySSm',
  fontSize = fontSizeDefault,
  text = 'Lorem Ipsum',
  typeScale = 'copy',
  typeStyle = 'medium',
  fontWeight,
}) => {
  const ref = createRef()
  const { lineHeight, typeOffset, topSpace, heightCorrection, preventCollapse } = useTamingLineheight(
    ref,
    typeRowSpan[typeScale]
  )

  return (
    <StyledText
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      heightCorrection={heightCorrection}
      lineHeight={lineHeight}
      preventCollapse={preventCollapse}
      typeScale={typeScale}
      typeStyle={typeStyle}
      typeOffset={typeOffset}
      topSpace={topSpace}
      ref={ref}
    >
      {text}
    </StyledText>
  )
}
TamedText.propTypes = {
  ...textPropTypes,
  text: string,
  typeScale: string,
  typeStyle: string,
}
